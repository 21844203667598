import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  blogs: [],
  blog: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getBlogs: (state) => {
    return state.blogs;
  },
  getBlog: (state) => {
    return state.blog;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_BLOGS: (state, data) => (state.blogs = data),
  SET_BLOG: (state, data) => (state.blog = data),
};

const actions = {
  async fetchBlogs({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("blogs");
      commit("SET_BLOGS", response.data.blogs);
    } catch (error) {
      console.log(error);
      commit("SET_BLOGS", {});
    }
    commit("SET_LOADER", false);
  },
  async createBlog({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("blogs", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateBlog({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`/blogs/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteBlogById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`blogs/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
