<template>
  <div class="Quotation-detail">
    <div class="card">
      <div class="card-header header-elements-inline border-bottom">
        <h6 class="card-title">Quotation Details</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
          >
            <b><i class="icon-pencil mr-2"></i></b> Edit Quotations
          </button>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Create RMA
          </button>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center mb-2">
          <div class="col">
            <table class="table table-sm table-borderless">
              <tbody>
                <tr>
                  <td>Order ID</td>
                  <td>{{ getQuotationDetail?.quote?.Ref }}</td>
                  <td>Payment Status</td>
                  <td>
                    <div class="badge badge-success">
                      Paid
                    </div>
                  </td>
                  <td>Sales Channel</td>
                  <td>Sales 1</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{{ getQuotationDetail.quote?.client_name }}</td>
                  <td>Status Order</td>
                  <td>
                    <div class="badge badge-success">
                      Paid
                    </div>
                  </td>
                  <td>IP Address</td>
                  <td>192.168.1.202</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ getQuotationDetail.quote?.client_email }}</td>
                  <td>Sales Person</td>
                  <td>Rival</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{{ getQuotationDetail.quote?.client_phone }}</td>
                  <td>Last Operator</td>
                  <td>Admin</td>
                </tr>
                  <tr>
                    <td>Company</td>
                    <td>{{ getQuotationDetail.company.CompanyName }}</td>
                    <td>Date</td>
                    <td>{{ getQuotationDetail.quote?.date }}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title m-n2" data-toggle="collapse"  href="#billing" data-target="#billing" style="cursor: pointer;">Billing Address</h6>
                <table class="table table-sm table-borderless" id="billing">
                  <tbody>
                    <tr>
                      <td>Order ID</td>
                      <td>#{{ getQuotationDetail?.quote?.Ref }}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{{ getQuotationDetail?.quote?.client.name }}</td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>{{ getQuotationDetail?.company?.CompanyName }}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{ getQuotationDetail?.quote?.client.adresse }}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{{ getQuotationDetail?.quote?.client.city }}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{{ getQuotationDetail?.quote?.client.country }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{{ getQuotationDetail?.quote?.client.phone }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ getQuotationDetail?.quote?.client.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title m-n2" data-toggle="collapse"  href="#shipping" data-target="#shipping" style="cursor: pointer;">Shipping Address</h6>
                <table class="table table-sm table-borderless" id="shipping">
                  <tbody>
                    <tr>
                      <td>Date</td>
                      <td>{{ getQuotationDetail?.quote?.date }}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{{ getQuotationDetail?.quote?.client.name }}</td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>{{ getQuotationDetail?.company?.CompanyName }}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{ getQuotationDetail?.quote?.client.adresse }}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{{ getQuotationDetail?.quote?.client.city }}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{{ getQuotationDetail?.quote?.client.country }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{{ getQuotationDetail?.quote?.client.phone }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ getQuotationDetail?.quote?.client.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
          <div class="row">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Qty</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Disc</th>
                    <th scope="col">Coupon</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(quote, index) in getQuotationDetail?.details" :key="quote?.code">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ quote?.quantity }}</td>
                    <td>ISB-B600-299</td>
                    <td>{{ quote?.name }}</td>
                    <td>{{ quote?.price }}</td>
                    <td>{{ quote?.discount }}</td>
                    <td>0</td>
                    <td>{{ quote?.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row p-4">
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h6 class="card-title">Shipping</h6>
                  <table class="table table-sm table-borderless">
                    <tbody>
                      <tr>
                        <td>Actual Weight</td>
                        <td>10kg</td>
                      </tr>
                      <tr>
                        <td>Shipping Method</td>
                        <td>Lion Parcel</td>
                      </tr>
                      <tr>
                        <td>Delivery Cost</td>
                        <td>$10</td>
                      </tr>
                      <tr>
                        <td>Delivery Discount</td>
                        <td>$0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <table class="table table-sm">
                    <tbody>
                      <tr>
                        <td>Product Subtotal</td>
                        <td>${{ getProductSubtotal }}</td>
                      </tr>
                      <tr>
                        <td>Tax (10%)</td>
                        <td>${{ getProductTax }}</td>
                      </tr>
                      <tr>
                        <td>Shipping</td>
                        <td>${{ getQuotationDetail.quote?.shipping }}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>${{ getQuotationDetail.quote?.discount }}</td>
                      </tr>
                      <tr>
                        <td>Grand Total</td>
                        <td>${{ getQuotationDetail.quote?.GrandTotal }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label>Notes</label>
                <textarea
                  rows="2"
                  cols="3"
                  class="form-control"
                  placeholder="Notes"
                >
                </textarea
                >
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body p-n2">
              <button type="button" class="btn btn-primary btn-sm float-right" @click="deleteQuotation()">
                <i class="icon-trash"></i>
                Save Changes
              </button>
            </div>
          </div>
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-link active" id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-selected="true">Payment</a>
              <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Payment & Refund History </a>
              <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">History</a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label>Card Number</label>
                    <input
                      type="text"
                      placeholder="Card number"
                      class="form-control"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Expiration</label>
                    <input
                      type="text"
                      placeholder="Expiration"
                      class="form-control"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>
                  <div class="col-sm-6">
                    <label>CVC</label>
                    <input
                      type="text"
                      placeholder="CVC"
                      class="form-control"
                    />
                    <span v-if="errors.ShortName" class="form-text text-danger">{{ errors.ShortName[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Amount</label>
                    <input
                      type="text"
                      placeholder="Amount"
                      class="form-control"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>
                  <div class="col-sm-6">
                    <label>Notes</label>
                    <input
                      type="text"
                      placeholder="Notes"
                      class="form-control"
                    />
                    <span v-if="errors.ShortName" class="form-text text-danger">{{ errors.ShortName[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="float-right m-3">
                <button type="button" class="btn btn-link" data-dismiss="modal">
                  Close
                </button>
                <button type="submit" class="btn btn-primary" @click="modal == 0 ? addUnit() : editUnitSubmit()">
                  Submit <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...a</div>
            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
          </div>
        </div>
      <!-- <div class="row d-flex justify-content-center align-items-center mb-2">
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold" v-if="data.firstname">Full name:</span>
              <div class="ml-auto">
                {{ getQuotationDetail.firstname }} {{ getQuotationDetail.lastname }}
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Username</span>
              <div class="ml-auto">{{ getQuotationDetail.username }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Email</span>
              <div class="ml-auto">{{ getQuotationDetail.email }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Phone Number</span>
              <div class="ml-auto">{{ getQuotationDetail.phone }}</div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getQuotationDetail.address">{{ getQuotationDetail.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getQuotationDetail.address">{{ getQuotationDetail.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getQuotationDetail.address">{{ getQuotationDetail.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Actions</span>
              <div class="ml-auto">
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditQuotation"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm" @click="deleteQuotation()">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'

export default {
  data() {
    return {
      errors: [],
      data: {
        id: null,
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        phone: null,
        phone_2: null,
        address: null,
        password: null,
        role_id: null,
        permission_id: [],
        statut: null,
      },
    };
  },
  created() {
    this.quotationById({ id: this.$route.params.id });
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-Quotation')) {
      $(".table-Quotation").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 400,
          },
          {
            orderable: false,
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-excel"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
          {
            extend: "pdfHtml5",
            text: '<i class="icon-printer"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Anytime;
    Summernote;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".select-role").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".select-status").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    if (!$("#begin_time")) {
      $("#begin_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    if (!$("#begin_time")) {
      $("#end_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".summernote").summernote();
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  computed: {
    ...mapGetters({
      getQuotationDetail: "quotations/getQuotation",
    }),
    getProductSubtotal() {
      var subtotal = 0;
      this.getQuotationDetail.details.map(item => {
        subtotal += item.total
      })
      return subtotal;
    },
    getProductTax() {
      var tax = 0;
      this.getQuotationDetail.details.map(item => {
        tax += item.taxe
      })
      return tax;
    },
  },
  methods: {
    ...mapActions({
      quotationById: "quotations/quotationById",
    }),
    selectedPermissions(id) {
      this.data.permission_id.includes(id) ? this.data.permission_id = this.data.permission_id.filter((x) => x != id) : this.data.permission_id.push(id)
    },
    updateQuotation() {
      this.editQuotation({id: this.$route.params.id, data: this.data }).then(() => {
        util.showToast('Quotation successfully changed');
        $('#EditQuotation').modal('hide');
        this.fetchQuotation()
      }).catch(err => {
        this.errors = err.response?.data.errors;
      })
    },
    async deleteQuotation(id) {
      const action = () => {
        this.deleteQuotationById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchQuotation()
    },
    async exportTimesheet() {
      await ApiService.get("/Quotation/exportExcel", {
        'headers': {
          'Content-Disposition': "attachment; filename=TimeSheets.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TimeSheets.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  watch: {
    'data.statut' : function(data, prev) {
      if (prev != null) {
        util.showToast('Status successfully changed');
        this.editQuotationStatus({id: this.$route.params.id, data })
      }
    },
    'data.role_id' : function(data, prev) {
      if (prev != null) {
        util.showToast('Role successfully changed');
        this.editQuotation({id: this.$route.params.id, data: this.data })
      }
    }
  }
};
</script>
