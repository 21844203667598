
import { defineComponent } from "@vue/runtime-core"
import { useRoute } from "vue-router"
import { StorageService } from "@/services/storage.service";

export default defineComponent({
  name: "TheSidebar",
  setup(){
    const route = useRoute()
    const isMenuActive = (match: String, index: number = 0): Boolean => {
      if (typeof route.matched[index] === 'undefined') {
        return false
      } else {
        return Boolean(route.matched[index].path == match)
      }
    }
    const user = StorageService.getUser();
    const toggleMenu = ($event: any) => {
      // console.log($event);
      // $event.target.parentElement.classList.toggle('nav-item-open')
      $event.target.parentElement.classList.toggle('nav-item-expanded')
    }
    return {
      isMenuActive,
      toggleMenu,
      user,
    }
  }
})
