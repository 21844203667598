import util from '@/helpers/util.js'
import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  suppliers: [],
  supplier: {},
  client: {},
  departement: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getSuppliers: (state) => {
    return state.suppliers;
  },
  getSupplier: (state) => {
    return state.supplier;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_SUPPLIERS: (state, data) => (state.suppliers = data),
  SET_SUPPLIER: (state, data) => (state.supplier = data),
};

const actions = {
  async fetchSuppliers({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/provider/list-supplier?limit=10&SortField=id&SortType=desc&search"
      );
      commit("SET_SUPPLIERS", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_SUPPLIERS", {});
    }
    commit("SET_LOADER", false);
  },
  async supplierById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.post(
        "/provider/detail-supplier?ref_id=" + id
      );
      console.log(response.data.data)
      commit("SET_SUPPLIER", response.data.data);
    } catch (error) {
      console.log(error);
      commit("SET_SUPPLIER", {});
    }
    commit("SET_LOADER", false);
  },
  async createSupplier({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("/provider/add-supplier", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateSupplier({ commit }, { id, data }) {
    let response = await ApiService.post("/provider/edit-supplier/" + id, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async editSupplierStatus({ commit }, { id, url }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post(
        `/tiket/${url}/${id}`
      );
      util.showToast('Successfully Update Supplier Status')
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
  async deleteSupplierById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/provider/delete-supplier/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
