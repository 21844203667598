<template>
  <!-- Main navbar -->
  <div class="navbar navbar-expand-md navbar-dark navbar-static noprint">
    <div class="navbar-brand">
      <a href="index.html" class="d-inline-block">
        <img src="@/assets/images/logo_invoice.png" />
      </a>
    </div>

    <div class="d-md-none">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-mobile"
      >
        <i class="icon-tree5"></i>
      </button>
      <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
        <i class="icon-paragraph-justify3"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            href="#"
            class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
          >
            <i class="icon-paragraph-justify3"></i>
          </a>
        </li>
      </ul>

      <span class="navbar-text ml-md-3">
        <span class="badge badge-mark border-orange-300 mr-2"></span>
        Hello, {{ user?.first_name }}!
      </span>

      <ul class="navbar-nav ml-md-auto">
        <!-- <li class="nav-item dropdown">
          <a
            href="#"
            class="navbar-nav-link dropdown-toggle"
            data-toggle="dropdown"
          >
            <i class="icon-make-group mr-2"></i>
            Connect
          </a>

          <div
            class="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350"
          >
            <div class="dropdown-content-body p-2">
              <div class="row no-gutters">
                <div class="col-12 col-sm-4">
                  <a
                    href="#"
                    class="d-block text-default text-center ripple-dark rounded p-3"
                  >
                    <i class="icon-github4 icon-2x"></i>
                    <div
                      class="font-size-sm font-weight-semibold text-uppercase mt-2"
                    >
                      Github
                    </div>
                  </a>

                  <a
                    href="#"
                    class="d-block text-default text-center ripple-dark rounded p-3"
                  >
                    <i class="icon-dropbox text-blue-400 icon-2x"></i>
                    <div
                      class="font-size-sm font-weight-semibold text-uppercase mt-2"
                    >
                      Dropbox
                    </div>
                  </a>
                </div>

                <div class="col-12 col-sm-4">
                  <a
                    href="#"
                    class="d-block text-default text-center ripple-dark rounded p-3"
                  >
                    <i class="icon-dribbble3 text-pink-400 icon-2x"></i>
                    <div
                      class="font-size-sm font-weight-semibold text-uppercase mt-2"
                    >
                      Dribbble
                    </div>
                  </a>

                  <a
                    href="#"
                    class="d-block text-default text-center ripple-dark rounded p-3"
                  >
                    <i class="icon-google-drive text-success-400 icon-2x"></i>
                    <div
                      class="font-size-sm font-weight-semibold text-uppercase mt-2"
                    >
                      Drive
                    </div>
                  </a>
                </div>

                <div class="col-12 col-sm-4">
                  <a
                    href="#"
                    class="d-block text-default text-center ripple-dark rounded p-3"
                  >
                    <i class="icon-twitter text-info-400 icon-2x"></i>
                    <div
                      class="font-size-sm font-weight-semibold text-uppercase mt-2"
                    >
                      Twitter
                    </div>
                  </a>

                  <a
                    href="#"
                    class="d-block text-default text-center ripple-dark rounded p-3"
                  >
                    <i class="icon-youtube text-danger icon-2x"></i>
                    <div
                      class="font-size-sm font-weight-semibold text-uppercase mt-2"
                    >
                      Youtube
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a
            href="#"
            class="navbar-nav-link dropdown-toggle"
            data-toggle="dropdown"
          >
            <i class="icon-pulse2 mr-2"></i>
            Activity
          </a>

          <div
            class="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350"
          >
            <div class="dropdown-content-header">
              <span
                class="font-size-sm line-height-sm text-uppercase font-weight-semibold"
                >Latest activity</span
              >
              <a href="#" class="text-default"
                ><i class="icon-search4 font-size-base"></i
              ></a>
            </div>

            <div class="dropdown-content-body dropdown-scrollable">
              <ul class="media-list">
                <li class="media">
                  <div class="mr-3">
                    <a
                      href="#"
                      class="btn bg-success-400 rounded-round btn-icon"
                      ><i class="icon-mention"></i
                    ></a>
                  </div>

                  <div class="media-body">
                    <a href="#">Taylor Swift</a> mentioned you in a post
                    "Angular JS. Tips and tricks"
                    <div class="font-size-sm text-muted mt-1">
                      4 minutes ago
                    </div>
                  </div>
                </li>

                <li class="media">
                  <div class="mr-3">
                    <a href="#" class="btn bg-pink-400 rounded-round btn-icon"
                      ><i class="icon-paperplane"></i
                    ></a>
                  </div>

                  <div class="media-body">
                    Special offers have been sent to subscribed users by
                    <a href="#">Donna Gordon</a>
                    <div class="font-size-sm text-muted mt-1">
                      36 minutes ago
                    </div>
                  </div>
                </li>

                <li class="media">
                  <div class="mr-3">
                    <a href="#" class="btn bg-blue rounded-round btn-icon"
                      ><i class="icon-plus3"></i
                    ></a>
                  </div>

                  <div class="media-body">
                    <a href="#">Chris Arney</a> created a new
                    <span class="font-weight-semibold">Design</span> branch in
                    <span class="font-weight-semibold">Limitless</span>
                    repository
                    <div class="font-size-sm text-muted mt-1">2 hours ago</div>
                  </div>
                </li>

                <li class="media">
                  <div class="mr-3">
                    <a href="#" class="btn bg-purple-300 rounded-round btn-icon"
                      ><i class="icon-truck"></i
                    ></a>
                  </div>

                  <div class="media-body">
                    Shipping cost to the Netherlands has been reduced, database
                    updated
                    <div class="font-size-sm text-muted mt-1">Feb 8, 11:30</div>
                  </div>
                </li>

                <li class="media">
                  <div class="mr-3">
                    <a
                      href="#"
                      class="btn bg-warning-400 rounded-round btn-icon"
                      ><i class="icon-comment"></i
                    ></a>
                  </div>

                  <div class="media-body">
                    New review received on
                    <a href="#">Server side integration</a> services
                    <div class="font-size-sm text-muted mt-1">Feb 2, 10:20</div>
                  </div>
                </li>

                <li class="media">
                  <div class="mr-3">
                    <a href="#" class="btn bg-teal-400 rounded-round btn-icon"
                      ><i class="icon-spinner11"></i
                    ></a>
                  </div>

                  <div class="media-body">
                    <strong>January, 2018</strong> - 1320 new users, 3284
                    orders, $49,390 revenue
                    <div class="font-size-sm text-muted mt-1">Feb 1, 05:46</div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="dropdown-content-footer bg-darl">
              <a
                href="#"
                class="font-size-sm line-height-sm text-uppercase font-weight-semibold text-grey mr-auto"
                >All activity</a
              >
              <div>
                <a
                  href="#"
                  class="text-grey"
                  data-popup="tooltip"
                  title="Clear list"
                  ><i class="icon-checkmark3"></i
                ></a>
                <a
                  href="#"
                  class="text-grey ml-2"
                  data-popup="tooltip"
                  title="Settings"
                  ><i class="icon-gear"></i
                ></a>
              </div>
            </div>
          </div>
        </li> -->

        <!-- <vue-toggle :title="cs_status == 1 ? 'CS Online' : 'CS Offline'" name="vue-toggle" :toggled="!cs_status" @toggle="changeCsStatus()" /> -->

        <li class="nav-item">
          <a href="#" :onClick="doLogout" class="navbar-nav-link">
            <i class="icon-switch2"></i>
            <button class="d-md-none ml-2">Logout</button>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import VueToggle from 'vue-toggle-component';
import Switchery from "@/assets/js/plugins/forms/styling/switchery.min.js";
import Switch from "@/assets/js/plugins/forms/styling/switch.min.js";
import Style from "@/assets/js/demo_pages/form_checkboxes_radios.js";
import util from '@/helpers/util';
import { StorageService } from "@/services/storage.service";

export default {
  name: "TheNavigation",
  data() {
    return {
      cs_status: null,
      user: null
    }
  },
  components: {
    // 'vue-toggle': VueToggle
  },
  computed: {
    ...mapGetters({
      getCs: "settings/getCs",
    }),
  },
  mounted() {
    Switchery;
    Switch;
    Style;
    this.user = this.getUser();
    // if (this.getCs == null) {
    //   this.getCsStatus()
    //   .then(res => {
    //     if (res.status) {
    //       this.cs_status = res.cs_status
    //     }
    //   });
    // }
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      getCsStatus: "settings/getCsStatus",
      updateCsStatus: "settings/updateCsStatus",
    }),
    doLogout() {
      this.logout();
    },
    getUser() {
      return StorageService.getUser();
    },
    changeCsStatus() {
      this.updateCsStatus()
      .then(res => {
        if (res.status) {
          util.showToast('Successfully change CS status!');
          this.cs_status = Number(res.cs_status)
        }
        this.getCsStatus;
      });
    }
  },
};
</script>
