<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <!-- <div class="form-group">
              <div class="row">
                <div class="col-md-2 col-lg-2">
                  <label for="InvoiceIsOffline">Method:</label>
                </div>
                <div class="col-md-10 col-lg-10 form-group">
                  <vue-select
                    v-model="method"
                    :options="[{id: 1, name: 'Send via Email'}, {id: 2, name: 'Print (Offline'}]"
                    placeholder="Please Select"
                    label-by="name"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <div class="row">
                <div class="col-md-2 col-lg-2">
                  <label for="InvoiceIsOffline">Client:</label>
                </div>
                <div class="col-md-10 col-lg-10 form-group">
                  <!-- {{ getInvoiceDetail.sale.client_name }} -->
                  <vue-select
                    v-if="getClients"
                    v-model="data.client_id"
                    :options="getClients"
                    placeholder="Please Select"
                    label-by="fullname"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                  <span v-if="errors.client_id" class="form-text text-danger">{{
                    errors.client_id[0]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered bg-white">
          <tr>
            <td class="invoice-input">Invoice Number</td>
            <td class="invoice-input p-2">
              <div class="d-flex justify-content-between px-2">
                <span>{{ data.invoice_number }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="invoice-input">Invoice Date</td>
            <td class="invoice-input">
              <input
                type="date"
                placeholder="Enter invoice date"
                class="form-control"
                v-model="data.date"
              />
              <span v-if="errors.date" class="form-text text-danger">
                {{ errors.date[0] }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="invoice-input">Courier</td>
            <td class="invoice-input">
              <vue-select
                v-model="data.courier"
                :options="[{ name: 'Australia Post' }, { name: 'Pick Up' }, { name: 'Shipping' }]"
                placeholder="Please Select"
                label-by="name"
                value-by="name"
                close-on-select
                style="width: 100% !important"
                searchable
              />
              <span v-if="errors.courier" class="form-text text-danger">
                {{ errors.courier[0] }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="invoice-input">Tracking Number</td>
            <td class="invoice-input">
              <input
                type="input"
                placeholder="Enter Tracking Number"
                class="form-control"
                v-model="data.tracking_number"
              />
              <span v-if="errors.tracking_number" class="form-text text-danger">
                {{ errors.tracking_number[0] }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="invoice-input">Payment Method</td>
            <td class="invoice-input">
              <vue-select
                v-model="data.payment_method"
                :options="[
                  { value: 'BANK', name: 'Bank' },
                  { value: 'Cash', name: 'Cash' },
                  { value: 'CC', name: 'Credit Card' },
                  { value: 'PAYPAL', name: 'Paypal' }]"
                placeholder="Please Select"
                label-by="name"
                value-by="value"
                close-on-select
                searchable
                style="width: 100% !important;max-height: 100px !important;"
              />
              <span v-if="errors.payment_method" class="form-text text-danger">
                {{ errors.payment_method[0] }}
              </span>
            </td>
          </tr>
          <!-- <tr>
            <td class="invoice-input">Issue Date</td>
            <td class="invoice-input">
              <input
                type="date"
                placeholder="Enter issue date"
                class="form-control"
                v-model="data.date"
              />
              <span v-if="errors.date" class="form-text text-danger">
                {{ errors.date[0] }}
              </span>
            </td>
          </tr> -->
        </table>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h3>Shipping Details</h3>
        <div class="row">
          <div class="col-md-2">
            <label>Country</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Country"
                    class="form-control"
                    v-model="data.shipping_country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>State</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="State"
                    class="form-control"
                    v-model="data.shipping_state"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Suburb</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Suburb"
                    class="form-control"
                    v-model="data.shipping_city"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Post Code</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="number"
                    placeholder="Post Code"
                    class="form-control"
                    v-model="data.shipping_postcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label>Street Address</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Street Address"
                    class="form-control"
                    v-model="data.shipping_address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Billing Details</h3>
        <div class="row">
          <div class="col-md-2">
            <label>Country</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Country"
                    class="form-control"
                    v-model="data.billing_country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>State</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="State"
                    class="form-control"
                    v-model="data.billing_state"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Suburb</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Suburb"
                    class="form-control"
                    v-model="data.billing_city"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Post Code</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="number"
                    placeholder="Post Code"
                    class="form-control"
                    v-model="data.billing_postcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label>Street Address</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Street Address"
                    class="form-control"
                    v-model="data.billing_address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="data.same_billing">
          <label class="form-check-label" for="defaultCheck1">
            Use Shipping Address as Billing Address
          </label>
        </div> -->
        <!-- <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="data.same_billing">
          <label class="form-check-label" for="defaultCheck1">
            Use Shipping Address as Billing Address
          </label>
        </div> -->
      </div>
    </div>
    <div class="row mt-4">
      <table class="table table-sm bg-white">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Item</th>
            <th scope="col">SKU</th>
            <th scope="col">Unit Price</th>
            <th scope="col">Qty</th>
            <th scope="col">Tax</th>
            <th scope="col">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in data.details" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td style="width: 20%">
              <vue-select
                v-if="getProducts"
                v-model="data.details[index].product_selected"
                :options="getProducts"
                placeholder="Please Select"
                label-by="title"
                value-by="id"
                close-on-select
                searchable
                @update:modelValue="(value) => onSelectedProduct(value, index)"
              />
            </td>
            <td>
              {{ data.details[index].sku }}
            </td>
            <td>
              <input
                type="number"
                placeholder="Unit price"
                class="form-control"
                v-model="data.details[index].price"
                @input="productSubtotal(index)"
              />
            </td>
            <td>
              <input
                type="number"
                placeholder="Qty"
                class="form-control" :class="`qty${index}`"
                v-model="data.details[index].quantity"
                @input="productSubtotal(index)"
              />
            </td>
            <td>
              {{ data.details[index].tax_method == 1 || data.details[index].tax_method == 'Inclusive'  ? 'GST INC' : 'GST' }}
            </td>
            <td>${{ data.details[index].subtotal }}</td>
            <td><i class="icon-trash me-3" @click="removeItem(index)" style="cursor: pointer;"></i></td>
          </tr>
        </tbody>
      </table>
      <span v-if="errors.issue_date" class="form-text text-danger">
        {{ errors.issue_date[0] }}
      </span>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="addProductInput()"
        >
          <b><i class="icon-plus-circle2 mr-2"></i></b> Add Items
        </button>
        <!-- <input
          type="checkbox"
          class="form-input-styled mx-2"
          v-model="data.payment_statut"
        />
        <span>Is Paid?</span>
        <input
          type="checkbox"
          class="form-input-styled mx-2"
          v-model="data.status"
        />
        <span>Is Complete?</span> -->
      </div>
      <div class="col-md-4 ml-auto bg-white">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <th>Subtotal:</th>
                <td class="text-right">
                  ${{ subtotal.toFixed(2) }}
                </td>
              </tr>
              <!-- <tr>
                <th>
                  Tax: <span class="font-weight-normal">(10%)</span>
                </th>
                <td class="text-right">
                  ${{ tax.toFixed(2) }}
                </td>
              </tr> -->
              <tr v-if="data.coupon">
                <th>
                  Coupon:
                </th>
                <td class="text-right">
                  <span v-if="data.coupon.is_percent">-{{ data.coupon.value }}% (${{ (subtotal * data.coupon.value / 100).toFixed(2) }})</span>
                  <span v-else>-${{ data.coupon.value }}</span>
                </td>
              </tr>
              <tr>
                <th>
                  Discount:
                </th>
                <td class="text-right">
                  <span v-if="data.isPercentDiscount">-{{ data.discount }}% (${{ (subtotal * data.discount / 100).toFixed(2) }})</span>
                  <span v-else>-${{ data.discount }}</span>
                </td>
              </tr>
              <tr>
                <th>Total:</th>
                <td class="text-right text-primary">
                  <h5 class="font-weight-semibold">
                    ${{ getTotal() }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      <i class="icon-plus-circle2 mr-2"></i> MORE OPTIONS
    </button>
    <div class="collapse mt-2" id="collapseExample">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-discount-tab" data-toggle="tab" href="#nav-discount" role="tab" aria-controls="nav-discount" aria-selected="true"><b>Discount</b></a>
          <!-- <a class="nav-item nav-link" id="nav-shipping-details-tab" data-toggle="tab" href="#nav-shipping-details" role="tab" aria-controls="nav-shipping-details" aria-selected="false"><b>Shipping Details</b></a>
          <a class="nav-item nav-link" id="nav-attach-document-tab" data-toggle="tab" href="#nav-attach-document" role="tab" aria-controls="nav-attach-document" aria-selected="false"><b>Attach Document</b></a>
          <a class="nav-item nav-link" id="nav-terms-tab" data-toggle="tab" href="#nav-terms" role="tab" aria-controls="nav-terms" aria-selected="false"><b>Terms & Conditions</b></a> -->
        </div>
      </nav>
      <div class="tab-content card mt-n3" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-discount" role="tabpanel" aria-labelledby="nav-discount-tab">
          <div class="container">
            <div class="form-group mt-3">
              <div class="row">
                <div class="col-sm-12">
                  <label>Discount</label>
                  <div class="d-flex">
                    <div class="form-inline">
                      <input
                        type="number"
                        placeholder="Enter discount"
                        class="form-control mr-5"
                        v-model="data.discount"
                        @input="getDiscount()"
                      />
                      <vue-select
                        v-model="data.isPercentDiscount"
                        :options="[
                          { id: 0, name: 'Dollar Value ($)' },
                          { id: 1, name: 'Percentage (%)' },
                        ]"
                        placeholder="Please Select"
                        label-by="name"
                        value-by="id"
                        close-on-select
                        searchable
                        @update:modelValue="() => getDiscount()"
                      />
                    </div>
                  </div>
                  <span v-if="errors.amount" class="form-text text-danger">{{
                    errors.amount[0]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-shipping-details" role="tabpanel" aria-labelledby="nav-shipping-details-tab">
          <div class="col-md-7 p-2">
            <h5>Shipping</h5>
            <div class="form-group mb-n1">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Weight</p>
                </div>
                <div class="col-sm-4">
                  <p>0.00000kg</p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <!-- <vue-select
                    v-model="data.status"
                    :options="[
                      { id: 'Pending', name: 'Pending' },
                      { id: 'Unpaid', name: 'Unpaid' },
                      { id: 'Paid', name: 'Paid' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  /> -->
                </div>
                <div class="col-sm-4">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="addProductInput()"
                  >
                    Calculate Shipping
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group mb-n1">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Discount</p>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">-$</span>
                    </span>
                    <input type="text" class="form-control">
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                    <span class="input-group-text">(</span>
                    </span>
                    <input type="text" class="form-control">
                    <span class="input-group-append">
                    <span class="input-group-text">%)</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Discount</p>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </span>
                    <input type="text" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" checked="">
              Tax free shipping?
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" checked="">
              Signature Required?
              </label>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-attach-document" role="tabpanel" aria-labelledby="nav-attach-document-tab">
          <div class="container">
            <input
              type="file"
              ref="attachments"
              class="form-control-uniform"
              data-fouc
              multiple
              @change="handleAttachments($event)"
            />
          </div>
        </div>
        <div class="tab-pane fade" id="nav-terms" role="tabpanel" aria-labelledby="nav-terms-tab">
          <div class="container">
            <div class="card mt-3">
              <div class="card-body">
                <label>Terms & Conditions</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <ckeditor :editor="wys" v-model="tos"></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label>Notes</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <ckeditor :editor="wys" v-model="data.notes"></ckeditor>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>Internal Notes</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <ckeditor :editor="wys" v-model="data.internal_notes"></ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="button" @click="saveInvoice()">
      <i class="icon-plus-circle2 mr-2"></i> SAVE
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import VueNextSelect from "vue-next-select";
import moment from 'moment';
export default {
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      wys: ClassicEditor,
      tos: null,
      errors: [],
      isManualInvoiceNumber: false,
      countSelectedProduct: 0,
      tax: 0,
      subtotal: 0,
      total: 0,
      method: 0,
      data: {
        stripe_key: "",
        coupon: null,
        invoice_number: null,
        tos: null,
        date: null,
        issue_date: null,
        client_id: null,
        GrandTotal: 0,
        warehouse_id: null,
        tax_rate: 0,
        shipping_country: '',
        shipping_state: '',
        shipping_city: '',
        shipping_postcode: '',
        shipping_address: '',
        billing_country: '',
        billing_city: '',
        billing_state: '',
        billing_postcode: '',
        billing_address: '',
        // same_billing: true,
        TaxNet: 0,
        discount: 0,
        isPercentDiscount: 1,
        shipping: 0,
        payment_statut: null,
        status: null,
        notes: '',
        internal_notes: '',
        courier: null,
        tracking_number: null,
        payment_method: 'BANK',
        type: 'Order',
        payment: {
          status: 'Paid',
          Reglement: "Credit Card",
          amount: 0,
        },
        details: [
          {
            product_selected: null,
            sku: null,
            title: 0,
            description: null,
            quantity: 0,
            price: null,
            tax_percent: 0,
            tax_method: 'Inclusive',
            discount: 0,
            discount_Method: '',
            id: '',
            product_variant_id: '',
            subtotal: 0,
          },
        ],
      },
    }
  },
  async created() {
    await this.invoiceById({ id: this.$route.params.id });
    await this.fetchClients();
    await this.fetchProducts();
    await this.getLastId();
    if (this.getInvoiceDetail) {
      this.data.coupon = this.getInvoiceDetail.coupon
      this.data.client_id = this.getInvoiceDetail.user.id
      this.data.invoice_number = this.getInvoiceDetail.id
      this.total = this.getInvoiceDetail.total
      this.data.GrandTotal = this.getInvoiceDetail.total
      this.data.tos = this.getInvoiceDetail.tos
      this.data.date = moment(this.getInvoiceDetail.created_at).format('YYYY-MM-DD')
      this.data.issue_date = this.getInvoiceDetail.issue_date
      this.data.tax_rate = this.getInvoiceDetail.tax_rate
      this.data.shipping_country = this.getInvoiceDetail.shipping_country
      this.data.shipping_state = this.getInvoiceDetail.shipping_state
      this.data.shipping_city = this.getInvoiceDetail.shipping_city
      this.data.shipping_postcode = this.getInvoiceDetail.shipping_postcode
      this.data.shipping_address = this.getInvoiceDetail.shipping_address
      this.data.billing_country = this.getInvoiceDetail.billing_country
      this.data.billing_state = this.getInvoiceDetail.billing_state
      this.data.billing_city = this.getInvoiceDetail.billing_city
      this.data.billing_postcode = this.getInvoiceDetail.billing_postcode
      this.data.billing_address = this.getInvoiceDetail.billing_address
      this.data.discount = this.getInvoiceDetail.discount ?? 0
      this.data.isPercentDiscount = this.getInvoiceDetail.is_discount_percent
      this.data.shipping = this.getInvoiceDetail.shipping
      this.data.payment_statut = this.getInvoiceDetail.payment_statut == 'Paid' ? true : false
      this.data.status = this.getInvoiceDetail.statut == 'Completed' ? true : false
      this.data.notes = this.getInvoiceDetail.notes ?? ''
      this.data.internal_notes = this.getInvoiceDetail.internal_notes ?? ''
      this.data.courier = this.getInvoiceDetail.courier
      this.data.tracking_number = this.getInvoiceDetail.tracking_number
      this.data.payment_method = this.getInvoiceDetail?.payment_method ?? 'BANK'
      this.data.tos = this.getInvoiceDetail.tos
      this.getInvoiceDetail.transactions.map((item, i) => {
        this.data.details[i] = item.product
        this.data.details[i].sku = item.product.sku
        this.data.details[i].product_selected = item.product.id
        this.data.details[i].title = item.product.title
        this.data.details[i].subtotal = item.total
        this.data.details[i].price = item.product.price
        this.data.details[i].quantity = item.quantity
        this.productSubtotal(i);
      })
    }
  },
  mounted() {
    Summernote;
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();

  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
      getProducts: "products/getProducts",
      getInvoiceNumber: "invoices/getInvoiceNumber",
      getInvoiceDetail: "invoices/getInvoice",
    }),
  },
  updated() {

  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchProducts: "products/fetchProducts",
      updateInvoice: "invoices/updateInvoice",
      invoiceById: "invoices/invoiceById",
      getLastId: "invoices/getLastId",
    }),
    removeItem(i) {
      this.data.details.splice(i, 1)
      this.productSubtotal(this.data.details.length - 1)
      // console.log(this.data.transaction.splice(i, 1));
      // this.countSelectedProduct = this.countSelectedProduct - 1;
    },
    changeToManualInvoiceNumber() {
      this.isManualInvoiceNumber = true
    },
    onSelectedProduct(value, index) {
      let product = this.getProducts.find(item => item.id == value)
      if (this.data.details.includes(value.id)) {
        this.data.details = this.data.details.filter(item => item.product.id != value.id)
      }
      console.log(product)
      this.data.details[index].id = product.id
      this.data.details[index].sku = product.sku
      this.data.details[index].title = product.title
      this.data.details[index].description = product.note
      this.data.details[index].price = product.price
      this.data.details[index].tax_method = product.tax_method == 1 ? 'Inclusive' : 'Exclusive'
      $(`.qty${index}`).focus();
    },
    productSubtotal(i) {
      this.data.details[i].subtotal = this.data.details[i].price * this.data.details[i].quantity
      let tax = 0;
      let subtotal = 0;
      this.data.details.forEach(item => {
          subtotal += item.subtotal;
        // 1 = GST INC
        // if (item.tax_method == 1) {
        //   let itemTax = item.subtotal / 11;
        //   tax += itemTax;
        //   subtotal += item.subtotal - tax;
        // }else{
        //   tax += item.subtotal * 10 / 100;
        // }
      });
      this.tax = tax;
      this.subtotal = subtotal;
      // var total = tax + subtotal;
      if (this.data.isPercentDiscount == 0) {
        this.total = subtotal - this.data.discount;
        this.data.GrandTotal = subtotal - this.data.discount;
      }else{
        let disc = subtotal * this.data.discount / 100
        this.total = subtotal - disc;
        this.data.GrandTotal = subtotal - disc;
      }
      // if (this.data.coupon.is_percent == 0) {
      //   this.total = subtotal - this.data.coupon.value;
      //   this.data.GrandTotal = subtotal - this.data.coupon.value;
      // }else{
      //   let disc = subtotal * this.data.coupon.value / 100
      //   this.total = subtotal - disc;
      //   this.data.GrandTotal = subtotal - disc;
      // }
    },
    getDiscount() {
      // 10 = Percent | 1 = Fixed $
      var disc = 0;
      var total = this.subtotal;
      if (this.data.isPercentDiscount == 0) {
        disc = this.data.discount
        this.total = total - this.data.discount;
        this.data.GrandTotal = total - this.data.discount;
      }else{
        disc = total * this.data.discount / 100
        this.total = total - disc;
        this.data.GrandTotal = total - disc;
      }
      return disc;
    },
    addProductInput() {
      this.countSelectedProduct += 1;
      this.data.details = this.data.details.concat({
        product_selected: null,
        sku: null,
        description: null,
        quantity: 0,
        price: null,
        tax_percent: 0,
        tax_method: 'Inclusive',
        discount: 0,
        discount_Method: '',
        id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
    getTotal() {
      let total = this.total;
      if (this.data?.coupon) {
        if (this.data.coupon.is_percent == 0) {
          total = total - this.data.coupon.value;
        }else{
          let disc = total * this.data.coupon.value / 100
          total = total - disc;
        }
      }
      return total.toFixed(2)
      // return moment(date).format(format ?? 'MMMM DD, YYYY');
    },
    saveInvoice() {
      console.log(this.$route.params.id)
      this.updateInvoice({ id: this.$route.params.id, data: this.data })
      .then(res => {
        res.status  && this.$router.push('/invoices')
      })
      .catch(error => {
        console.log(error)
        this.errors = error.response.data.errors;
      })
    },
  },
}
</script>

<style>
  .invoice-input {
    padding: .25rem 1.25rem !important;
  }
  .uniform-uploader {
    margin-top: .625rem!important;
    margin-bottom: .625rem!important;
  }
  .note-frame {
    border: 1px solid #ddd !important;
  }
</style>