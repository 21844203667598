<template>
  <div class="staff">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Tickets</h6>
        <button
          type="button"
          class="btn btn-transparent btn-sm mr-2"
          data-toggle="modal"
          data-target="#AddTicket"
        >
          <b><i class="icon-plus-circle2 mr-2"></i></b> Add Ticket
        </button>
      </div>

      <table class="table text-nowrap table-tickets" id="datatable">
        <thead>
          <tr>
            <th>Ref Id</th>
            <th>Client</th>
            <th>Subject</th>
            <th>Time Start</th>
            <th>Time End</th>
            <th>Grand Total</th>
            <th>Date</th>
            <th>Priority</th>
            <th>Status</th>
            <th>Actions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ticket, index) in getTickets" :key="index">
            <td>
              <router-link
                :to="`/tickets/${ticket.ref_id}`"
                class="font-weight-semibold"
                >{{ ticket.ref_id }}
              </router-link>
            </td>
            <td>{{ ticket.client.name }}</td>
            <td>{{ ticket.subject }}</td>
            <td>{{ ticket.time_start }}</td>
            <td>{{ ticket.time_end }}</td>
            <td>$ {{ ticket.related.GrandTotal }}</td>
            <td>{{ ticket.related.date }}</td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  {{ ticket.periority }}
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button href="#" class="dropdown-item">
                    High
                  </button>
                  <button href="#" class="dropdown-item">
                    Medium
                  </button>
                  <button href="#" class="dropdown-item">
                    Low
                  </button>
                </div>
              </div>
              <!-- <div
                v-if="ticket.periority === 'High'"
                class="ml-auto badge badge-danger"
              >
                {{ ticket.periority }}
              </div>
              <div
                v-if="ticket.periority === 'MEDIUM'"
                class="ml-auto badge badge-warning"
              >
                {{ ticket.periority }}
              </div>
              <div
                v-if="ticket.periority === 'SLOW'"
                class="ml-auto badge badge-success"
              >
                {{ ticket.periority }}
              </div> -->
            </td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  {{ ticket.status }}
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button href="#" class="dropdown-item" @click="updateTicketStatus(ticket.id, 'OPEN')">
                    Open
                  </button>
                  <button href="#" class="dropdown-item" @click="updateTicketStatus(ticket.id, 'PENDING')">
                    Pending
                  </button>
                  <button href="#" class="dropdown-item" @click="updateTicketStatus(ticket.id, 'CLOSE')">
                    Close
                  </button>
                </div>
              </div>
            </td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"
                      ><i class="icon-file-pdf"></i> Invoices</a
                    >
                    <a href="#" class="dropdown-item"
                      ><i class="icon-cube2"></i> Shipping details</a
                    >
                    <a href="#" class="dropdown-item"
                      ><i class="icon-credit-card"></i> Billing details</a
                    >
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item"
                      ><i class="icon-warning2"></i> Report problem</a
                    >
                  </div>
                </div>
              </div>
            </td>
            <td class="pl-0"></td>
          </tr>
        </tbody>
      </table>

      <!-- Add Ticket -->
      <div id="AddTicket" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
          <div class="modal-content">
            <div class="modal-header pb-3">
              <h5 class="modal-title">Add Ticket</h5>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body py-0">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline1" name="customRadioInline" class="custom-control-input" :value="false" v-model="isCustomerExist" :checked="!isCustomerExist">
                <label class="custom-control-label" for="customRadioInline1">Add New Client</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="customRadioInline2" name="customRadioInline" class="custom-control-input" :value="true" v-model="isCustomerExist" :checked="isCustomerExist">
                <label class="custom-control-label" for="customRadioInline2">From Existing Client</label>
              </div>
              <div class="form-group mt-3" v-if="isCustomerExist">
                <div class="row">
                  <div class="col-sm-12">
                    <label>Client</label>
                    <vue-select
                      v-if="getClients.clients"
                      v-model="data.ticket.client_id"
                      :options="getClients.clients"
                      placeholder="Please Select"
                      label-by="name"
                      value-by="id"
                      close-on-select
                      style="width: 100% !important;"
                      searchable
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3" v-if="!isCustomerExist">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Enter your Name"
                        class="form-control"
                        v-model="data.client.name"
                      />
                      <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        class="form-control"
                        v-model="data.client.email"
                      />
                      <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Phone</label>
                      <input
                        type="number"
                        placeholder="Enter your phone"
                        class="form-control"
                        v-model="data.client.phone"
                      />
                      <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-6">
                      <label>Country</label>
                      <input
                        type="text"
                        placeholder="Enter your country"
                        class="form-control"
                        v-model="data.client.country"
                      />
                      <span v-if="errors.country" class="form-text text-danger">{{ errors.country[0] }}</span>
                    </div>
                    <div class="col-sm-6">
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Enter your city"
                        class="form-control"
                        v-model="data.client.city"
                      />
                      <span v-if="errors.city" class="form-text text-danger">{{ errors.city[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Enter your address"
                        class="form-control"
                        v-model="data.client.address"
                      />
                      <span v-if="errors.address" class="form-text text-danger">{{ errors.address[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="Enter your password"
                        class="form-control"
                        v-model="data.client.password"
                      />
                      <span v-if="errors.password" class="form-text text-danger">{{ errors.password[0] }}</span>
                    </div>
                    <small>*Optional</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label>Subject</label>
                    <input
                      type="text"
                      placeholder="Enter your subject"
                      class="form-control"
                      v-model="data.ticket.subject"
                    />
                    <span v-if="errors.subject" class="form-text text-danger">{{ errors.subject[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Message</label>
                  <textarea
                    rows="2"
                    cols="3"
                    class="form-control"
                    placeholder="Enter your message"
                    v-model="data.ticket.message"
                  >
                    Detail message
                  </textarea
                  >
                <span v-if="errors.message" class="form-text text-danger">{{ errors.message[0] }}</span>
                </div>
              </div>
            </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Amount</label>
                    <input
                      type="text"
                      placeholder="Enter your amount"
                      class="form-control"
                      v-model="data.ticket.amount"
                    />
                    <span v-if="errors.amount" class="form-text text-danger">{{ errors.amount[0] }}</span>
                  </div>
                  <div class="col-sm-6">
                    <label>Fees</label>
                    <input
                      type="text"
                      placeholder="Enter fees"
                      class="form-control"
                      v-model="data.ticket.fees"
                    />
                    <span v-if="errors.fees" class="form-text text-danger">{{ errors.fees[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Department</label>
                    <vue-select
                      v-model="data.ticket.departement_id"
                      :options="[{value: 1, label: 'Support'}, {value: 2, label: 'Billing'}, {value: 3, label: 'Sales'}]"
                      placeholder="Please Select Department..."
                      label-by="label"
                      value-by="value"
                      close-on-select
                      style="width: 100% !important;"
                      searchable
                    />
                    <span v-if="errors.departement_id" class="form-text text-danger">{{ errors.phone[0] }}</span>
                  </div>
                  <div class="col-sm-4">
                    <label>Related</label>
                    <vue-select
                      v-model="data.ticket.related_id"
                      :options="[{value: 1, label: 'Support'}, {value: 2, label: 'Billing'}, {value: 3, label: 'Sales'}]"
                      placeholder="Please Select Related"
                      label-by="label"
                      value-by="value"
                      close-on-select
                      style="width: 100% !important;"
                      searchable
                    />
                    <span v-if="errors.related_id" class="form-text text-danger">{{ errors.related_id[0] }}</span>
                  </div>
                  <div class="col-sm-4">
                    <label>Priority</label>
                    <vue-select
                      v-model="data.ticket.periority"
                      :options="[{value: 'high', label: 'High'}, {value: 'medium', label: 'Medium'}, {value: 'low', label: 'Low'}]"
                      placeholder="Please Select Priority"
                      label-by="label"
                      value-by="value"
                      close-on-select
                      style="width: 100% !important;"
                      searchable
                    />
                    <span v-if="errors.periority" class="form-text text-danger">{{ errors.periority[0] }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label>Message</label>
                    <div class="summernote"></div>
                  </div>
                </div>
              </div> -->
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12">
                    <label>Attachments</label>
                    <input
                      type="file"
                      ref="attachments"
                      class="form-control-uniform"
                      data-fouc
                      multiple
                      @change="handleAttachments($event)"
                    />
                    <span v-if="errors.attachments" class="form-text text-danger">{{ errors.attachments[0] }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer pt-3">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                @click="addTicket()"
              >
                Submit <i class="icon-paperplane ml-2" ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Ticket -->
      <div id="AddTicket2" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable modal-md">
          <div class="modal-content">
            <div class="modal-header pb-3">
              <h5 class="modal-title">Payment</h5>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <input
                      type="text"
                      placeholder="Enter your address"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-4">
                    <input
                      type="text"
                      placeholder="City"
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      placeholder="State"
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-4">
                    <input type="text" placeholder="ZIP" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <input
                      type="text"
                      placeholder="Card number"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <input
                      type="text"
                      placeholder="Expiration"
                      class="form-control"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input type="text" placeholder="CVC" class="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer pt-3">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                Submit <i class="icon-paperplane ml-2" ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Interactions from "@/assets/js/plugins/extensions/jquery_ui/interactions.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import VueNextSelect from 'vue-next-select'
import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'

export default {
  name: "Tickets",
  components: {
    'vue-select': VueNextSelect,
  },
  data() {
    return {
      isCustomerExist: false,
      errors: [],
      formData: new FormData(),
      data: {
        client: {
          name: null,
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          city: null,
          country: null,
          address: null,
          password: null,
        },

        ticket: {
          client_id: null,
          subject: null,
          message: '',
          amount: null,
          total: null,
          fees: null,
          departement_id: null,
          related_id: null,
          periority: null,
          attachments: null,
        }
      },
    }
  },
  created() {
    this.fetchTickets();
    this.fetchClients();
  },
  computed: {
    ...mapGetters({
      getTickets: "tickets/getTickets",
      getClients: "clients/getClients",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-tickets')) {
      $(".table-tickets").DataTable({
        initComplete: function () {
          this.api().columns().every( function () {
            var column = this;
            var select = $('<select><option value=""></option></select>')
              .appendTo( $(column.footer()).empty() )
              .on( 'change', function () {
                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                column.search( val ? '^'+val+'$' : '', true, false ).draw();
              });
            column.data().unique().sort().each( function ( d ) {
              select.append( '<option value="'+d+'">'+d+'</option>' )
            } );
          });
        },
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 100,
          },
          {
            orderable: false,
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-pdf"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Interactions;
    Select2;
    Uniform;
    Summernote;
    $(".select").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();

    Datatables;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchTickets: "tickets/fetchTickets",
      createTicket: "tickets/createTicket",
      editTicketStatus: "tickets/editTicketStatus",
      fetchClients: "clients/fetchClients",
    }),
    async updateTicketStatus(id, status) {
      if (status == 'OPEN') {
        await this.editTicketStatus({id, url: 'reopen-tiket'});
      } else if (status == 'PENDING') {
        await this.editTicketStatus({id, url: 'pending-tiket'});
      }else if (status == 'CLOSE') {
        await this.editTicketStatus({id, url: 'close-tiket'});
      }
      this.fetchTickets();
    },
    handleAttachments(event) {
      this.data.ticket.attachments = event.target.files;
      console.log(this.data.ticket.attachments)
    },
    addTicket(){
      this.data.ticket.total = Number(this.data.ticket.amount) + Number(this.data.ticket.fees);
      Object.entries(this.data.ticket).forEach(([key, value]) => {
        this.formData.append(key, value);
      });
      if (this.data.client?.name) {
        Object.entries(this.data.client).forEach(([key, value]) => {
          this.formData.append(key, value);
        });
        ApiService.post("/clients/clients", this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          this.data.ticket['client_id'] = res.data.data.id
          ApiService.post("/tiket/open-tiket", this.formData);
          $('#AddTicket').modal('hide');
          util.showToast('Ticket successfully addeds');
        }).catch(err => {
          this.errors = err.response.data.errors
        });
      }else{
        ApiService.post("/tiket/open-tiket", this.formData).then(() => {
          $('#AddTicket').modal('hide');
          util.showToast('Ticket successfully addeda');
        }).catch(err => {
          this.errors = err.response.data.errors
        });
      }
      this.fetchTickets();
      // this.createTicket({ client: this.data.client, ticket: this.data.ticket }).then(() => {
      //   util.showToast('Client successfully added');
      //   this.fetchClients();
      //   $('#AddClient').modal('hide');
      // }).catch(err => {
      //   console.log(err.response.data)
      //   this.errors = err.response.data.errors;
      // });
  },
  }
};
</script>
