<template>
  <div class="purchases">
    <div class="card noprint">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Stock Management</h6>
        <div>
          <button type="button" class="btn btn-danger" data-toggle="button" v-if="this.selectedIds.length > 0" @click="deletePurchaseBySelectedIds()">
            Delete Selected
          </button>
          <button
            @click="exportPurchases"
            type="button"
            class="btn btn-transparent btn-sm"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button>
          <router-link :to="`/stock-management/create`">
            <button type="button" class="btn btn-transparent btn-sm mr-2">
              <b><i class="icon-plus-circle2 mr-2"></i></b> Create Stock
            </button>
          </router-link>
        </div>
      </div>

      <table class="table text-nowrap table-purchases" id="datatable">
        <thead>
          <tr>
            <th>Stock ID</th>
            <th>Supplier</th>
            <th>Warehouse</th>
            <th>Payment Status</th>
            <th>Grand Total</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(purchase, index) in getPurchases.purchases" :key="index">
            <td>
              <router-link :to="'/stock-management/1'">
                #{{ purchase.Ref }}
              </router-link>
            </td>
            <td>{{ purchase.provider_name }}</td>
            <td>{{ purchase.warehouse_name }}</td>
            <td>
              <span class="badge" :class="purchase.payment_status == 'paid' ? 'badge-success' : 'badge-warning'">
                {{ purchase.payment_status.toUpperCase() }}
              </span>
            </td>
            <td>{{ purchase.GrandTotal }}</td>
            <td v-if="index != 2">
              <span class="badge" :class="purchase.statut == 'received' ? 'badge-success' : purchase.statut == 'pending' ? 'badge-warning' : 'badge-info'">
                {{ purchase.statut == 'received' ? 'FINISHED' : purchase.statut.toUpperCase() }}
              </span>
            </td>
            <td v-if="index == 2"><span class="badge badge-danger">
              PARTIALLY
              </span>
            </td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"
                      ><i class="icon-envelope"></i> Send Email</a
                    >
                    <a
                      href="#" class="dropdown-item"
                      data-toggle="modal"
                      data-target="#invoice"
                      ><i class="icon-printer"></i> Print</a
                    >
                    <a href="#" class="dropdown-item"
                      ><i class="icon-cube2"></i> Shipping details</a
                    >
                    <a href="#" class="dropdown-item" @click="openBillingDetailsModal()"
                      ><i class="icon-credit-card"></i> Billing details</a
                    >
                    <div class="dropdown-divider"></div>
                    <router-link :to="`/stock-management/edit/${purchase.id}`">
                      <a class="dropdown-item text-dark">
                        <i class="icon-pencil"></i> Edit Stock
                      </a>
                    </router-link>
                    <a href="#" class="dropdown-item" @click="deletePurchase(purchase.id)"
                      ><i class="icon-trash"></i> Delete Stock</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Purchase modal -->
    <div id="AddPurchase" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Purchase</h5>
            <h5 class="modal-title" v-else>Edit Purchase</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter purchase name"
                    class="form-control"
                    v-model="data.name"
                  />
                </div>
                <div class="col-sm-4">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter purchase phone"
                    class="form-control"
                    v-model="data.phone"
                  />
                </div>
                <div class="col-sm-4">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter purchase email"
                    class="form-control"
                    v-model="data.email"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Country</label>
                  <input
                    type="text"
                    placeholder="Enter purchase country"
                    class="form-control"
                    v-model="data.country"
                  />
                </div>
                <div class="col-sm-6">
                  <label>City</label>
                  <input
                    type="text"
                    placeholder="Enter purchase city"
                    class="form-control"
                    v-model="data.city"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Address</label>
                  <input
                    type="text"
                    placeholder="Enter purchase address"
                    class="form-control"
                    v-model="data.adresse"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addPurchase() : editPurchaseSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Purchase modal -->

    <!-- Purchase Details -->
    <div id="invoice" class="modal fade">
      <div class="modal-dialog modal-full">
        <div class="modal-content printable">
          <div class="modal-header">
            <h5 class="modal-title">PO Number #PO098123</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-4">
                  <img
                    src="@/assets/images/logo_invoice.png"
                    class="mb-3 mt-2"
                    alt=""
                    style="width: 120px"
                  />
                  <ul class="list list-unstyled mb-0">
                    <li>CHEAPTECH PTY LTD</li>
                    <li>1 Muller Lane</li>
                    <li>Mascot,NSW,2020</li>
                    <li>0289595765</li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mb-4">
                  <div class="text-sm-right">
                    <h4 class="text-primary mb-2 mt-md-2">
                      PO Number #PO098123
                    </h4>
                    <ul class="list list-unstyled mb-0">
                      <li>
                        Date:
                        <span class="font-weight-semibold">30 September 2021</span>
                      </li>
                      <!-- <li>
                        Due date:
                        <span class="font-weight-semibold">May 12, 2015</span>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-md-flex flex-md-wrap">
              <div class="mb-4 mb-md-2">
                <span class="text-muted">Invoice To:</span>
                <ul class="list list-unstyled mb-0">
                  <li>
                    <h5 class="my-2">Seadan PTY LTD</h5>
                  </li>
                  <li>
                    <span class="font-weight-semibold">Rival Wijokumoro</span>
                  </li>
                  <li>4 Muller Lane</li>
                  <li>Mascot,NSW,666</li>
                  <li>Australia</li>
                  <li>0289595765</li>
                  <li><a href="#">rebecca@gmail.com.au</a></li>
                </ul>
              </div>

              <div class="mb-2 ml-auto">
                <span class="text-muted">Supplier Payment Details:</span>
                <div class="d-flex flex-wrap wmin-md-400">
                  <ul class="list list-unstyled mb-0">
                    <li><h5 class="my-2">Total Order:</h5></li>
                    <li>Bank name:</li>
                    <li>BSB:</li>
                    <li>ACC Number:</li>
                    <li>Reference Number:</li>
                    <li>Payment Status:</li>
                  </ul>

                  <ul class="list list-unstyled text-right mb-0 ml-auto">
                    <li>
                      <h5 class="font-weight-semibold my-2">
                        $100
                      </h5>
                    </li>
                    <li>
                      <span class="font-weight-semibold"
                        >Westpac</span
                      >
                    </li>
                    <li>
                      <span class="font-weight-semibold"
                        >7484302</span
                      >
                    </li>
                    <li>
                      <span class="font-weight-semibold"
                        >98361927</span
                      >
                    </li>
                    <li><span class="font-weight-semibold">#PO098123</span></li>
                    <li><span class="badge badge-success font-weight-semibold">Paid</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-lg">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h6 class="mb-0">Bosch Solution 3000 PCB ONLY</h6>
                  </td>
                  <td>$100</td>
                  <td>1</td>
                  <td>
                    <span class="font-weight-semibold"
                      >$100</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <div class="d-md-flex flex-md-wrap">
              <div class="pt-2 mb-3">
                <h6 class="mb-3">Authorized person</h6>
                <div class="mb-3">
                  <img src="@/assets/images/signature.png" width="150" alt="" />
                </div>

                <ul class="list-unstyled text-muted">
                  <li>Allen Bruno</li>
                  <li>Cheaptech (Sales Manager)</li>
                  <li>1 Muller Lane</li>
                  <li>Mascot,NSW,2020</li>
                  <li>0289595765</li>
                </ul>
              </div>

              <div class="pt-2 mb-3 wmin-md-400 ml-auto">
                <h6 class="mb-3">Total due</h6>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Subtotal:</th>
                        <td class="text-right">
                          $100
                        </td>
                      </tr>
                      <tr>
                        <th>
                          GST: <span class="font-weight-normal">(10%)</span>
                        </th>
                        <td class="text-right">
                          $10
                        </td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td class="text-right text-primary">
                          <h5 class="font-weight-semibold">
                            $100
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer">
            <span class="text-muted"
              >Thank you for using Limitless. This invoice can be paid via
              PayPal, Bank transfer, Skrill or Payoneer. Payment is due within
              30 days from the date of delivery. Late payment is possible, but
              with with a fee of 10% per month. Company registered in England
              and Wales #6893003, registered office: 3 Goodman Street, London E1
              8BF, United Kingdom. Phone number: 888-555-2311</span
            >
          </div> -->

          <div class="modal-footer bg-transparent noprint">
            <div class="text-right mt-3">
              <button
                @click="sendInvoice()"
                type="button"
                class="btn btn-primary btn-labeled btn-labeled-left"
              >
                <b><i class="icon-paperplane"></i></b> Send invoice
              </button>
              <button
                @click="printInvoice()"
                type="button"
                class="btn btn-success btn-labeled btn-labeled-left ml-2"
              >
                <b><i class="icon-paperplane"></i></b> Print invoice
              </button>
              <button
                type="button"
                class="btn btn-light btn-labeled ml-2"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Purchase Details -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'

export default {
  name: "Purchases",
  data() {
    return {
      selectedIds: [],
      modal: 0,
      id: null,
      data: {
        name: null,
        phone: null,
        email: null,
        country: null,
        city: null,
        adresse: null,
      }
    }
  },
  created() {
    this.fetchPurchases();
  },
  computed: {
    ...mapGetters({
      getPurchases: "purchases/getPurchases",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable( '.table-purchases' )) {
      $(".table-purchases").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            orderable: false,
            targets: 0,
            width: 10,
          },
          {
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><""t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-pdf"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }

  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchPurchases: "purchases/fetchPurchases",
      AddPurchase: "purchases/AddPurchase",
      updatePurchase: "purchases/updatePurchase",
      deletePurchaseByIds: "purchases/deletePurchaseByIds",
      exportAllPurchases: "purchases/exportPurchases",
    }),
    printInvoice() {
      window.print();
    },
    addSelectedPurchase(id) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id);
      }else{
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId != id);
      }
    },
    openAddPurchaseModal() {
      this.modal = 0;
      this.id = null;
      this.data.name = null;
      this.data.phone = null;
      this.data.email = null;
      this.data.country = null;
      this.data.city = null;
      this.data.adresse = null;
      $('#AddPurchase').modal('show');
    },
    addPurchase() {
      this.AddPurchase({ data: this.data }).then(() => {
        util.showToast('Purchase successfully added');
        this.fetchPurchases();
        $('#AddPurchase').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editPurchase(i) {
      this.modal = 1;
      let val = this.getPurchases.purchases[i];
      this.id = val.id
      this.data.name = val.name
      this.data.phone = val.phone
      this.data.email = val.email
      this.data.country = val.country
      this.data.city = val.city
      this.data.adresse = val.adresse
      $('#AddPurchase').modal('show');
    },
    editPurchaseSubmit() {
      let data = this.data;
      this.updatePurchase({ id: this.id, data }).then(() => {
        util.showToast('Purchase successfully changed');
        this.fetchPurchases();
        $('#AddPurchase').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deletePurchase(id) {
      const action = () =>  {
        this.deletePurchaseById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchPurchases()
    },
    async deletePurchaseBySelectedIds() {
      const action = () =>  {
        this.deletePurchaseByIds({selectedIds: this.selectedIds})
      }
      await util.deleteConfirmationToast(action)
      this.fetchPurchases()
    },
    async exportPurchases() {
      this.exportAllPurchases()
    }
  },
};
</script>
