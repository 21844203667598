import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  coupons: [],
  coupon: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getCoupons: (state) => {
    return state.coupons;
  },
  getCoupon: (state) => {
    return state.coupon;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_COUPONS: (state, data) => (state.coupons = data),
  SET_COUPON: (state, data) => (state.coupon = data),
};

const actions = {
  async fetchCoupons({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("coupons");
      commit("SET_COUPONS", response.data.coupons);
    } catch (error) {
      console.log(error);
      commit("SET_COUPONS", {});
    }
    commit("SET_LOADER", false);
  },
  async createCoupon({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("coupons", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateCoupon({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`/coupons/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteCouponById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`coupons/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
