<template>
  <div class="staff">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Compare</h6>
        <div>
          <button
            @click="exportCompare"
            type="button"
            class="btn btn-transparent btn-sm mr-2"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm mr-2"
          >
            <i class="icon-rotate-cw3"></i>
            Refresh Compare
          </button>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mb-2">
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold">Expensive Products :</span>
              <div class="ml-auto">
                4 Products
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Valid URL :</span>
              <div class="ml-auto">3 URLs</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">URL Error :</span>
              <div class="ml-auto">2 URLs</div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold">Price Changes Today :</span>
              <div class="ml-auto">3 Products </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Last Updated URL :</span>
              <div class="ml-auto">3 URLs</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Last Updated :</span>
              <div class="ml-auto">30 September 2021 20.00 PM</div>
            </li>
            <!-- <li class="list-group-item my-n1">
              <span class="font-weight-bold">Actions</span>
              <div class="ml-auto">
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditTicket"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </li> -->
          </ul>
        </div>
      </div>

      <table class="table text-nowrap table-suppliers" id="datatable">
        <thead>
          <tr>
            <th>#</th>
            <th>SKU</th>
            <th>Cheaptech</th>
            <th>Seadan</th>
            <th>CCTVMaster</th>
            <th>Security Wholesaler</th>
            <th>CheapAP</th>
            <th>Secusafe</th>
            <th>Sprint</th>
            <th>Actions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(supplier, index) in getSuppliers.providers" :key="index">
            <td>
              <input type="checkbox" name="id" id="">
            </td>
            <td>B426</td>
            <td style="color: red;">$101</td>
            <td style="color: green;">$95</td>
            <td>$99</td>
            <td>$99</td>
            <td>$99</td>
            <td>$99</td>
            <td>$99</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editSupplier(index)"
                      ><i class="icon-warning2"></i> Edit Supplier</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteSupplier(supplier.id)"
                      ><i class="icon-warning2"></i> Delete Supplier</a
                    >
                  </div>
                </div>
              </div>
            </td>
            <td class="pl-0"></td>
          </tr>
        </tbody>
      </table>

      <!-- Add Supplier -->
      <div id="AddSupplier" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
          <div class="modal-content">
            <div class="modal-header pb-3">
              <h5 class="modal-title" v-if="modal == 0">Add Supplier</h5>
              <h5 class="modal-title" v-if="modal == 1">Edit Supplier</h5>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body py-0">
              <div class="mt-3">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Enter your Name"
                        class="form-control"
                        v-model="data.name"
                      />
                      <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        class="form-control"
                        v-model="data.email"
                      />
                      <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Phone</label>
                      <input
                        type="number"
                        placeholder="Enter your phone"
                        class="form-control"
                        v-model="data.phone"
                      />
                      <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-6">
                      <label>Country</label>
                      <input
                        type="text"
                        placeholder="Enter your country"
                        class="form-control"
                        v-model="data.country"
                      />
                      <span v-if="errors.country" class="form-text text-danger">{{ errors.country[0] }}</span>
                    </div>
                    <div class="col-sm-6">
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Enter your city"
                        class="form-control"
                        v-model="data.city"
                      />
                      <span v-if="errors.city" class="form-text text-danger">{{ errors.city[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Enter your address"
                        class="form-control"
                        v-model="data.adresse"
                      />
                      <span v-if="errors.adresse" class="form-text text-danger">{{ errors.adresse[0] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer pt-3">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                v-if="modal == 0"
                class="btn btn-primary"
                @click="addSupplier()"
              >
                Submit <i class="icon-paperplane ml-2" ></i>
              </button>
              <button
                v-if="modal == 1"
                class="btn btn-primary"
                @click="editSupplierSubmit()"
              >
                Submit <i class="icon-paperplane ml-2" ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Supplier -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Interactions from "@/assets/js/plugins/extensions/jquery_ui/interactions.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'

export default {
  name: "Suppliers",
  data() {
    return {
      isCustomerExist: false,
      errors: [],
      modal: 0,
      id: null,
      data: {
        name: null,
        email: null,
        phone: null,
        city: null,
        country: null,
        adresse: null,
      },
    }
  },
  created() {
    this.fetchSuppliers();
  },
  computed: {
    ...mapGetters({
      getSuppliers: "suppliers/getSuppliers",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-suppliers')) {
      $(".table-suppliers").DataTable({
        initComplete: function () {
          this.api().columns().every( function () {
            var column = this;
            var select = $('<select><option value=""></option></select>')
              .appendTo( $(column.footer()).empty() )
              .on( 'change', function () {
                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                column.search( val ? '^'+val+'$' : '', true, false ).draw();
              });
            column.data().unique().sort().each( function ( d ) {
              select.append( '<option value="'+d+'">'+d+'</option>' )
            } );
          });
        },
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 30,
          },
          {
            orderable: false,
            width: 16,
            targets: 6,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-pdf"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Interactions;
    Select2;
    Uniform;
    Summernote;
    $(".select").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();

    Datatables;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchSuppliers: "suppliers/fetchSuppliers",
      createSupplier: "suppliers/createSupplier",
      updateSupplier: "suppliers/updateSupplier",
      deleteSupplierById: "suppliers/deleteSupplierById",
    }),
    addSupplier(){
      this.createSupplier({ data: this.data }).then(() => {
        util.showToast('Supplier successfully added');
        this.fetchSuppliers();
        $('#AddSupplier').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
      this.fetchSuppliers();
    },
    addSupplierModal() {
      this.modal = 0;
      this.data.name = null;
      this.data.email = null;
      this.data.phone = null;
      this.data.city = null;
      this.data.country = null;
      this.data.adresse = null;
      $('#AddSupplier').modal('show');
    },
    editSupplier(i) {
      this.modal = 1;
      let val = this.getSuppliers.providers[i];
      this.id = val.id
      this.data.name = val.name
      this.data.email = val.email
      this.data.phone = val.phone
      this.data.city = val.city
      this.data.country = val.country
      this.data.adresse = val.adresse
      $('#AddSupplier').modal('show');
    },
    editSupplierSubmit() {
      let data = this.data;
      this.updateSupplier({ id: this.id, data }).then(() => {
        util.showToast('Supplier successfully changed');
        this.fetchSuppliers();
        $('#AddSupplier').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteSupplier(id) {
      const action = () =>  {
        this.deleteSupplierById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchSuppliers()
    },
  }
};
</script>
