import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  colors: [],
  color: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getColors: (state) => {
    return state.colors;
  },
  getColor: (state) => {
    return state.color;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_COLORS: (state, data) => (state.colors = data),
  SET_COLOR: (state, data) => (state.color = data),
};

const actions = {
  async fetchColors({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("colors");
      commit("SET_COLORS", response.data.colors);
    } catch (error) {
      console.log(error);
      commit("SET_COLORS", {});
    }
    commit("SET_LOADER", false);
  },
  async createColor({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("colors", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateColor({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`/colors/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteColorById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`colors/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
