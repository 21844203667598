import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  membershipRules: [],
  membershipRule: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getMembershipRules: (state) => {
    return state.membershipRules;
  },
  getMembershipRule: (state) => {
    return state.membershipRule;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_PAGES: (state, data) => (state.membershipRules = data),
  SET_PAGE: (state, data) => (state.membershipRule = data),
};

const actions = {
  async fetchMembershipRules({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("membership-rules");
      commit("SET_PAGES", response.data.membership_rules);
    } catch (error) {
      console.log(error);
      commit("SET_PAGES", {});
    }
    commit("SET_LOADER", false);
  },
  async createMembershipRule({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("membership-rules", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateMembershipRule({ commit }, { id, data }) {
    let response = await ApiService.put(`/membership-rules/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteMembershipRuleById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`membership-rules/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
