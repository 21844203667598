<template>
  <div class="clients">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Clients</h6>
        <div>
          <button type="button" class="btn btn-danger" data-toggle="button" v-if="this.selectedIds.length > 0" @click="deleteSelectedClient()">
            Delete Selected
          </button>
          <!-- <button
            @click="exportClients"
            type="button"
            class="btn btn-transparent btn-sm"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button> -->
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddClientModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Client
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-clients" id="datatable">
        <thead>
          <tr>
            <th>#</th>
            <th>Profile</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Membership</th>
            <th>Point</th>
            <!-- <th>Trade Acc Status</th> -->
            <!-- <th>Payment method</th> -->
            <th>Orders history</th>
            <th>Order Total</th>
            <th>Actions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(client, index) in getClients" :key="index">
            <td>
              <input type="checkbox" class="form-input-styled" @click="addSelectedClient(client.id)">
            </td>
            <td>
              <div
                class="media d-flex justify-content-center align-items-center"
              >
                <div class="mr-3">
                  <router-link :to="`/clients/${client.id}`">
                    <img
                      src="@/assets/images/placeholders/placeholder.jpg"
                      width="40"
                      height="40"
                      class="rounded-circle"
                      alt=""
                    />
                  </router-link>
                </div>

                <div class="media-body align-self-center">
                  <router-link
                    :to="`/clients/${client.id}`"
                    class="font-weight-semibold"
                    style="text-transform: capitalize;"
                    >{{ client.fullname}}</router-link
                  >
                  <div class="text-muted font-size-sm">
                    <!-- Latest order : 30 September 2021 -->
                  </div>
                  <div class="text-muted font-size-sm">
                    <!-- Address: {{ client.address || '-' }} -->
                  </div>
                </div>
              </div>
            </td>
            <td>{{ client.phone }}</td>
            <td>
              <a href="#">{{ client.email }}</a>
            </td>
            <td>{{ getMembership(client.membership) }}</td>
            <td>{{ client.point }}</td>
            <!-- <td>{{ client.trade_account ? client.trade_account.status : 'Not Submitted' }}</td> -->
            <!-- <td>{{ client.credit_cards ? 'Credit Card' : '-' }}</td> -->
            <td>
              <ul class="list-unstyled mb-0">
                <li>
                  <i class="icon-infinite font-size-base text-warning mr-2"></i>
                  Pending:
                  <a href="#">{{ client.order_pending ?? 0 }} orders</a>
                </li>

                <li>
                  <i
                    class="icon-checkmark3 font-size-base text-success mr-2"
                  ></i>
                  Processed:
                  <a href="#">{{ client.order_process ?? 0 }} orders</a>
                </li>
              </ul>
            </td>
            <td>
              <h6 class="mb-0 font-weight-semibold">&dollar; {{ client.order_total.toFixed(2) ?? 0 }}</h6>
            </td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"
                      ><i class="icon-file-pdf"></i> Invoices</a
                    >
                    <a href="#" class="dropdown-item"
                      ><i class="icon-cube2"></i> Shipping details</a
                    >
                    <a href="#" class="dropdown-item" @click="openBillingDetailsModal()"
                      ><i class="icon-credit-card"></i> Billing details</a
                    >
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item" @click="editClient(index)"
                      ><i class="icon-pencil"></i> Edit Client</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteClient(client.id)"
                      ><i class="icon-trash"></i> Delete Client</a
                    >
                  </div>
                </div>
              </div>
            </td>
            <td class="pl-0"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Client modal -->
    <div id="AddClient" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Client</h5>
            <h5 class="modal-title" v-else>Edit Client</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Enter client name"
                    class="form-control"
                    v-model="data.first_name"
                  />
                  <span v-if="errors.first_name" class="form-text text-danger">{{ errors.first_name[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter client name"
                    class="form-control"
                    v-model="data.last_name"
                  />
                  <span v-if="errors.last_name" class="form-text text-danger">{{ errors.last_name[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter client email"
                    class="form-control"
                    v-model="data.email"
                  />
                  <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter client phone"
                    class="form-control"
                    v-model="data.phone"
                  />
                  <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="modal == 0">
              <div class="row">
                <div class="col-sm-6">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter client password"
                    class="form-control"
                    v-model="data.password"
                  />
                  <span v-if="errors.password" class="form-text text-danger">{{ errors.password[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Password Confirmation</label>
                  <input
                    type="password"
                    placeholder="Enter client password"
                    class="form-control"
                    v-model="data.password_confirmation"
                  />
                  <span v-if="errors.password_confirmation" class="form-text text-danger">{{ errors.password_confirmation[0] }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <div class="row">
                <div class="col-sm-3">
                  <label>Country</label>
                  <input
                    type="text"
                    placeholder="Enter client country"
                    class="form-control"
                    v-model="data.country"
                  />
                  <span v-if="errors.country" class="form-text text-danger">{{ errors.country[0] }}</span>
                </div>
                <div class="col-sm-3">
                  <label>Suburb</label>
                  <input
                    type="text"
                    placeholder="Enter client suburb"
                    class="form-control"
                    v-model="data.city"
                  />
                  <span v-if="errors.city" class="form-text text-danger">{{ errors.city[0] }}</span>
                </div>
                <div class="col-sm-3">
                  <label>State</label>
                  <input
                    type="text"
                    placeholder="Enter client state"
                    class="form-control"
                    v-model="data.state"
                  />
                  <span v-if="errors.state" class="form-text text-danger">{{ errors.state[0] }}</span>
                </div>
                <div class="col-sm-3">
                  <label>Postcode</label>
                  <input
                    type="text"
                    placeholder="Enter client postcode"
                    class="form-control"
                    v-model="data.postal_code"
                  />
                  <span v-if="errors.postal_code" class="form-text text-danger">{{ errors.postal_code[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Address</label>
                  <input
                    type="text"
                    placeholder="Enter client address"
                    class="form-control"
                    v-model="data.address"
                  />
                  <span v-if="errors.address" class="form-text text-danger">{{ errors.address[0] }}</span>
                </div>
              </div>
            </div> -->
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addClient() : editClientSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client modal -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import VueNextSelect from "vue-next-select";
import util from '@/helpers/util.js'

export default {
  name: "Clients",
  components: {
    // "vue-select": VueNextSelect,
  },
  data() {
    return {
      selectedIds: [],
      modal: 0,
      errors: [],
      id: null,
      data: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        password_confirmation: null,
        country: null,
        state: null,
        city: null,
        postal_code: null,
        address: null,
        gender: null,
        birth_date: null,
      }
    }
  },
  created() {
    this.fetchClients();
  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable( '.table-clients' )) {
      $(".table-clients").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            orderable: false,
            targets: 0,
            width: 10,
          },
          {
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        buttons: [
          // {
          //   extend: "pdfHtml5",
          //   text: '<i class="icon-file-pdf"></i>',
          //   className: "btn bg-light",
          //   orientation: "landscape",
          //   exportOptions: {
          //     columns: [0, 1, 2, 3, 4, 5],
          //     stripHtml: true,
          //   },
          //   customize: function(doc) {
          //     doc.content[1].table.widths = Array(
          //       doc.content[1].table.body[0].length + 1
          //     )
          //       .join("*")
          //       .split("");
          //   },
          // },
        ],
      });
    }

  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      AddClient: "clients/AddClient",
      updateClient: "clients/updateClient",
      deleteClientBySelectedIds: "clients/deleteClientBySelectedIds",
      deleteClientById: "clients/deleteClientById",
      exportAllClients: "clients/exportClients",
    }),
    addSelectedClient(id) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id);
      }else{
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId != id);
      }
    },
    openAddClientModal() {
      this.modal = 0;
      this.id = null;
      this.data.fullname = null
      this.data.first_name = null
      this.data.last_name = null
      this.data.gender = null
      this.data.birth_date = null
      this.data.phone = null
      this.data.email = null
      this.data.password = null
      this.data.password_confirmation = null
      this.data.country = null
      this.data.state = null
      this.data.postal_code = null
      this.data.city = null
      this.data.address = null
      $('#AddClient').modal('show');
    },
    addClient() {
      this.AddClient({ data: this.data }).then(res => {
        if (res.status) {
          util.showToast('Client successfully added');
          this.fetchClients();
        }else{
          console.log(res)
          this.errors = res.errors;
        }
        $('#AddClient').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editClient(i) {
      this.modal = 1;
      let val = this.getClients[i];
      this.id = val.id
      this.data.first_name = val.first_name
      this.data.last_name = val.last_name
      this.data.birth_date = val.birth_date
      this.data.phone = val.phone
      this.data.email = val.email
      this.data.country = val.addresses[0]?.country
      this.data.state = val.addresses[0]?.state
      this.data.postal_code = val.addresses[0]?.postal_code
      this.data.city = val.addresses[0]?.city
      this.data.address = val.addresses[0]?.street_line_1
      $('#AddClient').modal('show');
    },
    editClientSubmit() {
      let data = this.data;
      this.updateClient({ id: this.id, data }).then(() => {
        util.showToast('Client successfully changed');
        this.fetchClients();
        $('#AddClient').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteClient(id) {
      const action = () =>  {
        this.deleteClientById({id})
      }
      this.fetchClients()
      await util.deleteConfirmationToast(action)
    },
    async deleteSelectedClient() {
      await util.deleteConfirmationToast(async () =>  {
        await this.deleteClientBySelectedIds({selectedIds: this.selectedIds})
        await this.fetchClients()
      })
    },
    async exportClients() {
      this.exportAllClients()
    },
    getMembership(value) {
      if (value == 1) {
        return 'RUTHENIUM'
      } else if (value == 2) {
        return 'SILVER'
      } else if (value == 3) {
        return 'GOLD'
      } else if (value == 4) {
        return 'PLATINUM'
      }
      return '-'
    }
  },
};
</script>
