import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  brands: [],
  brand: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getBrands: (state) => {
    return state.brands;
  },
  getBrand: (state) => {
    return state.brand;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_BRANDS: (state, data) => (state.brands = data),
  SET_BRAND: (state, data) => (state.brand = data),
};

const actions = {
  async fetchBrands({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("brands");
      commit("SET_BRANDS", response.data.brands);
    } catch (error) {
      console.log(error);
      commit("SET_BRANDS", {});
    }
    commit("SET_LOADER", false);
  },
  async createBrand({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("brands", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateBrand({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`/brands/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteBrandById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`brands/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
