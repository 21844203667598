import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";

// Added Library
import 'vue-next-select/dist/index.css'
import "@/assets/css/custom.css";
import 'mosha-vue-toastify/dist/style.css'
import CKEditor from '@ckeditor/ckeditor5-vue'

// Global Stylesheet
import "@/assets/css/components.min.css";
import "@/assets/css/icons/icomoon/styles.min.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/bootstrap_limitless.min.css";
import "@/assets/css/layout.min.css";
import "@/assets/css/colors.min.css";
import "@/assets/sass/app.scss";

// Core JS Files
import "@/assets/js/main/jquery.min.js";
import "@/assets/js/main/bootstrap.bundle.min.js";
import "@/assets/js/plugins/loaders/blockui.min.js";
import "@/assets/js/plugins/ui/ripple.min.js";

// Theme JS Files
import "@/assets/js/plugins/visualization/d3/d3.min.js";
import "@/assets/js/plugins/visualization/d3/d3_tooltip.js";
import "@/assets/js/plugins/forms/styling/switchery.min.js";
import "@/assets/js/plugins/forms/styling/uniform.min.js";
import "@/assets/js/plugins/ui/moment/moment.min.js";
import "@/assets/js/plugins/pickers/daterangepicker.js";

import "@/assets/js/app.js";

import ApiService from "@/services/api.service";
import { StorageService } from "@/services/storage.service";

const firebaseConfig = {
  apiKey: "AIzaSyD5CxSRXioLRZ1GGIeE282NJuZ0GL1Kz5w",
  authDomain: "crystal-of-madagascar.firebaseapp.com",
  projectId: "crystal-of-madagascar",
  storageBucket: "crystal-of-madagascar.appspot.com",
  messagingSenderId: "84355554872",
  appId: "1:84355554872:web:d71fc2a35ab9357d0390cd",
  measurementId: "G-THTHM2HWB1"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ApiService.init(process.env.VUE_APP_API_URL);

// If token exists set header
if (StorageService.getToken()) {
  ApiService.setHeader();
}

createApp(App)
  .use(CKEditor)
  .use(store)
  .use(router)
  .mount("#app");
