<template>
  <div class="units">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Units</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddUnitModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Units
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-units" id="datatable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Short Name</th>
            <th>Operator</th>
            <th>Operator Value</th>
            <th>Base Unit Name</th>
            <th>Base Unit</th>
            <th>Base Unit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(unit, index) in getUnits.Units" :key="index">
            <td>{{ unit.name }}</td>
            <td>{{ unit.ShortName }}</td>
            <td>{{ unit.operator }}</td>
            <td>{{ unit.operator_value }}</td>
            <td>{{ unit.base_unit }}</td>
            <td>{{ unit.base_unit_name }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editUnit(index)"
                      ><i class="icon-pencil"></i> Edit Unit</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteUnit(unit.id)"
                      ><i class="icon-trash"></i> Delete Unit</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Unit modal -->
    <div id="AddUnits" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Unit</h5>
            <h5 class="modal-title" v-else>Edit Unit</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      class="form-control"
                      v-model="data.name"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>

                  <div class="col-sm-6">
                    <label>Short Name</label>
                    <input
                      type="text"
                      placeholder="Enter Short Name"
                      class="form-control"
                      v-model="data.ShortName"
                    />
                    <span v-if="errors.ShortName" class="form-text text-danger">{{ errors.ShortName[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Operator</label>
                    <input
                      type="text"
                      placeholder="Enter Operator"
                      class="form-control"
                      v-model="data.operator"
                    />
                    <span v-if="errors.operator" class="form-text text-danger">{{ errors.operator[0] }}</span>
                  </div>

                  <div class="col-sm-6">
                    <label>Operator Value</label>
                    <input
                      type="text"
                      placeholder="Enter Operator Value"
                      class="form-control"
                      v-model="data.operator_value"
                    />
                    <span v-if="errors.operator_value" class="form-text text-danger">{{ errors.operator_value[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Base Unit</label>
                    <input
                      type="text"
                      placeholder="Enter Base Unit"
                      class="form-control"
                      v-model="data.base_unit"
                    />
                    <span v-if="errors.base_unit" class="form-text text-danger">{{ errors.base_unit[0] }}</span>
                  </div>

                  <div class="col-sm-6">
                    <label>Base Unit Name</label>
                    <input
                      type="text"
                      placeholder="Enter Base Unit Name"
                      class="form-control"
                      v-model="data.base_unit_name"
                    />
                    <span v-if="errors.base_unit_name" class="form-text text-danger">{{ errors.base_unit_name[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addUnit() : editUnitSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Unit modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'

export default {
  name: "Units",
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      id: null,
      data: {
        name: null,
        ShortName: null,
        operator: null,
        operator_value: null,
        base_unit: null,
        base_unit_name: null,
      }
    }
  },
  created() {
    this.fetchUnits();
  },
  computed: {
    ...mapGetters({
      getUnits: "units/getUnits",
    }),
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    if (!$.fn.DataTable.isDataTable( '.table-units' )) {

      $(".table-units").DataTable({
        items: [{ subitems: []}],
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 100,
          },
          {
            orderable: false,
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          // {
          //   extend: "pdfHtml5",
          //   text: '<i class="icon-file-pdf"></i>',
          //   className: "btn bg-light",
          //   orientation: "landscape",
          //   exportOptions: {
          //     columns: [0, 1, 2, 3, 4, 5],
          //     stripHtml: true,
          //   },
          //   customize: function(doc) {
          //     doc.content[1].table.widths = Array(
          //       doc.content[1].table.body[0].length + 1
          //     )
          //       .join("*")
          //       .split("");
          //   },
          // },
        ],
      });
    }
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchUnits: "units/fetchUnits",
      AddUnit: "units/AddUnit",
      updateUnit: "units/updateUnit",
      deleteUnitById: "units/deleteUnitById",
    }),
    openAddUnitModal() {
      this.modal = 0;
      this.id = null;
      this.data.name = null;
      this.data.ShortName = null;
      this.data.operator = null;
      this.data.operator_value = null;
      this.data.base_unit = null;
      this.data.base_unit_name = null;
      $('#AddUnits').modal('show');
    },
    addUnit() {
      this.AddUnit({ data: this.data }).then(() => {
        util.showToast('Unit successfully added');
        this.fetchUnits();
        $('#AddUnits').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editUnit(i) {
      this.modal = 1;
      let val = this.getUnits.Units[i];
      this.id = val.id
      this.data.name = val.name
      this.data.ShortName = val.ShortName
      this.data.operator = val.operator
      this.data.operator_value = val.operator_value
      this.data.base_unit = val.base_unit
      this.data.base_unit_name = val.base_unit_name
      $('#AddUnits').modal('show');
    },
    editUnitSubmit() {
      let data = this.data;
      this.updateUnit({ id: this.id, data }).then(() => {
        util.showToast('Unit successfully changed');
        this.fetchUnits();
        $('#AddUnits').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteUnit(id) {
      const action = () =>  {
        this.deleteUnitById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchUnits()
    },
  },
};
</script>
