import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  settings: [],
  footer: {},
  cs: null,
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getFooter: (state) => {
    return state.footer;
  },
  getCs: (state) => {
    return state.cs;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_FOOTER: (state, data) => (state.footer = data),
  SET_CS: (state, data) => (state.cs = data),
};

const actions = {
  async fetchFooter({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("/setting/footer");
      commit("SET_FOOTER", response.data.footer);
      commit("SET_LOADER", false);
      return response.data.footer;
    } catch (error) {
      console.log(error);
      commit("SET_FOOTER", {});
    }
    commit("SET_LOADER", false);
  },
  async createConfig({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post("/setting/config", { ...data });
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateFooter({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.put("/setting/footer", { ...data });
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateConfig({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.put(`/setting/config/${id}`, { ...data });
    commit("SET_LOADER", false);
    return response.data;
  },
  async getCsStatus({ commit }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`/setting/get-cs-status`);
    commit("SET_CS", response.data.cs_status);
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateCsStatus({ commit }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`/setting/change-cs-status`);
    commit("SET_LOADER", false);
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
