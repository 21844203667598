<template>
  <div>
    <the-navigation />
    <div class="page-content">
      <the-sidebar />
      <div class="content-wrapper">
        <the-header />
        <div class="content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TheNavigation from "@/components/TheNavigation.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import TheHeader from "@/components/TheHeader.vue";
export default {
  name: "LayoutDefault",
  components: {
    TheNavigation,
    TheSidebar,
    TheHeader,
  },
};
</script>
