<template>
  <div class="invoices">
    <div class="card noprint">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Pre-Order Invoices</h6>
        <div>
          <button
            type="button"
            class="btn btn-danger"
            data-toggle="button"
            v-if="this.selectedIds.length > 0"
            @click="deleteInvoiceBySelectedIds()"
          >
            Delete Selected
          </button>
          <router-link :to="`/invoices/create`">
            <button
              type="button"
              class="btn btn-transparent btn-sm mr-2"
            >
              <b><i class="icon-plus-circle2 mr-2"></i></b> Create Invoices
            </button>
          </router-link>
        </div>
      </div>

      <table class="table table-lg invoice-archive">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Invoice ID</th>
            <th>Client Name</th>
            <th>Product</th>
            <th>Paid Amount</th>
            <th>Payment Method</th>
            <!-- <th>Warehouse Status</th> -->
            <th>Grand Total</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="inv in getPreOrderInvoices" :key="inv.id">
            <td>
              <input
                type="checkbox"
                class="form-input-styled mr-n5"
                @click="addSelectedInvoice(inv.id)"
              />
            </td>
            <td>{{ getFormatDate(inv.created_at) }}</td>
            <td>
              <router-link :to="`/invoices/${inv.id}`">
                #INV-{{ inv.transaction_group_id }}
              </router-link>
            </td>
            <td>
              <span style="text-transform: capitalize;">{{ inv.transaction_group.user?.first_name + ' ' + inv.transaction_group.user?.last_name }}</span><br>
              <small class="text-success">Membership : {{ getMembership(inv.transaction_group.user?.membership) }}</small>
            </td>
            <td>
              <span>{{ inv.product?.title }}</span><br>
              <small class="text-success">Release Date : {{ inv.product?.release_date ?? '-' }}</small>
            </td>
            <td>
              <div class="badge badge-success" v-if="inv?.transaction_group.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) > 0">
                ${{ inv?.transaction_group.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) > 0 ? inv?.transaction_group.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) : 0 }}
              </div>
              <div class="badge badge-danger" v-else>
                ${{ inv?.transaction_group.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) > 0 ? inv?.transaction_group.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) : 0 }}
              </div>
              <!-- Paid -->
            </td>
            <!-- <td><span class="badge badge-success">Ready</span></td> -->
            <td>
              <div class="d-flex flex-column align-items-center">
                <b>{{ inv?.transaction_group.transaction_payments?.length > 0 ? inv?.transaction_group.transaction_payments[0]?.payment_method ?? '-' : '-' }}</b>
                <!-- GST ${{ (inv.total * 10 / 100).toFixed(2) }} -->
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center">
                <b>${{ inv.transaction_group.total }}</b>
                <!-- GST ${{ (inv.total * 10 / 100).toFixed(2) }} -->
              </div>
            </td>
            <td class="text-center">
              <div class="list-icons list-icons-extended">
                <router-link :to="`/invoices/details/${inv.id}`" class="list-icons-item">
                  <i class="icon-file-eye"></i>
                </router-link>
                <div class="dropdown">
                  <a
                    href="#"
                    class="list-icons-item dropdown-toggle"
                    data-toggle="dropdown"
                    ><i class="icon-file-text2"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"
                      ><i class="icon-file-download"></i> Download</a
                    >
                    <a href="#" class="dropdown-item"
                      ><i class="icon-printer"></i> Print</a
                    >
                    <div class="dropdown-divider"></div>
                    <router-link :to="`/invoices/edit/${inv.id}`">
                      <a class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                    </router-link>
                    <a class="dropdown-item" @click="deleteInvoice(inv.id)"
                      ><i class="icon-cross2"></i> Delete</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal with invoice -->
    <div id="invoice" class="modal fade" v-if="getInvoice?.sale">
      <div class="modal-dialog modal-full">
        <div class="modal-content printable">
          <div class="modal-header">
            <h5 class="modal-title">Invoice {{ getInvoice.sale.Ref }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-4">
                  <img
                    src="@/assets/images/logo_invoice.png"
                    class="mb-3 mt-2"
                    alt=""
                    style="width: 120px"
                  />
                  <ul class="list list-unstyled mb-0">
                    <li>1 Muller Lane</li>
                    <li>Mascot,NSW,2020</li>
                    <li>0289595765</li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mb-4">
                  <div class="text-sm-right">
                    <h4 class="text-primary mb-2 mt-md-2">
                      Invoice {{ getInvoice.sale.Ref }}
                    </h4>
                    <ul class="list list-unstyled mb-0">
                      <li>
                        Date:
                        <span class="font-weight-semibold">{{
                          getInvoice.sale.date
                        }}</span>
                      </li>
                      <!-- <li>
                        Due date:
                        <span class="font-weight-semibold">May 12, 2015</span>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-md-flex flex-md-wrap">
              <div class="mb-4 mb-md-2">
                <span class="text-muted">Invoice To:</span>
                <ul class="list list-unstyled mb-0">
                  <li>
                    <h5 class="my-2">{{ getInvoice.sale.client_name }}</h5>
                  </li>
                  <li>
                    <span class="font-weight-semibold">{{
                      getInvoice.company?.CompanyName || ""
                    }}</span>
                  </li>
                  <li>4 Muller Lane</li>
                  <li>Mascot,NSW,666</li>
                  <li>Australia</li>
                  <li>0289595765</li>
                  <li><a href="#">rebecca@gmail.com.au</a></li>
                </ul>
              </div>

              <div class="mb-2 ml-auto">
                <span class="text-muted">Payment Details:</span>
                <div class="d-flex flex-wrap wmin-md-400">
                  <ul class="list list-unstyled mb-0">
                    <li><h5 class="my-2">Total Due:</h5></li>
                    <li>Bank name:</li>
                    <li>Country:</li>
                    <li>City:</li>
                    <li>Address:</li>
                    <li>IBAN:</li>
                    <li>SWIFT code:</li>
                  </ul>

                  <ul class="list list-unstyled text-right mb-0 ml-auto">
                    <li>
                      <h5 class="font-weight-semibold my-2">
                        ${{ getInvoice.sale.GrandTotal.toLocaleString() }}
                      </h5>
                    </li>
                    <li>
                      <span class="font-weight-semibold"
                        >Profit Bank Europe</span
                      >
                    </li>
                    <li>United Kingdom</li>
                    <li>London E1 8BF</li>
                    <li>3 Goodman Street</li>
                    <li>
                      <span class="font-weight-semibold"
                        >KFH37784028476740</span
                      >
                    </li>
                    <li><span class="font-weight-semibold">BPT4E</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-lg">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, i) in getInvoice.details" :key="i">
                  <td>
                    <h6 class="mb-0">{{ product.product_name }}</h6>
                  </td>
                  <td>{{ product.price.toLocaleString() }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>
                    <span class="font-weight-semibold"
                      >${{
                        (product.price * product.quantity).toLocaleString()
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <div class="d-md-flex flex-md-wrap">
              <div class="pt-2 mb-3">
                <h6 class="mb-3">Authorized person</h6>
                <div class="mb-3">
                  <img src="@/assets/images/signature.png" width="150" alt="" />
                </div>

                <ul class="list-unstyled text-muted">
                  <li>Cheaptech</li>
                  <li>1 Muller Lane</li>
                  <li>Mascot,NSW,2020</li>
                  <li>0289595765</li>
                </ul>
              </div>

              <div class="pt-2 mb-3 wmin-md-400 ml-auto">
                <h6 class="mb-3">Total due</h6>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Subtotal:</th>
                        <td class="text-right">
                          ${{ getSubtotal(getInvoice.details) }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Tax: <span class="font-weight-normal">(25%)</span>
                        </th>
                        <td class="text-right">
                          ${{ getInvoice.sale.tax_rate.toLocaleString() }}
                        </td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td class="text-right text-primary">
                          <h5 class="font-weight-semibold">
                            ${{ getInvoice.sale.GrandTotal.toLocaleString() }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer">
            <span class="text-muted"
              >Thank you for using Limitless. This invoice can be paid via
              PayPal, Bank transfer, Skrill or Payoneer. Payment is due within
              30 days from the date of delivery. Late payment is possible, but
              with with a fee of 10% per month. Company registered in England
              and Wales #6893003, registered office: 3 Goodman Street, London E1
              8BF, United Kingdom. Phone number: 888-555-2311</span
            >
          </div> -->

          <div class="modal-footer bg-transparent">
            <div class="text-right mt-3">
              <button
                @click="sendInvoice()"
                type="button"
                class="btn btn-primary btn-labeled btn-labeled-left"
              >
                <b><i class="icon-paperplane"></i></b> Send invoice
              </button>
              <button
                @click="printInvoice()"
                type="button"
                class="btn btn-secondary btn-labeled btn-labeled-left ml-2"
              >
                <b><i class="icon-paperplane"></i></b> Print invoice
              </button>
              <button
                type="button"
                class="btn btn-light btn-labeled ml-2"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /modal with invoice -->

    <!-- Add Invoice -->
    <div id="AddInvoice" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Add Invoice</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Client</label>
                  <vue-select
                    v-if="getClients.clients"
                    v-model="data.client_id"
                    :options="getClients.clients"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Date</label>
                  <div class="input-group">
                    <input type="date" class="form-control" id="date" />
                  </div>
                  <span v-if="errors.name" class="form-text text-danger">{{
                    errors.name[0]
                  }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Tax Rate</label>
                  <input
                    type="number"
                    placeholder="Enter Tax Rate"
                    class="form-control"
                  />
                  <span v-if="errors.country" class="form-text text-danger">{{
                    errors.country[0]
                  }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Warehouse</label>
                  <vue-select
                    v-model="data.warehouse_id"
                    :options="[
                      { id: 1, name: 'Gudang 1' },
                      { id: 2, name: 'Gudang 2' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Discount</label>
                  <input
                    type="text"
                    placeholder="Enter discount"
                    class="form-control"
                  />
                  <span v-if="errors.discount" class="form-text text-danger">{{
                    errors.discount[0]
                  }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Shipping</label>
                  <input
                    type="text"
                    placeholder="Enter shipping rate"
                    class="form-control"
                  />
                  <span v-if="errors.shipping" class="form-text text-danger">{{
                    errors.shipping[0]
                  }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Status</label>
                  <vue-select
                    v-model="data.status"
                    :options="[
                      { id: 'Pending', name: 'Pending' },
                      { id: 'Unpaid', name: 'Unpaid' },
                      { id: 'Paid', name: 'Paid' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
            <h5 class="modal-title">Payment</h5>
            <div class="form-group mt-3">
              <div class="row">
                <div class="col-sm-4">
                  <label>Amount</label>
                  <input
                    type="number"
                    placeholder="Enter shipping rate"
                    class="form-control"
                  />
                  <span v-if="errors.amount" class="form-text text-danger">{{
                    errors.amount[0]
                  }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Reglement</label>
                  <input
                    type="text"
                    placeholder="Enter reglement"
                    class="form-control"
                  />
                  <span v-if="errors.reglement" class="form-text text-danger">{{
                    errors.reglement[0]
                  }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Status</label>
                  <vue-select
                    v-model="data.payment.status"
                    :options="[
                      { id: 'Pending', name: 'Pending' },
                      { id: 'Unpaid', name: 'Unpaid' },
                      { id: 'Paid', name: 'Paid' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
            <h5 class="modal-title">Product</h5>
            <div v-for="(product, index) in countSelectedProduct + 1" :key="index">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="d-flex align-items-center justify-content-between">
                      <label>Product {{ index + 1 }} </label>
                      <button class="btn btn-sm btn-danger col-md-2 mx-2" @click="deleteProductInput(index)">
                        Delete <i class="icon-trash"></i>
                      </button>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mt-3">
                      <vue-select
                        v-if="getProducts.products"
                        v-model="data.details[index].product_id"
                        :options="getProducts.products"
                        placeholder="Please Select"
                        label-by="name"
                        close-on-select
                        class="col-md-8"
                        searchable
                      />
                      <div class="col-sm-2">
                        <input
                          type="number"
                          placeholder="Enter unit price"
                          class="form-control"
                          v-model="data.details[index].Unit_price"
                        />
                        <span v-if="errors.Unit_price" class="form-text text-danger">{{
                          errors.Unit_price[0]
                        }}</span>
                      </div>
                      <div class="col-sm-2">
                        <input
                          type="number"
                          placeholder="Enter quantity"
                          class="form-control"
                          v-model="data.details[index].quantity"
                        />
                        <span v-if="errors.quantity" class="form-text text-danger">{{
                          errors.quantity[0]
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <label>Tax Method</label>
                    <input
                      type="text"
                      placeholder="Enter your tax method"
                      class="form-control"
                      v-model="data.details[index].tax_method"
                    />
                    <span v-if="errors.tax_method" class="form-text text-danger">{{
                      errors.tax_method[0]
                    }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Tax Percent</label>
                    <input
                      type="text"
                      placeholder="Enter tax percent"
                      class="form-control"
                      v-model="data.details[index].tax_percent"
                    />
                    <span v-if="errors.tax_percent" class="form-text text-danger">{{
                      errors.tax_percent[0]
                    }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Discount Method</label>
                    <input
                      type="text"
                      placeholder="Enter your discount method"
                      class="form-control"
                      v-model="data.details[index].discount_Method"
                    />
                    <span v-if="errors.discount_Method" class="form-text text-danger">{{
                      errors.discount_Method[0]
                    }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Discount</label>
                    <input
                      type="text"
                      placeholder="Enter discount"
                      class="form-control"
                      v-model="data.details[index].discount"
                    />
                    <span v-if="errors.discount" class="form-text text-danger">{{
                      errors.discount[0]
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-secondary float-right" @click="addProductInput()">
              Add Product <i class="icon-plus-circle2 ml-2"></i>
            </button>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="addInvoice()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Add Invoice -->
  </div>
</template>

<style scoped>
@media print {
  .printable {
    overflow: hidden;
  }
  .modal-footer {
    display: none;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import { StorageService } from '@/services/storage.service'
import VueNextSelect from "vue-next-select";
import util from "@/helpers/util.js";
import moment from "moment";

export default {
  name: "Invoices",
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      selectedIds: [],
      errors: [],
      modal: 0,
      id: null,
      selectedProducts: [],
      countSelectedProduct: 0,
      data: {
        stripe_key: "",
        date: null,
        client_id: null,
        GrandTotal: 0,
        warehouse_id: null,
        tax_rate: 0,
        TaxNet: 0,
        discount: 0,
        shipping: 0,
        status: 'Paid',
        notes: '',
        payment: {
          status: 'Paid',
          Reglement: "",
          amount: 0,
        },
        details: [
          {
            quantity: null,
            Unit_price: null,
            tax_percent: 0,
            tax_method: '',
            discount: 0,
            discount_Method: '',
            product_id: '',
            product_variant_id: '',
            subtotal: 0,
          },
        ],
      },
    };
  },
  created() {
    this.fetchPreOrderInvoices();
    this.fetchClients();
    this.fetchProducts();
  },
  updated() {
    if (!$.fn.DataTable.isDataTable(".invoice-archive")) {
      $(".invoice-archive").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            width: '2%',
            targets: 0,
          },
          {
            visible: false,
            targets: 1,
          },
          {
            orderable: false,
            width: 120,
            targets: 5,
          },
          {
            width: "15%",
            targets: 4,
          },
          {
            width: "20%",
            targets: 3,
          },
        ],
        order: [[0, "desc"]],
        dom: '<"datatable-header"fl><"datatable-scroll-lg"t><"datatable-footer"ip>',
        language: {
          search: "<span>Filter:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 25,
        drawCallback: function () {
          var api = this.api();
          var rows = api.rows({ page: "current" }).nodes();
          var last = null;
          api
            .column(1, { page: "current" })
            .data()
            .each(function (group, i) {
              if (last !== group) {
                $(rows)
                  .eq(i)
                  .before(
                    '<tr class="table-active table-border-double"><td colspan="8" class="font-weight-semibold">' +
                      group +
                      "</td></tr>"
                  );
                last = group;
              }
            });
          // Initializw Select2
          if (!$().select2) {
            console.warn("Warning - select2.min.js is not loaded.");
            return;
          }
          $(".form-control-select2").select2({
            width: 150,
            minimumResultsForSearch: Infinity,
          });
        },
      });
    }
  },
  computed: {
    ...mapGetters({
      getPreOrderInvoices: "invoices/getPreOrderInvoices",
      getInvoices: "invoices/getInvoices",
      getInvoice: "invoices/getInvoice",
      getProducts: "products/getProducts",
      getClients: "clients/getClients",
    }),
  },
  async mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Anytime;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    if (!$("#date")) {
      $("#date").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchPreOrderInvoices: "invoices/fetchPreOrderInvoices",
      fetchInvoices: "invoices/fetchInvoices",
      fetchProducts: "products/fetchProducts",
      fetchClients: "clients/fetchClients",
      invoiceById: "invoices/invoiceById",
    }),
    addProductInput() {
      this.countSelectedProduct += 1;
      this.data.details = this.data.details.concat({
        quantity: null,
        Unit_price: null,
        tax_percent: 0,
        tax_method: '',
        discount: 0,
        discount_Method: '',
        product_id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
    deleteProductInput(i) {
      this.countSelectedProduct -= 1;
      this.data.details.splice(i, 1)
    },
    getFormatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getSubtotal(product) {
      let subtotal = 0;
      product.map((item) => {
        subtotal += item.price * item.quantity;
      });
      return subtotal.toLocaleString();
    },
    openInvoice(id) {
      console.log(id);
      this.invoiceById({ id });
      $("#invoice").modal("show");
    },
    sendInvoice() {},
    printInvoice() {
      window.print();
    },
    addSelectedInvoice(id) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id);
      } else {
        this.selectedIds = this.selectedIds.filter(
          (selectedId) => selectedId != id
        );
      }
    },
    openAddInvoiceModal() {
      this.modal = 0;
      this.id = null;
      this.data.name = null;
      this.data.phone = null;
      this.data.email = null;
      this.data.country = null;
      this.data.city = null;
      this.data.adresse = null;
      $("#AddInvoice").modal("show");
    },
    addInvoice() {
      console.log(this.data)
      // this.AddInvoice({ data: this.data })
      //   .then(() => {
      //     util.showToast("Invoice successfully added");
      //     this.fetchPreOrderInvoices();
      //     $("#AddInvoice").modal("hide");
      //   })
      //   .catch((err) => {
      //     console.log(err.response.data);
      //     this.errors = err.response.data.errors;
      //   });
    },
    editInvoice(i) {
      this.modal = 1;
      let val = this.getPreOrderInvoices.clients[i];
      this.id = val.id;
      this.data.name = val.name;
      this.data.phone = val.phone;
      this.data.email = val.email;
      this.data.country = val.country;
      this.data.city = val.city;
      this.data.adresse = val.adresse;
      $("#AddInvoice").modal("show");
    },
    editInvoiceSubmit() {
      let data = this.data;
      this.updateInvoice({ id: this.id, data })
        .then(() => {
          util.showToast("Invoice successfully changed");
          this.fetchPreOrderInvoices();
          $("#AddInvoice").modal("hide");
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.response.data.errors;
        });
    },
    async deleteInvoice(id) {
      const action = () => {
        this.deleteInvoiceById({ id });
      };
      await util.deleteConfirmationToast(action);
      this.fetchPreOrderInvoices();
    },
    async deleteInvoiceBySelectedIds() {
      const action = () => {
        this.deleteInvoiceByIds({ selectedIds: this.selectedIds });
      };
      await util.deleteConfirmationToast(action);
      this.fetchPreOrderInvoices();
    },
    getMembership(value) {
      if (value == 1) {
        return 'RUTHENIUM'
      } else if (value == 2) {
        return 'SILVER'
      } else if (value == 3) {
        return 'GOLD'
      } else if (value == 4) {
        return 'PLATINUM'
      }
      return '-'
    }
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
