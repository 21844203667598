import ApiService from "@/services/api.service";
import { StorageService } from "@/services/storage.service";

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to StorageService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  login: async function(email, password) {
    const requestData = {
      method: "post",
      url: "auth/login",
      data: {
        email: email,
        password: password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      if (response.data.status) {
        StorageService.saveToken(response.data.data.token);
        StorageService.saveUser(JSON.stringify(response.data.data.user));
        ApiService.setHeader();
      }
      // StorageService.saveRefreshToken(response.data.refresh_token);

      // NOTE: We haven't covered this yet in our ApiService
      //       but don't worry about this just yet - I'll come back to it later

      // ApiService.mount401Interceptor();

      return response.data;
    } catch (error) {
      console.log(error);
      // throw new AuthenticationError(
      //   error.response.data.status,
      //   error.response.data.messsage
      // );
    }
  },

  /**
   * Refresh the access token.
   **/
  refreshToken: async function() {
    const refreshToken = StorageService.getRefreshToken();

    const requestData = {
      method: "post",
      url: "/o/token/",
      data: {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_CLIENT_SECRET,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      StorageService.saveToken(response.data.token);
      StorageService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      ApiService.setHeader();

      return response.data.token;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    StorageService.removeUser();
    StorageService.removeToken();
    StorageService.removeRefreshToken();
    ApiService.removeHeader();

    // NOTE: Again, we'll cover the 401 Interceptor a bit later.
    // ApiService.unmount401Interceptor()
  },
};

export default UserService;

export { UserService, AuthenticationError };
