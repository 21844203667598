<template>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <label for="InvoiceIsOffline">Method:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group">
                  <vue-select
                    v-model="method"
                    :options="[{id: 1, name: 'Send via Email'}, {id: 2, name: 'Print (Offline'}]"
                    placeholder="Please Select"
                    label-by="name"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <label for="InvoiceIsOffline">Invoice:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group">
                  <vue-select
                    v-if="getInvoices.sales"
                    v-model="invoices"
                    :options="getInvoices.sales"
                    placeholder="Please Select"
                    label-by="Ref"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                    @update:modelValue="(value) => onSelectedInvoice(value, index)"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <label for="InvoiceIsOffline">Refund Type:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group">
                  <vue-select
                    v-model="data.refundType"
                    :options="[
                      { name: 'Credit' },
                      { name: 'New Replacement' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="name"
                    style="width: 100% !important"
                    close-on-select
                    searchable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered bg-white">
          <tr>
            <td class="invoice-input">Invoice Number</td>
            <td class="invoice-input p-2" v-if="!isManualInvoiceNumber">
              <div class="d-flex justify-content-between px-2">
                <span>INV123098</span>
                <i class="icon-trash cursor-pointer" @click="changeToManualInvoiceNumber()"></i>
              </div>
            </td>
            <td class="invoice-input" v-if="isManualInvoiceNumber">
              <input
                type="text"
                placeholder="Enter invoice number"
                class="form-control"
              />
            </td>
          </tr>
          <tr>
            <td class="invoice-input">Invoice Date</td>
            <td class="invoice-input">
              <input
                type="date"
                placeholder="Enter invoice date"
                class="form-control"
                v-model="data.date"
              />
            </td>
          </tr>
          <tr>
            <td class="invoice-input">Issue Date</td>
            <td class="invoice-input">
              <input
                type="date"
                placeholder="Enter issue date"
                class="form-control"
                v-model="data.issue_date"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row mt-4">
      <table class="table table-sm bg-white">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Item</th>
            <th scope="col">Unit Price</th>
            <th scope="col">Qty</th>
            <th scope="col">Tax</th>
            <th scope="col">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in data.details.length" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td style="width: 20%">
              {{ data.details[index].product_name }}
            </td>
            <td>
              ${{ data.details[index].Unit_price }}
            </td>
            <td>
              {{ data.details[index].quantity }}
            </td>
            <td>
              {{ data.details[index].tax_method == 1 ? 'GST INC' : 'GST' }}
            </td>
            <td>${{ data.details[index].subtotal }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div>
        <!-- <button
          type="button"
          class="btn btn-success btn-sm"
          @click="addProductInput()"
        >
          <b><i class="icon-plus-circle2 mr-2"></i></b> Add Items
        </button> -->
      </div>
      <div class="col-md-4 ml-auto bg-white">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <th>Subtotal:</th>
                <td class="text-right">
                  ${{ subtotal.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <th>
                  Tax: <span class="font-weight-normal">(10%)</span>
                </th>
                <td class="text-right">
                  ${{ tax.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <th>
                  Discount:
                </th>
                <td class="text-right">
                  <span v-if="data.isPercentDiscount == 10">{{ data.discount }}%</span>
                  <span v-if="data.isPercentDiscount == 1">${{ getDiscount() }}</span>
                </td>
              </tr>
              <tr>
                <th>Total:</th>
                <td class="text-right text-primary">
                  <h5 class="font-weight-semibold">
                    ${{ total.toFixed(2) }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body">
        <div class="col-sm-12">
          <label>Notes </label>
          <textarea
            rows="2"
            cols="3"
            class="form-control"
            placeholder="Notes"
            v-model="data.notes"
          >
          </textarea>
          {{ data }}
          <button class="btn btn-primary float-right mt-2" type="button" @click="submit()">
            <i class="icon-plus-circle2 mr-2"></i> SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import VueNextSelect from "vue-next-select";
import util from '@/helpers/util';
export default {
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      wys: ClassicEditor,
      tos: null,
      errors: [],
      isManualInvoiceNumber: false,
      countSelectedProduct: 0,
      invoices: null,
      tax: 0,
      subtotal: 0,
      total: 0,
      method: 0,
      data: {
        refundType: null,
        date: new Date().toISOString().slice(0,10),
        issue_date: new Date().toISOString().slice(0,10),
        client_id: null,
        sale_id: null,
        warehouse_id: null,
        tax_rate: 0,
        TaxNet: 0,
        discount: 0,
        shipping: 0,
        GrandTotal: 0,
        statut: 'Paid',
        type: 'Refund',
        notes: '',
        payment: {
          status: 'Paid',
          Reglement: "",
          amount: 0,
        },
        details: [
        ],
      },
    }
  },
  created() {
    this.fetchClients();
    this.fetchProducts();
    this.fetchInvoices();
  },
  mounted() {
    Summernote;
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();
  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
      getProducts: "products/getProducts",
      getInvoices: "invoices/getInvoices",
    }),
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchProducts: "products/fetchProducts",
      fetchInvoices: "invoices/fetchInvoices",
      createReturn: "refunds/createReturn",
    }),
    changeToManualInvoiceNumber() {
      this.isManualInvoiceNumber = true
    },
    sumRefundSubtotal(i) {
      this.data.details[i].subtotal = this.data.details[i].refund_quantity * this.data.details[i].Unit_price
    },
    onSelectedInvoice(value) {
      console.log('valuee', value.details)
      this.data.client_id = value.client_id
      this.data.sale_id = value.id
      this.data.warehouse_id = value.warehouse_id
      this.data.tax_rate = value.tax_rate
      this.data.TaxNet = value.TaxNet
      this.data.discount = value.discount
      this.data.shipping = value.shipping
      this.data.GrandTotal = value.GrandTotal
      this.data.statut = 'Complete'
      this.data.notes = ''
      var details = [];
      value.details.map(item =>
        details.push({
          quantity: item.quantity,
          refund_quantity: item.quantity,
          Unit_price: item.product.price,
          tax_percent: item.TaxNet,
          tax_method: item.tax_method,
          discount: item.discount,
          discount_Method: item.discount_method,
          product_id: item.product_id,
          product_name: item.product.name,
          product_variant_id: item.product_variant_id,
          subtotal: item.total,
        })
      );
      this.data.details = details;
      this.productSubtotal();
    },
    onSelectedProduct(value, index) {
      if (this.data.details.includes(value.product_id)) {
        this.data.details = this.data.details.filter(item => item.product.id != value.product_id)
      }
      this.data.details[index].description = value.note
      this.data.details[index].Unit_price = value.price
      this.data.details[index].tax_method = value.tax_method
      $(`.qty${index}`).focus();
      console.log(value)
    },
    productSubtotal(i) {
      let tax = 0;
      let subtotal = 0;
      this.data.details.forEach(item => {
        // 1 = GST INC
        if (item.tax_method == 1) {
          let itemTax = item.subtotal / 11;
          tax += itemTax;
          subtotal += item.subtotal - tax;
        }else{
          subtotal += item.subtotal;
          tax += item.subtotal * 10 / 100;
        }
      });
      this.tax = tax;
      this.subtotal = subtotal;
      var total = tax + subtotal;
      var disc = total * this.data.discount / 100
      this.total = subtotal + tax - disc;
      if (this.data.details[i]) {
        this.data.details[i].subtotal = this.data.details[i]?.Unit_price * this.data.details[i]?.quantity
      }
    },
    getDiscount() {
      // 10 = Percent | 1 = Fixed $
      console.log(this.data.isPercentDiscount)
      var disc = 0;
      var total = this.subtotal + this.tax;
      if (this.data.isPercentDiscount == 1) {
      console.log('percent')
        disc = this.data.discount
        this.total = total - this.data.discount;
      }else{
        disc = total * this.data.discount / 100
        this.total = total - disc;
      }
      return disc;
    },
    addProductInput() {
      console.log(this.wys)
      this.countSelectedProduct += 1;
      this.data.details = this.data.details.concat({
        description: null,
        quantity: null,
        Unit_price: null,
        tax_percent: 0,
        tax_method: '',
        discount: 0,
        discount_Method: '',
        product_id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
    submit() {
      this.createReturn({data: this.data})
      .then(res => {
        res.success && this.$router.push('/refund-receipts')
        util.showToast('Refund successfully created')
      })
      .catch(error => {
        console.log(error)
        this.errors = error.response.data.errors;
      })
    }
  },
}
</script>

<style>
  .invoice-input {
    padding: .25rem 1.25rem !important;
  }
  .uniform-uploader {
    margin-top: .625rem!important;
    margin-bottom: .625rem!important;
  }
  .note-frame {
    border: 1px solid #ddd !important;
  }
</style>