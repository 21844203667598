/* ------------------------------------------------------------------------------
 *
 *  # Checkboxes and radios
 *
 *  Demo JS code for form_checkboxes_radios.html page
 *
 * ---------------------------------------------------------------------------- */

// Setup module
// ------------------------------
/* eslint-disable */
var InputsCheckboxesRadios = (function() {
  //
  // Setup components
  //

  // Uniform
  var _componentUniform = function() {
    if (!$().uniform) {
      console.warn("Warning - uniform.min.js is not loaded.");
      return;
    }

    // Default initialization
    $(".form-check-input-styled").uniform();

    //
    // Contextual colors
    //

    // Primary
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });

    // Danger
    $(".form-check-input-styled-danger").uniform({
      wrapperClass: "border-danger text-danger",
    });

    // Success
    $(".form-check-input-styled-success").uniform({
      wrapperClass: "border-success text-success",
    });

    // Warning
    $(".form-check-input-styled-warning").uniform({
      wrapperClass: "border-warning text-warning",
    });

    // Info
    $(".form-check-input-styled-info").uniform({
      wrapperClass: "border-info text-info",
    });

    // Custom color
    $(".form-check-input-styled-custom").uniform({
      wrapperClass: "border-indigo-400 text-indigo-400",
    });
  };

  // Switchery
  var _componentSwitchery = function() {
    if (typeof Switchery == "undefined") {
      console.warn("Warning - switchery.min.js is not loaded.");
      return;
    }

    // Initialize multiple switches
    var elems = Array.prototype.slice.call(
      document.querySelectorAll(".form-check-input-switchery")
    );
    elems.forEach(function(html) {
      var switchery = new Switchery(html);
    });

    // Colored switches
    var primary = document.querySelector(".form-check-input-switchery-primary");
    var switchery = new Switchery(primary, { color: "#2196F3" });

    var danger = document.querySelector(".form-check-input-switchery-danger");
    var switchery = new Switchery(danger, { color: "#EF5350" });

    var warning = document.querySelector(".form-check-input-switchery-warning");
    var switchery = new Switchery(warning, { color: "#FF7043" });

    var info = document.querySelector(".form-check-input-switchery-info");
    var switchery = new Switchery(info, { color: "#00BCD4" });
  };

  // Bootstrap switch
  var _componentBootstrapSwitch = function() {
    if (!$().bootstrapSwitch) {
      console.warn("Warning - switch.min.js is not loaded.");
      return;
    }

    // Initialize
    $(".form-check-input-switch").bootstrapSwitch();
  };

  //
  // Return objects assigned to module
  //

  return {
    initComponents: function() {
      _componentUniform();
      _componentSwitchery();
      _componentBootstrapSwitch();
    },
  };
})();

// Initialize module
// ------------------------------

document.addEventListener("DOMContentLoaded", function() {
  InputsCheckboxesRadios.initComponents();
});
