import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  stocks: {
    stocks: [],
  },
  stock: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStocks: (state) => {
    return state.stocks;
  },
  getStock: (state) => {
    return state.stock;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_STOCKS: (state, data) => (state.stocks = data),
  SET_STOCK: (state, data) => (state.stock = data),
};

const actions = {
  async fetchStocks({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/stocks/stocks?limit=10000&search&SortType=desc&SortField=id"
      );
      commit("SET_STOCKS", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_STOCKS", {});
    }
    commit("SET_LOADER", false);
  },

  async stockById({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get("/stock-management/show/" + id);
    commit("SET_STOCK", response.data);
    commit("SET_LOADER", false);
    return response.data
  },
  async AddStock({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("/stock-management/create", { ...data });
    console.log('response', response)
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateStock({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.post("/stock-management/update/" + id, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response.data;
  },
  async exportStocks() {
    await ApiService.get("/stocks/export/Excel", {
      'headers': {
        'Content-Disposition': "attachment; filename=Users.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Staff.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  async deleteStockById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/stocks/stocks/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
  async deleteStockBySelectedIds({ commit }, { selectedIds }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/stocks/stocks/", {
        selectedIds
      });
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
