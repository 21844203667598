const ACCESS_TOKEN = 'access_token'
const ACCESS_REFRESH_TOKEN = 'refresh_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/
const StorageService = {
    getUser() {
        return JSON.parse(localStorage.getItem('user'))
    },

    saveUser(accessUser) {
        localStorage.setItem('user', accessUser)
    },

    removeUser() {
        localStorage.removeItem('user')
    },
    getToken() {
        return localStorage.getItem(ACCESS_TOKEN)
    },

    saveToken(accessToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken)
    },

    removeToken() {
        localStorage.removeItem(ACCESS_TOKEN)
    },

    getRefreshToken() {
        return localStorage.getItem(ACCESS_REFRESH_TOKEN)
    },

    saveRefreshToken(refreshToken) {
        localStorage.setItem(ACCESS_REFRESH_TOKEN, refreshToken)
    },

    removeRefreshToken() {
        localStorage.removeItem(ACCESS_REFRESH_TOKEN)
    }

}

export { StorageService }