import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  points: [],
  point: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPoints: (state) => {
    return state.points;
  },
  getPoint: (state) => {
    return state.point;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_POINTS: (state, data) => (state.points = data),
  SET_POINT: (state, data) => (state.point = data),
};

const actions = {
  async fetchPoints({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`points`);
      commit("SET_POINTS", response.data.points);
    } catch (error) {
      console.log(error);
      commit("SET_POINTS", {});
    }
    commit("SET_LOADER", false);
  },
  async createPoint({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("points", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updatePoint({ commit }, { id, data }) {
    let response = await ApiService.put(`points/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deletePointById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`points/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
