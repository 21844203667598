import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  products: [],
  product: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getProducts: (state) => {
    return state.products;
  },
  getProduct: (state) => {
    return state.product;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_PRODUCTS: (state, data) => (state.products = data),
  SET_PRODUCT: (state, data) => (state.product = data),
};

const actions = {
  async fetchProducts({ commit }) {
    commit("SET_LOADER", true);
    try {
      var response = null;
      response = await ApiService.get(`products`);
      // if (filter_brand || filter_category) {
      //   response = await ApiService.get(`products?filter_brand=${filter_brand || ''}&filter_category=${filter_category || ''}`);
      // }else{
      //   response = await ApiService.get(`products?filter_brand=${filter_brand || ''}&filter_category=${filter_category || ''}`);
      // }
      commit("SET_PRODUCTS", response.data.products);
    } catch (error) {
      console.log(error);
      commit("SET_PRODUCTS", {});
    }
    commit("SET_LOADER", false);
  },
  async createProduct({ commit }, { data }) {
    commit("SET_LOADER", true);
    var formData = new FormData();
    for (var key in data) {
      if (Array.isArray(data[key])) {
        data[key].map((v, i) => {
          formData.append(key + `[${i}]`, Number(v))
        })
      }else{
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
    }
    let response = await ApiService.post("products", formData, { "Content-Type": "multipart/form-data" });
    commit("SET_LOADER", false);
    console.log(response)
    return response.data;
  },
  async updateStatusProduct({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`products/update-status/${id}`);
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateStock({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`products/update-stock/${id}`);
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateStockProduct({ commit }, { id, stock }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post(`products/update-stock-product/${id}`, {
      stock
    });
    commit("SET_LOADER", false);
    return response.data;
  },
  async updatePreOrderProduct({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`products/update-preorder/${id}`);
    commit("SET_LOADER", false);
    return response.data;
  },
  async updatePreOwnProduct({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`products/update-preown/${id}`);
    commit("SET_LOADER", false);
    return response.data;
  },
  async updatePriceProduct({ commit }, { id, price }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post(`products/update-price/${id}`, {
      price
    });
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateProduct({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    var formData = new FormData();
    for (var key in data) {
      if (Array.isArray(data[key])) {
        data[key].map((v, i) => {
          formData.append(key + `[${i}]`, Number(v))
        })
      } else {
        if (data[key] != null) {
          formData.append(key, data[key]);
        }
      }
    }
    formData.append('_method', 'PUT');
    let cms2 = await ApiService.post("/products/" + id, formData, { "Content-Type": "multipart/form-data" })
    console.log(cms2)
    commit("SET_LOADER", false);
    return cms2.data;
  },
  async deleteProductById({ commit }, { product_id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/products/" + product_id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
