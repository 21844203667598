import { UserService } from "@/services/user.service";
import { StorageService } from "@/services/storage.service";
import router from "@/router";

const state = {
  authenticating: false,
  accessToken: StorageService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: "",
};

const getters = {
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },

  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode;
  },

  authenticationError: (state) => {
    return state.authenticationError;
  },

  authenticating: (state) => {
    return state.authenticating;
  },
};

const actions = {
  async login({ commit }, { email, password }) {
    commit("loginRequest");

    // try {
      const response = await UserService.login(email, password);
      if (response.status) {
        commit("loginSuccess", response.data.token);
      }

      return response;
    // } catch (e) {
    //   if (e instanceof AuthenticationError) {
    //     commit("loginError", {
    //       errorCode: e.errorCode,
    //       errorMessage: e.message,
    //     });
    //   }

    //   return e;
    // }
  },

  logout({ commit }) {
    UserService.logout();
    commit("logoutSuccess");
    router.push("/login");
  },
};

const mutations = {
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = "";
    state.authenticationErrorCode = 0;
  },

  loginSuccess(state, accessToken) {
    state.accessToken = accessToken;
    state.authenticating = false;
  },

  loginError(state, { errorCode, errorMessage }) {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },

  logoutSuccess(state) {
    state.accessToken = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
