import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  purchases: {
    purchases: [],
  },
  purchase: {},
  purchaseNumber: '',
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPurchases: (state) => {
    return state.purchases;
  },
  getPurchase: (state) => {
    return state.purchase;
  },
  getPurchaseNumber: (state) => {
    return state.purchase;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_PURCHASES: (state, data) => (state.purchases = data),
  SET_PURCHASE: (state, data) => (state.purchase = data),
  SET_PURCHASE_NUMBER: (state, data) => (state.purchaseNumber = data),
};

const actions = {
  async fetchPurchases({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/purchases/list-purchases?limit=10000&search&SortType=desc&SortField=id"
      );
      commit("SET_PURCHASES", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_PURCHASES", {});
    }
    commit("SET_LOADER", false);
  },

  async purchaseById({ commit }, { id }) {
    commit("SET_LOADER", true);
      let response = await ApiService.get("/purchases/list-purchases/" + id);
      console.log(response.data)
      commit("SET_PURCHASE", response.data);
      commit("SET_LOADER", false);
      return response.data
  },
  async getLastId({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("/purchases/get-purchase-number");
      console.log(response.data)
      commit("SET_PURCHASE_NUMBER", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_PURCHASE_NUMBER", '');
    }
    commit("SET_LOADER", false);
  },
  async AddPurchase({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("/purchases/purchases", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updatePurchase({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put("/purchases/purchases/" + id, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async exportPurchases() {
    await ApiService.get("/purchases/export/Excel", {
      'headers': {
        'Content-Disposition': "attachment; filename=Users.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Staff.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  async deletePurchaseById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/purchases/purchases/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
  async deletePurchaseBySelectedIds({ commit }, { selectedIds }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/purchases/purchases/", {
        selectedIds
      });
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
