<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <label for="InvoiceIsOffline">PO Number:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group">
                  <vue-select
                    v-if="getPurchases.purchases"
                    v-model="purchase"
                    :options="getPurchases.purchases"
                    placeholder="Please Select"
                    label-by="Ref"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                    @update:modelValue="(value) => onSelectedPurchase(value, index)"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <label for="InvoiceIsOffline">Supplier:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group">
                  <span>{{ supplier }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mt-n1">
              <div class="row">
                <div class="col-md-3 col-lg-3 mt-2">
                  <label for="InvoiceIsOffline">Issue date:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group d-flex align-items-center">
                  <input
                    type="date"
                    placeholder="Enter issue date"
                    class="form-control"
                    v-model="data.issue_date"
                  />
                </div>
                <span v-if="errors.issue_date" class="form-text text-danger">
                  {{ errors.issue_date[0] }}
                </span>
              </div>
              <span v-if="errors.issue_date" class="form-text text-danger">
                {{ errors.issue_date[0] }}
              </span>
            </div>
            <div class="form-group mt-n1">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <label for="InvoiceIsOffline">Warehouse:</label>
                </div>
                <div class="col-md-9 col-lg-9 form-group">
                  <span>{{ warehouse }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <table class="table table-sm bg-white" id="products" v-if="products">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">SKU</th>
            <th scope="col">Name</th>
            <th scope="col">Unit Price</th>
            <th scope="col">Serial Number</th>
          </tr>
        </thead>
        <tbody v-for="(product, index) in products" :key="index">
          <tr v-for="(sn, i) in products[index]?.quantity" :key="i">
            <th scope="row">{{ i + 1 }}</th>
            <td style="width: 20%">
              <span>{{ products[index]?.product?.sku1 }}</span>
            </td>
            <td style="width: 32.5%">
              <span>{{ products[index]?.product?.name }}</span>
            </td>
            <td>
              <span>${{ products[index]?.product?.price }}</span>
            </td>
            <td>
              <input
                type="text"
                placeholder="Serial Number"
                class="form-control"
                @change="handleSerialNumber($event, i, products[index]?.product?.id, index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-body">
      <button class="btn btn-primary ml-auto" type="button" @click="saveStock()">
        <i class="icon-plus-circle2 mr-2"></i> SAVE
      </button>
    </div>
  </div>
</template>

<script>
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import { mapActions, mapGetters } from 'vuex';
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import VueNextSelect from "vue-next-select";
import util from '@/helpers/util';
export default {
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      wys: ClassicEditor,
      tos: null,
      details: [],
      purchase: null,
      supplier: '-',
      warehouse: '-',
      errors: [],
      countSelectedProduct: 0,
      tax: 0,
      subtotal: 0,
      total: 0,
      data: {
        purchase_id: '',
        supplier_id: '',
        serial_numbers: [],
        issue_date: null,
      },
    }
  },
  created() {
    this.fetchClients();
    this.fetchProducts();
    this.fetchWarehouses();
    this.fetchPurchases();
  },
  mounted() {
    Summernote;
    Datatables
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();
  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
      getProducts: "products/getProducts",
      getWarehouses: "warehouses/getWarehouses",
      getPurchases: "purchases/getPurchases",
    }),
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchProducts: "products/fetchProducts",
      fetchWarehouses: "warehouses/fetchWarehouses",
      fetchPurchases: "purchases/fetchPurchases",
      AddStock: "stockManagement/AddStock",
    }),
    handleSerialNumber(event, i, productId) {
      let check = this.data.serial_numbers.filter(item => item.product_id == productId)
      if (!check) {
        this.data.serial_numbers = this.data.serial_numbers.concat({
          serial_number: null,
          product_id: null,
        })
        this.data.serial_numbers[i].serial_number = event.target.value;
        this.data.serial_numbers[i].product_id = productId;
      }else{
        this.data.serial_numbers = this.data.serial_numbers.concat({
          serial_number: event.target.value,
          product_id: productId,
        })
      }
    },
    saveStock() {
      this.AddStock({data: this.data})
      .then(res => {
        res.success && this.$router.push('/stock-management')
        util.showToast('Stock successfully saved')
      })
      .catch(error => {
        console.log(error)
        this.errors = error.response.data.errors;
      })
    },
    onSelectedPurchase(value) {
      this.supplier = value.provider_name;
      this.data.supplier_id = value.provider_id;
      this.data.purchase_id = value.id;
      this.products = value.details;
      this.warehouse = value.warehouse_name;
      console.log(value)
      $("#products").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            orderable: false,
            targets: 0,
            width: 10,
          },
          {
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-pdf"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    },
    productSubtotal(i) {
      this.data.details[i].subtotal = this.data.details[i].Unit_price * this.data.details[i].quantity
      let tax = 0;
      let subtotal = 0;
      this.data.details.forEach(item => {
        // 1 = GST INC
        if (item.tax_method == 1) {
          let itemTax = item.subtotal / 11;
          tax += itemTax;
          subtotal += item.subtotal - tax;
        }else{
          subtotal += item.subtotal;
          tax += item.subtotal * 10 / 100;
        }
      });
      this.tax = tax;
      this.subtotal = subtotal;
      this.total = subtotal + tax;
    },
    addProductInput() {
      console.log(this.wys)
      this.countSelectedProduct += 1;
      this.data.details = this.data.details.concat({
        description: null,
        quantity: null,
        Unit_price: null,
        tax_percent: 0,
        tax_method: '',
        discount: 0,
        discount_Method: '',
        product_id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
  },
}
</script>

<style>
  .invoice-input {
    padding: .25rem 1.25rem !important;
  }
  .uniform-uploader {
    margin-top: .625rem!important;
    margin-bottom: .625rem!important;
  }
  .note-frame {
    border: 1px solid #ddd !important;
  }
</style>