import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  pages: [],
  page: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getPages: (state) => {
    return state.pages;
  },
  getPage: (state) => {
    return state.page;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_PAGES: (state, data) => (state.pages = data),
  SET_PAGE: (state, data) => (state.page = data),
};

const actions = {
  async fetchPages({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("pages");
      commit("SET_PAGES", response.data.pages);
    } catch (error) {
      console.log(error);
      commit("SET_PAGES", {});
    }
    commit("SET_LOADER", false);
  },
  async createPage({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("pages", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updatePage({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`/pages/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deletePageById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`pages/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
