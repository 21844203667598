<template>
  <div class="ticket-detail">
    <div class="card">
      <div class="card-header header-elements-inline border-bottom">
        <h6 class="card-title">Client Details</h6>
      </div>
      <div class="row d-flex justify-content-center align-items-center mb-2" v-if="getTicket">
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold">Full name:</span>
              <div class="ml-auto">
                {{ getClient.name}}
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Email</span>
              <div class="ml-auto">{{ getClient.email }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Phone Number</span>
              <div class="ml-auto">{{ getClient.phone }}</div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto">{{ getClient.adresse || '-' }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Country</span>
              <div class="ml-auto">{{ getClient.country || '-' }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">City</span>
              <div class="ml-auto">{{ getClient.city || '-' }}</div>
            </li>
            <!-- <li class="list-group-item my-n1">
              <span class="font-weight-bold">Actions</span>
              <div class="ml-auto">
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditTicket"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="card-header header-elements-inline border-bottom border-top">
        <h6 class="card-title">Ticket Details</h6>
      </div>
      <div class="row d-flex justify-content-center align-items-center mb-2" v-if="getTicket">
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold">Departement:</span>
              <div class="ml-auto">
                {{ getDepartement.value}}
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Ref ID</span>
              <div class="ml-auto">{{ getTicket.ref_id }} hours</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Duration</span>
              <div class="ml-auto">{{ getTicket.duration }} hours</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Subject</span>
              <div class="ml-auto">{{ getTicket.subject }}</div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold">Priority</span>
              <div class="ml-auto">{{ getTicket.periority || '-' }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Status</span>
              <div class="btn-group ml-auto mr-n3">
                <button
                  type="button"
                  class="btn btn-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  {{ getTicket.status }}
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button href="#" class="dropdown-item" @click="updateTicketStatus(getTicket.id, 'OPEN', getTicket.ref_id)">
                    Open
                  </button>
                  <button href="#" class="dropdown-item" @click="updateTicketStatus(getTicket.id, 'PENDING')">
                    Pending
                  </button>
                  <button href="#" class="dropdown-item" @click="updateTicketStatus(getTicket.id, 'CLOSE')">
                    Close
                  </button>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Time Start</span>
              <div class="ml-auto">{{ getTicket.time_start || '-' }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Time End</span>
              <div class="ml-auto">{{ getTicket.time_end || '-' }}</div>
            </li>
            <!-- <li class="list-group-item my-n1">
              <span class="font-weight-bold">Actions</span>
              <div class="ml-auto">
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditTicket"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- Edit Ticket Modal -->
    <div id="EditTicket" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Edit Ticket</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0" v-if="getClient.firstname">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Firstname</label>
                  <input
                    type="text"
                    placeholder="Enter your firstname"
                    class="form-control"
                    v-model="data.firstname"
                  />
                <span v-if="errors.firstname" class="form-text text-danger">{{ errors.firstname[0] }}</span>

                </div>

                <div class="col-sm-6">
                  <label>Lastname</label>
                  <input
                    type="text"
                    placeholder="Enter your lastname"
                    class="form-control"
                    v-model="data.lastname"
                  />
                <span v-if="errors.lastname" class="form-text text-danger">{{ errors.lastname[0] }}</span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    class="form-control"
                    v-model="data.email"
                  />
                  <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Username</label>
                  <input
                    type="username"
                    placeholder="Enter your username"
                    class="form-control"
                    v-model="data.username"
                  />
                  <span v-if="errors.username" class="form-text text-danger">{{ errors.username[0] }}</span>

                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter your phone number"
                    class="form-control"
                    v-model="data.phone"
                  />
                  <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Phone 2</label>
                  <input
                    type="tel"
                    placeholder="Enter your second phone number"
                    class="form-control"
                    v-model="data.phone_2"
                  />
                  <span v-if="errors.phone_2" class="form-text text-danger">{{ errors.phone_2[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Address</label>
                  <textarea
                    rows="2"
                    cols="3"
                    class="form-control"
                    placeholder="Enter your address"
                    v-model="data.address"
                  >
                    Detail address
                  </textarea
                  >
                <span v-if="errors.address" class="form-text text-danger">{{ errors.address[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    class="form-control"
                    v-model="data.password"
                  />
                  <small>* Leave this blank if won't change</small>
                  <span v-if="errors.password" class="form-text text-danger">{{ errors.password[0] }}</span>

                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Ticket -->

    <!-- Add Ticket Modal -->
    <div id="AddTicket" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Add Ticket</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control daterange-single" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" id="begin_time" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" id="end_time" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>Duration (hour)</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value="4 hours"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="2" cols="3" class="form-control"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="4" cols="3" class="form-control"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Ticket -->

    <!-- Edit Ticket Modal -->
    <div id="EditTicket" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Edit Ticket</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control daterange-single" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" value="08:00" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" value="17:00" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>Duration (hour)</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value="9 hours"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="3" cols="3" class="form-control">
 List Activities </textarea
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="5" cols="3" class="form-control">
Description ...</textarea
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">
              Update <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Ticket -->

    <!-- Detail Ticket Modal -->
    <div id="DetailTicket" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Detail Ticket</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      class="form-control daterange-single"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      value="08:00"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      value="17:00"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>Duration (hour)</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value="9 hours"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="3" cols="3" class="form-control" disabled>
 List Activities </textarea
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="5" cols="3" class="form-control" disabled>
Description ...</textarea
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3"></div>
        </div>
      </div>
    </div>
    <!-- /Detail Ticket -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'

export default {
  name: "TicketDetail",
  data() {
    return {
      errors: [],
      // data: {
        // id: null,
        // firstname: null,
        // lastname: null,
        // username: null,
        // email: null,
        // phone: null,
        // phone_2: null,
        // address: null,
        // password: null,
        // role_id: null,
        // permission_id: [],
        // statut: null,
      // },
    };
  },
  created() {
    this.ticketById({ id: this.$route.params.id });
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-customers')) {
      $(".table-customers").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 400,
          },
          {
            orderable: false,
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-excel"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
          {
            extend: "pdfHtml5",
            text: '<i class="icon-printer"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Anytime;
    Summernote;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".select-role").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".select-status").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    if (!$("#begin_time")) {
      $("#begin_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    if (!$("#begin_time")) {
      $("#end_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".summernote").summernote();
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  computed: {
    ...mapGetters({
      getTicket: "tickets/getTicket",
      getClient: "tickets/getClient",
      getDepartement: "tickets/getDepartement",
    }),
  },
  methods: {
    ...mapActions({
      ticketById: "tickets/ticketById",
      editTicketStatus: "tickets/editTicketStatus",
    }),
    async updateTicketStatus(id, status) {
      if (status == 'OPEN') {
        await this.editTicketStatus({id, url: 'reopen-tiket'});
      } else if (status == 'PENDING') {
        await this.editTicketStatus({id, url: 'pending-tiket'});
      }else if (status == 'CLOSE') {
        await this.editTicketStatus({id, url: 'close-tiket'});
      }
      this.ticketById({ id: this.$route.params.id });
    },
    async deleteTicket(id) {
      const action = () => {
        this.deleteTicketById({id})
      }
      await util.deleteConfirmationToast(action)
      this.ticketById({ id: this.$route.params.id })
    },
    async exportTicket() {
      await ApiService.get("/ticket/exportExcel", {
        'headers': {
          'Content-Disposition': "attachment; filename=TimeSheets.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TimeSheets.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
