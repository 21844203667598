import util from '@/helpers/util.js'
import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  invoices: {
    invoices: [],
  },
  invoice: null,
  clientInvoices: null,
  invoiceNumber: '',
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getInvoices: (state) => {
    return state.invoices;
  },
  getPreOrderInvoices: (state) => {
    return state.preOrderInvoices;
  },
  getClientInvoices: (state) => {
    return state.clientInvoices;
  },
  getInvoice: (state) => {
    return state.invoice;
  },
  getInvoiceNumber: (state) => {
    return state.invoiceNumber;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_INVOICES: (state, data) => (state.invoices = data),
  SET_PRE_ORDER_INVOICES: (state, data) => (state.preOrderInvoices = data),
  SET_CLIENT_INVOICES: (state, data) => (state.clientInvoices = data),
  SET_INVOICE: (state, data) => (state.invoice = data),
  SET_INVOICE_NUMBER: (state, data) => (state.invoiceNumber = data),
};

const actions = {
  async fetchInvoices({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("transactions");
      commit("SET_INVOICES", response.data.transaction_groups);
    } catch (error) {
      console.log(error);
      commit("SET_INVOICES", {});
    }
    commit("SET_LOADER", false);
  },
  async fetchPreOrderInvoices({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("preorder-transactions");
      commit("SET_PRE_ORDER_INVOICES", response.data.transactions);
    } catch (error) {
      console.log(error);
      commit("SET_PRE_ORDER_INVOICES", {});
    }
    commit("SET_LOADER", false);
  },

  async invoiceByClientId({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("client-transactions/" + id);
      console.log(response.data.transaction_groups)
      commit("SET_CLIENT_INVOICES", response.data.transaction_groups);
      commit("SET_LOADER", false);
      return response.data.transaction_groups
    } catch (error) {
      console.log(error);
      commit("SET_CLIENT_INVOICES", {});
    }
    commit("SET_LOADER", false);
  },
  async invoiceById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("transactions/" + id);
      console.log(response.data.data.transaction_groups)
      commit("SET_INVOICE", response.data.data.transaction_groups);
      commit("SET_LOADER", false);
      return response.data.data.transaction_groups
    } catch (error) {
      console.log(error);
      commit("SET_INVOICE", {});
    }
    commit("SET_LOADER", false);
  },
  async getLastId({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("/transactions/get-last-id");
      console.log(response.data)
      commit("SET_INVOICE_NUMBER", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_INVOICE_NUMBER", '');
    }
    commit("SET_LOADER", false);
  },
  async AddInvoice({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post("transactions", { ...data });
    commit("SET_LOADER", false);
    console.log(response)
    if (response.data?.status) {
      util.showToast('Invoice successfully saved')
    }
    return response.data;
  },
  async updateInvoice({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.put("/transactions", { ...data });
    if (response.data?.success) {
      util.showToast('Invoice successfully saved')
    }
    console.log(response)
    console.log(id)
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateStatus({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post(`transactions/update-status/${id}`, { ...data });
    if (response.data?.status) {
      util.showToast('Status successfully updated')
    }
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateShipping({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post(`transactions/update-shipping/${id}`, { ...data });
    if (response.data?.status) {
      util.showToast('Shipping successfully updated')
    }
    commit("SET_LOADER", false);
    return response.data;
  },
  async updatePaymentMethod({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post(`transactions/update-payment/${id}`, { ...data });
    if (response.data?.status) {
      util.showToast('Payment method successfully updated')
    }
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateTrackingNumber({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post(`transactions/update-tracking/${id}`, { ...data });
    if (response.data?.status) {
      util.showToast('Tracking number successfully updated')
    }
    commit("SET_LOADER", false);
    return response.data;
  },
  async exportInvoices() {
    await ApiService.get("/invoices/export/Excel", {
      'headers': {
        'Content-Disposition': "attachment; filename=Users.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Staff.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  async deleteInvoiceById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/transactions/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
  async deleteInvoiceByIds({ commit }, { selectedIds }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/transactions/delete-multiple", {
        selectedIds
      });
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
