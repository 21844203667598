<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-2 col-lg-2">
                  <label for="QuotationIsOffline">Method:</label>
                </div>
                <div class="col-md-10 col-lg-10 form-group">
                  <vue-select
                    v-if="getClients.clients"
                    v-model="data.client_id"
                    :options="getClients.clients"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-2 col-lg-2">
                  <label for="QuotationIsOffline">Client:</label>
                </div>
                <div class="col-md-10 col-lg-10 form-group">
                  <vue-select
                    v-if="getClients.clients"
                    v-model="data.client_id"
                    :options="getClients.clients"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered bg-white">
          <tr>
            <td class="quotation-input">Quotation Number</td>
            <td class="invoice-input p-2" v-if="!isManualQuotationNumber">
              <div class="d-flex justify-content-between px-2">
                <span>{{ getQuotationNumber }}</span>
                <i class="icon-trash cursor-pointer" @click="changeToManualQuotationNumber()"></i>
              </div>
            </td>
            <td class="quotation-input" v-if="isManualQuotationNumber">
              <input
                type="text"
                placeholder="Enter quotation number"
                class="form-control"
                v-model="data.quotation_number"
              />
            </td>
          </tr>
          <tr>
            <td class="quotation-input">Quotation Date</td>
            <td class="quotation-input">
              <input
                type="date"
                placeholder="Enter quotation date"
                class="form-control"
                v-model="data.date"
              />
            </td>
          </tr>
          <tr>
            <td class="quotation-input">Issue Date</td>
            <td class="quotation-input">
              <input
                type="date"
                placeholder="Enter issue date"
                class="form-control"
                v-model="data.issue_date"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row mt-4">
      <table class="table table-sm bg-white">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Item</th>
            <th scope="col">Description</th>
            <th scope="col">Unit Price</th>
            <th scope="col">Qty</th>
            <th scope="col">Tax</th>
            <th scope="col">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in countSelectedProduct + 1" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td style="width: 20%">
              <vue-select
                v-if="getProducts.products"
                v-model="data.details[index].product_selected"
                :options="getProducts.products"
                placeholder="Please Select"
                label-by="name"
                close-on-select
                searchable
                @update:modelValue="(value) => onSelectedProduct(value, index)"
              />
            </td>
            <td style="width: 32.5%">
              <input
                type="text"
                placeholder="Enter description"
                class="form-control"
                v-model="data.details[index].description"
              />
            </td>
            <td>
              <input
                type="number"
                placeholder="Unit price"
                class="form-control"
                v-model="data.details[index].Unit_price"
                @change="productSubtotal(index)"
              />
            </td>
            <td>
              <input
                type="number"
                placeholder="Qty"
                class="form-control" :class="`qty${index}`"
                v-model="data.details[index].quantity"
                @change="productSubtotal(index)"
                data-popup="popover"
                data-trigger="focus"
                :data-content="`Available Stock ${data.details[index].quantity}`"
                data-placement="bottom"
              />
            </td>
            <td>
              {{ data.details[index].tax_method == 1 ? 'GST INC' : 'GST' }}
            </td>
            <td>${{ data.details[index].subtotal }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="addProductInput()"
        >
          <b><i class="icon-plus-circle2 mr-2"></i></b> Add Items
        </button>
      </div>
      <div class="col-md-4 ml-auto bg-white">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <th>Subtotal:</th>
                <td class="text-right">
                  ${{ subtotal.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <th>
                  Tax: <span class="font-weight-normal">(10%)</span>
                </th>
                <td class="text-right">
                  <span v-if="data.isPercentDiscount == 10">{{ data.discount }}%</span>
                  <span v-else>${{ getDiscount() }}</span>
                </td>
              </tr>
              <tr>
                <th>Total:</th>
                <td class="text-right text-primary">
                  <h5 class="font-weight-semibold">
                    ${{ total.toFixed(2) }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      <i class="icon-plus-circle2 mr-2"></i> MORE OPTIONS
    </button>
    <div class="collapse mt-2" id="collapseExample">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-discount-tab" data-toggle="tab" href="#nav-discount" role="tab" aria-controls="nav-discount" aria-selected="true"><b>Discount</b></a>
          <a class="nav-item nav-link" id="nav-shipping-details-tab" data-toggle="tab" href="#nav-shipping-details" role="tab" aria-controls="nav-shipping-details" aria-selected="false"><b>Shipping Details</b></a>
          <a class="nav-item nav-link" id="nav-attach-document-tab" data-toggle="tab" href="#nav-attach-document" role="tab" aria-controls="nav-attach-document" aria-selected="false"><b>Attach Document</b></a>
          <a class="nav-item nav-link" id="nav-terms-tab" data-toggle="tab" href="#nav-terms" role="tab" aria-controls="nav-terms" aria-selected="false"><b>Terms & Conditions</b></a>
        </div>
      </nav>
      <div class="tab-content card mt-n3" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-discount" role="tabpanel" aria-labelledby="nav-discount-tab">
          <div class="container">
            <div class="form-group mt-3">
              <div class="row">
                <div class="col-sm-12">
                  <label>Discount</label>
                  <div class="d-flex">
                    <div class="form-inline">
                      <input
                        type="number"
                        placeholder="Enter discount"
                        class="form-control mr-5"
                        v-model="data.discount"
                        @change="getDiscount()"
                      />
                      <vue-select
                        v-model="data.isPercentDiscount"
                        :options="[
                          { id: 10, name: 'Percentage (%)' },
                          { id: 1, name: 'Dollar Value ($)' },
                        ]"
                        placeholder="Please Select"
                        label-by="name"
                        value-by="id"
                        close-on-select
                        searchable
                      />
                    </div>
                  </div>
                  <span v-if="errors.amount" class="form-text text-danger">{{
                    errors.amount[0]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-shipping-details" role="tabpanel" aria-labelledby="nav-shipping-details-tab">
          <div class="col-md-7 p-2">
            <h5>Shipping</h5>
            <div class="form-group mb-n1">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Weight</p>
                </div>
                <div class="col-sm-4">
                  <p>0.00000kg</p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <vue-select
                    v-model="data.status"
                    :options="[
                      { id: 'Pending', name: 'Pending' },
                      { id: 'Unpaid', name: 'Unpaid' },
                      { id: 'Paid', name: 'Paid' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
                <div class="col-sm-4">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="addProductInput()"
                  >
                    Calculate Shipping
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group mb-n1">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Discount</p>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">-$</span>
                    </span>
                    <input type="text" class="form-control">
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                    <span class="input-group-text">(</span>
                    </span>
                    <input type="text" class="form-control">
                    <span class="input-group-append">
                    <span class="input-group-text">%)</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Discount</p>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </span>
                    <input type="text" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" checked="">
              Tax free shipping?
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" checked="">
              Signature Required?
              </label>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-attach-document" role="tabpanel" aria-labelledby="nav-attach-document-tab">
          <div class="container">
            <input
              type="file"
              ref="attachments"
              class="form-control-uniform"
              data-fouc
              multiple
              @change="handleAttachments($event)"
            />
          </div>
        </div>
        <div class="tab-pane fade" id="nav-terms" role="tabpanel" aria-labelledby="nav-terms-tab">
          <div class="container">
            <div class="card mt-3">
              <div class="card-body">
                <label>Terms & Conditions</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <ckeditor :editor="wys" v-model="tos"></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <label>Notes</label>
        <div class="form-group">
          <div class="row">
            <div class="col-sm-12">
              <ckeditor :editor="wys" v-model="data.notes"></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="button" @click="submit()">
      <i class="icon-plus-circle2 mr-2"></i> SAVE
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import VueNextSelect from "vue-next-select";
import util from '@/helpers/util';
export default {
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      wys: ClassicEditor,
      tos: null,
      errors: [],
      isManualQuotationNumber: null,
      countSelectedProduct: 0,
      tax: 0,
      subtotal: 0,
      total: 0,
      data: {
        stripe_key: "",
        date: new Date().toISOString().slice(0,10),
        issue_date: new Date().toISOString().slice(0,10),
        client_id: null,
        GrandTotal: 0,
        warehouse_id: null,
        tax_rate: 0,
        TaxNet: 0,
        discount: 0,
        isPercentDiscount: null,
        shipping: 0,
        status: 'Paid',
        notes: '',
        payment: {
          status: 'Paid',
          Reglement: "",
          amount: 0,
        },
        details: [
          {
            product_selected: null,
            description: null,
            quantity: 0,
            stock: 0,
            Unit_price: null,
            tax_percent: 0,
            tax_method: 'Inclusive',
            discount: 0,
            discount_Method: '',
            product_id: '',
            product_variant_id: '',
            subtotal: 0,
          },
        ],
      },
    }
  },
  created() {
    this.fetchClients();
    this.fetchProducts();
    this.getLastId();
  },
  mounted() {
    Summernote;
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();
  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
      getProducts: "products/getProducts",
      getQuotationNumber: "quotations/getQuotationNumber",
    }),
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchProducts: "products/fetchProducts",
      getLastId: "quotations/getLastId",
      AddQuotation: "quotations/AddQuotation",
    }),
    changeToManualQuotationNumber() {
      this.isManualQuotationNumber = true
    },
    onSelectedProduct(value, index) {
      if (this.data.details.includes(value.product_id)) {
        this.data.details = this.data.details.filter(item => item.product.id != value.product_id)
      }
      this.data.details[index].product_id = value.id
      this.data.details[index].description = value.note
      this.data.details[index].stock = value.stock
      this.data.details[index].Unit_price = value.price
      this.data.details[index].tax_method = value.tax_method
      $(`.qty${index}`).focus();
      console.log(value)
    },
    productSubtotal(i) {
      this.data.details[i].subtotal = this.data.details[i].Unit_price * this.data.details[i].quantity
      let tax = 0;
      let subtotal = 0;
      this.data.details.forEach(item => {
        // 1 = GST INC
        if (item.tax_method == 1) {
          let itemTax = item.subtotal / 11;
          tax += itemTax;
          subtotal += item.subtotal - tax;
        }else{
          subtotal += item.subtotal;
          tax += item.subtotal * 10 / 100;
        }
      });
      this.tax = tax;
      this.subtotal = subtotal;
      this.total = subtotal + tax;
    },
    getDiscount() {
      // 10 = Percent | 1 = Fixed $
      console.log(this.data.isPercentDiscount)
      var disc = 0;
      var total = this.subtotal + this.tax;
      if (this.data.isPercentDiscount == 1) {
      console.log('percent')
        disc = this.data.discount
        this.total = total - this.data.discount;
      }else{
        disc = total * this.data.discount / 100
        this.total = total - disc;
      }
      return disc;
    },
    addProductInput() {
      console.log(this.wys)
      this.countSelectedProduct += 1;
      this.data.details = this.data.details.concat({
        description: null,
        quantity: null,
        Unit_price: null,
        tax_percent: 0,
        tax_method: '',
        discount: 0,
        discount_Method: '',
        product_id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
    submit() {
      this.AddQuotation({data: this.data})
      .then(res => {
        res.data.success && this.$router.push('/quotations')
        util.showToast('Quotations successfully created')
      })
      .catch(error => {
        console.log(error)
        this.errors = error.response.data.errors;
      })
    }
  },
}
</script>

<style>
  .quotation-input {
    padding: .25rem 1.25rem !important;
  }
  .uniform-uploader {
    margin-top: .625rem!important;
    margin-bottom: .625rem!important;
  }
  .note-frame {
    border: 1px solid #ddd !important;
  }
</style>