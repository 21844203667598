<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <!-- <div class="form-group">
              <div class="row">
                <div class="col-md-2 col-lg-2">
                  <label for="InvoiceIsOffline">Method:</label>
                </div>
                <div class="col-md-10 col-lg-10 form-group">
                  <vue-select
                    v-model="method"
                    :options="[{id: 1, name: 'Send via Email'}, {id: 2, name: 'Print (Offline'}]"
                    placeholder="Please Select"
                    label-by="name"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
              </div>
            </div> -->
            <div class="form-group">
              <label class="d-block font-weight-semibold">Select Client</label>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="select-client" v-model="type_client" value="new">
                  New Client
                </label>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="select-client" v-model="type_client" value="registered">
                  Registered Client
                </label>
              </div>
            </div>
            <div class="form-group" v-if="type_client == 'registered'">
              <div class="row">
                <div class="col-md-2 col-lg-2">
                  <label for="InvoiceIsOffline">Client:</label>
                </div>
                <div class="col-md-10 col-lg-10 form-group">
                  <vue-select
                    v-if="getClients"
                    v-model="data.user_id"
                    :options="getClients"
                    placeholder="Please Select"
                    label-by="fullname"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                  <span v-if="errors.user_id" class="form-text text-danger">{{
                    errors.user_id[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="form-group" v-else>
              <div class="row">
                <div class="col">
                  <label>First Name</label>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          type="text"
                          placeholder="First Name"
                          class="form-control"
                          v-model="data.first_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <label>Last Name</label>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          type="text"
                          placeholder="Last Name"
                          class="form-control"
                          v-model="data.last_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <label>Email</label>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          type="text"
                          placeholder="Email"
                          class="form-control"
                          v-model="data.email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <label>Phone</label>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <input
                          type="text"
                          placeholder="Phone"
                          class="form-control"
                          v-model="data.phone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered bg-white">
          <tr>
            <td class="invoice-input">Invoice Number</td>
            <td class="invoice-input p-2" v-if="!isManualInvoiceNumber">
              <div class="d-flex justify-content-between px-2">
                <span>INV-{{ getInvoiceNumber }}</span>
                <i class="icon-trash cursor-pointer" @click="changeToManualInvoiceNumber()"></i>
              </div>
            </td>
            <td class="invoice-input" v-if="isManualInvoiceNumber">
              <input
                type="text"
                placeholder="Enter invoice number"
                class="form-control"
                v-model="data.invoice_number"
              />
            </td>
          </tr>
          <!-- <tr>
            <td class="invoice-input">Invoice Date</td>
            <td class="invoice-input">
              <input
                type="date"
                placeholder="Enter invoice date"
                class="form-control"
                v-model="data.date"
              />
              <span v-if="errors.date" class="form-text text-danger">
                {{ errors.date[0] }}
              </span>
            </td>
          </tr> -->
          <tr>
            <td class="invoice-input">Issue Date</td>
            <td class="invoice-input">
              <input
                type="date"
                placeholder="Enter issue date"
                class="form-control"
                v-model="data.date_invoiced"
              />
              <span v-if="errors.date_invoiced" class="form-text text-danger">
                {{ errors.date_invoiced[0] }}
              </span>
            </td>
            <span v-if="errors.date_invoiced" class="form-text text-danger">
              {{ errors.date_invoiced[0] }}
            </span>
          </tr>
          <tr>
              <td class="invoice-input">Courier</td>
              <td class="invoice-input">
                <vue-select
                  v-model="data.courier"
                  :options="[{ name: 'Australia Post' }, { name: 'Pick Up' }, { name: 'Shipping' }]"
                  placeholder="Please Select"
                  label-by="name"
                  value-by="name"
                  close-on-select
                  style="width: 100% !important"
                  searchable
                />
                <span v-if="errors.courier" class="form-text text-danger">
                  {{ errors.courier[0] }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="invoice-input">Tracking Number</td>
              <td class="invoice-input">
                <input
                  type="input"
                  placeholder="Enter Tracking Number"
                  class="form-control"
                  v-model="data.tracking_number"
                />
                <span v-if="errors.tracking_number" class="form-text text-danger">
                  {{ errors.tracking_number[0] }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="invoice-input">Payment Method</td>
              <td class="invoice-input">
                <vue-select
                  v-model="data.payment_method"
                  :options="[
                    { value: 'BANK', name: 'Bank' },
                    { value: 'Cash', name: 'Cash' },
                    { value: 'CC', name: 'Credit Card' },
                    { value: 'PAYPAL', name: 'Paypal' }]"
                  placeholder="Please Select"
                  label-by="name"
                  value-by="value"
                  close-on-select
                  searchable
                  style="width: 100% !important;max-height: 100px !important;"
                  @update:modelValue="(value) => changePaymentMethod(value)"
                />
                <span v-if="errors.payment_method" class="form-text text-danger">
                  {{ errors.payment_method[0] }}
                </span>
              </td>
            </tr>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h3>Shipping Details</h3>
        <div class="row">
          <div class="col-md-2">
            <label>Country</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Country"
                    class="form-control"
                    v-model="data.shipping_country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>State</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="State"
                    class="form-control"
                    v-model="data.shipping_state"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Suburb</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Suburb"
                    class="form-control"
                    v-model="data.shipping_city"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Post Code</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="number"
                    placeholder="Post Code"
                    class="form-control"
                    v-model="data.shipping_postcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <label>Street Address</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Street Address"
                    class="form-control"
                    v-model="data.shipping_address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 v-if="!data.same_billing">Billing Details</h3>
        <div class="row" v-if="!data.same_billing">
          <div class="col-md-2">
            <label>Country</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Country"
                    class="form-control"
                    v-model="data.billing_country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Suburb</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Suburb"
                    class="form-control"
                    v-model="data.billing_city"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <label>Post Code</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="number"
                    placeholder="Post Code"
                    class="form-control"
                    v-model="data.billing_postcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>Street Address</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <input
                    type="text"
                    placeholder="Street Address"
                    class="form-control"
                    v-model="data.billing_address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" v-model="data.same_billing">
          <label class="form-check-label" for="defaultCheck1">
            Use Shipping Address as Billing Address
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <table class="table table-sm bg-white">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Item</th>
            <th scope="col">SKU</th>
            <th scope="col">Unit Price</th>
            <th scope="col">Qty</th>
            <!-- <th scope="col">Tax</th> -->
            <th scope="col">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in data.transaction" :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td style="width: 30%">
              <vue-select
                style="width: 100%"
                v-if="getProducts"
                v-model="data.transaction[index].product_selected"
                :options="getProducts"
                placeholder="Please Select"
                label-by="title"
                close-on-select
                searchable
                @update:modelValue="(value) => onSelectedProduct(value, index)"
              />
            </td>
            <td>
              {{ data.transaction[index].sku1 }}
            </td>
            <td>
              ${{ data.transaction[index].price }}
            </td>
            <td>
              <input
                type="number"
                placeholder="Qty"
                class="form-control" :class="`qty${index}`"
                v-model="data.transaction[index].quantity"
                @input="productSubtotal(index)"
              />
            </td>
            <!-- <td>
              {{ data.transaction[index].tax_method == 1 ? 'GST INC' : 'GST' }}
            </td> -->
            <td>${{ data.transaction[index].subtotal.toFixed(2) }}</td>
            <td><i class="icon-trash me-3" @click="removeItem(index)" style="cursor: pointer;"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div>
        <button
          type="button"
          class="btn btn-success btn-sm"
          @click="addProductInput()"
        >
          <b><i class="icon-plus-circle2 mr-2"></i></b> Add Items
        </button>
        <!-- <input
          type="checkbox"
          class="form-input-styled mx-2"
          v-model="data.payment_statut"
        />
        <span>Is Paid?</span>
        <input
          type="checkbox"
          class="form-input-styled mx-2"
          v-model="data.status"
        />
        <span>Is Complete?</span> -->
      </div>
      <div class="col-md-4 ml-auto bg-white">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <th>Subtotal:</th>
                <td class="text-right">
                  ${{ subtotal.toFixed(2) }}
                </td>
              </tr>
              <!-- <tr>
                <th>
                  Tax: <span class="font-weight-normal">(10%)</span>
                </th>
                <td class="text-right">
                  ${{ tax.toFixed(2) }}
                </td>
              </tr> -->
              <tr>
                <th>
                  Discount:
                </th>
                <td class="text-right">
                  <span v-if="data.isPercentDiscount == 0">${{ data.discount }}</span>
                  <span v-if="data.isPercentDiscount == 1">{{ data.discount }}% (${{ getDiscount() }})</span>
                </td>
              </tr>
              <tr>
                <th>Total:</th>
                <td class="text-right text-primary">
                  <h5 class="font-weight-semibold">
                    ${{ total.toFixed(2) }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      <i class="icon-plus-circle2 mr-2"></i> MORE OPTIONS
    </button>
    <div class="collapse mt-2" id="collapseExample">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-discount-tab" data-toggle="tab" href="#nav-discount" role="tab" aria-controls="nav-discount" aria-selected="true"><b>Discount</b></a>
          <!-- <a class="nav-item nav-link" id="nav-shipping-details-tab" data-toggle="tab" href="#nav-shipping-details" role="tab" aria-controls="nav-shipping-details" aria-selected="false"><b>Shipping Details</b></a>
          <a class="nav-item nav-link" id="nav-attach-document-tab" data-toggle="tab" href="#nav-attach-document" role="tab" aria-controls="nav-attach-document" aria-selected="false"><b>Attach Document</b></a>
          <a class="nav-item nav-link" id="nav-terms-tab" data-toggle="tab" href="#nav-terms" role="tab" aria-controls="nav-terms" aria-selected="false"><b>Terms & Conditions</b></a> -->
        </div>
      </nav>
      <div class="tab-content card mt-n3" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-discount" role="tabpanel" aria-labelledby="nav-discount-tab">
          <div class="container">
            <div class="form-group mt-3">
              <div class="row">
                <div class="col-sm-12">
                  <label>Discount</label>
                  <div class="d-flex">
                    <div class="form-inline">
                      <input
                        type="number"
                        placeholder="Enter discount"
                        class="form-control mr-5"
                        v-model="data.discount"
                        @input="getDiscount()"
                      />
                      <vue-select
                        v-model="data.isPercentDiscount"
                        :options="[
                          { id: 1, name: 'Percentage (%)' },
                          { id: 0, name: 'Dollar Value ($)' },
                        ]"
                        placeholder="Please Select"
                        label-by="name"
                        value-by="id"
                        close-on-select
                        searchable
                        @update:modelValue="() => getDiscount()"
                      />
                    </div>
                  </div>
                  <span v-if="errors.amount" class="form-text text-danger">{{
                    errors.amount[0]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-shipping-details" role="tabpanel" aria-labelledby="nav-shipping-details-tab">
          <div class="col-md-7 p-2">
            <h5>Shipping</h5>
            <div class="form-group mb-n1">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Weight</p>
                </div>
                <div class="col-sm-4">
                  <p>0.00000kg</p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <vue-select
                    v-model="data.status"
                    :options="[
                      { id: 'Pending', name: 'Pending' },
                      { id: 'Unpaid', name: 'Unpaid' },
                      { id: 'Paid', name: 'Paid' },
                    ]"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                  />
                </div>
                <div class="col-sm-4">
                  <button
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="addProductInput()"
                  >
                    Calculate Shipping
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group mb-n1">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Discount</p>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">-$</span>
                    </span>
                    <input type="text" class="form-control">
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                    <span class="input-group-text">(</span>
                    </span>
                    <input type="text" class="form-control">
                    <span class="input-group-append">
                    <span class="input-group-text">%)</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <p>Shipping Discount</p>
                </div>
                <div class="col-sm-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </span>
                    <input type="text" class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" checked="">
              Tax free shipping?
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
              <input type="checkbox" class="form-check-input" checked="">
              Signature Required?
              </label>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-attach-document" role="tabpanel" aria-labelledby="nav-attach-document-tab">
          <div class="container">
            <input
              type="file"
              ref="attachments"
              class="form-control-uniform"
              data-fouc
              multiple
              @change="handleAttachments($event)"
            />
          </div>
        </div>
        <div class="tab-pane fade" id="nav-terms" role="tabpanel" aria-labelledby="nav-terms-tab">
          <div class="container">
            <div class="card mt-3">
              <div class="card-body">
                <label>Terms & Conditions</label>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <ckeditor :editor="wys" v-model="tos"></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label>Notes</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <ckeditor :editor="wys" v-model="data.notes"></ckeditor>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>Internal Notes</label>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <ckeditor :editor="wys" v-model="data.internal_notes"></ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="button" @click="saveInvoice()">
      <i class="icon-plus-circle2 mr-2"></i> SAVE
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import VueNextSelect from "vue-next-select";
export default {
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      wys: ClassicEditor,
      type_client: 'new',
      errors: [],
      isManualInvoiceNumber: false,
      countSelectedProduct: 0,
      tax: 0,
      subtotal: 0,
      total: 0,
      method: 0,
      data: {
        first_name: null,
        last_name: null,
        invoice_number: null,
        date: new Date().toISOString().slice(0,10),
        date_invoiced: new Date().toISOString().slice(0,10),
        user_id: null,
        GrandTotal: 0,
        discount: 0,
        isPercentDiscount: 0,
        shipping_country: '',
        shipping_state: '',
        shipping_city: '',
        shipping_postcode: '',
        shipping_address: '',
        billing_country: '',
        billing_state: '',
        billing_city: '',
        billing_postcode: '',
        billing_address: '',
        same_billing: true,
        shipping: 0,
        payment_statut: null,
        status: null,
        notes: '',
        type: 'Order',
        courier: null,
        tracking_number: null,
        payment_method: 'BANK',
        internal_notes: '',
        transaction: [
          {
            product_selected: null,
            sku1: null,
            description: null,
            quantity: 0,
            price: null,
            tax_percent: 0,
            tax_method: 'Inclusive',
            discount: 0,
            discount_Method: '',
            product_id: '',
            product_variant_id: '',
            subtotal: 0,
          },
        ],
      },
    }
  },
  created() {
    this.fetchClients();
    this.fetchProducts({ filter_brand: '', filter_category: '' });
    this.getLastId();
  },
  mounted() {
    Summernote;
    $(".form-control-uniform").uniform();
    $(".summernote").summernote();
  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
      getProducts: "products/getProducts",
      getInvoiceNumber: "invoices/getInvoiceNumber",
    }),
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchProducts: "products/fetchProducts",
      AddInvoice: "invoices/AddInvoice",
      getLastId: "invoices/getLastId",
    }),
    removeItem(i) {
      this.data.transaction.splice(i, 1)
      // console.log(this.data.transaction.splice(i, 1));
      // this.countSelectedProduct = this.countSelectedProduct - 1;
    },
    changeToManualInvoiceNumber() {
      this.isManualInvoiceNumber = true
    },
    onSelectedProduct(value, index) {
      if (this.data.transaction.includes(value.id)) {
        this.data.transaction = this.data.transaction.filter(item => item.product.id != value.id)
      }
      this.data.transaction[index].product_id = value.id
      this.data.transaction[index].sku1 = value.sku
      this.data.transaction[index].price = value.price
      $(`.qty${index}`).focus();
      console.log(value)
    },
    productSubtotal(i) {
      this.data.transaction[i].subtotal = this.data.transaction[i].price * this.data.transaction[i].quantity
      let tax = 0;
      let subtotal = 0;
      this.data.transaction.forEach(item => {
        let itemTax = item.subtotal * 10 / 100;
        tax += itemTax;
        // subtotal += item.subtotal - itemTax;
        subtotal += item.subtotal;
      });
      this.tax = tax;
      this.subtotal = subtotal;
      var total = subtotal;
      this.total = total;
      var disc = 0;
      if (this.data.isPercentDiscount == 0) {
        disc = this.data.discount
        this.total = total - this.data.discount;
        this.data.GrandTotal = total - this.data.discount;
      } else {
        disc = total * this.data.discount / 100
        this.total = total - disc;
        this.data.GrandTotal = total - disc;
        console.log(this.data.GrandTotal)
      }
    },
    getDiscount() {
      // 10 = Percent | 1 = Fixed $
      console.log(this.data.isPercentDiscount)
      var disc = 0;
      // var total = this.subtotal + this.tax;
      var total = this.subtotal;
      if (this.data.isPercentDiscount == 0) {
        disc = this.data.discount
        this.total = total - this.data.discount;
        this.data.GrandTotal = total - this.data.discount;
      }else{
        disc = total * this.data.discount / 100
        this.total = total - disc;
        this.data.GrandTotal = total - disc;
      }
      return disc;
    },
    addProductInput() {
      console.log(this.wys)
      this.countSelectedProduct += 1;
      this.data.transaction = this.data.transaction.concat({
        product_selected: null,
        sku1: null,
        description: null,
        quantity: 0,
        price: null,
        tax_percent: 0,
        tax_method: 'Inclusive',
        discount: 0,
        discount_Method: '',
        product_id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
    saveInvoice() {
      this.AddInvoice({ data: this.data })
      .then(res => {
        res.status && this.$router.push('/invoices')
      })
      .catch(error => {
        console.log(error)
        this.errors = error.response.data.errors;
      })
    },
  },
}
</script>

<style>
  label {
    margin-bottom: 0px !important;
  }
  .invoice-input {
    padding: .25rem 1.25rem !important;
  }
  .uniform-uploader {
    margin-top: .625rem!important;
    margin-bottom: .625rem!important;
  }
  .note-frame {
    border: 1px solid #ddd !important;
  }
</style>