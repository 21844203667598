import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  credits: [],
  subcredits: [],
  credit: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getCredits: (state) => {
    return state.credits;
  },
  getCredit: (state) => {
    return state.credit;
  },
  getSubCredits: (state) => {
    return state.subcredits;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_CREDITS: (state, data) => (state.credits = data),
  SET_SUBCREDITS: (state, data) => (state.subcredits = data),
  SET_CREDIT: (state, data) => (state.credit = data),
};

const actions = {
  async fetchCredits({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`credits`);
      commit("SET_CREDITS", response.data.credits);
    } catch (error) {
      console.log(error);
      commit("SET_CREDITS", {});
    }
    commit("SET_LOADER", false);
  },
  async createCredit({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("credits", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateCredit({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`credits/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteCreditById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`credits/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
