import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  testimonials: [],
  testimonial: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getTestimonials: (state) => {
    return state.testimonials;
  },
  getTestimonial: (state) => {
    return state.testimonial;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_TESTIMONIALS: (state, data) => (state.testimonials = data),
  SET_TESTIMONIAL: (state, data) => (state.testimonial = data),
};

const actions = {
  async fetchTestimonials({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`testimonials`);
      commit("SET_TESTIMONIALS", response.data.testimonials);
    } catch (error) {
      console.log(error);
      commit("SET_TESTIMONIALS", {});
    }
    commit("SET_LOADER", false);
  },
  async createTestimonials({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("testimonials", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateTestimonials({ commit }, { id, data }) {
    let response = await ApiService.put(`testimonials/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteTestimonialsById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`testimonials/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
