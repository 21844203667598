<template>
  <div class="Invoice-detail">
    <div class="card">
      <div class="card-header bg-transparent header-elements-inline" id="noprint">
        <h6 class="card-title">Invoice</h6>
        <div class="header-elements">
          <!-- <button type="button" class="btn btn-light btn-sm"><i class="icon-file-check mr-2"></i> Save</button> -->
          <button type="button" class="btn btn-light btn-sm ml-3" @click="print()"><i class="icon-printer mr-2"></i> Print</button>
          <router-link :to="`${getInvoiceDetail?.type == 'Service' ? `/services/edit/${getInvoiceDetail?.id}` : `/invoices/edit/${getInvoiceDetail?.id}`}`" class="btn btn-light btn-sm ml-3">
            <i class="icon-pencil"></i>
          </router-link>
        </div>
      </div>

      <div class="card-body" v-if="getInvoiceDetail">
        <div id="noprint">
          <h3>Order Information</h3>
          <hr>
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                        <th>Order Date</th>
                        <td>{{ getDate(getInvoiceDetail.created_at, 'DD MMM YYYY HH:mm') }}</td>
                        <!-- <td>asdf</td> -->
                    </tr>
                    <tr>
                        <th>Order Status</th>
                        <td>
                          <vue-select
                            v-model="data.status"
                            :options="[{ name: 'NEW' }, { name: 'PAID' }, { name: 'PENDING' }, { name: 'PARTIALLY' }]"
                            placeholder="Please Select"
                            label-by="name"
                            value-by="name"
                            close-on-select
                            clear-on-select
                            searchable
                            style="width: 100% !important;max-height: 100px !important; z-index: 999;"
                            @update:modelValue="(value) => changeStatus(value)"
                          />
                        </td>
                    </tr>
                    <tr>
                        <th>Paid Amount</th>
                        <td v-if="getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) > 0">
                          <span v-if="getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) > 0">
                            {{ `$${getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2)}` }}
                          </span>
                          <span v-else>
                            {{ `$${0}` }}
                          </span>
                        </td>
                        <td v-else>
                          <span v-if="getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2) > 0">
                            {{ `$${getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.total, 0).toFixed(2)}` }}
                          </span>
                          <span v-else>
                            {{ `$${0}` }}
                          </span>
                        </td>
                    </tr>
                    <tr>
                        <th>Shipping</th>
                        <td>
                          <vue-select
                            v-model="data.courier"
                            :options="[{ name: 'Australia Post' }, { name: 'Pick Up' }, { name: 'Shipping' }]"
                            placeholder="Please Select"
                            label-by="name"
                            value-by="name"
                            close-on-select
                            maxHeight="100"
                            style="width: 100% !important; z-index: 99;"
                            searchable
                            @update:modelValue="(value) => changeShipping(value)"
                          />
                        </td>
                    </tr>
                    <tr>
                        <th>Tracking Number</th>
                        <td>
                          <input
                            type="input"
                            placeholder="Enter Tracking Number"
                            class="form-control"
                            v-model="data.tracking_number"
                            @input="changeTrackingNumber()"
                          />
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                        <th>Payment Method</th>
                        <td>
                          <vue-select
                            v-model="data.payment_method"
                            :options="[
                              { value: 'BANK', name: 'Bank' },
                              { value: 'Cash', name: 'Cash' },
                              { value: 'CC', name: 'Credit Card' },
                              { value: 'PAYPAL', name: 'Paypal' }]"
                            placeholder="Please Select"
                            label-by="name"
                            value-by="value"
                            close-on-select
                            searchable
                            style="width: 100% !important;max-height: 100px !important;"
                            @update:modelValue="(value) => changePaymentMethod(value)"
                          />
                        </td>
                    </tr>
                    <tr>
                        <th>Customer Name</th>
                        <td>{{ getInvoiceDetail.first_name }} {{ getInvoiceDetail.last_name }}</td>
                    </tr>
                    <tr>
                        <th>Customer Email</th>
                        <td>{{ getInvoiceDetail.user?.email }}</td>
                    </tr>
                    <tr>
                        <th>Customer Phone</th>
                        <td>{{ getInvoiceDetail.user?.phone }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="mb-4">
              <img src="../../assets/images/logo.png" class="mb-3 mt-2" alt="" style="width: 120px;">
              <ul class="list list-unstyled mb-0 font-size-16">
                <li>Sage Gaming</li>
                <li>6 Diamond Ave, Granville NSW 2142</li>
                <li>{{getPhone}}</li>
              </ul>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mb-4">
              <div class="text-sm-right">
                <h4 class="text-primary mb-2 mt-md-2">Invoice #{{ getInvoiceDetail?.id }}</h4>
                <ul class="list list-unstyled mb-0">
                  <li>Date: <span class="font-weight-semibold">{{ formatDate(getInvoiceDetail.created_at) }}</span></li>
                  <!-- <li>Due date: <span class="font-weight-semibold">May 12, 2015</span></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h3>Address Information</h3>
        <hr>
        <div class="row">
          <div class="col mb-4 mb-md-2">
            <span class="text-muted">Shipped To:</span>
            <ul class="list list-unstyled mb-0">
              <li><h5 class="my-2">{{ getInvoiceDetail.user.fullname }}</h5></li>
              <li>{{ getInvoiceDetail.shipping_address }}</li>
              <li>{{ getInvoiceDetail.shipping_city }}, {{ getInvoiceDetail.shipping_country }}, {{ getInvoiceDetail.shipping_postcode }}</li>
              <li>{{ getInvoiceDetail.user.phone }}</li>
              <li>{{ getInvoiceDetail.user.email }}</li>
            </ul>
          </div>

          <div class="col mb-2">
            <span class="text-muted d-flex justify-content-end">Billed To:</span>
            <div class="d-flex flex-wrap wmin-md-200">
              <!-- <ul class="list list-unstyled mb-0">
                <li><h5 class="my-2">Total:</h5></li>
                <li>Total Paid:</li>
                <li>Total Due:</li>
                <li>Paid At:</li>
              </ul> -->

              <ul class="list list-unstyled text-right mb-0 ml-auto">
                <li><h5 class="my-2">{{ getInvoiceDetail.user.fullname }}</h5></li>
                <li>{{ getInvoiceDetail.billing_address }}</li>
                <li>{{ getInvoiceDetail.billing_city }}, {{ getInvoiceDetail.billing_country }}, {{ getInvoiceDetail.billing_postcode }}</li>
                <li>{{ getInvoiceDetail.user.phone }}</li>
                <li>{{ getInvoiceDetail.user.email }}</li>
              </ul>
            </div>
          </div>
        </div>

        <h3>Items Ordered</h3>
        <hr>
        <div class="table-responsive">
          <table class="table table-lg">
              <thead>
                  <tr>
                      <th width="20">No.</th>
                      <th>SKU</th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Total</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(product, i) in getInvoiceDetail?.transactions" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ product?.product?.sku }}</td>
                      <td>{{ product?.product?.title ?? product.name }}</td>
                      <td>${{ product?.product?.price.toFixed(2) ?? product.price.toFixed(2) }}</td>
                      <td>{{ product.quantity }}</td>
                      <td>${{ product.total.toFixed(2) }}</td>
                  </tr>
              </tbody>
          </table>
        </div>
      </div>


      <div class="card-body">
        <div class="d-md-flex flex-md-wrap">
          <div class="pt-2 mb-3">
            <h6 class="">Notes:</h6>
            <div class="" v-html="getInvoiceDetail?.notes">
            </div>
          </div>

          <div class="pt-2 mb-3 wmin-md-400 ml-auto w-50" v-if="getInvoiceDetail">
            <!-- <h6 class="mb-3">Total due</h6> -->
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Subtotal:</th>
                    <td class="text-right">${{ getInvoiceDetail?.transactions?.reduce((next, prev) =>  next + prev.total, 0).toFixed(2) }}</td>
                  </tr>
                  <tr v-if="getInvoiceDetail.discount">
                    <th>Discount:</th>
                    <td class="text-right" v-if="getInvoiceDetail.is_discount_percent">-{{ getInvoiceDetail.discount }}% (${{ (getInvoiceDetail.discount * getInvoiceDetail?.transactions?.reduce((next, prev) => next + prev.total, 0) / 100).toFixed(2) }})</td>
                    <td class="text-right" v-else>-${{ getInvoiceDetail.discount.toFixed(2) }}</td>
                  </tr>
                  <tr v-if="getInvoiceDetail.coupon_id">
                    <th>Coupon:</th>
                    <td class="text-right" v-if="getInvoiceDetail.coupon.is_percent">-{{ Number(getInvoiceDetail.coupon.value).toFixed(2) }}% (-${{ getInvoiceDetail.total * Number(getInvoiceDetail.coupon.value) / 100 }})</td>
                    <td class="text-right" v-else>-${{ Number(getInvoiceDetail.coupon.value).toFixed(2) }}</td>
                  </tr>
                  <tr v-if="getInvoiceDetail.delivery_fee">
                    <th>Delivery Fee:</th>
                    <td class="text-right">${{ getInvoiceDetail.delivery_fee.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <th>Total:</th>
                    <td class="text-right">${{ getTotal() }}</td>
                  </tr>
                  <tr>
                    <th>Total Paid:</th>
                    <td class="text-right">
                      {{ getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.debet, 0).toFixed(2) > 0 ? `$${getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.debet, 0).toFixed(2)}` : `$${0}` }}
                      <!-- {{ Math.abs(getInvoiceDetail.transaction_payments.reduce((prev, next) => prev + next.total + prev, 0)) }} -->
                    </td>
                  </tr>
                  <tr>
                    <th>Total Due:</th>
                    <td class="text-right">${{ Number(getTotal() - getInvoiceDetail?.transaction_payments?.reduce((prev, next) => prev + next.debet, 0)).toFixed(2) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="text-right mt-3">
              <button type="button" class="btn btn-primary btn-labeled btn-labeled-left"><b><i class="icon-paperplane"></i></b> Send invoice</button>
            </div> -->
          </div>
        </div>
      </div>

      <!-- <div class="card-footer">
        <span class="text-muted">Thank you for using Limitless. This invoice can be paid via PayPal, Bank transfer, Skrill or Payoneer. Payment is due within 30 days from the date of delivery. Late payment is possible, but with with a fee of 10% per month. Company registered in England and Wales #6893003, registered office: 3 Goodman Street, London E1 8BF, United Kingdom. Phone number: 888-555-2311</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from '@/helpers/util.js'
import moment from 'moment';
import VueNextSelect from "vue-next-select";

export default {
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      errors: [],
      data: {
        id: null,
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        phone: null,
        phone_2: null,
        address: null,
        password: null,
        role_id: null,
        permission_id: [],
        status: null,
        payment_method: 'BANK',
        courier: null,
        tracking_number: null,
      },
    };
  },
  async mounted() {
    await this.invoiceById({ id: this.$route.params.id });
    this.data.payment_method = this.getInvoiceDetail?.payment_method ?? 'BANK'
    this.data.status = this.getInvoiceDetail.status
    this.data.courier = this.getInvoiceDetail.courier
    this.data.tracking_number = this.getInvoiceDetail.tracking_number
  },
  computed: {
    ...mapGetters({
      getInvoiceDetail: "invoices/getInvoice",
    }),
  },
  methods: {
    ...mapActions({
      invoiceById: "invoices/invoiceById",
      updateStatus: "invoices/updateStatus",
      updateShipping: "invoices/updateShipping",
      updatePaymentMethod: "invoices/updatePaymentMethod",
      updateTrackingNumber: "invoices/updateTrackingNumber",
    }),
    changeStatus() {
      this.updateStatus({ id: this.$route.params.id, data: { status: this.data.status }});
    },
    changeShipping() {
      this.updateShipping({ id: this.$route.params.id, data: { courier: this.data.courier }});
    },
    changePaymentMethod() {
      this.updatePaymentMethod({ id: this.$route.params.id, data: { payment_method: this.data.payment_method }});
    },
    changeTrackingNumber() {
      this.updateTrackingNumber({ id: this.$route.params.id, data: { tracking_number: this.data.tracking_number }});
    },
    updateInvoice() {
      this.editInvoice({id: this.$route.params.id, data: this.data }).then(() => {
        util.showToast('Invoice successfully changed');
        $('#EditInvoice').modal('hide');
        this.invoiceById({ id: this.$route.params.id });
      }).catch(err => {
        this.errors = err.response?.data.errors;
      })
    },
    async deleteInvoice(id) {
      const action = () => {
        this.deleteInvoiceById({id})
      }
      await util.deleteConfirmationToast(action)
      this.invoiceById({ id: this.$route.params.id });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD MMM YYYY')
      }
    },
    print() {
      window.print();
    },
    getTotal() {
      let total = this.getInvoiceDetail.total;
      if (this.getInvoiceDetail?.coupon) {
        if (this.getInvoiceDetail.coupon.is_percent == 0) {
          return total - this.getInvoiceDetail.coupon.value;
        }else{
          let disc = total * this.getInvoiceDetail.coupon.value / 100
          return total - disc;
        }
      }
      return this.getInvoiceDetail.total;
    },
    getDate(date, format) {
      return moment(date).format(format ?? 'MMMM DD, YYYY');
    },
  },
};
</script>

<style scoped>
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media print
  {
    .Invoice-detail {
      font-size: 16pt;
    }
    #noprint {
      display: none !important;
    }
  }
</style>