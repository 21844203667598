import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  memberships: {
    memberships: [],
  },
  invoice: null,
  invoiceNumber: '',
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getMemberships: (state) => {
    return state.memberships;
  },
  getInvoice: (state) => {
    return state.invoice;
  },
  getInvoiceNumber: (state) => {
    return state.invoiceNumber;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_MEMBERSHIPS: (state, data) => (state.memberships = data),
  SET_INVOICE: (state, data) => (state.invoice = data),
  SET_INVOICE_NUMBER: (state, data) => (state.invoiceNumber = data),
};

const actions = {
  async fetchMemberships({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("memberships");
      commit("SET_MEMBERSHIPS", response.data.memberships);
    } catch (error) {
      console.log(error);
      commit("SET_MEMBERSHIPS", {});
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
