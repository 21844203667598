<template>
  <div class="staff-detail">
    <div class="card">
      <div class="card-header header-elements-inline border-bottom">
        <h6 class="card-title">Staff Details</h6>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            data-target="#AddTimesheet"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Timesheet
          </button>
      </div>
      <div class="row d-flex justify-content-center align-items-center mb-2">
        <div class="col-md-1">
          <div class="media d-flex justify-content-center">
            <img
              src="@/assets/images/placeholders/placeholder.jpg"
              width="90"
              height="90"
              class="rounded-circle"
              alt=""
            />
          </div>
        </div>
        <div class="col-md-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold" v-if="data.firstname">Full name:</span>
              <div class="ml-auto">
                {{ getStaffDetail.firstname }} {{ getStaffDetail.lastname }}
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Username</span>
              <div class="ml-auto">{{ getStaffDetail.username }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Email</span>
              <div class="ml-auto">{{ getStaffDetail.email }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Phone Number</span>
              <div class="ml-auto">{{ getStaffDetail.phone }}</div>
            </li>
          </ul>
        </div>
        <div class="col-md-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getStaffDetail.address">{{ getStaffDetail.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Role</span>
              <div class="ml-auto col-md-6" v-if="getStaffDetail.role_id">
                <vue-select
                  v-if="getStaffDetail.role_id && getRoles.data"
                  v-model="data.role_id"
                  :options="getRoles.data"
                  placeholder="Please Select"
                  label-by="label"
                  value-by="id"
                  close-on-select
                />
              </div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Status</span>
              <div class="ml-auto col-md-6">
                <vue-select
                  v-model="data.statut"
                  :options="[{id: 1, value: 'Active'}, {id: 0, value: 'Inactive'}]"
                  placeholder="Please Select"
                  label-by="value"
                  value-by="id"
                  close-on-select
                />
              </div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Actions</span>
              <div class="ml-auto">
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditStaff"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm" @click="deleteStaff()">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-header header-elements-inline">
        <h6 class="card-title">TimeSheet</h6>
        <div>
          <button
            @click="exportTimesheet"
            type="button"
            class="btn btn-transparent btn-sm mr-2"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Start Timesheet
          </button>
        </div>
      </div>
      <div class="row">
        <div id="date_filter" class="col-md-8">
          <div class="d-flex justify-content-around align-items-center">
            <span id="date-label-from" class="date-label mx-2">From: </span>
            <input class="date_range_filter date form-control" type="date" id="datepicker_from" v-model="date_from" />
            <span id="date-label-to" class="date-label mx-2">To:</span>
            <input class="date_range_filter date form-control" type="date" id="datepicker_to" v-model="date_to" />
            <button
              type="button"
              class="btn btn-success btn-sm mx-2"
              @click="filterTime()"
            >
              <b>Filter</b>
            </button>
          </div>
        </div>
      </div>
      <table class="table text-nowrap table-timesheet mt-2">
        <thead>
          <tr>
            <th>Date</th>
            <th>Begin</th>
            <th>End</th>
            <th>Duration</th>
            <th>Actions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="timesheet in getTimesheetDetail.timesheet" :key="timesheet.id">
            <td>{{ timesheet.day }}</td>
            <td>{{ timesheet.time_start }}</td>
            <td>{{ timesheet.time_end }}</td>
            <td>{{ timesheet.duration }} hours</td>

            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#DetailTimesheet"
                    >
                      <i class="icon-eye2"></i> See Timesheet
                    </button>
                    <button
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#EditTimesheet"
                    >
                      <i class="icon-pencil"></i> Edit Timesheet
                    </button>
                    <div class="dropdown-divider"></div>
                  </div>
                </div>
              </div>
            </td>
            <td class="pl-0"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Edit Staff Modal -->
    <div id="EditStaff" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Edit Staff</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0" v-if="getStaffDetail.firstname">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Firstname</label>
                  <input
                    type="text"
                    placeholder="Enter your firstname"
                    class="form-control"
                    v-model="data.firstname"
                  />
                <span v-if="errors.firstname" class="form-text text-danger">{{ errors.firstname[0] }}</span>

                </div>

                <div class="col-sm-6">
                  <label>Lastname</label>
                  <input
                    type="text"
                    placeholder="Enter your lastname"
                    class="form-control"
                    v-model="data.lastname"
                  />
                <span v-if="errors.lastname" class="form-text text-danger">{{ errors.lastname[0] }}</span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    class="form-control"
                    v-model="data.email"
                  />
                  <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Username</label>
                  <input
                    type="username"
                    placeholder="Enter your username"
                    class="form-control"
                    v-model="data.username"
                  />
                  <span v-if="errors.username" class="form-text text-danger">{{ errors.username[0] }}</span>

                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter your phone number"
                    class="form-control"
                    v-model="data.phone"
                  />
                  <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Phone 2</label>
                  <input
                    type="tel"
                    placeholder="Enter your second phone number"
                    class="form-control"
                    v-model="data.phone_2"
                  />
                  <span v-if="errors.phone_2" class="form-text text-danger">{{ errors.phone_2[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Address</label>
                  <textarea
                    rows="2"
                    cols="3"
                    class="form-control"
                    placeholder="Enter your address"
                    v-model="data.address"
                  >
                    Detail address
                  </textarea
                  >
                <span v-if="errors.address" class="form-text text-danger">{{ errors.address[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    class="form-control"
                    v-model="data.password"
                  />
                  <small>* Leave this blank if won't change</small>
                  <span v-if="errors.password" class="form-text text-danger">{{ errors.password[0] }}</span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Roles</label>
                  <vue-select
                    v-if="getRoles.data"
                    v-model="data.role_id"
                    :options="getRoles.data"
                    placeholder="Please Select"
                    label-by="label"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important;"
                  />
                <span v-if="errors.role_id" class="form-text text-danger">{{ errors.role_id[0] }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="updateStaff()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Staff -->

    <!-- Add Timesheet Modal -->
    <div id="AddTimesheet" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Add Timesheet</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control daterange-single" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" id="begin_time" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" id="end_time" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>Duration (hour)</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value="4 hours"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="2" cols="3" class="form-control"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="4" cols="3" class="form-control"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Timesheet -->

    <!-- Edit Timesheet Modal -->
    <div id="EditTimesheet" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Edit Timesheet</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control daterange-single" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" value="08:00" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control" value="17:00" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>Duration (hour)</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value="9 hours"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="3" cols="3" class="form-control">
 List Activities </textarea
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="5" cols="3" class="form-control">
Description ...</textarea
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">
              Update <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Timesheet -->

    <!-- Detail Timesheet Modal -->
    <div id="DetailTimesheet" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Detail Timesheet</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      class="form-control daterange-single"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      value="08:00"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      value="17:00"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <label>Duration (hour)</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value="9 hours"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="3" cols="3" class="form-control" disabled>
 List Activities </textarea
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="5" cols="3" class="form-control" disabled>
Description ...</textarea
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3"></div>
        </div>
      </div>
    </div>
    <!-- /Detail Timesheet -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import VueNextSelect from 'vue-next-select'
import util from '@/helpers/util.js'
import moment from 'moment';

export default {
  name: "StaffDetail",
  components: {
    'vue-select': VueNextSelect,
  },
  data() {
    return {
      errors: [],
      date_from: null,
      date_to: null,
      data: {
        id: null,
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        phone: null,
        phone_2: null,
        address: null,
        password: null,
        role_id: null,
        permission_id: [],
        statut: null,
      },
    };
  },
  created() {
    this.staffById({ id: this.$route.params.id });
    this.fetchRoles();
    this.fetchPermissions();
    this.timesheetById({
      staffId: this.$route.params.id,
      date_from: this.date_from,
      date_to: this.date_to,
    });
    // try {
    //   ApiService.get(`/staff/detail-staff/${this.$route.params.id}?date_from=${this.date_from}?date_to=${this.date_to}`).then(x => {
    //     this.data.id = x.data.data.id;
    //     this.data.firstname = x.data.data.firstname;
    //     this.data.lastname = x.data.data.lastname;
    //     this.data.username = x.data.data.username;
    //     this.data.email = x.data.data.email;
    //     this.data.phone = x.data.data.phone;
    //     this.data.phone_2 = x.data.data.phone_2;
    //     this.data.address = x.data.data.address;
    //     this.data.role_id = x.data.data.role_id;
    //     this.data.statut = x.data.data.statut;
    //   });
    // } catch (error) {
    //   console.log(error.response.data)
    //   this.errors = error.response.data.errors;
    // }
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-timesheet')) {
      $(".table-timesheet").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 400,
          },
          {
            orderable: false,
            width: 36,
            targets: 4,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-excel"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
          {
            extend: "pdfHtml5",
            text: '<i class="icon-printer"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Anytime;
    Summernote;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".select-role").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".select-status").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    if (!$("#begin_time")) {
      $("#begin_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    if (!$("#begin_time")) {
      $("#end_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".summernote").summernote();
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  computed: {
    ...mapGetters({
      getStaffDetail: "staff/getStaffDetail",
      getRoles: "roles/getRoles",
      getPermissions: "permissions/getPermissions",
      getTimesheetDetail: "timesheet/getTimesheetDetail",
    }),
    filteredTimesheet: function () {
      var getDaysBetweenDates = function(startDate, endDate) {
        var now = startDate.clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('DD/MM/YYYY'));
          now.add(1, 'days');
        }
        return dates;
      };


      let date_from = moment(this.date_from);
      let date_to = moment(this.date_to);
      let dates = getDaysBetweenDates(date_from, date_to);
      console.log(getDaysBetweenDates(date_from, date_to).includes(moment("19 Sep 2021").format('DD/MM/YYYY')))
      // let date = moment("2021/09/17").format("YYYY/MM/DD");
      var timesheet = this.getStaffDetail?.timesheet;
      // console.log(moment.range(date_from, date_to));
      if (this.date_from && !this.date_to) {
        timesheet = timesheet?.filter(item => moment(item.day).format("DD/MM/DD") == date_from)
      }else if (this.date_to && !this.date_from) {
        timesheet = timesheet?.filter(item => moment(item.day).format("DD/MM/YYYY") == date_to)
      }else if (this.date_from && this.date_to) {
        console.log('tessss')
        return timesheet?.filter(item => dates.includes(item.day))
      }
      console.log(timesheet)
      return timesheet;
    }
  },
  methods: {
    ...mapActions({
      editStaff: "staff/editStaff",
      editStaffStatus: "staff/editStaffStatus",
      staffById: "staff/staffById",
      fetchRoles: "roles/fetchRoles",
      fetchPermissions: "permissions/fetchPermissions",
      timesheetById: "timesheet/timesheetById",
    }),
    filterTime(){
      this.timesheetById({
        staffId: this.$route.params.id,
        date_from: this.date_from,
        date_to: this.date_to,
      });
    },
    selectedPermissions(id) {
      this.data.permission_id.includes(id) ? this.data.permission_id = this.data.permission_id.filter((x) => x != id) : this.data.permission_id.push(id)
    },
    updateStaff() {
      this.editStaff({id: this.$route.params.id, data: this.data }).then(() => {
        util.showToast('Staff successfully changed');
        $('#EditStaff').modal('hide');
        this.fetchStaff()
      }).catch(err => {
        this.errors = err.response?.data.errors;
      })
    },
    async deleteStaff(id) {
      const action = () => {
        this.deleteStaffById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchStaff()
    },
    async exportTimesheet() {
      await ApiService.get("/staff/exportExcel", {
        'headers': {
          'Content-Disposition': "attachment; filename=TimeSheets.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TimeSheets.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  watch: {
    'data.statut' : function(data, prev) {
      if (prev != null) {
        util.showToast('Status successfully changed');
        this.editStaffStatus({id: this.$route.params.id, data })
      }
    },
    'data.role_id' : function(data, prev) {
      if (prev != null) {
        util.showToast('Role successfully changed');
        this.editStaff({id: this.$route.params.id, data: this.data })
      }
    }
  }
};
</script>
