<template>
  <div class="clients-detail">
    <div class="card">
      <div class="card-header header-elements-inline border-bottom">
        <h6 class="card-title">Client Details</h6>
        <!-- <button type="button" class="btn btn-sm btn-transparent" @click="openBillingDetailsModal()">
          <i class="icon-credit-card mr-2"></i> Card
        </button> -->
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <ul class="list-group list-group-flush">
                <li class="list-group-item mr-n3 pr-0">
                  <span class="font-weight-bold">Full name:</span>
                  <div class="ml-auto">{{ getClient.fullname }}</div>
                </li>
                <li class="list-group-item mr-n3 pr-0">
                  <span class="font-weight-bold">Phone number:</span>
                  <div class="ml-auto">{{ getClient.phone }}</div>
                </li>
                <!-- <li class="d-flex w-100 ml-3 my-2 align-items-center">
                  <span class="font-weight-bold">Price Group: </span>
                  <div class="ml-auto">
                    <vue-select
                      v-if="getClients.clients"
                      v-model="client_id"
                      :options="getClients.clients"
                      placeholder="Please Select"
                      label-by="name"
                      value-by="id"
                      close-on-select
                      style="width: 100% !important"
                      searchable
                    />
                  </div>
                </li> -->
                <li class="d-flex w-100 ml-3 mt-1">
                  <span class="font-weight-bold">Email address:</span>
                  <div class="ml-auto">{{ getClient.email }}</div>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="list-group list-group-flush">
                <!-- <li class="list-group-item d-flex">
                  <span class="font-weight-bold">Address:</span>
                  <div class="ml-auto">{{ getClient.address }}</div>
                </li>
                <li class="list-group-item">
                  <span class="font-weight-bold">Suburb:</span>
                  <div class="ml-auto">{{ getClient.addresses[0].city }}</div>
                </li>
                <li class="list-group-item">
                  <span class="font-weight-bold">Country:</span>
                  <div class="ml-auto">{{ getClient.addresses[0].country }}</div>
                </li> -->
                <!-- <li class="list-group-item d-flex">
                  <span class="font-weight-bold">Email address:</span>
                  <div class="ml-auto">{{ getClient.email }}</div>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row mx-4">
            <!-- <div class="col-4">
              <a href="#" @click="toggleInvoices">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex">
                      <h3 class="font-weight-semibold mb-0">{{ getClient.transaction_group.length }}</h3>
                      <div class="list-icons ml-auto">
                        <i class="icon-ticket"></i>
                      </div>
                    </div>
                    <div>
                      Invoices
                      <div class="text-muted font-size-sm">${{ getInvoiceTotal }}</div>
                    </div>
                  </div>
                </div>
              </a>
            </div> -->
            <!-- <div class="col-4">
              <a href="#" @click="toggleTicketing">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex">
                      <h3 class="font-weight-semibold mb-0">{{ getClient.tiket.length }}</h3>
                      <div class="list-icons ml-auto">
                        <i class="icon-ticket"></i>
                      </div>
                    </div>
                    <div>
                      Ticket
                      <div class="text-muted font-size-sm">4,728 avg</div>
                    </div>
                  </div>
                </div>
              </a>
            </div> -->
            <div class="col-4">
              <a href="#" @click="toggleQuote">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex">
                      <h3 class="font-weight-semibold mb-0">10</h3>
                      <div class="list-icons ml-auto">
                        <i class="icon-ticket"></i>
                      </div>
                    </div>
                    <div>
                      Quote
                      <div class="text-muted font-size-sm">4,728 avg</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="toggle.ticketing" class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="ml-3">Ticketing</h6>
          <div class="mt-n3">
            <div class="ml-auto d-flex align-items-center">
              <span class="font-weight-bold">Actions</span>
              <div>
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditClient"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm" @click="deleteClient(getClient.id)">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <table class="table text-nowrap" id="table-client-detail">
          <thead>
            <tr>
              <th>Ref Id</th>
              <th>Subject</th>
              <th>Time Start</th>
              <th>Time End</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ticket, index) in getClient.tiket" :key="index">
              <td>{{ ticket.ref_id }}</td>
              <td>{{ ticket.subject }}</td>
              <td>{{ ticket.time_start }}</td>
              <td>{{ ticket.time_end }}</td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {{ ticket.periority }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item">
                      High
                    </a>
                    <a href="#" class="dropdown-item">
                      Medium
                    </a>
                    <a href="#" class="dropdown-item">
                      Low
                    </a>
                  </div>
                </div>
                <div
                  v-if="ticket.periority === 'High'"
                  class="ml-auto badge badge-danger"
                >
                  {{ ticket.periority }}
                </div>
                <div
                  v-if="ticket.periority === 'MEDIUM'"
                  class="ml-auto badge badge-warning"
                >
                  {{ ticket.periority }}
                </div>
                <div
                  v-if="ticket.periority === 'SLOW'"
                  class="ml-auto badge badge-success"
                >
                  {{ ticket.periority }}
                </div>
              </td>
              <td>
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {{ ticket.status }}
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item">
                      Open
                    </a>
                    <a href="#" class="dropdown-item">
                      Pending
                    </a>
                    <a href="#" class="dropdown-item">
                      Close
                    </a>
                  </div>
                </div>
              </td>
              <td class="text-right">
                <div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu7"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-pdf"></i> Invoices</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cube2"></i> Shipping details</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-credit-card"></i> Billing details</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-warning2"></i> Report problem</a
                      >
                    </div>
                  </div>
                </div>
              </td>
              <td class="pl-0"></td>
            </tr>
          </tbody>
        </table> -->
      </div>
      <!-- <div v-if="toggle.invoices" class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="ml-3">Invoices</h6>
          <div class="mt-n3">
            <div class="ml-auto d-flex align-items-center">
              <span class="font-weight-bold">Actions</span>
              <div>
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditClient"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm" @click="deleteClient(getClient.id)">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-lg invoice-archive">
          <thead>
            <tr>
              <th>#</th>
              <th>Invoice Status</th>
              <th>Payment Status</th>
              <th>Date</th>
              <th>Amount</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in getClient.sales" :key="invoice.id">
              <td>
                <router-link :to="`/invoices/${invoice.id}`">
                  #{{ invoice.Ref }}
                </router-link>
              </td>
              <td>
                <span class="badge"
                  :class="{ 'badge-success': invoice.statut == 'completed', 'badge-warning': invoice.statut != 'completed', 'badge-danger': invoice.statut == 'cancel' }"
                >
                  {{ invoice.statut.toUpperCase() }}
                </span>
              </td>
              <td>
                <span class="badge"
                  :class="{ 'badge-success': invoice.payment_statut == 'paid', 'badge-danger': invoice.payment_statut == 'cancel', 'badge-warning': invoice.payment_statut != 'completed' }"
                >
                  {{ invoice.payment_statut.toUpperCase() }}
                </span>
              </td>
              <td>
                {{ getDate(invoice.date) }}
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  ${{ invoice.GrandTotal }}
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT ${{ invoice.TaxNet }}</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <!-- <div v-if="toggle.quote" class="mt-4">
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="ml-3">Quote</h6>
          <div class="mt-n3">
            <div class="ml-auto d-flex align-items-center">
              <span class="font-weight-bold">Actions</span>
              <div>
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditClient"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm" @click="deleteClient(getClient.id)">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-lg invoice-archive">
          <thead>
            <tr>
              <th>#</th>
              <th>Period</th>
              <th>Issued to</th>
              <th>Status</th>
              <th>Issue date</th>
              <th>Due date</th>
              <th>Amount</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#0025</td>
              <td>February 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Rebecca Manes</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Skrill</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold" selected>On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                April 18, 2015
              </td>
              <td>
                <span class="badge badge-success">Paid on Mar 16, 2015</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $17,890
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $4,890</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0024</td>
              <td>February 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">James Alexander</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Wire transfer</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold">On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid" selected>Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                April 17, 2015
              </td>
              <td>
                <span class="badge badge-warning">5 days</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $2,769
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $2,839</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0023</td>
              <td>February 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Jeremy Victorino</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Payoneer</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold">On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid" selected>Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                April 17, 2015
              </td>
              <td>
                <span class="badge badge-primary">27 days</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $1,500
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $1,984</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0022</td>
              <td>January 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Margo Baker</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Paypal</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold">On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel" selected>Canceled</option>
                </select>
              </td>
              <td>
                January 15, 2015
              </td>
              <td>
                <span class="badge badge-primary">12 days</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $4,580
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $992</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0021</td>
              <td>January 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Beatrix Diaz</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Paypal</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue" selected>Overdue</option>
                  <option value="hold">On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                January 10, 2015
              </td>
              <td>
                <span class="badge badge-danger">- 3 days</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $7,990
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $1,294</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0020</td>
              <td>January 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Richard Vango</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Wire transfer</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold">On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="invalid" selected>Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                January 10, 2015
              </td>
              <td>
                <span class="badge badge-secondary">On hold</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $12,120
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $3,278</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0019</td>
              <td>January 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Will Baker</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Paypal</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold" selected>On hold</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                December 26, 2014
              </td>
              <td>
                <span class="badge badge-success">Paid on Feb 25, 2015</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $5,390
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $2,880</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>#0018</td>
              <td>January 2015</td>
              <td>
                <h6 class="mb-0">
                  <a href="#">Joseph Mills</a>
                  <span class="d-block font-size-sm text-muted"
                    >Payment method: Skrill</span
                  >
                </h6>
              </td>
              <td>
                <select
                  name="status"
                  class="form-control form-control-select2"
                  data-placeholder="Select status"
                >
                  <option value="overdue">Overdue</option>
                  <option value="hold">On hold</option>
                  <option value="pending" selected>Pending</option>
                  <option value="paid">Paid</option>
                  <option value="invalid">Invalid</option>
                  <option value="cancel">Canceled</option>
                </select>
              </td>
              <td>
                June 17, 2015
              </td>
              <td>
                <span class="badge badge-secondary">On hold</span>
              </td>
              <td>
                <h6 class="mb-0 font-weight-bold">
                  $10,280
                  <span
                    class="d-block font-size-sm text-muted font-weight-normal"
                    >VAT $2,190</span
                  >
                </h6>
              </td>
              <td class="text-center">
                <div class="list-icons list-icons-extended">
                  <a
                    href="#"
                    class="list-icons-item"
                    data-toggle="modal"
                    data-target="#invoice"
                    ><i class="icon-file-eye"></i
                  ></a>
                  <div class="dropdown">
                    <a
                      href="#"
                      class="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                      ><i class="icon-file-text2"></i
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-download"></i> Download</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-printer"></i> Print</a
                      >
                      <div class="dropdown-divider"></div>
                      <a href="#" class="dropdown-item"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                      <a href="#" class="dropdown-item"
                        ><i class="icon-cross2"></i> Remove</a
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <!-- Edit Client modal -->
    <div id="EditClient" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Edit Client</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Firstname</label>
                  <input
                    type="text"
                    placeholder="Enter your firstname"
                    class="form-control"
                    value="John"
                  />
                </div>

                <div class="col-sm-6">
                  <label>Lastname</label>
                  <input
                    type="text"
                    placeholder="Enter your lastname"
                    class="form-control"
                    value="Smith"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    class="form-control"
                    value="johnsmith@gmail.com"
                  />
                </div>
                <div class="col-sm-6">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter your phone number"
                    class="form-control"
                    value="089237151"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Address</label>
                  <input
                    type="text"
                    placeholder="Enter your address"
                    class="form-control"
                    value="Jl Baru Test"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Birth Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input type="text" class="form-control daterange-single"/>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="d-block">Gender</label>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      name="gender"
                      class="form-check-input-styled-primary"
                      checked
                      data-fouc
                    />
                    <span class="mr-4" checked>Male</span>
                    <input
                      type="radio"
                      name="gender"
                      class="form-check-input-styled-primary"
                      data-fouc
                    />
                    <span class="mr-4">Female</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <label>Image Profile</label>
                  <input type="file" class="form-control-uniform" data-fouc />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">
              Update <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client modal -->

    <!-- Modal with invoice -->
    <div id="invoice" class="modal fade">
      <div class="modal-dialog modal-full">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Invoice #49029</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-4">
                  <img
                    src="@/assets/images/logo_demo.png"
                    class="mb-3 mt-2"
                    alt=""
                    style="width: 120px;"
                  />
                  <ul class="list list-unstyled mb-0">
                    <li>2269 Elba Lane</li>
                    <li>Paris, France</li>
                    <li>888-555-2311</li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mb-4">
                  <div class="text-sm-right">
                    <h4 class="text-primary mb-2 mt-md-2">Invoice #49029</h4>
                    <ul class="list list-unstyled mb-0">
                      <li>
                        Date:
                        <span class="font-weight-semibold"
                          >January 12, 2015</span
                        >
                      </li>
                      <li>
                        Due date:
                        <span class="font-weight-semibold">May 12, 2015</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-md-flex flex-md-wrap">
              <div class="mb-4 mb-md-2">
                <span class="text-muted">Invoice To:</span>
                <ul class="list list-unstyled mb-0">
                  <li><h5 class="my-2">Rebecca Manes</h5></li>
                  <li>
                    <span class="font-weight-semibold">Normand axis LTD</span>
                  </li>
                  <li>3 Goodman Street</li>
                  <li>London E1 8BF</li>
                  <li>United Kingdom</li>
                  <li>888-555-2311</li>
                  <li><a href="#">rebecca@normandaxis.ltd</a></li>
                </ul>
              </div>

              <div class="mb-2 ml-auto">
                <span class="text-muted">Payment Details:</span>
                <div class="d-flex flex-wrap wmin-md-400">
                  <ul class="list list-unstyled mb-0">
                    <li><h5 class="my-2">Total Due:</h5></li>
                    <li>Bank name:</li>
                    <li>Country:</li>
                    <li>City:</li>
                    <li>Address:</li>
                    <li>IBAN:</li>
                    <li>SWIFT code:</li>
                  </ul>

                  <ul class="list list-unstyled text-right mb-0 ml-auto">
                    <li><h5 class="font-weight-semibold my-2">$8,750</h5></li>
                    <li>
                      <span class="font-weight-semibold"
                        >Profit Bank Europe</span
                      >
                    </li>
                    <li>United Kingdom</li>
                    <li>London E1 8BF</li>
                    <li>3 Goodman Street</li>
                    <li>
                      <span class="font-weight-semibold"
                        >KFH37784028476740</span
                      >
                    </li>
                    <li><span class="font-weight-semibold">BPT4E</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-lg">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Hours</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h6 class="mb-0">Create UI design model</h6>
                    <span class="text-muted"
                      >One morning, when Gregor Samsa woke from troubled.</span
                    >
                  </td>
                  <td>$70</td>
                  <td>57</td>
                  <td><span class="font-weight-semibold">$3,990</span></td>
                </tr>
                <tr>
                  <td>
                    <h6 class="mb-0">
                      Support tickets list doesn't support commas
                    </h6>
                    <span class="text-muted"
                      >I'd have gone up to the boss and told him just what i
                      think.</span
                    >
                  </td>
                  <td>$70</td>
                  <td>12</td>
                  <td><span class="font-weight-semibold">$840</span></td>
                </tr>
                <tr>
                  <td>
                    <h6 class="mb-0">Fix website issues on mobile</h6>
                    <span class="text-muted"
                      >I am so happy, my dear friend, so absorbed in the
                      exquisite.</span
                    >
                  </td>
                  <td>$70</td>
                  <td>31</td>
                  <td><span class="font-weight-semibold">$2,170</span></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <div class="d-md-flex flex-md-wrap">
              <div class="pt-2 mb-3">
                <h6 class="mb-3">Authorized person</h6>
                <div class="mb-3">
                  <img src="@/assets/images/signature.png" width="150" alt="" />
                </div>

                <ul class="list-unstyled text-muted">
                  <li>Eugene Kopyov</li>
                  <li>2269 Elba Lane</li>
                  <li>Paris, France</li>
                  <li>888-555-2311</li>
                </ul>
              </div>

              <div class="pt-2 mb-3 wmin-md-400 ml-auto">
                <h6 class="mb-3">Total due</h6>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Subtotal:</th>
                        <td class="text-right">$7,000</td>
                      </tr>
                      <tr>
                        <th>
                          Tax: <span class="font-weight-normal">(25%)</span>
                        </th>
                        <td class="text-right">$1,750</td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td class="text-right text-primary">
                          <h5 class="font-weight-semibold">$8,750</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="text-right mt-3">
                  <button
                    type="button"
                    class="btn btn-primary btn-labeled btn-labeled-left"
                  >
                    <b><i class="icon-paperplane"></i></b> Send invoice
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <span class="text-muted"
              >Thank you for using Limitless. This invoice can be paid via
              PayPal, Bank transfer, Skrill or Payoneer. Payment is due within
              30 days from the date of delivery. Late payment is possible, but
              with with a fee of 10% per month. Company registered in England
              and Wales #6893003, registered office: 3 Goodman Street, London E1
              8BF, United Kingdom. Phone number: 888-555-2311</span
            >
          </div>

          <div class="modal-footer bg-transparent">
            <button type="button" class="btn btn-light" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /modal with invoice -->

    <!-- Billing Details Modal -->
    <div id="BillingDetails" class="modal fade" tabindex="-2">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Billing Details</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body py-0">
            <table class="table text-nowrap table-billings" id="datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Card Name</th>
                  <th>Card Number</th>
                  <th>Expiration</th>
                  <th>CVC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>MasterCard</td>
                  <td>3291-2932-1235-3451</td>
                  <td>12/2022</td>
                  <td>928</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Billing Details Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import moment from 'moment';
// import VueNextSelect from "vue-next-select";
import util from '@/helpers/util.js'

export default {
  name: "ClientsDetail",
  // components: {
  //   "vue-select": VueNextSelect,
  // },
  data() {
    return {
      statusOptions: [
        {id: 'overdue', name: 'Overdue'},
        {id: 'hold', name: 'Hold'},
        {id: 'pending', name: 'Pending'},
        {id: 'paid', name: 'Paid'},
        {id: 'invalid', name: 'Invalid'},
        {id: 'cancel', name: 'Cancel'},
      ],
      toggle: {
        ticketing: false,
        invoices: true,
        quote: false,
      },
      client_id: null,
      data: {
        invoice: {
          ref_id: null,
          status: null,
          issue_date: null,
          due_date: null,
          amount: null,
        }
      }
    };
  },
  created() {
    this.fetchClients();
    this.clientById({ id: this.$route.params.id });
  },
  computed: {
    ...mapGetters({
      getClient: "clients/getClient",
      getClients: "clients/getClients",
    }),
    getInvoiceTotal: function() {
      var total = 0;
      this.getClient.sales.map(item => {
        total += item.GrandTotal;
      })
      return total;
    },
    // getTicketTotal: function() {
    //   var total = 0;
    //   this.getClient.tiket.map(item => {
    //     total += item.GrandTotal;
    //   })
    //   return total;
    // },
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.invoice-archive') && $.fn.DataTable.isDataTable('#table-client-detail')) {
      $("#table-client-detail").DataTable({
        autoWidth: true,
        columnDefs: [
          {
            targets: 0,
            width: 100,
          },
          {
            targets: 3,
            width: 350,
          },
          {
            orderable: false,
            width: 16,
            targets: 5,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-pdf"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
      $(".invoice-archive").DataTable({
        autoWidth: true,
        columnDefs: [
          {
            width: 30,
            targets: 0,
          },
          {
            orderable: false,
            width: 120,
            targets: 5,
          },
          {
            width: "15%",
            targets: [4, 5],
          },
          {
            width: "15%",
            targets: 4,
          },
          {
            width: "25%",
            targets: 3,
          },
        ],
        order: [[0, "desc"]],
        dom:
          '<"datatable-header"fl><"datatable-scroll-lg"t><"datatable-footer"ip>',
        language: {
          search: "<span>Filter:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 25,
        // drawCallback: function() {
        //   var api = this.api();
        //   var rows = api.rows({ page: "current" }).nodes();
        //   var last = null;

        //   api
        //     .column(1, { page: "current" })
        //     .data()
        //     .each(function(group, i) {
        //       if (last !== group) {
        //         $(rows)
        //           .eq(i)
        //           .before(
        //             '<tr class="table-active table-border-double"><td colspan="8" class="font-weight-semibold">' +
        //               group +
        //               "</td></tr>"
        //           );

        //         last = group;
        //       }
        //     });

        //   // Initializw Select2
        //   if (!$().select2) {
        //     console.warn("Warning - select2.min.js is not loaded.");
        //     return;
        //   }
        //   $(".form-control-select2").select2({
        //     width: 150,
        //     minimumResultsForSearch: Infinity,
        //   });
        // },
      });
    }
  },
  mounted() {
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".select").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      clientById: "clients/clientById",
    }),
    toggleTicketing() {
      this.toggle.ticketing = true;
      this.toggle.invoices = false;
      this.toggle.quote = false;
    },
    toggleInvoices() {
      this.toggle.invoices = true;
      this.toggle.ticketing = false;
      this.toggle.quote = false;
    },
    toggleQuote() {
      this.toggle.quote = true;
      this.toggle.invoices = false;
      this.toggle.ticketing = false;
    },
    openBillingDetailsModal() {
      console.log('tesssss')
      $('#BillingDetails').modal('show');
    },
    getDate(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
    async deleteClient(id) {
      const action = () =>  {
        this.deleteClientById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchClients()
    },
  },
};
</script>
