<template>
  <div class="categories">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Best Sellers</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddBestSellersModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Best Sellers
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-best_sellers" id="datatable">
        <thead>
          <tr>
            <th>#</th>
            <th>Details</th>
            <th>Product</th>
            <th>Image</th>
            <th>Status</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(best_sellers, index) in getBestSellers" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ best_sellers.info }}</td>
            <td>{{ best_sellers.product.title }}</td>
            <td>
                <img :src="best_sellers.image" alt="BestSellers image" width="60" height="60" v-if="best_sellers.image">
            </td>
            <td>{{ best_sellers.status ? 'Active' : 'Inactive' }}</td>
            <td>{{ best_sellers.created_at }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editBestSellers(index)"
                      ><i class="icon-pencil"></i> Edit Best Sellers</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteBestSellers(best_sellers.id)"
                      ><i class="icon-trash"></i> Delete Best Sellers</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add BestSellers modal -->
    <div id="AddBestSellers" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Best Sellers</h5>
            <h5 class="modal-title" v-else>Edit BestSellers</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <div class="col">
                  <label>Product</label>
                    <vue-select
                      v-if="getProducts"
                      v-model="data.product_id"
                      :options="getProducts"
                      placeholder="Please Select"
                      label-by="title"
                      value-by="id"
                      close-on-select
                      clear-on-select
                      searchable
                      maxHeight="100"
                      class="form-control"
                      style="width: 100% !important;max-height: 100px !important;"
                    />
                  </div>
                  <span v-if="errors.product_id" class="form-text text-danger">{{ errors.product_id[0] }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Details</label>
                    <textarea cols="20" rows="3" placeholder="Enter Details" v-model="data.info" class="form-control"></textarea>
                  </div>
                  <span v-if="errors.info" class="form-text text-danger">{{ errors.info[0] }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Status</label>
                    <vue-select
                      v-model="data.status"
                      :options="[
                        { id: 0, name: 'Inactive' },
                        { id: 1, name: 'Active' },
                      ]"
                      placeholder="Please Select"
                      label-by="name"
                      value-by="id"
                      close-on-select
                      style="width: 100% !important"
                      searchable
                    />
                    <span v-if="errors.status" class="form-text text-danger">{{ errors.status[0] }}</span>
                  </div>
                  <span v-if="errors.info" class="form-text text-danger">{{ errors.info[0] }}</span>
                </div>
              </div>
              <div class="form-group" v-if="modal == 0">
                <upload-file :max="1" maxError="Max files exceed" @changed="handleImages"></upload-file>
							</div>
              <div class="form-group" v-if="modal == 1">
								<label class="font-weight-semibold">Image</label>
								<upload-file :max="1" maxError="Max files exceed" @changed="handleImages" :imageData="[data.image].filter(item => item != null)"></upload-file>
							</div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addBestSellers() : editBestSellersSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add BestSellers modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'
import VueNextSelect from 'vue-next-select'
import UploadFileVue from '@/components/UploadFile.vue';
import moment from 'moment';

export default {
  name: "BestSellers",
  components: {
    'upload-file': UploadFileVue,
    'vue-select': VueNextSelect,
  },
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      id: null,
      data: {
        product_id: null,
        info: null,
        image: null,
        status: null,
      },
    }
  },
  created() {
    this.fetchProducts()
    this.fetchBestSellers()
    .then(() => {
      this.initDatatables();
    });
  },
  computed: {
    ...mapGetters({
      getBestSellers: "bestsellers/getBestSellers",
      getProducts: "products/getProducts",
    }),
  },
  mounted() {
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      fetchBestSellers: "bestsellers/fetchBestSellers",
      createBestSellers: "bestsellers/createBestSellers",
      updateBestSellers: "bestsellers/updateBestSellers",
      deleteBestSellersById: "bestsellers/deleteBestSellersById",
    }),
    openAddBestSellersModal() {
      this.modal = 0;
      this.id = null;
      this.data.product_id = null;
      this.data.info = null;
      this.data.image = null;
      this.data.status = null;
      $('#AddBestSellers').modal('show');
    },
    handleImages(img) {
      this.data.image = img[0] ?? '';
    },
    addBestSellers() {
      this.createBestSellers({ data: this.data }).then(() => {
        util.showToast('BestSellers successfully added');
        $(".table-best_sellers").DataTable().destroy()
        this.fetchBestSellers()
        .then(() => {
          this.initDatatables();
        });
        $('#AddBestSellers').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editBestSellers(i) {
      this.modal = 1;
      let val = this.getBestSellers[i];
      this.id = val.id
      this.data.product_id = val.product_id;
      this.data.info = val.info;
      this.data.image = val.image;
      this.data.status = val.status;
      $('#AddBestSellers').modal('show');
    },
    editBestSellersSubmit() {
      let data = this.data;
      this.updateBestSellers({ id: this.id, data }).then(() => {
        util.showToast('BestSellers successfully changed');
        $(".table-best_sellers").DataTable().destroy()
        this.fetchBestSellers()
        .then(() => {
          this.initDatatables();
        });
        $('#AddBestSellers').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteBestSellers(id) {
      const action = () =>  {
        this.deleteBestSellersById({id})
        this.fetchBestSellers()
        .then(() => {
          this.initDatatables();
        });
      }
      await util.deleteConfirmationToast(action)
    },
    initDatatables() {
      if (!$.fn.DataTable.isDataTable('.table-best_sellers')) {
        $(".table-best_sellers").DataTable({
          items: [{ subitems: []}],
          autoWidth: false,
          columnDefs: [
            {
              className: "control",
              orderable: false,
              targets: -1,
            },
          ],
          order: [[0, "asc"]],
          dom:
            '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
          language: {
            search: "<span>Search people:</span> _INPUT_",
            searchPlaceholder: "Type to filter...",
            lengthMenu: "<span>Show:</span> _MENU_",
            paginate: {
              first: "First",
              last: "Last",
              next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
              previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
            },
          },
          lengthMenu: [25, 50, 75, 100],
          displayLength: 50,
          // responsive: {
          //   details: {
          //     type: "column",
          //     target: -1,
          //   },
          // },
          buttons: [
            // {
            //   extend: "pdfHtml5",
            //   text: '<i class="icon-file-pdf"></i>',
            //   className: "btn bg-light",
            //   orientation: "landscape",
            //   exportOptions: {
            //     columns: [0, 1, 2, 3, 4, 5],
            //     stripHtml: true,
            //   },
            //   customize: function(doc) {
            //     doc.content[1].table.widths = Array(
            //       doc.content[1].table.body[0].length + 1
            //     )
            //       .join("*")
            //       .split("");
            //   },
            // },
          ],
        });
      }
    },
    getDateInput(date) {
      return moment(date).format('MM/DD/YYYY hh:mm');
    }
  },
};
</script>
