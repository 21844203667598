import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  returns: [],
  return: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getReturns: (state) => {
    return state.returns;
  },
  getReturn: (state) => {
    return state.return;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_RETURNS: (state, data) => (state.returns = data),
  SET_RETURN: (state, data) => (state.return = data),
};

const actions = {
  async fetchReturns({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/returns/sale?limit=10&search&SortType=desc&SortField=id"
      );
      commit("SET_RETURNS", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_RETURNS", {});
    }
    commit("SET_LOADER", false);
  },
  async fetchRMA({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/returns/sale/rma/list?limit=10&search&SortType=desc&SortField=id"
      );
      commit("SET_RETURNS", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_RETURNS", {});
    }
    commit("SET_LOADER", false);
  },
  async getRefundById({ commit }, { id }) {
      commit("SET_LOADER", true);
      let response = await ApiService.get(
        `/returns/sale/${id}`
      );
      commit("SET_RETURN", response.data);
      commit("SET_LOADER", false);
      return response.data;
  },
  async createReturn({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("/returns/sale", { ...data });
    commit("SET_LOADER", false);
    return response.data;
  },
  async updateReturn({ commit }, { id, data }) {
    let response = await ApiService.patch("/returns/sale/" + id, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteReturnById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/returns/sale/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
