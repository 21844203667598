<template>
  <div class="Invoice-detail">
    <div class="card">
      <div class="card-header header-elements-inline border-bottom">
        <h6 class="card-title">Invoice Details</h6>
        <!-- <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
          >
            <b><i class="icon-pencil mr-2"></i></b> Edit Invoices
          </button>
          <router-link :to="`/rma/create/${ getInvoiceDetail.id }`">
            <button
              type="button"
              class="btn btn-transparent btn-sm mr-2"
              data-toggle="modal"
            >
              <b><i class="icon-plus-circle2 mr-2"></i></b> Create RMA
            </button>
          </router-link>
        </div> -->
        <!-- <div class="btn-group justify-content-start">
					<a href="#" class="btn bg-indigo-400 dropdown-toggle" data-toggle="dropdown">More</a>
					<div class="dropdown-menu">
            <router-link :to="`/rma/create/${ getInvoiceDetail.id }`">
						<a class="dropdown-item text-dark">Create RMA</a>
            </router-link>
            <router-link :to="`/invoices/create/${ getInvoiceDetail.id }`">
						<a class="dropdown-item text-dark">Edit Invoices</a>
            </router-link>
						<a class="dropdown-item text-dark">Send Email</a>
					</div>
				</div> -->
        <router-link :to="`${ getInvoiceDetail.type == 'Service' ? `/services/edit/${getInvoiceDetail.id}` : `/invoices/edit/${getInvoiceDetail.id}` }`" class="">
          <a class="btn btn-sm bg-warning ">Edit Invoices</a>
        </router-link>
      </div>
      <div class="container">
        <div class="row d-flex justify-content-center align-content-center align-items-center mb-2">
          <div class="col">
            <table class="table table-sm table-borderless">
              <tbody v-if="getInvoiceDetail && getInvoiceDetail.user">
                <tr>
                  <td>Order ID</td>
                  <td>#ORDER{{ getInvoiceDetail.id }}</td>
                  <td>Date</td>
                  <td>{{ formatDate(getInvoiceDetail.created_at) }}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{{ getInvoiceDetail?.user.first_name + ' ' + getInvoiceDetail.user.last_name }}</td>
                  <td>Total Amount</td>
                  <td>
                    <div class="badge badge-success">
                      ${{ getInvoiceDetail.transactions?.reduce((next, prev) =>  next + prev.total, 0).toFixed(2) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ getInvoiceDetail.user.email }}</td>
                  <td>Discount</td>
                  <td>-${{ getInvoiceDetail.discount }}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{{ getInvoiceDetail.user.phone }}</td>
                  <td>Paid Amount</td>
                  <td>${{ getInvoiceDetail.transaction_payments?.reduce((next, prev) =>  next + prev.total, 0) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row p-4">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title m-n2" data-toggle="collapse"  href="#billing" data-target="#billing" style="cursor: pointer;" aria-expanded="false">Billing Address</h6>
                <table class="table table-sm table-borderless collapse" id="billing">
                  <tbody v-if="getInvoiceDetail && getInvoiceDetail.user">
                    <tr>
                      <td>Order ID</td>
                      <td>#INV-{{ getInvoiceDetail.id }}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{{ getInvoiceDetail.user.first_name + ' ' + getInvoiceDetail.user.last_name }}</td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>{{ getInvoiceDetail.company  }}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{ getInvoiceDetail.billing_address }}</td>
                    </tr>
                    <tr>
                      <td>Suburb</td>
                      <td>{{ getInvoiceDetail.billing_city }}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{{ getInvoiceDetail.billing_country }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{{ getInvoiceDetail.user.phone }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ getInvoiceDetail.user.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title m-n2" data-toggle="collapse"  href="#shipping" data-target="#shipping" style="cursor: pointer;" aria-expanded="false">Shipping Address</h6>
                <table class="table table-sm table-borderless collapse" id="shipping">
                  <tbody v-if="getInvoiceDetail && getInvoiceDetail.user">
                    <tr>
                      <td>Date</td>
                      <td>{{ getInvoiceDetail.created_at }}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{{ getInvoiceDetail.user.first_name + ' ' + getInvoiceDetail.user.last_name }}</td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>{{ getInvoiceDetail.company }}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{ getInvoiceDetail.billing_address }}</td>
                    </tr>
                    <tr>
                      <td>Suburb</td>
                      <td>{{ getInvoiceDetail.billing_city }}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{{ getInvoiceDetail.billing_country }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{{ getInvoiceDetail.user.phone }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{{ getInvoiceDetail.user.email }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs mb-3 mr-auto justify-content-end" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Sales View</a>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Warehouse View</a>
          </li> -->
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="row">
              <div class="col">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">SKU</th>
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Warehouse</th> -->
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                      <!-- <th scope="col">Coupon</th> -->
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(invoice, i) in getInvoiceDetail.transactions" :key="i">
                      <th scope="row">{{ i + 1 }}</th>
                      <th>{{ invoice.product?.sku ?? invoice?.sku }}</th>
                      <th>{{ invoice.product?.title ?? invoice?.name }}</th>
                      <!-- <td>Gudang 1</td> -->
                      <th>{{ invoice.quantity }}</th>
                      <td>${{ invoice.price }}</td>
                      <!-- <td>$0</td> -->
                      <td>${{ invoice.price * invoice.quantity }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="row">
              <div class="col">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Qty</th>
                      <th scope="col">SKU</th>
                      <th scope="col">Name</th>
                      <th scope="col">Warehouse</th>
                      <th scope="col">Qty Ready</th>
                      <th scope="col">Pack</th>
                      <th scope="col">Backorder</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(invoice, i) in getInvoiceDetail.transactions" :key="i">
                      <th scope="row">{{ i + 1 }}</th>
                      <th>{{ invoice.quantity }}</th>
                      <th>{{ invoice.product?.sku }}</th>
                      <th>{{ invoice.product?.title }}</th>
                      <td>Gudang 1</td>
                      <td>
                        <input
                          type="number"
                          placeholder="Qty Ready"
                          class="form-control"
                        />
                      </td>
                      <td>
                        <input type="checkbox" class="form-check-input m-auto">
                      </td>
                      <td>
                        <input
                          type="number"
                          placeholder="Backorder"
                          class="form-control"
                        />
                      </td>
                      <td>${{ invoice.product?.price * invoice.quantity }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="card">
          <div class="card-body">
            <div class="col-md-7">
              <h5>Shipping</h5>
              <div class="form-group mb-n1">
                <div class="row">
                  <div class="col-sm-6">
                    <p>Shipping Weight</p>
                  </div>
                  <div class="col-sm-4 mt-n3">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <span class="input-group-append">
                        <span class="input-group-text">kg</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <vue-select
                      v-model="data.status"
                      :options="[
                        { id: 'Pending', name: 'Pending' },
                        { id: 'Unpaid', name: 'Unpaid' },
                        { id: 'Paid', name: 'Paid' },
                      ]"
                      placeholder="Please Select"
                      label-by="name"
                      value-by="id"
                      close-on-select
                      style="width: 100% !important"
                      searchable
                    />
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="addProductInput()"
                    >
                      Calculate Shipping
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group mb-n1">
                <div class="row">
                  <div class="col-sm-6">
                    <p>Shipping Discount</p>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <span class="input-group-text">-$</span>
                      </span>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group">
                      <span class="input-group-prepend">
                      <span class="input-group-text">(</span>
                      </span>
                      <input type="text" class="form-control">
                      <span class="input-group-append">
                      <span class="input-group-text">%)</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <p>Shipping Discount</p>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </span>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                <input type="checkbox" class="form-check-input" checked="">
                Tax free shipping?
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                <input type="checkbox" class="form-check-input" checked="">
                Signature Required?
                </label>
              </div>
            </div>
          </div>
        </div> -->
          <!-- <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <label>Notes</label>
                <textarea
                  rows="2"
                  cols="3"
                  class="form-control"
                  placeholder="Notes"
                >
                </textarea>
              </div>
            </div>
          </div> -->
          <!-- <div class="card">
            <div class="card-body p-n2">
              <button type="button" class="btn btn-primary btn-sm float-right" @click="deleteInvoice()">
                <i class="icon-trash"></i>
                Save Changes
              </button>
            </div>
          </div> -->
          <!-- <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-link active" id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-selected="true">Payment</a>
              <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Payment & Refund History </a>
              <a class="nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">History</a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label>Card Number</label>
                    <input
                      type="text"
                      placeholder="Card number"
                      class="form-control"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Expiration</label>
                    <input
                      type="text"
                      placeholder="Expiration"
                      class="form-control"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>
                  <div class="col-sm-6">
                    <label>CVC</label>
                    <input
                      type="text"
                      placeholder="CVC"
                      class="form-control"
                    />
                    <span v-if="errors.ShortName" class="form-text text-danger">{{ errors.ShortName[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Amount</label>
                    <input
                      type="text"
                      placeholder="Amount"
                      class="form-control"
                    />
                    <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                  </div>
                  <div class="col-sm-6">
                    <label>Notes</label>
                    <input
                      type="text"
                      placeholder="Notes"
                      class="form-control"
                    />
                    <span v-if="errors.ShortName" class="form-text text-danger">{{ errors.ShortName[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="float-right m-3">
                <button type="button" class="btn btn-link" data-dismiss="modal">
                  Close
                </button>
                <button type="submit" class="btn btn-primary" @click="modal == 0 ? addUnit() : editUnitSubmit()">
                  Submit <i class="icon-paperplane ml-2"></i>
                </button>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...a</div>
            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
          </div> -->
        </div>
      <!-- <div class="row d-flex justify-content-center align-items-center mb-2">
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span class="font-weight-bold" v-if="data.firstname">Full name:</span>
              <div class="ml-auto">
                {{ getInvoiceDetail.id.firstname }} {{ getInvoiceDetail.id.lastname }}
              </div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Username</span>
              <div class="ml-auto">{{ getInvoiceDetail.id.username }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Email</span>
              <div class="ml-auto">{{ getInvoiceDetail.id.email }}</div>
            </li>
            <li class="list-group-item">
              <span class="font-weight-bold">Phone Number</span>
              <div class="ml-auto">{{ getInvoiceDetail.id.phone }}</div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="list-group list-group-flush">
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getInvoiceDetail.id.address">{{ getInvoiceDetail.id.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getInvoiceDetail.id.address">{{ getInvoiceDetail.id.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Address</span>
              <div class="ml-auto" v-if="getInvoiceDetail.id.address">{{ getInvoiceDetail.id.address || '-' }}</div>
            </li>
            <li class="list-group-item my-n1">
              <span class="font-weight-bold">Actions</span>
              <div class="ml-auto">
                <button
                  type="button"
                  class="btn btn-transparent btn-sm mr-1"
                  data-toggle="modal"
                  data-target="#EditInvoice"
                >
                  <i class="icon-pencil7"></i>
                </button>
                <button type="button" class="btn btn-transparent btn-sm" @click="deleteInvoice()">
                  <i class="icon-trash"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'
import moment from 'moment';

export default {
  data() {
    return {
      errors: [],
      data: {
        id: null,
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        phone: null,
        phone_2: null,
        address: null,
        password: null,
        role_id: null,
        permission_id: [],
        statut: null,
      },
    };
  },
  created() {
    this.invoiceById({ id: this.$route.params.id });
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-Invoice')) {
      $(".table-Invoice").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 400,
          },
          {
            orderable: false,
            width: 16,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-excel"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
          {
            extend: "pdfHtml5",
            text: '<i class="icon-printer"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Anytime;
    Summernote;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".select-role").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".select-status").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    if (!$("#begin_time")) {
      $("#begin_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    if (!$("#begin_time")) {
      $("#end_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".summernote").summernote();
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  computed: {
    ...mapGetters({
      getInvoiceDetail: "invoices/getInvoice",
    }),
  },
  methods: {
    ...mapActions({
      invoiceById: "invoices/invoiceById",
    }),
    selectedPermissions(id) {
      this.data.permission_id.includes(id) ? this.data.permission_id = this.data.permission_id.filter((x) => x != id) : this.data.permission_id.push(id)
    },
    updateInvoice() {
      this.editInvoice({id: this.$route.params.id, data: this.data }).then(() => {
        util.showToast('Invoice successfully changed');
        $('#EditInvoice').modal('hide');
        this.fetchInvoice()
      }).catch(err => {
        this.errors = err.response?.data.errors;
      })
    },
    async deleteInvoice(id) {
      const action = () => {
        this.deleteInvoiceById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchInvoice()
    },
    async exportTimesheet() {
      await ApiService.get("/Invoice/exportExcel", {
        'headers': {
          'Content-Disposition': "attachment; filename=TimeSheets.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TimeSheets.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD MMM YYYY')
      }
    }
  },
  watch: {
    'data.statut' : function(data, prev) {
      if (prev != null) {
        util.showToast('Status successfully changed');
        this.editInvoiceStatus({id: this.$route.params.id, data })
      }
    },
    'data.role_id' : function(data, prev) {
      if (prev != null) {
        util.showToast('Role successfully changed');
        this.editInvoice({id: this.$route.params.id, data: this.data })
      }
    }
  }
};
</script>
