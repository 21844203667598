import axios from 'axios'
import { StorageService } from '@/services/storage.service'
import UserService from './user.service';
import router from '@/router';

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        axios.defaults.headers.common["token"] = `${StorageService.getToken()}`
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    async get(resource, params) {
      return axios.get(resource, params).then(res => {
        if (res.data.message == 'Expired Token' || res.data.message == 'Invalid Token' || res.data.message == 'Invalid User Token') {
          UserService.logout();
          router.push("/login");
        }
        return res;
      })
    },

    async post(resource, data, config) {
      return axios.post(resource, data, config).then(res => {
        if (res.data.message == 'Expired Token' || res.data.message == 'Invalid Token' || res.data.message == 'Invalid User Token') {
          UserService.logout();
          router.push("/login");
        }
        return res;
      })
    },

    async put(resource, data, config) {
      return axios.put(resource, data, config).then(res => {
        if (res.data.message == 'Expired Token' || res.data.message == 'Invalid Token' || res.data.message == 'Invalid User Token') {
          UserService.logout();
          router.push("/login");
        }
        return res;
      })
    },

    async patch(resource, data) {
      return axios.patch(resource, data).then(res => {
        if (res.data.message == 'Expired Token' || res.data.message == 'Invalid Token' || res.data.message == 'Invalid User Token') {
          UserService.logout();
          router.push("/login");
        }
        return res;
      })
    },

    async delete(resource) {
      return axios.delete(resource).then(res => {
        if (res.data.message == 'Expired Token' || res.data.message == 'Invalid Token' || res.data.message == 'Invalid User Token') {
          UserService.logout();
          router.push("/login");
        }
        return res;
      })
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        return axios(data)
    }
}

export default ApiService