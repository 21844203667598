<template>
  <div class="returns">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">RMA</h6>
        <div>
          <!-- <router-link :to="`/refunds/create`">
            <button
              type="button"
              class="btn btn-transparent btn-sm mr-2"
            >
              <b><i class="icon-plus-circle2 mr-2"></i></b> Add RMA
            </button>
          </router-link> -->
        </div>
      </div>

      <table class="table table-refunds" id="datatable">
        <thead>
          <tr>
            <th>Invoice Ref ID</th>
            <th>Client</th>
            <th>Products</th>
            <th>Refund Type</th>
            <th>Paid Amount</th>
            <th>Total Refund</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(refund, index) in getReturns.sale_Return" :key="index">
            <td>
              <router-link :to="`/rma/${refund.id}`">
              {{ refund.invoice.Ref }}
              </router-link>
            </td>
            <td>{{ refund.client_name }}</td>
            <td>{{ refund.details.filter(item => item.quantity > 0).length }} Products</td>
            <td>Coupon/Money Back</td>
            <td>${{ refund.paid_amount }}</td>
            <td>${{ refund.GrandTotal }}</td>
            <td>{{ refund.statut }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editReturn()"
                      ><i class="icon-pencil"></i> Edit Refunds</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteReturn()"
                      ><i class="icon-trash"></i> Delete Refunds</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Return modal -->
    <div id="AddReturns" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Return</h5>
            <h5 class="modal-title" v-else>Edit Return</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Code</label>
                  <input
                    type="text"
                    placeholder="Enter Code"
                    class="form-control"
                    v-model="data.code"
                  />
                  <span v-if="errors.code" class="form-text text-danger">{{ errors.code[0] }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    class="form-control"
                    v-model="data.name"
                  />
                  <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                </div>
              </div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addReturn() : editReturnSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Return modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'

export default {
  name: "Returns",
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      data: {
        code: null,
        name: null,
      }
    }
  },
  created() {
    this.fetchRMA();
  },
  computed: {
    ...mapGetters({
      getReturns: "refunds/getReturns",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable(".invoice-archive")) {
      $(".table-refunds").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 100,
          },
          {
            orderable: false,
            targets: 6,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          // {
          //   extend: "pdfHtml5",
          //   text: '<i class="icon-file-pdf"></i>',
          //   className: "btn bg-light",
          //   orientation: "landscape",
          //   exportOptions: {
          //     columns: [0, 1, 2, 3, 4, 5],
          //     stripHtml: true,
          //   },
          //   customize: function(doc) {
          //     doc.content[1].table.widths = Array(
          //       doc.content[1].table.body[0].length + 1
          //     )
          //       .join("*")
          //       .split("");
          //   },
          // },
        ],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchRMA: "refunds/fetchRMA",
      createReturn: "refunds/createReturn",
      updateReturn: "refunds/updateReturn",
      deleteReturnById: "refunds/deleteReturnById",
    }),
    openAddReturnModal() {
      this.modal = 0;
      this.id = null;
      this.data.code = null;
      this.data.name = null;
      $('#AddReturns').modal('show');
    },
    handleImage(event) {
      this.imageName = event.target.files[0].name
      console.log()
      this.image = event.target.files;
    },
    addReturn() {
      this.createReturn({ data: this.data }).then(() => {
        util.showToast('Return successfully added');
        this.fetchRMA();
        $('#AddReturns').modal('hide');
      }).
      ch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editReturn(i) {
      this.modal = 1;
      let val = this.getReturns.returns[i];
      this.id = val.id
      this.data.code = val.code
      this.data.name = val.name
      $('#AddReturns').modal('show');
    },
    editReturnSubmit() {
      let data = this.data;
      this.updateReturn({ id: this.id, data }).then(() => {
        util.showToast('Return successfully changed');
        this.fetchRMA();
        $('#AddReturns').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteReturn(id) {
      const action = () =>  {
        this.deleteReturnById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchRMA()
    },
    getImageUrl(image) {
      return process.env.VUE_APP_IMAGE_URL + image;
    },
  },
};
</script>
