<template>
  <div class="staff">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Staff</h6>
        <div>
          <!-- <button
            @click="exportStaff"
            type="button"
            class="btn btn-transparent btn-sm mr-2"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button> -->
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            @click="openAddStaffModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Staff
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-staff" id="datatable">
        <thead>
          <tr>
            <th>Profile</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Role</th>
            <!-- <th>Status</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(staff, index) in getStaff" :key="index">
            <td>
              <div
                class="media d-flex justify-content-center align-items-center"
              >
                <div class="mr-3">
                  <router-link :to="`/staff/${staff.id}`">
                    <img
                      src="@/assets/images/placeholders/placeholder.jpg"
                      width="40"
                      height="40"
                      class="rounded-circle"
                      alt=""
                    />
                  </router-link>
                </div>

                <div class="media-body align-self-center">
                  <router-link
                    :to="`/staff/${staff.id}`"
                    class="font-weight-semibold"
                    >{{ staff.fullname }}</router-link
                  >
                  <div class="text-muted font-size-sm">
                    Created: {{ staff.created_at ? getCreatedAt(staff.created_at) : '-' }}
                  </div>
                </div>
              </div>
            </td>
            <td>{{ staff.phone }}</td>
            <td>
              <a :href="'mailto:' + staff.email">{{ staff.email }}</a>
            </td>
            <td>{{ staff.role.name }}</td>
            <!-- <td>
              <div v-if="staff.statut == 1" class="ml-auto badge badge-success">
                Active
              </div>
              <div v-else class="ml-auto badge badge-danger">
                Inactive
              </div>
            </td> -->
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editStaff(index)"
                      ><i class="icon-pencil"></i> Edit Staff</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteStaff(staff.id)"
                      ><i class="icon-trash"></i> Delete Staff</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Staff Modal -->
    <div id="AddStaff" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-md">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Add Staff</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Firstname</label>
                  <input
                    type="text"
                    placeholder="Enter your firstname"
                    class="form-control"
                    v-model="data.first_name"
                  />
                <span v-if="errors.first_name" class="form-text text-danger">{{ errors.first_name[0] }}</span>

                </div>

                <div class="col-sm-6">
                  <label>Lastname</label>
                  <input
                    type="text"
                    placeholder="Enter your lastname"
                    class="form-control"
                    v-model="data.last_name"
                  />
                <span v-if="errors.last_name" class="form-text text-danger">{{ errors.last_name[0] }}</span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    class="form-control"
                    v-model="data.email"
                  />
                  <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                </div>
                <div class="col-sm-6">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter your phone number"
                    class="form-control"
                    v-model="data.phone"
                  />
                  <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    class="form-control"
                    v-model="data.password"
                  />
                  <span v-if="errors.password" class="form-text text-danger">{{ errors.password[0] }}</span>

                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Roles</label>
                  <vue-select
                    v-if="getRoles"
                    v-model="data.role_id"
                    :options="getRoles"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important;"
                  />
                  <span v-if="errors.role_id" class="form-text text-danger">{{ errors.role_id[0] }}</span>
                </div>
              </div>
            </div>

          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addStaff() : editStaffSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Staff -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
// import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
// import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
// import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import VueNextSelect from 'vue-next-select'
import util from '@/helpers/util.js'
import moment from 'moment';

export default {
  name: "Staff",
  components: {
    'vue-select': VueNextSelect,
  },
  data() {
    return {
      roles: [],
      modal: 0,
      permissions: [],
      errors: [],
      id: null,
      data: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        role_id: null,
        permission_id: [],
      }
    }
  },
  created() {
    this.fetchRoles();
    this.fetchPermissions();
    this.fetchStaff()
    .then(() => {
      this.initDatatables();
    });
  },
  // updated() {
  //   if (!$.fn.DataTable.isDataTable('.table-staff')) {
  //     $(".table-staff").DataTable({
  //       autoWidth: false,
  //       columnDefs: [
  //         {
  //           targets: 0,
  //           width: 250,
  //         },
  //         {
  //           orderable: false,
  //           width: 16,
  //           targets: 6,
  //         },
  //         // {
  //         //   className: "control",
  //         //   orderable: false,
  //         //   targets: -1,
  //         // },
  //       ],
  //       order: [[0, "asc"]],
  //       dom:
  //         '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
  //       language: {
  //         search: "<span>Search people:</span> _INPUT_",
  //         searchPlaceholder: "Type to filter...",
  //         lengthMenu: "<span>Show:</span> _MENU_",
  //         paginate: {
  //           first: "First",
  //           last: "Last",
  //           next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
  //           previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
  //         },
  //       },
  //       lengthMenu: [25, 50, 75, 100],
  //       displayLength: 50,
  //       responsive: {
  //         details: {
  //           type: "column",
  //           target: -1,
  //         },
  //       },
  //       buttons: [
  //         {
  //           text: '<i class="icon-file-pdf" onclick=".exportStaff()"></i>',
  //           className: "btn bg-light d-none",
  //           orientation: "landscape",
  //           customize: function(doc) {
  //             doc.content[1].table.widths = Array(
  //               doc.content[1].table.body[0].length + 1
  //             )
  //               .join("*")
  //               .split("");
  //           },
  //         },
  //       ],
  //     });
  //   }
  // },
  mounted() {
    window.pdfMake.vfs = pdfFonts.pdfMake.vfs;
    Datatables;
    Select2;
    Jszip;
    // Pdfmake;
    // Vfs;
    Buttons;
    Responsive;
    $(".select").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  computed: {
    ...mapGetters({
      getRoles: "roles/getRoles",
      getStaff: "staff/getStaff",
      getPermissions: "permissions/getPermissions",
    }),
  },
  methods: {
    ...mapActions({
      fetchStaff: "staff/fetchStaff",
      createStaff: "staff/createStaff",
      editUserStaff: "staff/editStaff",
      editStaffStatus: "staff/editStaffStatus",
      deleteStaffById: "staff/deleteStaffById",
      fetchRoles: "roles/fetchRoles",
      fetchPermissions: "permissions/fetchPermissions",
    }),
    getCreatedAt(date) {
      return moment(date).format('YYYY.MM.DD');
    },
    selectedPermissions(id) {
      this.data.permission_id.includes(id) ? this.data.permission_id = this.data.permission_id.filter((x) => x != id) : this.data.permission_id.push(id)
      console.log(this.data.permission_id)
    },
    openAddStaffModal() {
      this.modal = 0;
      this.id = null;
      $('#AddStaff').modal('show');
    },
    async addStaff() {
      try {
        this.createStaff({ data: this.data }).then((res) => {
          if (!res.data.status) {
            this.errors = res.data.errors;
            return util.showToast('Staff cannot be added', 'error');
          }else{
            util.showToast('Staff successfully added');
            $(".table-staff").DataTable().destroy()
            this.fetchStaff()
            .then(() => {
              this.initDatatables();
            });
            $('#AddStaff').modal('hide');
          }
        })
      } catch (error) {
        this.errors = error.response.data.errors;
        console.log(error);
      }
    },
    editStaff(i) {
      this.modal = 1;
      let val = this.getStaff[i];
      this.id = val.id;
      this.data.first_name = val.first_name;
      this.data.last_name = val.last_name;
      this.data.email = val.email;
      this.data.phone = val.phone;
      this.data.password = val.password;
      this.data.role_id = val.role_id;
      this.data.permission_id = [];
      $('#AddStaff').modal('show');
    },
    editStaffSubmit() {
      let data = this.data;
      try {
        this.editUserStaff({ id: this.id, data }).then(() => {
          util.showToast('Staff successfully changed');
          $(".table-staff").DataTable().destroy()
          this.fetchStaff()
          .then(() => {
            this.initDatatables();
          });
          $('#AddStaff').modal('hide');
        }).catch(err => {
          console.log(err)
          this.errors = err.response.data.errors;
        });
      } catch (error) {
        console.log(error)
      }
    },
    async deleteStaff(id) {
      const action = () =>  {
        this.deleteStaffById({id})
        this.fetchStaff()
        .then(() => {
          this.initDatatables();
        });
      }
      await util.deleteConfirmationToast(action)
    },
    getPermissionString: (string) => {
      var i, frags = string.split('_');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    },
    async exportStaff() {
      try {
        await ApiService.get("/staff/users/export/Excel", {
          'headers': {
                'Content-Disposition': "attachment; filename=Users.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',
        }).then(response=> {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Staff.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      } catch (error) {
        this.errors = error.response.data.errors;
        console.log(error);
      }
    },
    initDatatables() {
      if (!$.fn.DataTable.isDataTable('.table-staff')) {
        $(".table-staff").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 250,
          },
          {
            orderable: false,
            width: 16,
            targets: 4,
          },
          // {
          //   className: "control",
          //   orderable: false,
          //   targets: -1,
          // },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            text: '<i class="icon-file-pdf" onclick=".exportStaff()"></i>',
            className: "btn bg-light d-none",
            orientation: "landscape",
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
      }
    },
  },
};
</script>
