
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { defineComponent, ref, computed, reactive } from 'vue';
import { PieChart, usePieChart, LineChart, useLineChart } from 'vue-chart-3'
import { Chart, ChartData, ChartOptions, Plugin, registerables } from "chart.js";
import { formatMoney, sum } from '@/utils/Helpers';
Chart.register(...registerables);

export default defineComponent({
  name: 'Home',
  components: {
    PieChart,
    LineChart
  },
  setup(){
    const dataValues = ref([30, 40, 60, 70, 5]);
    const statics = reactive({
      sales: [100, 200, 300, 400, 500, 600, 700],
      quotations: [120, 260, 320, 410, 590, 630, 780],
      tickets: [130, 270, 320, 440, 530, 610, 790],
    })

    const pieData = computed<ChartData<"pie">>(() => ({
      labels: ['New', 'Pack', 'On Hold', 'Pending', 'Pick'],
      datasets: [
        {
          label: 'Dataset 1',
          data: dataValues.value,
          backgroundColor: [
            "#BAA1DD",
            "#09B3F0",
            "#FFB67C",
            "#EA757B",
            "#00CACC",
          ],
        }
      ]
    }));

    const pieOptions = computed<ChartOptions<"pie">>(() => ({
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
          display: false
        },
        title: {
          display: false,
          text: 'Payment Chart',
          align: 'start',
          padding: {
            bottom: 20
          },
          color: 'black'
        }
      }
    }))
    
    const { pieChartProps } = usePieChart({
      chartData: pieData,
      options: pieOptions
    })

    const lineData = computed<ChartData<"line">>(() => ({
      labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      datasets: [
        {
          label: `${formatMoney(sum(statics.sales))} Sales`,
          data: statics.sales,
          backgroundColor: 'white',
          borderColor: '#5767BE',
        },
        {
          label: `${formatMoney(sum(statics.quotations))} Quotations`,
          data: statics.quotations,
          backgroundColor: 'white',
          borderColor: '#31B83E',
        },
        {
          label: `${formatMoney(sum(statics.tickets))} Tickets`,
          data: statics.tickets,
          backgroundColor: 'white',
          borderColor: '#D7241F',
        },
      ]
    }))
    const lineOptions = computed<ChartOptions<"line">>(() => ({
      responsive: true,
      title: "Sales Statistic",
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
          text: 'Sales Statics',
          align: 'start',
          padding: {
            bottom: 20
          },
          color: 'black'
        }
      }
    }))

    const { lineChartProps } = useLineChart({
      chartData: lineData,
      options: lineOptions,
    })

    const totalSales = ref([50, 35, 65, 50, 65, 20, 60])
    const salesData = computed<ChartData<'line'>>(() => ({
      labels: ['1', '2', '3', '4', '5', '6', '7'],
      datasets: [
        {
          label: 'tes',
          data: totalSales.value,
          backgroundColor: '#3F51B5',
          borderColor: '#3F51B5',
        }
      ]
    }))
    const salesOptions = computed<ChartOptions<'line'>>(() => ({
      aspectRatio: 2.5,
      plugins: {
        legend: {
          display: false,
          title: {
            display: false
          }
        }
      },
      scales: {
        y: {
          display: false
        }
      }
    }))

    const { lineChartProps: salesChart } = useLineChart({
      chartData: salesData,
      options: salesOptions
    })
    return {
      sum,
      statics,
      formatMoney,
      salesChart,
      pieChartProps,
      lineChartProps
    }
  }
});
