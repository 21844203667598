<template>
  <div class="card">
    <div class="card-body">
      <nav>
        <div class="nav nav-tabs nav-justified nav-tabs-highlight" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-config-tab" data-toggle="tab" href="#nav-config" role="tab" aria-controls="nav-config" aria-selected="true">Config</a>
          <a class="nav-link" id="nav-config-tab" data-toggle="tab" href="#nav-home-banner" role="tab" aria-controls="nav-home-banner" aria-selected="true">Home Banner</a>
          <a class="nav-link" id="nav-config-tab" data-toggle="tab" href="#nav-shop-banner" role="tab" aria-controls="nav-shop-banner" aria-selected="true">Shop Banner</a>
          <a class="nav-link" id="nav-config-tab" data-toggle="tab" href="#nav-membership" role="tab" aria-controls="nav-membership" aria-selected="true">Membership</a>
          <!-- <a class="nav-link" id="nav-privacy-policy-tab" data-toggle="tab" href="#nav-privacy-policy" role="tab" aria-controls="nav-privacy-policy" aria-selected="true">Privacy policy</a>
          <a class="nav-link" id="nav-terms-conditions-tab" data-toggle="tab" href="#nav-terms-conditions" role="tab" aria-controls="nav-terms-conditions" aria-selected="false">Terms & Conditions</a>
          <a class="nav-link" id="nav-about-us-tab" data-toggle="tab" href="#nav-about-us" role="tab" aria-controls="nav-about-us" aria-selected="false">About Us</a>
          <a class="nav-link" id="nav-delivery-information-tab" data-toggle="tab" href="#nav-delivery-information" role="tab" aria-controls="nav-delivery-information" aria-selected="false">Delivery Information</a>
          <a class="nav-link" id="nav-return-policy-tab" data-toggle="tab" href="#nav-return-policy" role="tab" aria-controls="nav-return-policy" aria-selected="false">Return Policy</a>
          <a class="nav-link" id="nav-security-policy-tab" data-toggle="tab" href="#nav-security-policy" role="tab" aria-controls="nav-return-policy" aria-selected="false">Security Policy</a>
          <a class="nav-link" id="nav-terms-of-use-tab" data-toggle="tab" href="#nav-terms-of-use" role="tab" aria-controls="nav-terms-of-use" aria-selected="false">Terms of Use</a> -->
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-config" role="tabpanel" aria-labelledby="nav-config-tab">
          <div class="card-header header-elements-inline mt-n3">
            <h6 class="card-title">Config</h6>
          </div>
          <table class="table text-nowrap table-footer" id="datatable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Variable</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(footer, index) in getFooter.data" :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-capitalize">{{ footer.var.replaceAll('-', ' ') }}</td>
                <td class="ellipsis">{{ footer.value }}</td>
                <td class="text-right">
                  <div class="list-icons">
                    <div class="dropdown">
                      <a href="#" class="list-icons-item" data-toggle="dropdown">
                        <i class="icon-menu7"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" @click="editConfig(footer.id)"
                          ><i class="icon-pencil"></i> Edit Variable</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="nav-home-banner" role="tabpanel" aria-labelledby="nav-home-banner-tab">
          <div class="card-header header-elements-inline mt-n3">
            <h6 class="card-title">Home Banner</h6>
          </div>
          <table class="table text-nowrap table-footer" id="datatable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Variable</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(footer, index) in getFooter.home_banner" :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-capitalize">{{ footer.var.replaceAll('-', ' ') }}</td>
                <td>
                  <img :src="footer.value" :alt="footer.var" width="150" height="100">
                </td>
                <td class="text-right">
                  <div class="list-icons">
                    <div class="dropdown">
                      <a href="#" class="list-icons-item" data-toggle="dropdown">
                        <i class="icon-menu7"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" @click="editConfig(footer.id)"
                          ><i class="icon-pencil"></i> Edit Variable</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="nav-shop-banner" role="tabpanel" aria-labelledby="nav-shop-banner-tab">
          <div class="card-header header-elements-inline mt-n3">
            <h6 class="card-title">Shop Banner</h6>
          </div>
          <table class="table text-nowrap table-footer" id="datatable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Variable</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(footer, index) in getFooter.shop_banner" :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-capitalize">{{ footer.var.replaceAll('-', ' ') }}</td>
                <td>
                  <img :src="footer.value" :alt="footer.var" width="150" height="100">
                </td>
                <td class="text-right">
                  <div class="list-icons">
                    <div class="dropdown">
                      <a href="#" class="list-icons-item" data-toggle="dropdown">
                        <i class="icon-menu7"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" @click="editConfig(footer.id)"
                          ><i class="icon-pencil"></i> Edit Variable</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="nav-membership" role="tabpanel" aria-labelledby="nav-membership-tab">
          <div class="card-header header-elements-inline mt-n3">
            <h6 class="card-title">Membership</h6>
          </div>
          <table class="table text-nowrap table-footer" id="datatable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Variable</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(footer, index) in getFooter.membership" :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-capitalize">{{ footer.var.replaceAll('-', ' ') }}</td>
                <td v-if="footer.var == 'membership_categories_exclude'">{{ JSON.parse(footer.value).map(item => getCategories.find(value => value.id == item)?.name ?? '') }}</td>
                <td v-if="footer.var == 'membership_subcategories_exclude'">{{ JSON.parse(footer.value).map(item => getSubcategories.find(value => value.id == item)?.name ?? '') }}</td>
                <td class="text-right">
                  <div class="list-icons">
                    <div class="dropdown">
                      <a href="#" class="list-icons-item" data-toggle="dropdown">
                        <i class="icon-menu7"></i>
                      </a>

                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" @click="editConfig(footer.id)"
                          ><i class="icon-pencil"></i> Edit Variable</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="nav-privacy-policy" role="tabpanel" aria-labelledby="nav-privacy-policy-tab">
          <label>Privacy Policy</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.privacy_policy"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-terms-conditions" role="tabpanel" aria-labelledby="nav-terms-conditions-tab">
          <label>Terms & Conditions</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.terms_conditions"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-about-us" role="tabpanel" aria-labelledby="nav-about-us-tab">
          <label>About Us</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.about_us"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-delivery-information" role="tabpanel" aria-labelledby="nav-delivery-information-tab">
          <label>Delivery Information</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.delivery_information"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-return-policy" role="tabpanel" aria-labelledby="nav-return-policy-tab">
          <label>Return Policy</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.return_policy"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-security-policy" role="tabpanel" aria-labelledby="nav-security-policy-tab">
          <label>Security Policy</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.security_policy"></ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-terms-of-use" role="tabpanel" aria-labelledby="nav-terms-of-use-tab">
          <label>Terms of Use</label>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <ckeditor :editor="editor" v-model="data.terms_of_use"></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-primary float-right" @click="save()">
        SAVE <i class="icon-paperplane ml-2"></i>
      </button>

      <!-- Add Config modal -->
    <div id="AddConfigs" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Config</h5>
            <h5 class="modal-title" v-else>Edit Config</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Variable</label>
                  <input
                    type="text"
                    placeholder="Enter variable"
                    class="form-control"
                    v-model="editData.var"
                    :disabled="modal == 1"
                  />
                  <span v-if="errors.var" class="form-text text-danger">{{ errors.var[0] }}</span>
                </div>
              </div>
              <div class="form-group" v-if="editData.column_type == 'Text'">
                <div class="row">
                  <label>Value</label>
                  <input
                    type="text"
                    placeholder="Enter value"
                    class="form-control"
                    v-model="editData.value"
                  />
                  <span v-if="errors.value" class="form-text text-danger">{{ errors.name[0] }}</span>
                </div>
              </div>
              <div class="form-group" v-if="editData.column_type == 'Multiple'">
                <div class="row">
                  <label>Value</label>
                  <vue-select
                    v-if="getCategories && editData.var == 'membership_categories_exclude'"
                    v-model="editData.value"
                    :options="getCategories"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    multiple
                    style="width: 100% !important"
                    searchable
                  />
                  <vue-select
                    v-if="getCategories && editData.var == 'membership_subcategories_exclude'"
                    v-model="editData.value"
                    :options="getSubcategories"
                    placeholder="Please Select"
                    label-by="name"
                    value-by="id"
                    multiple
                    style="width: 100% !important"
                    searchable
                  />
                  <span v-if="errors.value" class="form-text text-danger">{{ errors.name[0] }}</span>
                </div>
              </div>
               <div class="form-group" v-if="editData.column_type == 'Image'">
								<label class="font-weight-semibold">Image</label>
								<upload-file :max="1" maxError="Max files exceed" @changed="handleImages" :imageData="[editData.value].filter(item => item != null)"></upload-file>
                  <span v-if="errors.value" class="form-text text-danger">{{ errors.name[0] }}</span>
							</div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addConfig() : saveConfig()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Config modal -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import util from '@/helpers/util';
import UploadFileVue from '@/components/UploadFile.vue';
import VueNextSelect from "vue-next-select";

export default {
  components: {
    'upload-file': UploadFileVue,
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      editor: ClassicEditor,
      errors: [],
      modal: 0,
      editData: {
        id: null,
        var: null,
        value: null,
        column_type: null,
      },
      data: {
        privacy_policy: null,
        terms_conditions: null,
        about_us: null,
        delivery_information: null,
        return_policy: null,
        security_policy: null,
        terms_of_use: null,
      }
    }
  },
  created() {
    Datatables;
    this.fetchFooter()
    this.fetchCategories()
    this.fetchSubcategories()
    .then(res => {
      console.log(res);
      this.data.privacy_policy = res.privacy_policy
      this.data.terms_conditions = res.terms_conditions
      this.data.about_us = res.about_us
      this.data.delivery_information = res.delivery_information
      this.data.return_policy = res.return_policy
      this.data.security_policy = res.security_policy
      this.data.terms_of_use = res.terms_of_use
      $(".table-footer").DataTable({
        autoWidth: false,
        dom:
        '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search products:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        buttons: []
        // responsive: {
        //   details: {
        //     type: "column",
        //     target: -1,
        //   },
        // },
      });
    })
  },
  computed: {
    ...mapGetters({
      getCategories: "categories/getCategories",
      getSubcategories: "subcategories/getSubCategories",
      getFooter: "settings/getFooter",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
      fetchSubcategories: "subcategories/fetchSubcategories",
      fetchFooter: "settings/fetchFooter",
      createConfig: "settings/createConfig",
      updateConfig: "settings/updateConfig",
      updateFooter: "settings/updateFooter",
    }),
    openAddConfigModal() {
      this.modal = 0;
      this.editData.id = null;
      this.editData.var = null;
      this.editData.value = null;
      this.editData.column_type = null;
      $('#AddConfigs').modal('show');
    },
    editConfig(i) {
      console.log(this.getFooter);
      this.modal = 1;
      let val = this.getFooter.data.find(item => item.id == i);
      this.editData.id = val.id
      this.editData.var = val.var
      this.editData.value = val.type == 'Membership' ? JSON.parse(val.value) : val.value
      this.editData.column_type = val.column_type
      $('#AddConfigs').modal('show');
    },
    addConfig() {
      this.createConfig({ data: this.editData }).then(res => {
        if (res.status) {
          util.showToast('Settings successfully created');
        }
        $('#AddConfigs').modal('hide');
        this.fetchFooter();
      }).catch(err => {
        console.log(err.response.data)
      });
    },
    saveConfig() {
      this.updateConfig({ id: this.editData.id, data: this.editData }).then(res => {
        if (res.status) {
          util.showToast('Config successfully saved');
        }
        $('#AddConfigs').modal('hide');
        this.fetchFooter();
      }).catch(err => {
        console.log(err.response.data)
      });
    },
    save() {
      this.updateFooter({ data: this.data }).then(res => {
        if (res.status) {
          util.showToast('Settings successfully saved');
        }
        $('#AddConfigs').modal('hide');
        this.fetchFooter();
      }).catch(err => {
        console.log(err.response.data)
      });
    },
    handleImages(img) {
      this.editData.value = img[0] ?? '';
    },
  }
}
</script>

<style>
  .ellipsis  {
    max-width: 500px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>