import util from '@/helpers/util.js'
import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  tickets: [],
  ticket: {},
  client: {},
  departement: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getTickets: (state) => {
    return state.tickets;
  },
  getTicket: (state) => {
    return state.ticket;
  },
  getClient: (state) => {
    return state.client;
  },
  getDepartement: (state) => {
    return state.departement;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_TICKETS: (state, data) => (state.tickets = data),
  SET_TICKET: (state, data) => (state.ticket = data),
  SET_CLIENT: (state, data) => (state.client = data),
  SET_DEPARTEMENT: (state, data) => (state.departement = data),
};

const actions = {
  async fetchTickets({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/tiket/list-tiket?limit=1000&SortField=ref_id&SortType=desc&search"
      );
      commit("SET_TICKETS", response.data.data);
    } catch (error) {
      console.log(error);
      commit("SET_TICKETS", {});
    }
    commit("SET_LOADER", false);
  },
  async ticketById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.post(
        "/tiket/detail-tiket?ref_id=" + id
      );
      console.log(response.data.data)
      commit("SET_TICKET", response.data.data);
      commit("SET_CLIENT", response.data.data.client);
      commit("SET_DEPARTEMENT", response.data.data.departement);
    } catch (error) {
      console.log(error);
      commit("SET_TICKET", {});
      commit("SET_CLIENT", {});
    }
    commit("SET_LOADER", false);
  },
  async createTicket({ commit }, { client, ticket }) {
    commit("SET_LOADER", true);
    if (client) {
      ApiService.post("/clients/clients", { ...client }).then(res => {
        ticket['client_id'] = res.data.data.id
        var response = ApiService.post("/tiket/open-tiket", { ...ticket });
        commit("SET_LOADER", false);
        return response;
      });
    }else{
      var response = ApiService.post("/tiket/open-tiket", { ...ticket });
    }
    commit("SET_LOADER", false);
    return response;
  },
  async editTicketStatus({ commit }, { id, url }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post(
        `/tiket/${url}/${id}`
      );
      util.showToast('Successfully Update Ticket Status')
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
