import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  roles: [],
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getRoles: (state) => {
    return state.roles;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_ROLES: (state, data) => (state.roles = data),
};

const actions = {
  async fetchRoles({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("/roles");
      commit("SET_ROLES", response.data.roles);
    } catch (error) {
      console.log(error);
      commit("SET_ROLES", {});
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
