<template>
  <div class="products">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Products</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddProductModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Products
          </button>
        </div>
      </div>

      <div class="container">
        <div class="col">
          <div class="form-group row">
            <label class="col-form-label">Search SKU</label>
            <input type="text" class="form-control col mx-2" placeholder="Filter by SKU" v-model="search_sku" @input="searchSKU()">
            <label class="col-form-label">Filter Brand</label>
            <vue-select
              v-if="getBrands2"
              v-model="filter_brand"
              :options="getBrands2"
              placeholder="Please Select"
              label-by="name"
              value-by="id"
              close-on-select
              clear-on-select
              searchable
              maxHeight="100"
              class="form-control col mx-2"
              style="width: 100% !important;max-height: 100px !important;"
              @update:modelValue="(value) => searchBrand(value)"
            />
            <label class="col-form-label">Filter Category</label>
            <vue-select
              v-if="listcategories"
              v-model="filter_category"
              :options="listcategories"
              placeholder="Please Select"
              label-by="name"
              value-by="id"
              close-on-select
              clear-on-select
              searchable
              class="form-control col mx-2"
              style="width: 100% !important;max-height: 100px !important;"
              @update:modelValue="(value) => searchCategory(value)"
            />
          </div>
          <!-- <div class="form-group">
            <div class="row">
              <label class="col-form-label">Search SKU</label>
              <input type="text" class="form-control col-md-3 mx-2" placeholder="Filter by SKU" v-model="search_sku" @input="searchSKU()">
            </div>
          </div> -->
        </div>
      </div>

      <table class="table text-nowrap table-products" id="datatable">
        <thead>
          <tr>
            <th>ID</th>
            <th>SKU</th>
            <th>Name</th>
            <th>Category</th>
            <th>Brand</th>
            <!-- <th>Unit</th> -->
            <th>Price</th>
            <th>Stock</th>
            <th>Image</th>
            <th>In Stock</th>
            <th>Pre Order</th>
            <th>Pre Owned</th>
            <th>Status</th>
            <th>Updated</th>
            <th>#</th>
          </tr>
        </thead>
        <!-- <tbody>
          <tr v-for="(product, index) in getProducts" :key="index">
            <td>{{ product.id }}</td>
            <td class="ellipsis">{{ product.sku }}</td>
            <td class="ellipsis">{{ product.title }}</td>
            <td>{{ product.nested_categories[0]?.category?.name }}</td>
            <td>{{ product.brand?.name }}</td>
            <td>{{ product.unit.name }}</td>
            <td>
              <input type="number" :value="product.price" class="form-control" @change="changePrice(id, $event)" debounce="5000">
            </td>
            <td>{{ product.stock }}</td>
            <td>
              <img
                :src="product.image1"
                width="40"
                height="40"
                alt=""
              >
            </td>
            <td>
              <input type="checkbox" @change="changeStock(product.id)" :checked="product.in_stock">
            </td>
            <td>
              <input type="checkbox" @change="changePreOrder(product.id)" :checked="product.preorder == 1">
            </td>
            <td>
              <input type="checkbox" @change="changePreOwn(product.id)" :checked="product.pre_own == 1">
            </td>
            <td>
              <input type="checkbox" @change="changeStatusWebsite(product.id)" :checked="product.website_status">
            </td>
            <td>{{ getUpdatedDate(product.updated_at) }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" @click="editProduct(index)"
                      ><i class="icon-pencil"></i> Edit Product</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteProduct(product.id)"
                      ><i class="icon-trash"></i> Delete Product</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody> -->
      </table>
    </div>
    <!-- Add Product modal -->
    <div id="AddProducts" class="modal fade" data-focus="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Product</h5>
            <h5 class="modal-title" v-else>Edit Product</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <label>SKU 1</label>
                    <input
                      type="text"
                      placeholder="Enter product SKU 1"
                      class="form-control"
                      v-model="data.sku"
                      @change="getSeoValue()"
                    />
                    <span v-if="errors.sku" class="form-text text-danger">{{ errors.sku[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>SKU 2</label>
                    <input
                      type="text"
                      placeholder="Enter product SKU 2"
                      class="form-control"
                      v-model="data.sku_2"
                      @change="getSeoValue()"
                    />
                    <span v-if="errors.sku_2" class="form-text text-danger">{{ errors.sku_2[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Title Name</label>
                    <input
                      type="text"
                      placeholder="Enter product name"
                      class="form-control"
                      v-model="data.title"
                      @change="getSeoValue()"
                    />
                    <span v-if="errors.title" class="form-text text-danger">{{ errors.title[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Brand</label>
                    <vue-select
                      v-if="getBrands"
                      v-model="data.brand"
                      :options="getBrands"
                      placeholder="Please Select"
                      label-by="name"
                      value-by="name"
                      close-on-select
                      searchable
                      maxHeight="100"
                      class="form-control"
                      style="width: 100% !important;max-height: 100px !important;"
                      @update:modelValue="getSeoValue()"
                    />
                    <span v-if="errors.brand" class="form-text text-danger">{{ errors.brand[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Buying Price Exclude</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.buying_price_exc"
                      @input="calculateBuyingPrice()"
                    />
                    <span v-if="errors.buying_price_exc" class="form-text text-danger">{{ errors.buying_price_exc[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Buying Price Include</label>
                    <input
                      type="text"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.buying_price_inc"
                      step="any"
                      disabled
                    />
                    <span v-if="errors.buying_price_inc" class="form-text text-danger">{{ errors.buying_price_inc[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Price</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.price"
                    />
                    <span class="form-text text-success">Profit : {{ (data.price - data.buying_price_inc).toFixed(2) }}</span>
                    <span v-if="errors.price" class="form-text text-danger">{{ errors.price[0] }}</span>
                  </div>
                  <!-- <div class="col">
                    <label>Price 1</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.price_group_1"
                    />
                    <span class="form-text text-success">Profit : {{ (data.price_group_1 - data.buying_price_inc).toFixed(2) }}</span>
                    <span v-if="errors.price_group_1" class="form-text text-danger">{{ errors.price_group_1[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Price 2</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.price_group_2"
                    />
                    <span class="form-text text-success">Profit : {{ (data.price_group_2 - data.buying_price_inc).toFixed(2) }}</span>
                    <span v-if="errors.price_group_2" class="form-text text-danger">{{ errors.price_group_2[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Price 3</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.price_group_3"
                    />
                    <span class="form-text text-success">Profit : {{ (data.price_group_3 - data.buying_price_inc).toFixed(2) }}</span>
                    <span v-if="errors.price_group_3" class="form-text text-danger">{{ errors.price_group_3[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Price 4</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.price_group_4"
                    />
                    <span class="form-text text-success">Profit : {{ (data.price_group_4 - data.buying_price_inc).toFixed(2) }}</span>
                    <span v-if="errors.price_group_4" class="form-text text-danger">{{ errors.price_group_4[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Price 5</label>
                    <input
                      type="number"
                      placeholder="Enter price"
                      class="form-control"
                      v-model="data.price_group_5"
                    />
                    <span class="form-text text-success">Profit : {{ (data.price_group_5 - data.buying_price_inc).toFixed(2) }}</span>
                    <span v-if="errors.price_group_5" class="form-text text-danger">{{ errors.price_group_5[0] }}</span>
                  </div> -->
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <label>Weight</label>
                    <div class="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Enter weight"
                        class="form-control"
                        v-model="data.weight"
                      />
                      <span>kg</span>
                    </div>
                    <span v-if="errors.weight" class="form-text text-danger">{{ errors.weight[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Length</label>
                    <div class="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Enter length"
                        class="form-control"
                        v-model="data.length"
                      />
                      <span>cm</span>
                    </div>
                    <span v-if="errors.length" class="form-text text-danger">{{ errors.length[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Width</label>
                    <div class="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Enter width"
                        class="form-control"
                        v-model="data.width"
                      />
                      <span>cm</span>
                    </div>
                    <span v-if="errors.width" class="form-text text-danger">{{ errors.width[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Height</label>
                    <div class="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Enter height"
                        class="form-control"
                        v-model="data.height"
                      />
                      <span>cm</span>
                    </div>
                    <span v-if="errors.height" class="form-text text-danger">{{ errors.height[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Discount</label>
                    <input
                      type="text"
                      placeholder="Enter discount"
                      class="form-control"
                      v-model="data.discount"
                    />
                    <span v-if="errors.discount" class="form-text text-danger">{{ errors.discount[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Discount End</label>
                    <input
                      type="date"
                      placeholder="Enter length"
                      class="form-control"
                      v-model="data.discount_end"
                    />
                    <span v-if="errors.discount_end" class="form-text text-danger">{{ errors.discount_end[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Discount Start</label>
                    <input
                      type="date"
                      placeholder="Enter length"
                      class="form-control"
                      v-model="data.discount_start"
                    />
                    <span v-if="errors.discount_start" class="form-text text-danger">{{ errors.discount_start[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row" v-if="data.preorder">
                  <div class="col-md-4">
                    <label>Release Date</label>
                    <input
                      type="date"
                      placeholder="Enter length"
                      class="form-control"
                      defa
                      v-model="data.release_date"
                    />
                    <span v-if="errors.release_date" class="form-text text-danger">{{ errors.release_date[0] }}</span>
                  </div>
                  <div class="col-md-4">
                    <label>Deposit</label>
                    <input
                      type="number"
                      placeholder="Enter deposit"
                      class="form-control"
                      v-model="data.deposit"
                    />
                    <span v-if="errors.deposit" class="form-text text-danger">{{ errors.deposit[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <label class="col-form-label"
                    ><strong>Add to Category</strong></label
                  >
                  <div class="row">
                    <div class="col">
                      <vue-select
                        v-if="listcategories"
                        v-model="selectedCategory"
                        :options="listcategories"
                        placeholder="Select Category"
                        label-by="name"
                        :filter="true"
                        @update:modelValue="getSubCategory1"
                        class="w-100 my-2 form-control"
                        close-on-select
                        searchable
                      />
                      <span v-if="errors.categories" class="form-text text-danger">{{
                        errors.categories[0]
                      }}</span>
                    </div>
                    <div class="col">
                      <vue-select
                        v-if="listsubcategory1"
                        v-model="selectedSubCategory1"
                        :options="listsubcategory1"
                        placeholder="Select Sub Category 1"
                        label-by="name"
                        :filter="true"
                        @update:modelValue="getSubCategory2"
                        class="w-100 my-2 form-control"
                        close-on-select
                        searchable
                        :disabled="selectedCategory < 1 ? true : false"
                      />
                      <span v-if="errors.sub_category_id" class="form-text text-danger">{{
                        errors.sub_category_id[0]
                      }}</span>
                    </div>
                    <!-- <div class="col">
                      <vue-select
                        v-if="listsubcategory2"
                        v-model="selectedSubCategory2"
                        :options="listsubcategory2"
                        placeholder="Select Sub Category 2"
                        label-by="name"
                        :filter="true"
                        @update:modelValue="getSubCategory3"
                        class="w-100 my-2 form-control"
                        close-on-select
                        searchable
                        :disabled="selectedSubCategory1.length < 1 ? true : false"
                      />
                    </div>
                    <div class="col">
                      <vue-select
                        v-if="listsubcategory3"
                        v-model="selectedSubCategory3"
                        :options="listsubcategory3"
                        placeholder="Select Sub Category 3"
                        label-by="name"
                        :filter="true"
                        @update:modelValue="getSubCategory4"
                        class="w-100 my-2 form-control"
                        close-on-select
                        searchable
                        :disabled="selectedSubCategory2.length < 1 ? true : false"
                      />
                    </div>
                    <div class="col">
                      <vue-select
                        v-if="listsubcategory4"
                        v-model="selectedSubCategory4"
                        :options="listsubcategory4"
                        placeholder="Select Sub Category 4"
                        label-by="name"
                        :filter="true"
                        class="w-100 my-2 form-control"
                        close-on-select
                        searchable
                        :disabled="selectedSubCategory3.length < 1 ? true : false"
                      />
                    </div> -->
                    <div class="col">
                      <button
                        @click="
                          addToCategory(
                            selectedCategory,
                            [
                              selectedSubCategory1,
                              selectedSubCategory2,
                              selectedSubCategory3,
                              selectedSubCategory3,
                              selectedSubCategory4,
                            ].filter(Boolean)
                          )
                        "
                        class="btn btn-default d-block w-100 mt-2"
                        style="background-color: #e6e6e6"
                      >
                        <i class="icon-plus3"></i>
                        Add Category
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="col-form-label"
                    ><strong>Product Category</strong></label
                  >
                  <div v-for="(item, index) in subcategories" :key="index">
                    <div
                      style="border-top: 1px solid #000; width: 100%; padding: 8px 0"
                    >
                      {{ item.name }}
                      <a href="#" @click.prevent="deleteCategory(index)">(delete)</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <label class="col-form-label">
                    <strong>Product Colors</strong>
                  </label>
                  <vue-select
                    v-if="getColors"
                    v-model="data.colors"
                    :options="getColors"
                    placeholder="Select Colors"
                    label-by="name"
                    value-by="id"
                    taggable
                    :filter="true"
                    multiple
                    class="w-100 my-2 form-control"
                    close-on-select
                    searchable
                  />
                </div>
                <div class="col">
                  <div class="d-flex flex-column">
                      <label class="col-form-label">
                        <strong>Type</strong>
                      </label>
                      <div class="mt-2">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="type1" name="type" class="custom-control-input" value="Sell" v-model="data.type" :checked="data.type == 'Sell'">
                          <label class="custom-control-label" for="type1">Sell</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="type2" name="type" class="custom-control-input" value="Repair" v-model="data.type" :checked="data.type == 'Repair'">
                          <label class="custom-control-label" for="type2">Repair</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="type3" name="type" class="custom-control-input" value="Rent" v-model="data.type" :checked="data.type == 'Rent'">
                          <label class="custom-control-label" for="type3">Rent</label>
                        </div>
                      </div>
                    </div>
                    <span v-if="errors.status" class="form-text text-danger">{{ errors.status[0] }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="col-form-label"
                    ><strong>Product Colors</strong></label
                  >
                  <div v-for="(item, index) in subcategories" :key="index">
                    <div
                      style="border-top: 1px solid #000; width: 100%; padding: 8px 0"
                    >
                      {{ item.name }}
                      <a href="#" @click.prevent="deleteCategory(index)">(delete)</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <label>Stock</label>
                    <input
                      type="text"
                      placeholder="Enter product stock"
                      class="form-control"
                      v-model="data.stock"
                    />
                    <span v-if="errors.stock" class="form-text text-danger">{{ errors.stock[0] }}</span>
                  </div>
                  <div class="col-sm-1">
                    <div class="d-flex flex-column align-items-center">
                      <label>Pre-Order</label>
                      <input
                        type="checkbox"
                        v-model="data.preorder"
                      />
                    </div>
                    <span v-if="errors.preorder" class="form-text text-danger">{{ errors.preorder[0] }}</span>
                  </div>
                  <div class="col-sm-1">
                    <div class="d-flex flex-column align-items-center">
                      <label>Backorder</label>
                      <input
                        type="checkbox"
                        v-model="data.backorder"
                      />
                    </div>
                    <span v-if="errors.backorder" class="form-text text-danger">{{ errors.backorder[0] }}</span>
                  </div>
                  <div class="col-sm-1">
                    <div class="d-flex flex-column align-items-center">
                      <label>Pre-Own</label>
                      <input
                        type="checkbox"
                        v-model="data.pre_own"
                      />
                    </div>
                    <span v-if="errors.pre_own" class="form-text text-danger">{{ errors.pre_own[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <div class="d-flex flex-column">
                      <label>Status</label>
                      <div class="mt-2">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="status1" name="status" class="custom-control-input" value="1" v-model="data.status" :checked="data.status > 0">
                          <label class="custom-control-label" for="status1">Active</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="status2" name="status" class="custom-control-input" value="0" v-model="data.status" :checked="data.status == 0">
                          <label class="custom-control-label" for="status2">Inactive</label>
                        </div>
                      </div>
                    </div>
                    <span v-if="errors.status" class="form-text text-danger">{{ errors.status[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <div class="d-flex flex-column">
                      <label>Website Status</label>
                      <div class="mt-2">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="websiteStatus1" name="websiteStatus" class="custom-control-input" :value="1" v-model="data.website_status" :checked="data.website_status > 0">
                          <label class="custom-control-label" for="websiteStatus1">Active</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" id="websiteStatus2" name="websiteStatus" class="custom-control-input" :value="0" v-model="data.website_status" :checked="data.website_status == 0">
                          <label class="custom-control-label" for="websiteStatus2">Inactive</label>
                        </div>
                      </div>
                    </div>
                    <span v-if="errors.website_status" class="form-text text-danger">{{ errors.website_status[0] }}</span>
                  </div>
                </div>
              </div>

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">Description</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="true">Video</a>
                </li>
                <!-- <li class="nav-item" role="presentation">
                  <a class="nav-link" id="short-desc-tab" data-toggle="tab" href="#short-desc" role="tab" aria-controls="short-desc" aria-selected="false">Short Description</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="manual-tab" data-toggle="tab" href="#manual" role="tab" aria-controls="manual" aria-selected="false">Manual</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="warranty-tab" data-toggle="tab" href="#warranty" role="tab" aria-controls="warranty" aria-selected="false">Warranty</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="return-policy-tab" data-toggle="tab" href="#return-policy" role="tab" aria-controls="return-policy" aria-selected="false">Return Policy</a>
                </li> -->
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="seo-tab" data-toggle="tab" href="#seo" role="tab" aria-controls="return-policy" aria-selected="false">SEO</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <ckeditor :editor="editor" v-model="data.description" :config="editorConfig" id="editor"></ckeditor>
                      <span v-if="errors.description" class="form-text text-danger">{{
                        errors.description[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show" id="video" role="tabpanel" aria-labelledby="video-tab">
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <label>Youtube Link</label>
                        <input
                          type="text"
                          placeholder="Ex https://www.youtube.com/embed/crz7TGPjIRQ"
                          class="form-control"
                          v-model="data.youtube"
                        />
                        <span v-if="errors.youtube" class="form-text text-danger">{{ errors.youtube[0] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="short-desc" role="tabpanel" aria-labelledby="short-desc-tab">
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <ckeditor :editor="editor" v-model="data.short_description" @input="getSeoValue()"></ckeditor>
                      <span v-if="errors.short_description" class="form-text text-danger">{{
                        errors.short_description[0]
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="manual" role="tabpanel" aria-labelledby="manual-tab">
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <a v-if="data.manualPreview" :href="data.manualPreview" target="_blank" class="mb-2">
                        <i class="icon-file-pdf"></i> Manual
                      </a>
                      <input
                        v-if="!data.manualPreview"
                        type="file"
                        @change="previewManual"
                        accept="application/pdf"
                        class="form-control mb-1 mt-2 p-1"
                      />
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="warranty" role="tabpanel" aria-labelledby="warranty-tab">
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <ckeditor :editor="editor" v-model="data.specification"></ckeditor>
                      <span
                        v-if="errors.specification"
                        class="form-text text-danger"
                        >{{ errors.specification[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="return-policy" role="tabpanel" aria-labelledby="return-policy-tab">
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <ckeditor :editor="editor" v-model="data.return_policy"></ckeditor>
                      <span v-if="errors.return_policy" class="form-text text-danger">{{
                        errors.return_policy[0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="seo" role="tabpanel" aria-labelledby="seo-tab">
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <label>Meta Title</label>
                        <input
                          type="text"
                          placeholder="Enter Meta Title"
                          class="form-control"
                          v-model="data.seo"
                        />
                        <small :class="data.seo?.length <= 65 ? 'text-success' : 'text-danger'">
                          Text Count: {{ data.seo?.length ?? 0 }}
                        </small>
                        <span v-if="errors.seo" class="form-text text-danger">{{ errors.seo[0] }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <label>Meta Description</label>
                        <input
                          type="text"
                          placeholder="Enter Meta Description"
                          class="form-control"
                          v-model="data.seo_meta_data"
                        />
                        <small :class="data.seo_meta_data?.length <= 165 ? 'text-success' : 'text-danger'">
                          Text Count: {{ data.seo_meta_data?.length ?? 0 }}
                        </small>
                        <span v-if="errors.seo_meta_data" class="form-text text-danger">{{ errors.seo_meta_data[0] }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <label>SEO Keywords</label>
                        <input
                          type="text"
                          placeholder="Enter SEO Keywords"
                          class="form-control"
                          v-model="data.seo_keywords"
                        />
                        <span v-if="errors.seo_keywords" class="form-text text-danger">{{ errors.seo_keywords[0] }}</span>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="form-group" v-if="modal == 0">
                <upload-file :max="5" maxError="Max files exceed" @changed="handleImages"></upload-file>
							</div>
              <div class="form-group" v-if="modal == 1">
								<label class="font-weight-semibold">Image</label>
								<upload-file :max="5" maxError="Max files exceed" @changed="handleImages" :imageData="[this.data?.image1, this.data?.image2, this.data?.image3, this.data?.image4, this.data?.image5].filter(item => item != null)" v-if="imageComponent == 1" :key="imageComponent"></upload-file>
							</div>
            </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addProduct() : editProductSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>

        </div>
      </div>
    </div>
    <!-- /Add Product modal -->
    <!-- Detail Product modal -->
    <div id="DetailProducts" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Detail Product</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="d-block">SKU 1</h6>
                    <span>{{ data.sku }}</span>
                  </div>
                  <div class="col-sm-3">
                    <h6 class="d-block">SKU 2</h6>
                    <span>{{ data.sku_2 }}</span>
                  </div>
                  <div class="col-sm-3">
                    <h6 class="d-block">Title Name</h6>
                    <span>{{ data.title }}</span>
                  </div>
                  <div class="col-sm-3">
                    <h6 class="d-block">Brand</h6>
                    <span>{{ data.brand }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">Buying Price Exclude</h6>
                    <span>{{ data.buying_price_exc }}</span>
                  </div>
                  <div class="col">
                    <h6 class="d-block">Buying Price Include</h6>
                    <span>{{ data.buying_price_inc }}</span>
                  </div>
                  <div class="col">
                    <h6 class="d-block">Price</h6>
                    <span>{{ data.price }}</span>
                  </div>
                  <!-- <div class="col">
                    <h6 class="d-block">Price 1</h6>
                    <span>{{ data.price_group_1 }}</span>
                  </div>
                  <div class="col">
                    <h6 class="d-block">Price 2</h6>
                    <span>{{ data.price_group_2 }}</span>
                  </div>
                  <div class="col">
                    <h6 class="d-block">Price 3</h6>
                    <span>{{ data.price_group_3 }}</span>
                  </div>
                  <div class="col">
                    <h6 class="d-block">Price 4</h6>
                    <span>{{ data.price_group_4 }}</span>
                  </div>
                  <div class="col">
                    <h6 class="d-block">Price 5</h6>
                    <span>{{ data.price_group_5 }}</span>
                  </div> -->
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="d-block">Weight</h6>
                    <span>{{ data.weight }} kg</span>
                  </div>
                  <div class="col-sm-3">
                    <h6 class="d-block">Length</h6>
                    <span>{{ data.length }} cm</span>
                  </div>
                  <div class="col-sm-3">
                    <h6 class="d-block">Width</h6>
                    <span>{{ data.width }} cm</span>
                  </div>
                  <div class="col-sm-3">
                    <h6 class="d-block">Height</h6>
                    <span>{{ data.height }} cm</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="col">
                <label class="col-form-label"
                  ><strong>Product Category</strong></label
                >
                <div v-for="(item, index) in subcategories" :key="index">
                  <div
                    style="border-top: 1px solid #000; width: 100%; padding: 8px 0"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-4">
                    <h6 class="d-block">Stock</h6>
                    <span>{{ data.stock }}</span>
                  </div>
                  <div class="col-sm-4">
                    <div class="d-flex flex-column">
                      <h6 class="d-block">Status</h6>
                      <span>{{ data.status ? 'Active' : 'Inactive' }}</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="d-flex flex-column">
                      <h6 class="d-block">Website Status</h6>
                      <span>{{ data.website_status ? 'Active' : 'Inactive' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">SEO</h6>
                    <span>{{ data.seo }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">SEO Metadata</h6>
                    <span>{{ data.seo_meta_data }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">SEO Keywords</h6>
                    <span>{{ data.seo_keywords }}</span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">Description</h6>
                    <span v-html="data.description"></span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">Short Description</h6>
                    <span v-html="data.short_description"></span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">Spesification</h6>
                    <span v-html="data.specification"></span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">Return Policy</h6>
                    <span v-html="data.return_policy"></span>
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <h6 class="d-block">Image</h6>
                    <div class="d-flex">
                      <img :src="data.image1" v-if="data.image1" alt="image1" width="120" heigth="120">
                      <img :src="data.image2" v-if="data.image2" alt="image2" width="120" heigth="120">
                      <img :src="data.image3" v-if="data.image3" alt="image3" width="120" heigth="120">
                      <img :src="data.image4" v-if="data.image4" alt="image4" width="120" heigth="120">
                      <img :src="data.image5" v-if="data.image5" alt="image5" width="120" heigth="120">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
          </div>

        </div>
      </div>
    </div>
    <!-- /Detail Product modal -->
  </div>
</template>

<script>
// import _ from 'lodash'
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import util from '@/helpers/util.js'
import VueNextSelect from 'vue-next-select'
import ApiService from '@/services/api.service';
import UploadFileVue from '@/components/UploadFile.vue';
import moment from 'moment';
import { StorageService } from "@/services/storage.service";
import UserService from '@/services/user.service';
import router from "@/router";
import UploadAdapter from "@/components/UploadAdapter";
// import { StorageService } from '@/services/storage.service';

if ($.fn.DataTable.isDataTable('.table-products')) {
  $('input[type="search"]').on( 'keyup', function () {
      $(".table-products").DataTable()
          .columns( 1 )
          .search( this.value )
          .draw();
  } );
}

function initialState (){
  return {
    editor: null,
    editorConfig: {
      toolbar: {
        shouldNotGroupWhenFull: true
      },
      extraPlugins: [function (editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
          return new UploadAdapter(loader);
        }
      }],
      mediaEmbed: {
        previewsInData: true
      },
      indentBlock: {
        offset: 1,
        unit: 'em'
      }
    },
    search_sku: null,
    modal: 0, // 0 = Add || 1 = Edit
    errors: [],
    imageComponent: 0,
    selectedIds: [],
    filter_brand: '',
    filter_category: '',
    categories: [],
    subcategories: [],
    selectedCategory: [],
    selectedSubCategory1: [],
    selectedSubCategory2: [],
    selectedSubCategory3: [],
    selectedSubCategory4: [],
    listcategories: [],
    listsubcategory1: [],
    listsubcategory2: [],
    listsubcategory3: [],
    listsubcategory4: [],
    dataImage: [],
    data: {
      product_id: null,
      sku: null,
      sku_2: null,
      title: null,
      description: '',
      short_description: '',
      specification: '',
      return_policy: '',
      colors: [],
      categories: null,
      sub_category_id: null,
      brand: null,
      price: null,
      price_group_1: null,
      price_group_2: null,
      price_group_3: null,
      price_group_4: null,
      price_group_5: null,
      buying_price_exc: null,
      buying_price_inc: null,
      discount: null,
      discount_end: null,
      discount_start: null,
      stock: null,
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      type: 'Sell',
      status: 1,
      website_status: 1,
      note: '',
      weight: null,
      length: null,
      width: null,
      height: null,
      image: null,
      manual: null,
      manualPreview: null,
      seo: null,
      seo_meta_data: null,
      seo_keywords: null,
      warranty: '',
      youtube: '',
      preorder: false,
      deposit: 0,
      backorder: false,
      pre_own: false,
      release_date: null,
    },
    imageName: null,
  }
}

export default {
  name: "Products",
  components: {
    'vue-select': VueNextSelect,
    'upload-file': UploadFileVue,
  },
  data() {
    return initialState();
  },
  created() {
    this.editor = !this.editor && ClassicEditor;
    // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category })
    // .then(() => {
    // })
    // this.initDatatables();
    // this.fetchBrands();
    // this.fetchColors();
    // this.getCategories();
  },
  computed: {
    ...mapGetters({
      getProducts: "products/getProducts",
      getBrands: "brands/getBrands",
      getBrands2: "brands/getBrands",
      getColors: "colors/getColors",
      getUnits: "units/getUnits",
    }),
  },
  updated() {
    // this.initDatatables();
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
    this.filter_brand = null;
    this.filter_category = null;
    this.search_sku = null;
    this.initDatatables();
    this.fetchBrands();
    this.fetchColors();
    this.getCategories();
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      fetchBrands: "brands/fetchBrands",
      fetchColors: "colors/fetchColors",
      createProduct: "products/createProduct",
      updateProduct: "products/updateProduct",
      updateStatusProduct: "products/updateStatusProduct",
      updateStockProduct: "products/updateStockProduct",
      updateStock: "products/updateStock",
      updatePreOrderProduct: "products/updatePreOrderProduct",
      updatePreOwnProduct: "products/updatePreOwnProduct",
      updatePriceProduct: "products/updatePriceProduct",
      deleteProductById: "products/deleteProductById",
    }),
    searchSKU() {
      // $(".table-products").DataTable().ajax.reload()
      console.log(this.search_sku)
      $(".table-products").DataTable()
        .columns( 1 )
        .search( this.search_sku )
        .draw();
    },
    searchCategory() {
      $(".table-products").DataTable().ajax.reload()
    },
    searchBrand() {
      $(".table-products").DataTable().ajax.reload()
    },
    getUpdatedDate (date) {
      return moment(date).format('DD MMM YYYY');
    },
    async getCategories() {
      this.selectedSubCategory1 = "";
      this.selectedSubCategory2 = "";
      this.selectedSubCategory3 = "";
      this.selectedSubCategory4 = "";
      this.listsubcategory1 = [];
      this.listsubcategory2 = [];
      this.listsubcategory3 = [];
      this.listsubcategory4 = [];
      const body = {
        limit: 20,
        offset: 0,
      };
      const options = {
        headers: {
          token: this.userToken,
          "content-type": "application/json",
        },
      };
      await ApiService
        .post("category/get", body, options)
        .then((res) => {
          this.listcategories = res.data.data.categories;
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },

    getSubCategory1() {
      this.selectedSubCategory1 = "";
      this.selectedSubCategory2 = "";
      this.selectedSubCategory3 = "";
      this.selectedSubCategory4 = "";
      this.listsubcategory2 = [];
      this.listsubcategory3 = [];
      this.listsubcategory4 = [];
      const body = {
        limit: 20,
        offset: 0,
        filter_id: [this.selectedCategory.id],
      };
      const options = {
        headers: {
          token: this.userToken,
          "content-type": "application/json",
        },
      };
      ApiService
        .post("category/get", body, options)
        .then((res) => {
          this.listsubcategory1 = res.data.data.categories[0].sub_categories;
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },

    getSubCategory2() {
      this.selectedSubCategory2 = "";
      this.selectedSubCategory3 = "";
      this.selectedSubCategory4 = "";
      this.listsubcategory3 = [];
      this.listsubcategory4 = [];
      const body = {
        limit: 20,
        offset: 0,
        filter_sub_category_id: [this.selectedSubCategory1.id],
      };
      const options = {
        headers: {
          token: this.userToken,
          "content-type": "application/json",
        },
      };
      ApiService
        .post("category/get", body, options)
        .then((res) => {
          this.listsubcategory2 = res.data.data.categories[0].nested_children;
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },

    getSubCategory3() {
      this.selectedSubCategory3 = "";
      this.selectedSubCategory4 = "";
      this.listsubcategory4 = [];
      const body = {
        limit: 20,
        offset: 0,
        filter_sub_category_id: [this.selectedSubCategory2.id],
      };
      const options = {
        headers: {
          token: this.userToken,
          "content-type": "application/json",
        },
      };
      ApiService
        .post("category/get", body, options)
        .then((res) => {
          this.listsubcategory3 = res.data.data.categories[0].nested_children;
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },

    getSubCategory4() {
      const body = {
        limit: 20,
        offset: 0,
        filter_sub_category_id: [this.selectedSubCategory3.id],
      };
      const options = {
        headers: {
          token: this.userToken,
          "content-type": "application/json",
        },
      };
      ApiService
        .post("category/get", body, options)
        .then((res) => {
          this.listsubcategory4 = res.data.data.categories[0].nested_children;
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },

    getSubCategory5() {
      const body = {
        limit: 20,
        offset: 0,
        filter_sub_category_id: [this.subcategories[3]],
      };
      const options = {
        headers: {
          token: this.userToken,
          "content-type": "application/json",
        },
      };
      ApiService
        .post("category/get", body, options)
        .then((res) => {
          this.listsub5 = res.data.data.categories[0].nested_children;
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    },
    addToCategory(itemcat, itemsub) {
      if (this.categories.indexOf(itemcat) === -1) {
        this.categories.push(itemcat);
      }
      if (itemsub) {
        var currentsub = this.subcategories.map((item) => item.id);

        if (currentsub.indexOf(itemsub[itemsub.length-1].id) === -1) {
          this.subcategories.push(itemsub[itemsub.length - 1]);
        } else {
          util.showToast("This subcategory is already selected", 'error');
        }
      } else {
        util.showToast("Please Choose Subcategory", 'error');
      }
    },

    deleteCategory(indexCategory) {
      util.deleteConfirmationToast(
        this.subcategories = this.subcategories.filter(
          (value, index) => index !== indexCategory
        )
      );
    },
    handleImages(img) {
      this.data.image1 = img[0] ?? '';
      this.data.image2 = img[1] ?? '';
      this.data.image3 = img[2] ?? '';
      this.data.image4 = img[3] ?? '';
      this.data.image5 = img[4] ?? '';
    },
    filterProducts() {
      this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
    },
    openAddProductModal() {
      initialState();
      this.editor = null;
      this.modal = 0;
      this.errors = [];
      this.imageComponent = 0;
      this.selectedIds = [];
      this.categories = [];
      this.subcategories = [];
      this.selectedCategory = [];
      this.selectedSubCategory1 = [];
      this.selectedSubCategory2 = [];
      this.selectedSubCategory3 = [];
      this.selectedSubCategory4 = [];
      this.listsubcategory1 = [];
      this.listsubcategory2 = [];
      this.listsubcategory3 = [];
      this.listsubcategory4 = [];
      this.dataImage = [];
      this.data.product_id = null;
      this.data.sku = null;
      this.data.sku_2 = null;
      this.data.title = null;
      this.data.description = '';
      this.data.short_description = '';
      this.data.specification = '';
      this.data.return_policy = '';
      this.data.colors = [];
      this.data.categories = null;
      this.data.sub_category_id = null;
      this.data.brand = null;
      this.data.price = null;
      this.data.price_group_1 = null;
      this.data.price_group_2 = null;
      this.data.price_group_3 = null;
      this.data.price_group_4 = null;
      this.data.price_group_5 = null;
      this.data.buying_price_exc = null;
      this.data.buying_price_inc = null;
      this.data.discount = null;
      this.data.discount_end = null;
      this.data.discount_start = null;
      this.data.stock = null;
      this.data.image1 = "";
      this.data.image2 = "";
      this.data.image3 = "";
      this.data.image4 = "";
      this.data.image5 = "";
      this.data.type = 'Sell';
      this.data.status = 1;
      this.data.website_status = 1;
      this.data.note = '';
      this.data.weight = null;
      this.data.length = null;
      this.data.width = null;
      this.data.height = null;
      this.data.image = null;
      this.data.manual = null;
      this.data.manualPreview = null;
      this.data.seo = null;
      this.data.seo_meta_data = null;
      this.data.seo_keywords = null;
      this.data.warranty = '';
      this.data.youtube = '';
      this.data.backorder = false;
      this.data.preorder = false;
      this.data.deposit = 0;
      this.data.pre_own = false;
      this.data.release_date = null;
      $('#AddProducts').modal('show');
    },
    handleImage(event) {
      const image = event.target.files[0]
      this.imageName = event.target.files[0].name
      const reader = new FileReader();
      reader.onloadend = () => {
        this.data.image = reader.result;
      }
      reader.readAsDataURL(image)
    },
    previewManual(event){
      this.data.manual = event.target.files[0];
    },
    addProduct() {
      let data = this.data
      this.data.categories = this.subcategories.map((item) => item.category_id),
      this.data.sub_category_id = this.subcategories.map((item) => item.id),
      this.createProduct({ data }).then(res => {
        if (res.status) {
          util.showToast('Product successfully added');
          $(".table-products").DataTable().destroy()
          // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category })
          // .then(() => {
          // });
          this.initDatatables();
          $('#AddProducts').modal('hide');
        }
      }).catch(err => {
        if (err.response.data.rc == 99) {
          util.showToast(err.response.data.message, 'error');
        }else{
          let key = Object.keys(err.response.data.message)[0];
          util.showToast(err.response.data.message[key][0], 'error');
          if (err.response?.data?.errors) {
            this.errors = err.response?.data?.errors;
          }
        }
      });
    },
    editProduct(data) {
      this.modal = 1;
      let val = data;
      this.imageComponent = 0;
      this.data.product_id = val.id;
      this.data.title = val.title;
      this.data.sku = val.sku;
      this.data.sku_2 = val.sku_2;
      this.data.seo = val.seo;
      this.data.seo_meta_data = val.seo_meta_data;
      this.data.seo_keywords = val.seo_keywords;
      this.data.short_description = val.short_description ?? '';
      this.data.description = val.description ?? '';
      this.data.manualPreview = val.manual;
      this.data.manual = null;
      this.data.warranty = val.warranty ?? '';
      this.data.specification = val.specification ?? '';
      this.data.return_policy = val.return_policy ?? '';
      this.data.stock = val.stock;
      this.data.buying_price_exc = val.buying_price_exc;
      this.data.buying_price_inc = val.buying_price_inc;
      this.data.price = val.real_price;
      this.data.price_group_1 = val.price_group_1;
      this.data.price_group_2 = val.price_group_2;
      this.data.price_group_3 = val.price_group_3;
      this.data.price_group_4 = val.price_group_4;
      this.data.price_group_5 = val.price_group_5;
      this.data.discount = val.discount;
      this.data.discount_end = val.discount_end;
      this.data.discount_start = val.discount_start;
      this.data.brand = val.brand && val.brand?.name;
      // this.data.sizes = val.sizes.map((item) => item.name);
      this.data.colors = val.colors.map((item) => item.id);
      this.data.weight = val.weight;
      this.data.length = val.length;
      this.data.width = val.width;
      this.data.height = val.height;
      this.data.type = val.type;
      this.data.status = val.status;
      this.data.website_status = val.website_status;
      this.data.image1 = val?.image1?.replaceAll('&amp;', '&');
      this.data.image2 = val?.image2?.replaceAll('&amp;', '&');
      this.data.image3 = val?.image3?.replaceAll('&amp;', '&');
      this.data.image4 = val?.image4?.replaceAll('&amp;', '&');
      this.data.image5 = val?.image5?.replaceAll('&amp;', '&');
      this.data.pre_own = val.pre_own;
      this.data.preorder = val.preorder;
      this.data.deposit = val.dp;
      this.data.backorder = val.backorder;
      this.data.youtube = val.youtube;
      this.data.release_date = val.release_date;
      // this.categories = val.categories.map((item) => item.name);
      this.subcategories = val.nested_categories;
      this.categories = val.nested_categories.map(
        (item) => item.category_id
      );
      this.dataImage = [];
      this.data.image1 && this.dataImage.push(this.data?.image1);
      this.data.image2 && this.dataImage.push(this.data?.image2);
      this.data.image3 && this.dataImage.push(this.data?.image3);
      this.data.image4 && this.dataImage.push(this.data?.image4);
      this.data.image5 && this.dataImage.push(this.data?.image5);
      this.$nextTick(() => {
          // Add the component back in
          this.imageComponent = 1;
        });
      // if (isEdit) {
        $('#AddProducts').modal('show');
      // }else{
      //   $('#DetailProducts').modal('show');
      // }
    },
    editProductSubmit() {
      if (this.data.deposit >= this.data.price) {
        return util.showToast('Deposit amount cannot be lower than price amount', 'error');
      }
      let data = this.data;
      this.data.categories = this.subcategories.map((item) => item.category_id),
      this.data.sub_category_id = this.subcategories.map((item) => item.id),
      this.updateProduct({ id: this.data.product_id, data }).then(res => {
        if (res.error) {
          return util.showToast(res.error?.errorInfo[2], 'error');
        }else if(res.rc == 99) {
          return util.showToast(res.message, 'error');
        }else{
          util.showToast('Product successfully changed');
        }
        $(".table-products").DataTable().destroy()
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category })
        // .then(() => {
        // });
        this.initDatatables();
        $('#AddProducts').modal('hide');
      }).catch(err => {
        this.errors = err.response.data.errors;
        util.showToast('Failed To Update', 'error');
        console.log(err.response.data.errors)
      });
    },
    changeStatusWebsite(id) {
      this.updateStatusProduct({ id }).then(() => {
        util.showToast('Product successfully updated');
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    },
    changeStock(id) {
      this.updateStock({ id }).then(() => {
        util.showToast('Product successfully updated');
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    },
    changeStockProduct(id, stock) {
      this.updateStockProduct({ id, stock }).then(() => {
        util.showToast('Product successfully updated');
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    },
    changePreOrder(id) {
      this.updatePreOrderProduct({ id }).then(() => {
        util.showToast('Product successfully updated');
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    },
    changePreOwn(id) {
      this.updatePreOwnProduct({ id }).then(() => {
        util.showToast('Product successfully updated');
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    },
    changePrice(id, value) {
      this.updatePriceProduct({ id, price: value }).then(() => {
        util.showToast('Price successfully updated');
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category });
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    },
    async deleteProduct(id) {
      const action = () => {
        this.deleteProductById({product_id: id}).then(() => {
          $(".table-products").DataTable().destroy()
          this.initDatatables();
        })
        // this.fetchProducts({ filter_brand: this.filter_brand, filter_category: this.filter_category })
      }
      await util.deleteConfirmationToast(await action)

    },
    getImageUrl(image) {
      return process.env.VUE_APP_IMAGE_URL + image;
    },
    getSeoValue() {
      // this.data.seo = `${this.data.sku} - ${this.data.title} - ${this.data.brand} - ${this.data.short_description?.replace(/<\/?[^>]+(>|$)/g, "")}`
      // this.data.seo_meta_data = `${this.data.sku} - ${this.data.title} - ${this.data.brand} - ${this.data.short_description?.replace(/<\/?[^>]+(>|$)/g, "")}`
      this.data.seo = `${this.data.sku} - ${this.data.title} - ${this.data.brand}`
      this.data.seo_meta_data = `${this.data.sku} - ${this.data.title} - ${this.data.brand}`
    },
    calculateBuyingPrice() {
      let tax = this.data.buying_price_exc * 10 / 100;
      this.data.buying_price_inc = (tax + this.data.buying_price_exc).toFixed(2);
    },
    initDatatables() {
      var self = this;
      // this.filter_brand = null;
      // this.filter_category = null;
      // this.search_sku = null;
      if (!$.fn.DataTable.isDataTable('.table-products')) {
        var table = $(".table-products").DataTable({
          serverSide: true,
          deferRender: true,
          orderClasses: false,
          processing: true,
          // "ajax": {
          //   "url": "https://api.sagegaming.com.au/api/admin/products",
          //   "dataSrc": "data.products"
          // },
          stateSave: true,
          sort: true,
          info: true,
          filter: true,
          lengthChange: true,
          paginate: true,
          autoWidth: false,
          pageLength: 25,
          searchDelay: 350,
          // serverSide: true,
          ajax: function (data, callback) {
              var out = [];
              var params = {...data, filter_brand: self.filter_brand, filter_category: self.filter_category, dev: true}
              params['columns'][1]['search']['value'] = self.search_sku;
              $.ajax({
                  type: "get",
                  async: false,
                  url: process.env.VUE_APP_API_URL + 'products',
                  headers: { 'token': StorageService.getToken() },
                  data: params,
                  success: function (data) {
                      console.log("success:data: ", data);
                      if (data.message == 'Expired Token' || data.message == 'Invalid Token' || data.message == 'Invalid User Token') {
                        UserService.logout();
                        router.push("/login");
                      }
                      out = data.products;
                  }
              });
              callback({
                  "draw": out.draw,
                  "recordsTotal": out.recordsTotal,
                  "recordsFiltered": out.recordsFiltered,
                  "data": out.data,
              });
              return data.products;
          },
          columns: [
            { data: 'id', name: 'id', width: '5%', orderable: false },
            { data: 'sku', name: 'sku', width: '5%' },
            { data: 'title', name: 'title', orderable: false },
            {
              render: function (data, type, row) {
                return row?.nested_categories?.[0]?.category?.name;
              },
              defaultContent: ''
            },
            { data: 'brand.name', name: 'brand.name', orderable: false, defaultContent: '' },
            {
              render: function (data, type, row) {
                return `<input type="number" value="${row.real_price}" class="form-control" @change="changePrice(row.id, $event)" debounce="5000" id="change-price">`;
              },
            },
            {
              data: 'stock',
              name: 'stock',
              orderable: false,
              render: function (data, type, row) {
                return `<input type="number" value="${row.stock}" class="form-control" @change="changeStock(row.id, $event)" debounce="5000" id="change-stock">`;
              },
            },
            {
              render: function (data, type, row) {
                return `<img src="${row?.image1}" width="40" height="40" alt="">`;
              },
            },
            {
              render: function (data, type, row) {
                return `<input type="checkbox" ${row.in_stock == 1 ? 'checked' : null} id="change-instock">`;
              },
            },
            {
              render: function (data, type, row) {
                return `<input type="checkbox" ${row.preorder == 1 ? 'checked' : null} id="change-preorder">`;
              },
            },
            {
              render: function (data, type, row) {
                return `<input type="checkbox" ${row.pre_own == 1 ? 'checked' : null} id="change-preown">`;
              },
            },
            {
              render: function (data, type, row) {
                return `<input type="checkbox" ${row.website_status ? 'checked' : null} id="change-status">`;
              },
            },
            {
              render: function (data, type, row) {
                return moment(row.updated_at).format('DD MMM YYYY');
              },
            },
            {
              render: function (data, type, row, index) {
                return `<td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" @click="editProduct(${index.row})" id="edit-product"
                      ><i class="icon-pencil"></i> Edit Product</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteProduct(product.id)" id="delete-product"
                      ><i class="icon-trash"></i> Delete Product</a
                    >
                  </div>
                </div>
              </div>
            </td>`;
              },
            },
          ],
          rowCallback: function (row, data) {
            $(row).on("change", '#change-price', function () {
              var input = $(this);
              self.changePrice(data.id, input.val())
            });
            $(row).on("change", '#change-stock', async function () {
              // console.log($('#change-stock').val())
              self.changeStockProduct(data.id, $('#change-stock').val())
            });
            $(row).on("change", '#change-instock', async function () {
              self.changeStock(data.id)
            });
            $(row).on("change", '#change-preorder', function () {
              self.changePreOrder(data.id)
            });
            $(row).on("change", '#change-preown', function () {
              self.changePreOwn(data.id)
            });
            $(row).on("change", '#change-status', function () {
              self.changeStatusWebsite(data.id)
            });
            $(row).on("click", '#edit-product', function () {
              self.editProduct(data)
            });
            $(row).on("click", '#delete-product', function () {
              self.deleteProduct(data.id)
            });
          },
          order: [[0, "desc"]],
          dom: '<"datatable-header"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
          lengthMenu: [20, 25, 50, 100],
          displayLength: 25,
          buttons: [
          ],
        });
        table.on('page.dt', function () {
          $('input[type=search]').val(''); // Clear Search input.
          //table.search('').draw(); // Rebind all data.
        });
      }
    }
  },
}
</script>

<style>
  .ellipsis  {
    max-width: 200px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ck-editor__editable {
      min-height: 500px;
  }
  .ck-editor__editable_inline {
      min-height: 200px;
  }
  body {
  /* We need to assaign this CSS Custom property to the body instead of :root, because of CSS Specificity and codepen stylesheet placement before loaded CKE5 content. */
  --ck-z-default: 3100;
  --ck-z-modal: calc( var(--ck-z-default) + 3999 );
}
</style>