<template>
  <div class="quotations">
    <div class="card noprint">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Quotations</h6>
        <div>
          <button
            type="button"
            class="btn btn-danger"
            data-toggle="button"
            v-if="this.selectedIds.length > 0"
            @click="deleteQuotationBySelectedIds()"
          >
            Delete Selected
          </button>
          <router-link :to="`/quotations/create`">
            <button
              type="button"
              class="btn btn-transparent btn-sm mr-2"
            >
              <b><i class="icon-plus-circle2 mr-2"></i></b> Create Quotations
            </button>
          </router-link>
        </div>
      </div>

      <table class="table table-lg quotation-archive">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Quote ID</th>
            <th>Client Name</th>
            <th>Status</th>
            <th>Grand Total</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="inv in getQuotations.quotations" :key="inv.id">
            <td>
              <input
                type="checkbox"
                class="form-input-styled mr-n5"
                @click="addSelectedQuotation(inv.id)"
              />
            </td>
            <td>{{ getFormatDate(inv.date) }}</td>
            <td>
              <router-link :to="`/quotations/${inv.id}`">
                {{ inv.Ref }}
              </router-link>
            </td>
            <td>{{ inv.client_name }}</td>
            <td>
              <div class="badge badge-primary" :class="inv.payment_status == 'paid' ? 'badge-success' : 'badge-danger'">
                {{ inv.statut.toUpperCase() }}
              </div>
            </td>
            <td>
              <div class="d-flex flex-column align-items-center">
                <b>${{ inv.GrandTotal }}</b>
                GST ${{ (inv.GrandTotal / 11).toFixed(2) }}
              </div>
            </td>
            <td class="text-center">
              <div class="list-icons list-icons-extended">
                <a
                  class="list-icons-item"
                  @click="openQuotation(inv.client_id)"
                  data-toggle="modal"
                  data-target="#quotation"
                  ><i class="icon-file-eye"></i
                ></a>
                <div class="dropdown">
                  <a
                    href="#"
                    class="list-icons-item dropdown-toggle"
                    data-toggle="dropdown"
                    ><i class="icon-file-text2"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"
                      ><i class="icon-file-download"></i> Download</a
                    >
                    <a href="#" class="dropdown-item"
                      ><i class="icon-printer"></i> Print</a
                    >
                    <div class="dropdown-divider"></div>
                    <router-link :to="`/quotations/edit/${inv.id}`">
                      <a class="dropdown-item text-dark"
                        ><i class="icon-file-plus"></i> Edit</a
                      >
                    </router-link>
                    <a href="#" class="dropdown-item"
                      ><i class="icon-file-plus"></i> Convert to Invoice</a
                    >
                    <a class="dropdown-item" @click="deleteQuotation(inv.id)"
                      ><i class="icon-cross2"></i> Delete</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal with quotation -->
    <div id="quotation" class="modal fade" v-if="getQuotation.sale">
      <div class="modal-dialog modal-full">
        <div class="modal-content printable">
          <div class="modal-header">
            <h5 class="modal-title">Quotation {{ getQuotation.sale.Ref }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-4">
                  <img
                    src="@/assets/images/logo_invoice.png"
                    class="mb-3 mt-2"
                    alt=""
                    style="width: 120px"
                  />
                  <ul class="list list-unstyled mb-0">
                    <li>1 Muller Lane</li>
                    <li>Mascot,NSW,2020</li>
                    <li>0289595765</li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mb-4">
                  <div class="text-sm-right">
                    <h4 class="text-primary mb-2 mt-md-2">
                      Quotation {{ getQuotation.sale.Ref }}
                    </h4>
                    <ul class="list list-unstyled mb-0">
                      <li>
                        Date:
                        <span class="font-weight-semibold">{{
                          getQuotation.sale.date
                        }}</span>
                      </li>
                      <!-- <li>
                        Due date:
                        <span class="font-weight-semibold">May 12, 2015</span>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-md-flex flex-md-wrap">
              <div class="mb-4 mb-md-2">
                <span class="text-muted">Quotation To:</span>
                <ul class="list list-unstyled mb-0">
                  <li>
                    <h5 class="my-2">{{ getQuotation.sale.client_name }}</h5>
                  </li>
                  <li>
                    <span class="font-weight-semibold">{{
                      getQuotation.company?.CompanyName || ""
                    }}</span>
                  </li>
                  <li>4 Muller Lane</li>
                  <li>Mascot,NSW,666</li>
                  <li>Australia</li>
                  <li>0289595765</li>
                  <li><a href="#">rebecca@gmail.com.au</a></li>
                </ul>
              </div>

              <div class="mb-2 ml-auto">
                <span class="text-muted">Payment Details:</span>
                <div class="d-flex flex-wrap wmin-md-400">
                  <ul class="list list-unstyled mb-0">
                    <li><h5 class="my-2">Total Due:</h5></li>
                    <li>Bank name:</li>
                    <li>Country:</li>
                    <li>City:</li>
                    <li>Address:</li>
                    <li>IBAN:</li>
                    <li>SWIFT code:</li>
                  </ul>

                  <ul class="list list-unstyled text-right mb-0 ml-auto">
                    <li>
                      <h5 class="font-weight-semibold my-2">
                        ${{ getQuotation.sale.GrandTotal.toLocaleString() }}
                      </h5>
                    </li>
                    <li>
                      <span class="font-weight-semibold"
                        >Profit Bank Europe</span
                      >
                    </li>
                    <li>United Kingdom</li>
                    <li>London E1 8BF</li>
                    <li>3 Goodman Street</li>
                    <li>
                      <span class="font-weight-semibold"
                        >KFH37784028476740</span
                      >
                    </li>
                    <li><span class="font-weight-semibold">BPT4E</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-lg">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, i) in getQuotation.details" :key="i">
                  <td>
                    <h6 class="mb-0">{{ product.product_name }}</h6>
                  </td>
                  <td>{{ product.price.toLocaleString() }}</td>
                  <td>
                    <button type="button" class="btn btn-light" data-popup="popover" title="Popover title" data-trigger="focus" data-content="And here's some amazing content. It's very engaging. Right?">Popover <i class="icon-play3 ml-2"></i></button>
                    {{ product.quantity }}
                  </td>
                  <td>
                    <span class="font-weight-semibold"
                      >${{
                        (product.price * product.quantity).toLocaleString()
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <div class="d-md-flex flex-md-wrap">
              <div class="pt-2 mb-3">
                <h6 class="mb-3">Authorized person</h6>
                <div class="mb-3">
                  <img src="@/assets/images/signature.png" width="150" alt="" />
                </div>

                <ul class="list-unstyled text-muted">
                  <li>Cheaptech</li>
                  <li>1 Muller Lane</li>
                  <li>Mascot,NSW,2020</li>
                  <li>0289595765</li>
                </ul>
              </div>

              <div class="pt-2 mb-3 wmin-md-400 ml-auto">
                <h6 class="mb-3">Total due</h6>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th>Subtotal:</th>
                        <td class="text-right">
                          ${{ getSubtotal(getQuotation.details) }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Tax: <span class="font-weight-normal">(25%)</span>
                        </th>
                        <td class="text-right">
                          ${{ getQuotation.sale.tax_rate.toLocaleString() }}
                        </td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td class="text-right text-primary">
                          <h5 class="font-weight-semibold">
                            ${{ getQuotation.sale.GrandTotal.toLocaleString() }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="card-footer">
            <span class="text-muted"
              >Thank you for using Limitless. This quotation can be paid via
              PayPal, Bank transfer, Skrill or Payoneer. Payment is due within
              30 days from the date of delivery. Late payment is possible, but
              with with a fee of 10% per month. Company registered in England
              and Wales #6893003, registered office: 3 Goodman Street, London E1
              8BF, United Kingdom. Phone number: 888-555-2311</span
            >
          </div> -->

          <div class="modal-footer bg-transparent">
            <div class="text-right mt-3">
              <button
                @click="sendQuotation()"
                type="button"
                class="btn btn-primary btn-labeled btn-labeled-left"
              >
                <b><i class="icon-paperplane"></i></b> Send Quotation
              </button>
              <button
                @click="printQuotation()"
                type="button"
                class="btn btn-secondary btn-labeled btn-labeled-left ml-2"
              >
                <b><i class="icon-paperplane"></i></b> Print Quotation
              </button>
              <button
                type="button"
                class="btn btn-light btn-labeled ml-2"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /modal with quotation -->
  </div>
</template>

<style scoped>
@media print {
  .printable {
    overflow: hidden;
  }
  .modal-footer {
    display: none;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import { StorageService } from '@/services/storage.service'
import util from "@/helpers/util.js";
import moment from "moment";

export default {
  name: "Quotations",
  data() {
    return {
      selectedIds: [],
      errors: [],
      modal: 0,
      id: null,
      selectedProducts: [],
      countSelectedProduct: 0,
      data: {
        stripe_key: "",
        date: null,
        client_id: null,
        GrandTotal: 0,
        warehouse_id: null,
        tax_rate: 0,
        TaxNet: 0,
        discount: 0,
        shipping: 0,
        status: 'Paid',
        notes: '',
        payment: {
          status: 'Paid',
          Reglement: "",
          amount: 0,
        },
        details: [
          {
            quantity: null,
            Unit_price: null,
            tax_percent: 0,
            tax_method: '',
            discount: 0,
            discount_Method: '',
            product_id: '',
            product_variant_id: '',
            subtotal: 0,
          },
        ],
      },
    };
  },
  created() {
    this.fetchQuotations();
    this.fetchClients();
    this.fetchProducts();
  },
  updated() {
    if (!$.fn.DataTable.isDataTable(".quotation-archive")) {
      $(".quotation-archive").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            width: '2%',
            targets: 0,
          },
          {
            visible: false,
            targets: 1,
          },
          {
            orderable: false,
            width: 120,
            targets: 5,
          },
          {
            width: "15%",
            targets: 4,
          },
          {
            width: "20%",
            targets: 3,
          },
        ],
        order: [[0, "desc"]],
        dom: '<"datatable-header"fl><"datatable-scroll-lg"t><"datatable-footer"ip>',
        language: {
          search: "<span>Filter:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 25,
        drawCallback: function () {
          var api = this.api();
          var rows = api.rows({ page: "current" }).nodes();
          var last = null;
          api
            .column(1, { page: "current" })
            .data()
            .each(function (group, i) {
              if (last !== group) {
                $(rows)
                  .eq(i)
                  .before(
                    '<tr class="table-active table-border-double"><td colspan="8" class="font-weight-semibold">' +
                      group +
                      "</td></tr>"
                  );
                last = group;
              }
            });
          // Initializw Select2
          if (!$().select2) {
            console.warn("Warning - select2.min.js is not loaded.");
            return;
          }
          $(".form-control-select2").select2({
            width: 150,
            minimumResultsForSearch: Infinity,
          });
        },
      });
    }
  },
  computed: {
    ...mapGetters({
      getQuotations: "quotations/getQuotations",
      getQuotation: "quotations/getQuotation",
      getProducts: "products/getProducts",
      getClients: "clients/getClients",
    }),
  },
  async mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Anytime;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    if (!$("#date")) {
      $("#date").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchQuotations: "quotations/fetchQuotations",
      fetchProducts: "products/fetchProducts",
      fetchClients: "clients/fetchClients",
      quotationById: "quotations/quotationById",
    }),
    addProductInput() {
      this.countSelectedProduct += 1;
      this.data.details = this.data.details.concat({
        quantity: null,
        Unit_price: null,
        tax_percent: 0,
        tax_method: '',
        discount: 0,
        discount_Method: '',
        product_id: '',
        product_variant_id: '',
        subtotal: 0,
      })
    },
    deleteProductInput(i) {
      this.countSelectedProduct -= 1;
      this.data.details.splice(i, 1)
    },
    getFormatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getSubtotal(product) {
      let subtotal = 0;
      product.map((item) => {
        subtotal += item.price * item.quantity;
      });
      return subtotal.toLocaleString();
    },
    openQuotation(id) {
      console.log(id);
      this.quotationById({ id });
      $("#quotation").modal("show");
    },
    sendQuotation() {},
    printQuotation() {
      window.print();
    },
    addSelectedQuotation(id) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id);
      } else {
        this.selectedIds = this.selectedIds.filter(
          (selectedId) => selectedId != id
        );
      }
    },
    openAddQuotationModal() {
      this.modal = 0;
      this.id = null;
      this.data.name = null;
      this.data.phone = null;
      this.data.email = null;
      this.data.country = null;
      this.data.city = null;
      this.data.adresse = null;
      $("#AddQuotation").modal("show");
    },
    addQuotation() {
      console.log(this.data)
      // this.AddQuotation({ data: this.data })
      //   .then(() => {
      //     util.showToast("Quotation successfully added");
      //     this.fetchQuotations();
      //     $("#AddQuotation").modal("hide");
      //   })
      //   .catch((err) => {
      //     console.log(err.response.data);
      //     this.errors = err.response.data.errors;
      //   });
    },
    editQuotation(i) {
      this.modal = 1;
      let val = this.getQuotations.clients[i];
      this.id = val.id;
      this.data.name = val.name;
      this.data.phone = val.phone;
      this.data.email = val.email;
      this.data.country = val.country;
      this.data.city = val.city;
      this.data.adresse = val.adresse;
      $("#AddQuotation").modal("show");
    },
    editQuotationSubmit() {
      let data = this.data;
      this.updateQuotation({ id: this.id, data })
        .then(() => {
          util.showToast("Quotation successfully changed");
          this.fetchQuotations();
          $("#AddQuotation").modal("hide");
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.response.data.errors;
        });
    },
    async deleteQuotation(id) {
      const action = () => {
        this.deleteQuotationById({ id });
      };
      await util.deleteConfirmationToast(action);
      this.fetchQuotations();
    },
    async deleteQuotationBySelectedIds() {
      const action = () => {
        this.deleteQuotationByIds({ selectedIds: this.selectedIds });
      };
      await util.deleteConfirmationToast(action);
      this.fetchQuotations();
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
