<template>
  <div class="membership_rules">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Membership Rules</h6>
        <div>
          <!-- <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddMembershipRuleModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add MembershipRules
          </button> -->
        </div>
      </div>

      <table class="table text-nowrap table-membership_rules" id="datatable">
        <thead>
          <tr>
            <th>Key</th>
            <th>Ruthenium</th>
            <th>Silver</th>
            <th>Gold</th>
            <th>Platinum</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(membership_rule, index) in getMembershipRules" :key="index">
            <td>{{ toCamelCase(membership_rule.key) }}</td>
            <td>{{ membership_rule.membership_1 }}</td>
            <td>{{ membership_rule.membership_2 }}</td>
            <td>{{ membership_rule.membership_3 }}</td>
            <td>{{ membership_rule.membership_4 }}</td>
            <td>{{ formatDate(membership_rule.created_at) }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editMembershipRule(index)"
                      ><i class="icon-pencil"></i> Edit Rule</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteMembershipRule(membership_rule.id)"
                      ><i class="icon-trash"></i> Delete Rule</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add MembershipRule modal -->
    <div id="AddMembershipRules" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Rule</h5>
            <h5 class="modal-title" v-else>Edit Rule</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Key</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    class="form-control"
                    disabled
                    :value="toCamelCase(data.key ?? '')"
                  />
                  <span v-if="errors.key" class="form-text text-danger">{{ errors.key[0] }}</span>
                </div>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Ruthenium</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      class="form-control"
                      v-model="data.membership_1"
                    />
                    <span v-if="errors.membership_1" class="form-text text-danger">{{ errors.membership_1[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Silver</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      class="form-control"
                      v-model="data.membership_2"
                    />
                    <span v-if="errors.membership_2" class="form-text text-danger">{{ errors.membership_2[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Gold</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      class="form-control"
                      v-model="data.membership_3"
                    />
                    <span v-if="errors.membership_3" class="form-text text-danger">{{ errors.membership_3[0] }}</span>
                  </div>
                  <div class="col">
                    <label>Platinum</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      class="form-control"
                      v-model="data.membership_4"
                    />
                    <span v-if="errors.membership_4" class="form-text text-danger">{{ errors.membership_4[0] }}</span>
                  </div>
                </div>
              </div>

            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addMembershipRule() : editMembershipRuleSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add MembershipRule modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
// import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'
import moment from 'moment';

export default {
  name: "MembershipRules",
  components: {
  },
  data() {
    return {
      editor: null,
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      id: null,
      data: {
        key: null,
        membership_1: null,
        membership_2: null,
        membership_3: null,
        membership_4: null,
      },
    }
  },
  created() {
    this.editor = !this.editor && ClassicEditor;
    this.fetchMembershipRules();
  },
  computed: {
    ...mapGetters({
      getMembershipRules: "membershiprules/getMembershipRules",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-membership_rules')) {
      $(".table-membership_rules").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            orderable: false,
            targets: 2,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        // responsive: {
        //   details: {
        //     type: "column",
        //     target: -1,
        //   },
        // },
        buttons: [],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchMembershipRules: "membershiprules/fetchMembershipRules",
      createMembershipRule: "membershiprules/createMembershipRule",
      updateMembershipRule: "membershiprules/updateMembershipRule",
      deleteMembershipRuleById: "membershiprules/deleteMembershipRuleById",
    }),
    toCamelCase(str) {
      return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
          .toUpperCase()
          .replace('-', ' ')
          .replace('_', ' ')
      ) ?? '';
    },
    openAddMembershipRuleModal() {
      this.modal = 0;
      this.id = null;
      this.data.key = null;
      this.data.membership_1 = null;
      this.data.membership_2 = null;
      this.data.membership_3 = null;
      this.data.membership_4 = null;
      $('#AddMembershipRules').modal('show');
    },
    addMembershipRule() {
      this.createMembershipRule({ data: this.data })
      .then(() => {
        util.showToast('Membership Rule successfully added');
        this.fetchMembershipRules();
        $('#AddMembershipRules').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editMembershipRule(i) {
      this.modal = 1;
      let val = this.getMembershipRules[i];
      this.id = val.id
      this.data.key = val.key
      this.data.membership_1 = val.membership_1
      this.data.membership_2 = val.membership_2
      this.data.membership_3 = val.membership_3
      this.data.membership_4 = val.membership_4
      $('#AddMembershipRules').modal('show');
    },
    editMembershipRuleSubmit() {
      let data = this.data;
      this.updateMembershipRule({ id: this.id, data }).then(() => {
        util.showToast('Membership Rule successfully changed');
        this.fetchMembershipRules();
        $('#AddMembershipRules').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteMembershipRule(id) {
      const action = () =>  {
        this.deleteMembershipRuleById({id})
        this.fetchMembershipRules()
      }
      await util.deleteConfirmationToast(action)
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD MMM YYYY')
      }
    }
  },
};
</script>
