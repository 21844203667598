import util from "@/helpers/util";
import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  timesheet: [],
  timesheetDetail: {},
  timesheetHistory: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getTimesheet: (state) => {
    return state.timesheet;
  },
  getTimesheetDetail: (state) => {
    return state.timesheetDetail;
  },
  getTimesheetHistory: (state) => {
    return state.timesheetHistory;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_TIMESHEET: (state, data) => (state.timesheet = data),
  SET_TIMESHEET_DETAIL: (state, data) => (state.timesheetDetail = data),
  SET_TIMESHEET_HISTORY: (state, data) => (state.timesheetHistory = data),
};

const actions = {
  async fetchTimesheet({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.post("/staff/time-sheet");
      commit("SET_TIMESHEET", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_TIMESHEET", {});
    }
    commit("SET_LOADER", false);
  },
  async timesheetById({ commit }, { staffId, date_to, date_from }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`/staff/total-hours?staff_id=${staffId}?date_from=${date_from}?date_to=${date_to}`);
      commit("SET_TIMESHEET_DETAIL", response.data.data);
    } catch (error) {
      console.log(error);
      commit("SET_TIMESHEET_DETAIL", {});
    }
    commit("SET_LOADER", false);
  },
  async timesheetHistory({ commit }, { id }) {
    commit("SET_LOADER", true);
      let response = await ApiService.get(`/staff/time-sheet/history/${id}`);
      commit("SET_TIMESHEET_HISTORY", response.data.data);
      commit("SET_LOADER", false);
      return response.data;
  },
  async createTimesheet({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post("/staff/time-sheet/create", data);
      if (response.data?.success) {
        util.showToast('Timesheet successfully created')
      }
      commit("SET_LOADER", false);
      return response.data;
  },
  async updateTimesheet({ commit }, { id, data }) {
    commit("SET_LOADER", true);
      let response = await ApiService.post(`/staff/time-sheet/update/${id}`, data);
      if (response.data?.success) {
        util.showToast('Timesheet successfully updated')
      }
      commit("SET_LOADER", false);
      return response.data;
  },
  async startTimesheet({ commit }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/staff/time-sheet/start");
      // commit("SET_TIMESHEET", response.data);
    } catch (error) {
      console.log(error);
      // commit("SET_TIMESHEET", {});
    }
    commit("SET_LOADER", false);
  },
  async stopTimesheet({ commit }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/staff/time-sheet/stop");
      // commit("SET_TIMESHEET", response.data);
    } catch (error) {
      console.log(error);
      // commit("SET_TIMESHEET", {});
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
