<template>
  <div class="timesheet">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">TimeSheet</h6>
        <div>
          <button
            @click="exportTimesheet"
            type="button"
            class="btn btn-transparent btn-sm mr-2"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            @click="openAddTimesheetModal()"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Create Timesheet
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-timesheet mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Start</th>
            <th>End</th>
            <th>Duration</th>
            <th>Actions</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getTimesheet.data" :key="item.id">
            <td>
              <router-link :to="`/staff/${item.user.id}`">
                {{ `${item.user.firstname} ${item.user.lastname}` }}
              </router-link>
            </td>
            <td ><span v-if="item.day">{{item.day}}</span></td>
            <!-- <td>{{ item.time_start }}</td> -->
            <td>{{ item.time_start }}</td>
            <!-- <td>{{ item.time_end }}</td> -->
            <td>{{ item.time_end }}</td>
            <!-- <td>{{ item.duration }} hours</td> -->
            <td>{{ item.duration }} <span v-if="item.duration">hours</span></td>

            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <button
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#DetailTimesheet"
                    >
                      <i class="icon-eye2"></i> Print
                    </button>
                    <button
                      class="dropdown-item"
                      @click="detailTimesheet(index)"
                      data-toggle="modal"
                      data-target="#DetailTimesheet"
                    >
                      <i class="icon-eye2"></i> See Timesheet
                    </button>
                    <button
                      class="dropdown-item"
                      @click="editTimesheet(index)"
                    >
                      <i class="icon-pencil"></i> Edit Timesheet
                    </button>
                    <button
                      class="dropdown-item"
                      @click="showTimesheetHistory(item.id)"
                    >
                      <i class="icon-pencil"></i> Activity
                    </button>
                    <!-- <div class="dropdown-divider"></div>
                    <button
                      class="dropdown-item"
                      @click="startTimesheet(item.id)"
                    >
                      <i class="icon-pencil"></i> Start Timesheet
                    </button>
                    <button
                      class="dropdown-item"
                      @click="stopTimesheet(item.id)"
                    >
                      <i class="icon-pencil"></i> Stop Timesheet
                    </button> -->
                  </div>
                </div>
              </div>
            </td>
            <td class="pl-0"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Timesheet Modal -->
    <div id="AddTimesheet" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Timesheet</h5>
            <h5 class="modal-title" v-if="modal == 1">Edit Timesheet</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-calendar22"></i>
                      </span>
                    </span>
                    <input type="date" class="form-control daterange-single" v-model="data.day" />
                  </div>
                  <span v-if="errors.day" class="form-text text-danger">
                    {{ errors.day[0] }}
                  </span>
                </div>
                <div class="col-sm-4">
                  <label>Begin</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="time" class="form-control" id="begin_time" v-model="data.time_start" />
                  </div>
                  <span v-if="errors.time_start" class="form-text text-danger">
                    {{ errors.time_start[0] }}
                  </span>
                </div>
                <div class="col-sm-4">
                  <label>End</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="icon-alarm"></i>
                      </span>
                    </span>
                    <input type="time" class="form-control" id="end_time" v-model="data.time_end" />
                  </div>
                  <span v-if="errors.time_end" class="form-text text-danger">
                    {{ errors.time_end[0] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Activity</label>
                  <textarea rows="2" cols="3" class="form-control" v-model="data.activity" ></textarea>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-12">
                  <label>Description</label>
                  <textarea rows="4" cols="3" class="form-control" v-model="data.desc" ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button class="btn btn-primary" @click="modal == 0 ? addTimesheets() : editTimesheetSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Timesheet -->

    <!-- Detail Timesheet Modal -->
    <div id="DetailTimesheet" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">Detail Timesheet</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <table class="table table-sm table-borderless" id="billing">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>Alexander Rival</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>30 September 2021</td>
                      </tr>
                      <tr>
                        <td>Start</td>
                        <td>07.00 AM</td>
                      </tr>
                      <tr>
                        <td>End</td>
                        <td>17.00 PM</td>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <td>10 Hours</td>
                      </tr>
                      <tr>
                        <td>Activity</td>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, sequi.</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quod sit dolorum repellendus sint qui esse velit laudantium inventore perferendis dignissimos?</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3"></div>
        </div>
      </div>
    </div>
    <!-- /Detail Timesheet -->

    <!-- History Timesheet Modal -->
    <div id="HistoryTimesheet" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title">History Timesheet</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="col">
              <table class="table table-sm table-borderless" id="history">
                <thead>
                  <tr>
                    <th>Start</th>
                    <th>End</th>
                    <th>Date</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                    <th>Activity</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in history?.log" :key="log?.id">
                    <td>{{ log?.time_start || '-' }}</td>
                    <td>{{ log?.time_end || '-' }}</td>
                    <td>{{ log?.day || '-' }}</td>
                    <td>{{ log?.updated_by || '-' }}</td>
                    <td>{{ log?.created_at || '-' }}</td>
                    <td>{{ log?.activity || '-' }}</td>
                    <td>{{ log?.desc || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer pt-3"></div>
        </div>
      </div>
    </div>
    <!-- /History Timesheet -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'
import moment from 'moment';

export default {
  name: "Timesheet",
  data() {
    return {
      selectedIds: [],
      modal: 0, // 0 = Add | 1 = Edit
      id: null,
      errors: [],
      data: {
        time_start: null,
        time_end: null,
        day: null,
        activity: null,
        desc: null,
      },
      history: null,
    }
  },
  created() {
    this.fetchTimesheet();
  },
  computed: {
    ...mapGetters({
      getTimesheet: "timesheet/getTimesheet",
      getTimesheetHistory: "timesheet/getTimesheetHistory",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-timesheet')) {
      $(".table-timesheet").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            targets: 0,
            width: 400,
          },
          {
            visible: false,
            targets: 1,
          },
          {
            orderable: false,
            width: 16,
            targets: 5,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        drawCallback: function () {
          var api = this.api();
          var rows = api.rows({ page: "current" }).nodes();
          var last = null;
          api
            .column(1, { page: "current" })
            .data()
            .each(function (group, i) {
              if (last !== group) {
                $(rows)
                  .eq(i)
                  .before(
                    '<tr class="table-active table-border-double"><td colspan="8" class="font-weight-semibold">' +
                      group +
                      "</td></tr>"
                  );
                last = group;
              }
            });
          // Initializw Select2
          if (!$().select2) {
            console.warn("Warning - select2.min.js is not loaded.");
            return;
          }
          $(".form-control-select2").select2({
            width: 150,
            minimumResultsForSearch: Infinity,
          });
        },
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-excel"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
          {
            extend: "pdfHtml5",
            text: '<i class="icon-printer"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchTimesheet: "timesheet/fetchTimesheet",
      beginTimesheet: "timesheet/startTimesheet",
      endTimesheet: "timesheet/stopTimesheet",
      createTimesheet: "timesheet/createTimesheet",
      updateTimesheet: "timesheet/updateTimesheet",
      timesheetHistory: "timesheet/timesheetHistory",
    }),
    detailTimesheet() {
      $('#DetailTimesheet').modal('show');
    },
    startTimesheet(){
      this.beginTimesheet();
    },
    stopTimesheet(){
      this.endTimesheet();
    },
    openAddTimesheetModal() {
      this.modal = 0;
      // this.id = null;
      // this.data.name = null;
      // this.data.phone = null;
      // this.data.email = null;
      // this.data.country = null;
      // this.data.city = null;
      // this.data.adresse = null;
      $('#AddTimesheet').modal('show');
    },
    addTimesheets() {
      console.log('clicked')
      this.createTimesheet({ data: this.data })
      .then(res => {
        res.success && $('#AddTimesheet').modal('hide');
        this.fetchTimesheet();
      })
      .catch(error => {
        console.log(error)
        this.errors = error.response.data.errors;
      })
    },
    editTimesheet(i) {
      this.modal = 1;
      let val = this.getTimesheet.data[i];
      console.log(this.getTimesheet.data)
      this.id = val.id
      this.data.time_start = val.time_start
      this.data.time_end = val.time_end
      this.data.day = new Date(val.day).toISOString().slice(0,10)
      console.log(new Date(val.day).toISOString().slice(0,10))
      this.data.activity = val.activity
      this.data.desc = val.desc
      $('#AddTimesheet').modal('show');
    },
    editTimesheetSubmit() {
      this.data.day = moment(this.data.day).format('YYYY-M-DD');
      let data = this.data;
      this.updateTimesheet({ id: this.id, data })
      .then(res => {
        res.success && $('#AddTimesheet').modal('hide');
        this.fetchTimesheet();
      })
      .catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async showTimesheetHistory(id) {
      this.timesheetHistory({id})
      .then(res => {
        console.log(res.data)
        this.history = res.data;
      });
      $("#history").DataTable();
      $('#HistoryTimesheet').modal('show');
    },
    async deleteClient(id) {
      const action = () =>  {
        this.deleteClientById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchClients()
    },
    async deleteClientBySelectedIds() {
      const action = () =>  {
        this.deleteClientByIds({selectedIds: this.selectedIds})
      }
      await util.deleteConfirmationToast(action)
      this.fetchClients()
    },
    async exportTimesheet() {
      await ApiService.get("/staff/exportExcel", {
        'headers': {
          'Content-Disposition': "attachment; filename=TimeSheets.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TimeSheets.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
