<template>
  <div class="brands">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Brands</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddBrandModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Brands
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-brands" id="datatable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Image</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(brand, index) in getBrands" :key="index">
            <td>{{ brand.name }}</td>
            <td>
              <img
                :src="brand.image"
                width="40"
                height="40"
                alt=""
              >
            </td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editBrand(index)"
                      ><i class="icon-pencil"></i> Edit Brand</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteBrand(brand.id)"
                      ><i class="icon-trash"></i> Delete Brand</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Brand modal -->
    <div id="AddBrands" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Brand</h5>
            <h5 class="modal-title" v-else>Edit Brand</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    class="form-control"
                    v-model="data.name"
                  />
                  <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                </div>
              </div>
              <div class="form-group" v-if="modal == 0">
                <upload-file :max="1" maxError="Max files exceed" @changed="handleImages"></upload-file>
							</div>
              <div class="form-group" v-if="modal == 1">
								<label class="font-weight-semibold">Image</label>
								<upload-file :max="1" maxError="Max files exceed" @changed="handleImages" :imageData="[data.image].filter(item => item != null)"></upload-file>
							</div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addBrand() : editBrandSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Brand modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'
import UploadFileVue from '@/components/UploadFile.vue';

export default {
  name: "Brands",
  components: {
    'upload-file': UploadFileVue,
  },
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      id: null,
      data: {
        name: null,
        image: null,
      },
    }
  },
  created() {
    this.fetchBrands();
  },
  computed: {
    ...mapGetters({
      getBrands: "brands/getBrands",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-brands')) {
      $(".table-brands").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            orderable: false,
            targets: 2,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        // responsive: {
        //   details: {
        //     type: "column",
        //     target: -1,
        //   },
        // },
        buttons: [
          // {
          //   extend: "pdfHtml5",
          //   text: '<i class="icon-file-pdf"></i>',
          //   className: "btn bg-light",
          //   orientation: "landscape",
          //   exportOptions: {
          //     columns: [0, 1, 2, 3, 4, 5],
          //     stripHtml: true,
          //   },
          //   customize: function(doc) {
          //     doc.content[1].table.widths = Array(
          //       doc.content[1].table.body[0].length + 1
          //     )
          //       .join("*")
          //       .split("");
          //   },
          // },
        ],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchBrands: "brands/fetchBrands",
      createBrand: "brands/createBrand",
      updateBrand: "brands/updateBrand",
      deleteBrandById: "brands/deleteBrandById",
    }),
    openAddBrandModal() {
      this.modal = 0;
      this.id = null;
      this.data.name = null;
      this.data.image = null;
      $('#AddBrands').modal('show');
    },
    handleImages(img) {
      this.data.image = img[0] ?? '';
    },
    addBrand() {
      this.createBrand({ data: this.data })
      .then(() => {
        util.showToast('Brand successfully added');
        this.fetchBrands();
        $('#AddBrands').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editBrand(i) {
      this.modal = 1;
      let val = this.getBrands[i];
      this.id = val.id
      this.data.name = val.name
      this.data.image = val.image
      console.log(this.image)
      $('#AddBrands').modal('show');
    },
    editBrandSubmit() {
      let data = this.data;
      this.updateBrand({ id: this.id, data }).then(() => {
        util.showToast('Brand successfully changed');
        this.fetchBrands();
        $('#AddBrands').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteBrand(id) {
      const action = () =>  {
        this.deleteBrandById({id})
        this.fetchBrands()
      }
      await util.deleteConfirmationToast(action)
    },
  },
};
</script>
