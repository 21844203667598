import { createRouter, createWebHistory } from "vue-router";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import Home from "@/views/Home.vue";
// Auth
import Login from "@/views/auth/Login.vue";
// Timesheet
import Timesheet from "@/views/timesheet/Timesheet.vue";
// Staff
import Staff from "@/views/staff/Staff.vue";
import StaffDetail from "@/views/staff/StaffDetail.vue";
// Clients
import Clients from "@/views/clients/Clients.vue";
import ClientsDetail from "@/views/clients/ClientsDetail.vue";
import TradeAccounts from "@/views/trade-accounts/TradeAccounts.vue";
// Tickets
import Tickets from "@/views/tickets/Tickets.vue";
import TicketsDetail from "@/views/tickets/TicketsDetail.vue";
// Grouping Product
import Warehouses from "@/views/products/warehouses/Warehouses.vue";
import StockManagement from "@/views/stock-management/StockManagement.vue";
import StockDetail from "@/views/stock-management/StockDetail.vue";
import StockCreate from "@/views/stock-management/StockCreate.vue";
import StockEdit from "@/views/stock-management/StockEdit.vue";
import Units from "@/views/products/units/Units.vue";
import Brands from "@/views/products/brands/Brands.vue";
import Colors from "@/views/colors/Color.vue";
import Categories from "@/views/products/categories/Categories.vue";
import Subcategories from "@/views/products/categories/Subcategories.vue";
import SubcategoryChildren from "@/views/products/categories/SubcategoryChildren.vue";
import Products from "@/views/products/Products.vue";
// Homepage
import Coupons from "@/views/coupons/Coupon.vue";
import Blogs from "@/views/blogs/Blog.vue";
import Hotdeals from "@/views/homepage/Hotdeals.vue";
import Banners from "@/views/homepage/Banners.vue";
import BestSellers from "@/views/homepage/BestSellers.vue";
import NewArrivals from "@/views/homepage/NewArrivals.vue";
import FeaturedProducts from "@/views/homepage/FeaturedProducts.vue";
// import Testimonials from "@/views/homepage/Testimonials.vue";
// Invoices
import MembershipRules from "@/views/membership-rules/MembershipRule.vue";
import Membership from "@/views/invoices/Membership.vue";
import PreOrderInvoices from "@/views/invoices/PreOrderInvoices.vue";
import Invoices from "@/views/invoices/Invoices.vue";
import InvoiceDetail from "@/views/invoices/InvoiceDetail.vue";
import InvoicePrint from "@/views/invoices/InvoicePrint.vue";
import CreateInvoice from "@/views/invoices/CreateInvoice.vue";
import EditInvoice from "@/views/invoices/EditInvoice.vue";
import EditService from "@/views/invoices/EditService.vue";
import Quotations from "@/views/quotations/Quotations.vue";
import EditQuotation from "@/views/quotations/EditQuotation.vue";
import QuotationDetail from "@/views/quotations/QuotationDetail.vue";
import CreateQuotation from "@/views/quotations/CreateQuotation.vue";
import CreateService from "@/views/invoices/CreateService.vue";
// Credit Notes
import RMA from "@/views/refunds/RMA.vue";
import RMADetail from "@/views/refunds/RMADetail.vue";
import CreateRMA from "@/views/refunds/CreateRMA.vue";
import CreateRefund from "@/views/refunds/CreateRefund.vue";
import Refunds from "@/views/refunds/Refunds.vue";
import Point from "@/views/points/Point.vue";
import CreditNotes from "@/views/credit-notes/CreditNotes.vue";
import CreditNotesDetail from "@/views/credit-notes/CreditNotesDetail.vue";
//Purchases
import Suppliers from "@/views/suppliers/Suppliers.vue";
import Purchases from "@/views/purchases/Purchases.vue";
import CreatePurchase from "@/views/purchases/CreatePurchase.vue";
import Compare from "@/views/Compare.vue";
import Page from "@/views/pages/Page.vue";
import Footer from "@/views/setting/Footer.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: Warehouses,
    meta: {
      title: "Warehouses",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/stock-management",
    name: "StockManagement",
    component: StockManagement,
    meta: {
      title: "StockManagement",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/stock-management/create",
    name: "StockCreate",
    component: StockCreate,
    meta: {
      title: "StockCreate",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/stock-management/edit/:id",
    name: "StockEdit",
    component: StockEdit,
    meta: {
      title: "StockEdit",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/stock-management/:id",
    name: "StockDetail",
    component: StockDetail,
    meta: {
      title: "StockDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/units",
    name: "Units",
    component: Units,
    meta: {
      title: "Units",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/brands",
    name: "Brands",
    component: Brands,
    meta: {
      title: "Brands",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/colors",
    name: "Colors",
    component: Colors,
    meta: {
      title: "Colors",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: {
      title: "Categories",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/categories/:id",
    name: "Subcategories",
    component: Subcategories,
    meta: {
      title: "Subcategories",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/categories/children/:id",
    name: "SubcategoryChildren",
    component: SubcategoryChildren,
    meta: {
      title: "SubcategoryChildren",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      title: "Products",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/hotdeals",
    name: "Hotdeals",
    component: Hotdeals,
    meta: {
      title: "Hotdeals",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/banners",
    name: "Banners",
    component: Banners,
    meta: {
      title: "Banners",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/best-sellers",
    name: "BestSellers",
    component: BestSellers,
    meta: {
      title: "BestSellers",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/new-arrivals",
    name: "NewArrivals",
    component: NewArrivals,
    meta: {
      title: "NewArrivals",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/featured-products",
    name: "Featured Products",
    component: FeaturedProducts,
    meta: {
      title: "FeaturedProducts",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  // {
  //   path: "/testimonials",
  //   name: "Testimonials",
  //   component: Testimonials,
  //   meta: {
  //     title: "Testimonials",
  //     layout: LayoutDefault,
  //     adminAuth: true,
  //   },
  // },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      title: "Blogs",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    meta: {
      title: "Coupons",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/staff",
    name: "Staff",
    component: Staff,
    meta: {
      title: "Staff",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/staff/:id",
    name: "StaffDetail",
    component: StaffDetail,
    meta: {
      title: "StaffDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/timesheet",
    name: "Timesheet",
    component: Timesheet,
    meta: {
      title: "Timesheet",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: {
      title: "Clients",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/clients/:id",
    name: "ClientsDetail",
    component: ClientsDetail,
    meta: {
      title: "ClientsDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Tickets,
    meta: {
      title: "Tickets",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/trade-accounts",
    name: "TradeAccounts",
    component: TradeAccounts,
    meta: {
      title: "TradeAccounts",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/invoices/:id",
    name: "InvoiceDetail",
    component: InvoiceDetail,
    meta: {
      title: "InvoiceDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/invoices/details/:id",
    name: "InvoicePrint",
    component: InvoicePrint,
    meta: {
      title: "InvoicePrint",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/preorder-invoices",
    name: "PreOrderInvoices",
    component: PreOrderInvoices,
    meta: {
      title: "PreOrderInvoices",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      title: "Invoices",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/invoices/create",
    name: "CreateInvoice",
    component: CreateInvoice,
    meta: {
      title: "CreateInvoice",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/services/create",
    name: "CreateService",
    component: CreateService,
    meta: {
      title: "CreateService",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/invoices/edit/:id",
    name: "EditInvoice",
    component: EditInvoice,
    meta: {
      title: "EditInvoice",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/services/edit/:id",
    name: "EditService",
    component: EditService,
    meta: {
      title: "EditService",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/membership-rules",
    name: "MembershipRules",
    component: MembershipRules,
    meta: {
      title: "MembershipRules",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/memberships",
    name: "Memberships",
    component: Membership,
    meta: {
      title: "Memberships",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/quotations/:id",
    name: "QuotationDetail",
    component: QuotationDetail,
    meta: {
      title: "QuotationDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/quotations/edit/:id",
    name: "EditQuotation",
    component: EditQuotation,
    meta: {
      title: "EditQuotation",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/quotations",
    name: "Quotations",
    component: Quotations,
    meta: {
      title: "Quotations",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/quotations/create",
    name: "CreateQuotation",
    component: CreateQuotation,
    meta: {
      title: "CreateQuotation",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/refund-receipts",
    name: "Refunds",
    component: Refunds,
    meta: {
      title: "Refunds",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/refunds/create",
    name: "CreateRefund",
    component: CreateRefund,
    meta: {
      title: "CreateRefund",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/rma/create/:id",
    name: "CreateRMA",
    component: CreateRMA,
    meta: {
      title: "CreateRMA",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/rma",
    name: "RMA",
    component: RMA,
    meta: {
      title: "RMA",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/rma/:id",
    name: "RMADetail",
    component: RMADetail,
    meta: {
      title: "RMADetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/points",
    name: "Point",
    component: Point,
    meta: {
      title: "Point",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/credit-notes",
    name: "CreditNotes",
    component: CreditNotes,
    meta: {
      title: "CreditNotes",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/credit-notes/:id",
    name: "CreditNotesDetail",
    component: CreditNotesDetail,
    meta: {
      title: "CreditNotesDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: Suppliers,
    meta: {
      title: "Suppliers",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/purchases",
    name: "Purchases",
    component: Purchases,
    meta: {
      title: "Purchases",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/purchases/create",
    name: "CreatePurchase",
    component: CreatePurchase,
    meta: {
      title: "Create Purchase",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/tickets/:id",
    name: "TicketsDetail",
    component: TicketsDetail,
    meta: {
      title: "TicketsDetail",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare,
    meta: {
      title: "Compare",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/pages",
    name: "Page",
    component: Page,
    meta: {
      title: "Page",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: "/setting",
    name: "Footer",
    component: Footer,
    meta: {
      title: "Footer",
      layout: LayoutDefault,
      adminAuth: true,
    },
  },
  {
    path: '/reports',
    component: () => import("../layouts/LayoutBlank"),
    meta: {
      title: "",
      layout: LayoutDefault,
      adminAuth: true,
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "reports" */'../views/reports/Index') },
      { path: 'product', component: () => import(/* webpackChunkName: "reports" */'../views/reports/Product') },
      {
        path: 'client',
        component: () => import(/* webpackChunkName: "reports" */'../views/reports/Client'),
        children: [
          { path: 'report', component: () => import('../views/reports/client-tabs/Report') },
          { path: 'payment', component: () => import('../views/reports/client-tabs/Payment') },
          { path: 'quotation', component: () => import('../views/reports/client-tabs/Quotation') },
          { path: 'return', component: () => import('../views/reports/client-tabs/Return') },
        ]
      },
      {
        path: 'warehouse',
        component: () => import(/* webpackChunkName: "reports" */'../views/reports/Warehouse'),
        children: [
          {
            path: 'sales',
            name: 'tabReportSales',
            component: () => import('@/views/reports/warehouse-tabs/Sales')
          },
          {
            path: 'quotation',
            name: 'tabReportQuotation',
            component: () => import('@/views/reports/warehouse-tabs/Quotation')
          },
          {
            path: 'return',
            name: 'tabReportReturn',
            props: true,
            component: () => import('@/views/reports/warehouse-tabs/Return')
          },
          {
            path: 'expanse',
            name: 'tabReportExpanse',
            component: () => import('@/views/reports/warehouse-tabs/Expanse')
          },
        ]
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.adminAuth)) {
    if (localStorage.getItem("access_token") === null) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
