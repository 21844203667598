<template>
  <div class="points">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Points</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddPointModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Points
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-point" id="datatable">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Amount</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(point, i) in getPoints" :key="point.id">
            <td>
              <router-link :to="`/clients/${point.user.id}`">
                {{ point.user.fullname }}
              </router-link>
            </td>
            <td>${{ point.amount }}</td>
            <td>{{ point.notes ?? '-' }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editPoint(i)"
                      ><i class="icon-pencil"></i> Edit Points</a
                    >
                    <a href="#" class="dropdown-item" @click="deletePoint(point.id)"
                      ><i class="icon-trash"></i> Delete Points</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Point modal -->
    <div id="AddPoints" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Point</h5>
            <h5 class="modal-title" v-else>Edit Point</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-8">
                    <label>Client</label>
                    <vue-select
                      v-if="getClients"
                      v-model="data.user_id"
                      :options="getClients"
                      placeholder="Please Select"
                      label-by="fullname"
                      value-by="id"
                      close-on-select
                      style="width: 100% !important"
                      searchable
                    />
                    <span v-if="errors.user_id" class="form-text text-danger">{{ errors.user_id[0] }}</span>
                  </div>
                  <div class="col-md-4">
                    <label>Amount</label>
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      class="form-control"
                      v-model="data.amount"
                    />
                    <span v-if="errors.amount" class="form-text text-danger">{{ errors.amount[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label>Notes</label>
                  <input
                    type="text"
                    placeholder="Enter Notes"
                    class="form-control"
                    v-model="data.notes"
                  />
                  <span v-if="errors.notes" class="form-text text-danger">{{ errors.notes[0] }}</span>
                </div>
              </div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addPoint() : editPointSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Point modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import VueNextSelect from "vue-next-select";
import util from '@/helpers/util.js'

export default {
  name: "Points",
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      countProduct: 1,
      id: null,
      data: {
        user_id: null,
        amount: null,
        notes: null,
      }
    }
  },
  created() {
    this.fetchPoints();
    this.fetchClients();
  },
  computed: {
    ...mapGetters({
      getClientInvoices: "invoices/getClientInvoices",
      getPoints: "points/getPoints",
      getClients: "clients/getClients",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-point')) {
      $(".table-point").DataTable({
        items: [{ subitems: []}],
        autoWidth: false,
        columnDefs: [
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      invoiceByClientId: "invoices/invoiceByClientId",
      fetchClients: "clients/fetchClients",
      fetchPoints: "points/fetchPoints",
      createPoint: "points/createPoint",
      updatePoint: "points/updatePoint",
      deletePointById: "points/deletePointById",
    }),
    openAddPointModal() {
      this.modal = 0;
      this.id = null;
      this.data.user_id = null;
      this.data.amount = null;
      this.data.notes = null;
      $('#AddPoints').modal('show');
    },
    handleImage(event) {
      this.imageName = event.target.files[0].name
      console.log()
      this.image = event.target.files;
    },
    addPoint() {
      this.createPoint({ data: this.data }).then(() => {
        util.showToast('Point successfully added');
        this.fetchPoints();
        $('#AddPoints').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    async editPoint(i) {
      this.modal = 1;
      let val = this.getPoints[i];
      await this.invoiceByClientId({ id: val.user.id });
      this.data.transaction = this.getClientInvoices.find(value => value.id == val.id).transactions;

      this.id = val.id
      this.data.user_id = val.user_id
      this.data.amount = val.amount
      this.data.notes = val.notes
      $('#AddPoints').modal('show');
    },
    editPointSubmit() {
      let data = this.data;
      this.updatePoint({ id: this.id, data }).then(() => {
        util.showToast('Point successfully changed');
        this.fetchPoints();
        $('#AddPoints').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deletePoint(id) {
      const action = () =>  {
        this.deletePointById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchPoints()
    },
    getImageUrl(image) {
      return process.env.VUE_APP_IMAGE_URL + image;
    },
  },
};
</script>
