import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  trade_accounts: [],
  trade_account: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getTradeAccounts: (state) => {
    return state.trade_accounts;
  },
  getTradeAccount: (state) => {
    return state.trade_account;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_TRADE_ACCOUNTS: (state, data) => (state.trade_accounts = data),
  SET_TRADE_ACCOUNT: (state, data) => (state.trade_account = data),
};

const actions = {
  async fetchTradeAccounts({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`trade-accounts`);
      commit("SET_TRADE_ACCOUNTS", response.data.trade_accounts);
      commit("SET_LOADER", false);
      return response.data;
    } catch (error) {
      console.log(error);
      commit("SET_TRADE_ACCOUNTS", {});
    }
  },
  async createTradeAccounts({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("trade-accounts", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateTradeAccounts({ commit }, { id, data }) {
    let response = await ApiService.put(`trade-accounts/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async updateTradeAccountStatus({ commit }, { id, status }) {
    console.log(status)
    let response = await ApiService.put(`trade-accounts/status/${id}`, { status: status });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteTradeAccountById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`trade-accounts/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
