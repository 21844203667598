import Swal from "sweetalert2";
import { createToast } from 'mosha-vue-toastify';
import moment from "moment";

const util = {
  showToast: (message, type) => {
    createToast({
      title: type == 'error' ? 'Failed!' : 'Success!',
      description: message
    },
    {
      position: 'top-right',
      type: type == 'error' ? 'danger' : 'success',
      transition: 'bounce',
      hideProgressBar: 'false',
      showIcon: 'true',
      swipeClose: 'true',
    })
  },
  deleteConfirmationToast: (action) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        action();
        Swal.fire(
          'Deleted!',
          'Successfully deleted!',
          'success'
        )
      }else{
        return;
      }
    })
  },
  replaceUnderscore: (string) => {
    var i, frags = string.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  },
  formatDateInput: (date) => {
    return moment(date).format('yyyy-MM-DD');
  },
  paymentMethod: (method) => {
    var methods = [];
    methods['CC'] = 'Credit Card';
    methods['PAYPAL'] = 'Paypal';
    methods['Bank'] = 'Bank Transfer';
    methods['BANK'] = 'Bank Transfer';
    methods['Manual'] = 'Cash';
    methods['Cash'] = 'Cash';
    methods['Eftpos'] = 'Eftpos';
    return methods[method] ?? null;
  },
}

export default util;