<template>
  <!-- Page header -->
  <div class="page-header page-header-dark noprint">
    <div class="page-header-content header-elements-md-inline">
      <div class="page-title d-flex">
        <h4>
          <i class="icon-arrow-left52 mr-2"></i>
          <span class="font-weight-semibold">ECommerce</span> - Customers
        </h4>
        <a href="#" class="header-elements-toggle text-default d-md-none"
          ><i class="icon-more"></i
        ></a>
      </div>

      <!-- <div class="header-elements d-none">
        <div class="d-flex justify-content-center">
          <a
            href="#"
            class="btn btn-link btn-float font-size-sm font-weight-semibold text-default"
          >
            <i class="icon-bars-alt text-pink-300"></i>
            <span>Statistics</span>
          </a>
          <a
            href="#"
            class="btn btn-link btn-float font-size-sm font-weight-semibold text-default"
          >
            <i class="icon-calculator text-pink-300"></i>
            <span>Invoices</span>
          </a>
          <a
            href="#"
            class="btn btn-link btn-float font-size-sm font-weight-semibold text-default"
          >
            <i class="icon-calendar5 text-pink-300"></i>
            <span>Schedule</span>
          </a>
        </div>
      </div> -->
    </div>

    <div
      class="breadcrumb-line breadcrumb-line-dark header-elements-md-inline"
    >
      <div class="d-flex">
        <div class="breadcrumb">
          <a href="index.html" class="breadcrumb-item"
            ><i class="icon-home2 mr-2"></i> Home</a
          >
          <a href="ecommerce_orders_history.html" class="breadcrumb-item"
            >ECommerce</a
          >
          <span class="breadcrumb-item active">Customers</span>
        </div>

        <a href="#" class="header-elements-toggle text-default d-md-none"
          ><i class="icon-more"></i
        ></a>
      </div>

      <!-- <div class="header-elements d-none">
        <div class="breadcrumb justify-content-center">
          <a href="#" class="breadcrumb-elements-item">
            <i class="icon-comment-discussion mr-2"></i>
            Support
          </a>

          <div class="breadcrumb-elements-item dropdown p-0">
            <a
              href="#"
              class="breadcrumb-elements-item dropdown-toggle"
              data-toggle="dropdown"
            >
              <i class="icon-gear mr-2"></i>
              Settings
            </a>

            <div class="dropdown-menu dropdown-menu-right">
              <a href="#" class="dropdown-item"
                ><i class="icon-user-lock"></i> Account security</a
              >
              <a href="#" class="dropdown-item"
                ><i class="icon-statistics"></i> Analytics</a
              >
              <a href="#" class="dropdown-item"
                ><i class="icon-accessibility"></i> Accessibility</a
              >
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item"
                ><i class="icon-gear"></i> All settings</a
              >
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- /page header -->
</template>
<script>
export default {
  name: "TheHeader",
};
</script>
