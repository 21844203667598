const state = {
  warehouseId: ''
}

const mutations = {
  setWarehouseId (state, payload) {
    state.warehouseId = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}