import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  categories: [],
  subcategories: [],
  subcategory: {},
  subcategory_children: [],
  // subcategory_children: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getCategories: (state) => {
    return state.categories;
  },
  getCategory: (state) => {
    return state.category;
  },
  getSubCategories: (state) => {
    return state.subcategories;
  },
  getSubCategoryChildren: (state) => {
    return state.subcategory_children;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_SUBCATEGORIES: (state, data) => (state.subcategories = data),
  SET_SUBCATEGORY: (state, data) => (state.category = data),
  SET_SUBCATEGORIES_CHILDREN: (state, data) => (state.subcategory_children = data),
  SET_SUBCATEGORY_CHILDREN: (state, data) => (state.category_children = data),
};

const actions = {
  async fetchSubcategories({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`subcategories`);
      commit("SET_SUBCATEGORIES", response.data.subcategories);
    } catch (error) {
      console.log(error);
      commit("SET_SUBCATEGORIES", {});
    }
    commit("SET_LOADER", false);
  },
  async fetchSubCategoriesById({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`categories/get-subcategory/${id}`);
    commit("SET_SUBCATEGORIES", response.data.subcategories);
    commit("SET_LOADER", false);
    return response.data
  },
  async fetchSubCategoryChildren({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`categories/children?id=${id}`);
    commit("SET_SUBCATEGORIES_CHILDREN", response.data.subcategories);
    commit("SET_LOADER", false);
    return response.data
  },
  async createSubCategory({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("subcategories/create", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async createSubCategoryChildren({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("subcategories/children", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateSubCategory({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`subcategories/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteSubCategoryById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`subcategories/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
