<template>
  <div class="Invoice-detail">
    <div class="card">
      <div class="card-header header-elements-inline border-bottom">
        <h6 class="card-title">Credit Notes Details</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Issue Coupon
          </button>
        </div>
      </div>
      <div class="container mt-2">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-6">
                <div class="d-flex justify-content-between my-2">
                  <span>Credit Notes ID</span>
                  <span>#123098</span>
                </div>
                <div class="d-flex justify-content-between my-2">
                  <span>Invoice ID</span>
                  <span>Gudang 1</span>
                </div>
                <div class="d-flex justify-content-between my-2">
                  <span>Customer Name</span>
                  <span>Rival Wijokumoro</span>
                </div>
                <div class="d-flex justify-content-between my-2">
                  <span>Customer Phone Number</span>
                  <span>081260810865</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-between my-2">
                  <span>Remaining Balance</span>
                  <span>$0</span>
                </div>
                <div class="d-flex justify-content-between my-2">
                  <span>Status Order</span>
                  <span class="badge badge-danger">Not Yet Received</span>
                </div>
                <div class="d-flex justify-content-between my-2">
                  <span>Address</span>
                  <span>Lorem ipsum dolor sit amet.</span>
                </div>
                <div class="d-flex justify-content-between my-2">
                  <span>Last Return</span>
                  <span>20 September 2021</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Returned</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Used</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class="container">
            <div class="row">
              <div class="col">
                <table class="table table-stock" id="datatables">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Invoice Number</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Serial Number</th>
                      <th scope="col">Supplier</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>#INV123098</td>
                      <td>Bosch Blue Line G2 Pet Friendly PIR</td>
                      <td>123908</td>
                      <td>Seadan PTY LTD</td>
                      <td><span class="badge badge-success">Returned</span></td>
                      <td class="text-right">
                        <div class="list-icons">
                          <div class="dropdown">
                            <a href="#" class="list-icons-item" data-toggle="dropdown">
                              <i class="icon-menu7"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a href="#" class="dropdown-item"
                                ><i class="icon-envelope"></i> Send Email</a
                              >
                              <a
                                href="#" class="dropdown-item"
                                data-toggle="modal"
                                data-target="#invoice"
                                ><i class="icon-printer"></i> Print</a
                              >
                              <a href="#" class="dropdown-item"
                                ><i class="icon-cube2"></i> Shipping details</a
                              >
                              <a href="#" class="dropdown-item" @click="openBillingDetailsModal()"
                                ><i class="icon-credit-card"></i> Billing details</a
                              >
                              <div class="dropdown-divider"></div>
                              <a href="#" class="dropdown-item" @click="editPurchase()"
                                ><i class="icon-pencil"></i> Edit Purchase</a
                              >
                              <a href="#" class="dropdown-item" @click="deletePurchase()"
                                ><i class="icon-trash"></i> Delete Purchase</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>#INV123098</td>
                      <td>Bosch Blue Line G1 Pet Friendly</td>
                      <td>299888</td>
                      <td>Seadan PTY LTD</td>
                      <td><span class="badge badge-danger">Waiting</span></td>
                      <td class="text-right">
                        <div class="list-icons">
                          <div class="dropdown">
                            <a href="#" class="list-icons-item" data-toggle="dropdown">
                              <i class="icon-menu7"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a href="#" class="dropdown-item"
                                ><i class="icon-envelope"></i> Send Email</a
                              >
                              <a
                                href="#" class="dropdown-item"
                                data-toggle="modal"
                                data-target="#invoice"
                                ><i class="icon-printer"></i> Print</a
                              >
                              <a href="#" class="dropdown-item"
                                ><i class="icon-cube2"></i> Shipping details</a
                              >
                              <a href="#" class="dropdown-item" @click="openBillingDetailsModal()"
                                ><i class="icon-credit-card"></i> Billing details</a
                              >
                              <div class="dropdown-divider"></div>
                              <a href="#" class="dropdown-item" @click="editPurchase()"
                                ><i class="icon-pencil"></i> Edit Purchase</a
                              >
                              <a href="#" class="dropdown-item" @click="deletePurchase()"
                                ><i class="icon-trash"></i> Delete Purchase</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>#INV123098</td>
                      <td>Bosch Blue Line K4</td>
                      <td>998242</td>
                      <td>Seadan PTY LTD</td>
                      <td><span class="badge badge-success">Returned</span></td>
                      <td class="text-right">
                        <div class="list-icons">
                          <div class="dropdown">
                            <a href="#" class="list-icons-item" data-toggle="dropdown">
                              <i class="icon-menu7"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a href="#" class="dropdown-item"
                                ><i class="icon-envelope"></i> Send Email</a
                              >
                              <a
                                href="#" class="dropdown-item"
                                data-toggle="modal"
                                data-target="#invoice"
                                ><i class="icon-printer"></i> Print</a
                              >
                              <a href="#" class="dropdown-item"
                                ><i class="icon-cube2"></i> Shipping details</a
                              >
                              <a href="#" class="dropdown-item" @click="openBillingDetailsModal()"
                                ><i class="icon-credit-card"></i> Billing details</a
                              >
                              <div class="dropdown-divider"></div>
                              <a href="#" class="dropdown-item" @click="editPurchase()"
                                ><i class="icon-pencil"></i> Edit Purchase</a
                              >
                              <a href="#" class="dropdown-item" @click="deletePurchase()"
                                ><i class="icon-trash"></i> Delete Purchase</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div class="container">
            <div class="row">
              <div class="col">
                <table class="table table-stock" id="datatables">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Invoice Number</th>
                      <th scope="col">Date</th>
                      <th scope="col">Invoice Amount</th>
                      <th scope="col">Available Amount</th>
                      <th scope="col">Used Balance</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>#INV123098</td>
                      <td>30 September 2021</td>
                      <td>$40</td>
                      <td>$10</td>
                      <td>$10</td>
                      <td><span class="badge badge-info">Used</span></td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>#INV123098</td>
                      <td>30 September 2021</td>
                      <td>$40</td>
                      <td>$50</td>
                      <td>$40</td>
                      <td><span class="badge badge-info">Used</span></td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>#INV123098</td>
                      <td>30 September 2021</td>
                      <td>$50</td>
                      <td>$100</td>
                      <td>$50</td>
                      <td><span class="badge badge-info">Used</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Anytime from "@/assets/js/plugins/pickers/anytime.min.js";
import Summernote from "@/assets/js/plugins/editors/summernote/summernote.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'

export default {
  data() {
    return {
      errors: [],
      data: {
        id: null,
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        phone: null,
        phone_2: null,
        address: null,
        password: null,
        role_id: null,
        permission_id: [],
        statut: null,
      },
    };
  },
  created() {
    this.invoiceById({ id: this.$route.params.id });
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-stock')) {
      $(".table-stock").DataTable({
        autoWidth: true,
        columnDefs: [
          {
            targets: 0,
            width: 40,
          },
          {
            orderable: false,
            width: 16,
            targets: 7,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [
          {
            extend: "pdfHtml5",
            text: '<i class="icon-file-excel"></i>',
            className: "btn bg-light",
            orientation: "landscape",
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5],
              stripHtml: true,
            },
            customize: function(doc) {
              doc.content[1].table.widths = Array(
                doc.content[1].table.body[0].length + 1
              )
                .join("*")
                .split("");
            },
          },
        ],
      });
    }
  },
  mounted() {
    Anytime;
    Summernote;
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".select-role").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".select-status").select2({
      minimumResultsForSearch: Infinity,
    });
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    if (!$("#begin_time")) {
      $("#begin_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    if (!$("#begin_time")) {
      $("#end_time").AnyTime_picker({
        format: "%H:%i",
      });
    }
    $(".summernote").summernote();
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  computed: {
    ...mapGetters({
      getInvoiceDetail: "invoices/getInvoice",
    }),
  },
  methods: {
    ...mapActions({
      invoiceById: "invoices/invoiceById",
    }),
    selectedPermissions(id) {
      this.data.permission_id.includes(id) ? this.data.permission_id = this.data.permission_id.filter((x) => x != id) : this.data.permission_id.push(id)
    },
    updateInvoice() {
      this.editInvoice({id: this.$route.params.id, data: this.data }).then(() => {
        util.showToast('Invoice successfully changed');
        $('#EditInvoice').modal('hide');
        this.fetchInvoice()
      }).catch(err => {
        this.errors = err.response?.data.errors;
      })
    },
    async deleteInvoice(id) {
      const action = () => {
        this.deleteInvoiceById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchInvoice()
    },
    async exportTimesheet() {
      await ApiService.get("/Invoice/exportExcel", {
        'headers': {
          'Content-Disposition': "attachment; filename=TimeSheets.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TimeSheets.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
