import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  best_sellers: [],
  best_seller: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getBestSellers: (state) => {
    return state.best_sellers;
  },
  getBestSeller: (state) => {
    return state.best_seller;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_BEST_SELLERS: (state, data) => (state.best_sellers = data),
  SET_BEST_SELLER: (state, data) => (state.best_seller = data),
};

const actions = {
  async fetchBestSellers({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`best-sellers`);
      commit("SET_BEST_SELLERS", response.data.best_sellers);
    } catch (error) {
      console.log(error);
      commit("SET_BEST_SELLERS", {});
    }
    commit("SET_LOADER", false);
  },
  async createBestSellers({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("best-sellers", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateBestSellers({ commit }, { id, data }) {
    let response = await ApiService.put(`best-sellers/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteBestSellersById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`best-sellers/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
