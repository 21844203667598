import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  warehouses: {
    warehouses: [],
  },
  warehouse: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getWarehouses: (state) => {
    return state.warehouses;
  },
  getWarehouse: (state) => {
    return state.warehouse;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_WAREHOUSES: (state, data) => (state.warehouses = data),
  SET_WAREHOUSE: (state, data) => (state.warehouse = data),
};

const actions = {
  async fetchWarehouses({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/warehouses?limit=10000&search&SortType=desc&SortField=id"
      );
      commit("SET_WAREHOUSES", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_WAREHOUSES", {});
    }
    commit("SET_LOADER", false);
  },
  async AddWarehouse({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("/warehouses", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateWarehouse({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.patch("/warehouses/" + id, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteWarehouseById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/warehouses/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
