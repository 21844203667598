import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  banners: [],
  banner: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getBanners: (state) => {
    return state.banners;
  },
  getBanner: (state) => {
    return state.banner;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_BANNERS: (state, data) => (state.banners = data),
  SET_BANNER: (state, data) => (state.banner = data),
};

const actions = {
  async fetchBanners({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`banners`);
      commit("SET_BANNERS", response.data.banners);
    } catch (error) {
      console.log(error);
      commit("SET_BANNERS", {});
    }
    commit("SET_LOADER", false);
  },
  async createBanners({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("banners", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateBanners({ commit }, { id, data }) {
    let response = await ApiService.put(`banners/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteBannersById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`banners/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
