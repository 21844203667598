import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  units: [],
  unit: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getUnits: (state) => {
    return state.units;
  },
  getUnit: (state) => {
    return state.unit;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_UNITS: (state, data) => (state.units = data),
  SET_UNIT: (state, data) => (state.unit = data),
};

const actions = {
  async fetchUnits({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(
        "/units/list?limit=10&search&SortType=desc&SortField=id"
      );
      commit("SET_UNITS", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_UNITS", {});
    }
    commit("SET_LOADER", false);
  },
  async AddUnit({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("/units/create", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateUnit({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.post("/units/update/" + id, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteUnitById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/units/delete/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
