import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  clients: [],
  client: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getClients: (state) => {
    return state.clients;
  },
  getClient: (state) => {
    return state.client;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_CLIENTS: (state, data) => (state.clients = data),
  SET_CLIENT: (state, data) => (state.client = data),
};

const actions = {
  async fetchClients({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("users");
      commit("SET_CLIENTS", response.data.users);
    } catch (error) {
      console.log(error);
      commit("SET_CLIENTS", {});
    }
    commit("SET_LOADER", false);
  },

  async clientById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`/clients/${id}`);
      console.log(response.data)
      commit("SET_CLIENT", response.data.user);
    } catch (error) {
      console.log(error);
      commit("SET_CLIENT", {});
    }
    commit("SET_LOADER", false);
  },
  async AddClient({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.post("clients", { ...data });
    commit("SET_LOADER", false);
    console.log(response)
    return response.data;
  },
  async updateClient({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.put(`clients/${id}`, { ...data });
    commit("SET_LOADER", false);
    return response.data;
  },
  async exportClients() {
    await ApiService.get("/clients/export/Excel", {
      'headers': {
        'Content-Disposition': "attachment; filename=Users.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'arraybuffer',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Staff.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },
  async deleteClientById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/clients/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
  async deleteClientBySelectedIds({ commit }, { selectedIds }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.post("/clients/selected", {
        selectedIds
      });
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
