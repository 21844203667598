<template>
  <div class="clients">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Trade Accounts</h6>
        <div>
          <button type="button" class="btn btn-danger" data-toggle="button" v-if="this.selectedIds.length > 0" @click="deleteClientBySelectedIds()">
            Delete Selected
          </button>
          <button
            @click="exportClients"
            type="button"
            class="btn btn-transparent btn-sm"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddTradeAccountModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Trade Account
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-trade-accounts" id="datatable">
        <thead>
          <tr>
            <!-- <th>#</th> -->
            <th>Profile</th>
            <th>Company Name</th>
            <th>Trading Name</th>
            <th>ABN</th>
            <th>Address</th>
            <th>Status</th>
            <th>Approved Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(client, index) in getTradeAccounts" :key="index">
            <!-- <td>
              <input type="checkbox" class="form-input-styled" @click="addSelectedClient(client.id)">
            </td> -->
            <td>
              <div
                class="media d-flex justify-content-center align-items-center"
              >
                <div class="mr-3">
                  <router-link :to="`/clients/${client.id}`">
                    <img
                      src="@/assets/images/placeholders/placeholder.jpg"
                      width="40"
                      height="40"
                      class="rounded-circle"
                      alt=""
                    />
                  </router-link>
                </div>

                <div class="media-body align-self-center">
                  <router-link
                    :to="`/clients/${client.id}`"
                    class="font-weight-semibold"
                    style="text-transform: capitalize;"
                    >{{ client.user.fullname }}</router-link
                  >
                  <div class="text-muted font-size-sm">
                    Email : {{ client.user.email }}
                  </div>
                  <div class="text-muted font-size-sm">
                    Phone: {{ client.user.phone || '-' }}
                  </div>
                </div>
              </div>
            </td>
            <td>{{ client.company_name }}</td>
            <td>{{ client.trading_name }}</td>
            <td>{{ client.abn }}</td>
            <td>{{ client.billing_address }}</td>
            <td>{{ client.status }}</td>
            <td>{{ client.approved_date }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editClient(index)"
                      ><i class="icon-pencil"></i> Edit Trade Account</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteTradeAccount(client.id)"
                      ><i class="icon-trash"></i> Delete Trade Account</a
                    >
                    <a href="#" class="dropdown-item" v-if="client.status != 'APPROVED'" @click="updateStatus(client.id, 'APPROVED')"
                      ><i class="icon-trash"></i> Approve</a
                    >
                    <a href="#" class="dropdown-item" v-if="client.status != 'REJECTED'" @click="updateStatus(client.id, 'REJECTED')"
                      ><i class="icon-trash"></i> Reject</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Client modal -->
    <div id="AddClient" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Trade Account</h5>
            <h5 class="modal-title" v-else>Edit Trade Account</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="custom-control custom-radio custom-control-inline mb-2">
              <input type="radio" id="customRadioInline2" name="customRadioInline" class="custom-control-input" :value="false" v-model="data.isNew" :checked="!data.isNew">
              <label class="custom-control-label" for="customRadioInline2">From Existing Client</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline mb-2">
              <input type="radio" id="customRadioInline1" name="customRadioInline" class="custom-control-input" :value="true" v-model="data.isNew" :checked="data.isNew">
              <label class="custom-control-label" for="customRadioInline1">Add New Client</label>
            </div>
            <div v-if="data.isNew">
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter client name"
                      class="form-control"
                      v-model="data.client.fullname"
                    />
                    <span v-if="errors.fullname" class="form-text text-danger">{{ errors.fullname[0] }}</span>
                  </div>
                  <div class="col-sm-4">
                    <label>Gender</label>
                    <vue-select
                      v-model="data.client.gender"
                      :options="[{name: 'MALE'}, {name: 'FEMALE'}]"
                      placeholder="Please Select"
                      label-by="name"
                      value-by="name"
                      close-on-select
                      style="width: 100% !important"
                      searchable
                    />
                    <span v-if="errors.gender" class="form-text text-danger">{{ errors.gender[0] }}</span>
                  </div>
                  <div class="col-sm-4">
                    <label>Birth Date</label>
                    <input
                      type="date"
                      placeholder="Enter client birth date"
                      class="form-control"
                      v-model="data.client.birth_date"
                    />
                    <span v-if="errors.birth_date" class="form-text text-danger">{{ errors.birth_date[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter client email"
                      class="form-control"
                      v-model="data.client.email"
                    />
                    <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                  </div>
                  <div class="col-sm-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      placeholder="Enter client phone"
                      class="form-control"
                      v-model="data.client.phone"
                    />
                    <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                  </div>
                  <div class="col-sm-4">
                    <label>Password</label>
                    <input
                      type="text"
                      placeholder="Enter client password"
                      class="form-control"
                      v-model="data.client.password"
                    />
                    <span v-if="errors.password" class="form-text text-danger">{{ errors.password[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3">
                    <label>Country</label>
                    <input
                      type="text"
                      placeholder="Enter client country"
                      class="form-control"
                      v-model="data.client.country"
                    />
                    <span v-if="errors.country" class="form-text text-danger">{{ errors.country[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Suburb</label>
                    <input
                      type="text"
                      placeholder="Enter client suburb"
                      class="form-control"
                      v-model="data.client.city"
                    />
                    <span v-if="errors.city" class="form-text text-danger">{{ errors.city[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>State</label>
                    <input
                      type="text"
                      placeholder="Enter client state"
                      class="form-control"
                      v-model="data.client.state"
                    />
                    <span v-if="errors.state" class="form-text text-danger">{{ errors.state[0] }}</span>
                  </div>
                  <div class="col-sm-3">
                    <label>Postcode</label>
                    <input
                      type="text"
                      placeholder="Enter client postcode"
                      class="form-control"
                      v-model="data.client.postal_code"
                    />
                    <span v-if="errors.postal_code" class="form-text text-danger">{{ errors.postal_code[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-12">
                    <label>Address</label>
                    <input
                      type="text"
                      placeholder="Enter client address"
                      class="form-control"
                      v-model="data.client.address"
                    />
                    <span v-if="errors.address" class="form-text text-danger">{{ errors.address[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <vue-select
                v-if="getClients"
                v-model="data.user_id"
                :options="getClients"
                placeholder="Please Select"
                label-by="fullname"
                value-by="id"
                close-on-select
                style="width: 100% !important"
                class="mb-2"
                searchable
              />
            </div>
            <div class="card">
              <div class="card-body">
                <h3>Company Profile</h3>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Company Name</label>
                      <input
                        type="text"
                        placeholder="Enter company name"
                        class="form-control"
                        v-model="data.company_name"
                      />
                      <span v-if="errors.company_name" class="form-text text-danger">{{ errors.company_name[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Trading Name</label>
                      <input
                        type="text"
                        placeholder="Enter trading name"
                        class="form-control"
                        v-model="data.trading_name"
                      />
                      <span v-if="errors.trading_name" class="form-text text-danger">{{ errors.trading_name[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>ABN</label>
                      <input
                        type="text"
                        placeholder="Enter ABN"
                        class="form-control"
                        v-model="data.abn"
                      />
                      <span v-if="errors.abn" class="form-text text-danger">{{ errors.abn[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Suburb</label>
                      <input
                        type="text"
                        placeholder="Enter company suburb"
                        class="form-control"
                        v-model="data.city"
                      />
                      <span v-if="errors.city" class="form-text text-danger">{{ errors.city[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="Enter company state"
                        class="form-control"
                        v-model="data.state"
                      />
                      <span v-if="errors.state" class="form-text text-danger">{{ errors.state[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Postcode</label>
                      <input
                        type="text"
                        placeholder="Enter company postcode"
                        class="form-control"
                        v-model="data.postal_code"
                      />
                      <span v-if="errors.postal_code" class="form-text text-danger">{{ errors.postal_code[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Enter company address"
                        class="form-control"
                        v-model="data.address"
                      />
                      <span v-if="errors.address" class="form-text text-danger">{{ errors.address[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="custom-control custom-radio custom-control-inline mb-2">
                  <input type="radio" id="addressOption1" name="addressOption" class="custom-control-input" :value="true" v-model="data.is_same_address" :checked="data.is_same_address">
                  <label class="custom-control-label" for="addressOption1">Use Delivery Address for Billing Address</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline mb-2">
                  <input type="radio" id="addressOption2" name="addressOption" class="custom-control-input" :value="false" v-model="data.is_same_address" :checked="!data.is_same_address">
                  <label class="custom-control-label" for="addressOption2">Use Different Address for Billing Address</label>
                </div>
                <div v-if="!data.is_same_address">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-4">
                        <label>Billing Suburb</label>
                        <input
                          type="text"
                          placeholder="Enter company suburb"
                          class="form-control"
                          v-model="data.billing_city"
                        />
                        <span v-if="errors.billing_city" class="form-text text-danger">{{ errors.billing_city[0] }}</span>
                      </div>
                      <div class="col-sm-4">
                        <label>Billing State</label>
                        <input
                          type="text"
                          placeholder="Enter company state"
                          class="form-control"
                          v-model="data.billing_state"
                        />
                        <span v-if="errors.billing_state" class="form-text text-danger">{{ errors.billing_state[0] }}</span>
                      </div>
                      <div class="col-sm-4">
                        <label>Billing Postcode</label>
                        <input
                          type="text"
                          placeholder="Enter company postcode"
                          class="form-control"
                          v-model="data.billing_post_code"
                        />
                        <span v-if="errors.billing_post_code" class="form-text text-danger">{{ errors.billing_post_code[0] }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <label>Billing Address</label>
                        <input
                          type="text"
                          placeholder="Enter company address"
                          class="form-control"
                          v-model="data.billing_address"
                        />
                        <span v-if="errors.billing_address" class="form-text text-danger">{{ errors.billing_address[0] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Date Established</label>
                      <input
                        type="date"
                        placeholder="Enter client birth date"
                        class="form-control"
                        v-model="data.date_established"
                      />
                      <span v-if="errors.date_established" class="form-text text-danger">{{ errors.date_established[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Website</label>
                      <input
                        type="text"
                        placeholder="Enter client name"
                        class="form-control"
                        v-model="data.website"
                      />
                      <span v-if="errors.website" class="form-text text-danger">{{ errors.website[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>Status</label>
                      <vue-select
                        v-model="data.status"
                        :options="[{name: 'APPROVED'}, {name: 'REJECTED'}, {name: 'WAITING'}]"
                        placeholder="Please Select"
                        label-by="name"
                        value-by="name"
                        close-on-select
                        style="width: 100% !important"
                        searchable
                      />
                      <span v-if="errors.status" class="form-text text-danger">{{ errors.status[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Company License</label>
                      <vue-select
                        v-model="data.company_license_type"
                        :options="[{name: 'Master License'}]"
                        placeholder="Please Select"
                        label-by="name"
                        value-by="name"
                        close-on-select
                        style="width: 100% !important"
                        searchable
                      />
                      <span v-if="errors.company_license_type" class="form-text text-danger">{{ errors.company_license_type[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>License Number</label>
                      <input
                        type="text"
                        placeholder="Enter license number"
                        class="form-control"
                        v-model="data.company_license_number"
                      />
                      <span v-if="errors.company_license_number" class="form-text text-danger">{{ errors.company_license_number[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>License State</label>
                      <input
                        type="text"
                        placeholder="Enter license state"
                        class="form-control"
                        v-model="data.company_license_state"
                      />
                      <span v-if="errors.company_license_state" class="form-text text-danger">{{ errors.company_license_state[0] }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <label>Personal License</label>
                      <vue-select
                        v-model="data.personal_license_type"
                        :options="[{name: 'Master License'}]"
                        placeholder="Please Select"
                        label-by="name"
                        value-by="name"
                        close-on-select
                        style="width: 100% !important"
                        searchable
                      />
                      <span v-if="errors.personal_license_type" class="form-text text-danger">{{ errors.personal_license_type[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>License Number</label>
                      <input
                        type="text"
                        placeholder="Enter license number"
                        class="form-control"
                        v-model="data.personal_license_number"
                      />
                      <span v-if="errors.personal_license_number" class="form-text text-danger">{{ errors.personal_license_number[0] }}</span>
                    </div>
                    <div class="col-sm-4">
                      <label>License State</label>
                      <input
                        type="text"
                        placeholder="Enter license state"
                        class="form-control"
                        v-model="data.personal_license_state"
                      />
                      <span v-if="errors.personal_license_state" class="form-text text-danger">{{ errors.personal_license_state[0] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addTradeAcount() : editClientSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Client modal -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import VueNextSelect from "vue-next-select";
import util from '@/helpers/util.js'

function initialState() {
  return {
    selectedIds: [],
    modal: 0,
    errors: [],
    data: {
      id: null,
      isNew: true,
      is_same_address: true,
      user_id: null,
      client: {
        fullname: null,
        email: null,
        phone: null,
        password: null,
        country: null,
        state: null,
        city: null,
        postal_code: null,
        address: null,
        gender: null,
        birth_date: null,
      },
      date: null,
      company_name: null,
      trading_name: null,
      abn: null,
      city: null,
      state: null,
      postal_code: null,
      address: null,
      billing_city: null,
      billing_state: null,
      billing_post_code: null,
      billing_address: null,
      website: null,
      date_established: null,
      company_license_type: null,
      company_license_number: null,
      company_license_state: null,
      personal_license_type: null,
      personal_license_number: null,
      personal_license_state: null,
      status: null,
    }
  }
}

export default {
  name: "Clients",
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return initialState();
  },
  created() {
    this.fetchClients();
    this.fetchTradeAccounts().then(() => {
      this.initDatatables();
    });
  },
  computed: {
    ...mapGetters({
      getClients: "clients/getClients",
      getTradeAccounts: "tradeAccount/getTradeAccounts",
    }),
  },
  updated() {
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchTradeAccounts: "tradeAccount/fetchTradeAccounts",
      createTradeAccounts: "tradeAccount/createTradeAccounts",
      updateTradeAccounts: "tradeAccount/updateTradeAccounts",
      updateTradeAccountStatus: "tradeAccount/updateTradeAccountStatus",
      deleteTradeAccountById: "tradeAccount/deleteTradeAccountById",
    }),
    openAddTradeAccountModal() {
      this.modal = 0;
      initialState();
      $('#AddClient').modal('show');
    },
    addTradeAcount() {
      this.createTradeAccounts({ data: this.data }).then(res => {
        if (res.status) {
          util.showToast('Client successfully added');
          $(".table-trade-accounts").DataTable().destroy()
          this.fetchTradeAccounts()
          .then(() => {
            this.initDatatables();
          });
        }else{
          console.log(res)
          this.errors = res.errors;
        }
        $('#AddClient').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editClient(i) {
      this.modal = 1;
      let val = this.getTradeAccounts[i];
      this.data.id = val.id
      this.data.isNew = false,
      this.data.client.fullname = val.user.fullname
      this.data.client.gender = val.user.gender
      this.data.client.birth_date = val.user.birth_date
      this.data.client.phone = val.user.phone
      this.data.client.email = val.user.email
      this.data.client.country = val.user.addresses[0].country
      this.data.client.state = val.user.addresses[0].state
      this.data.client.postal_code = val.user.addresses[0].postal_code
      this.data.client.city = val.user.addresses[0].city
      this.data.client.address = val.user.addresses[0].street_line_1
      this.data.user_id = val.user_id
      this.data.date = val.date;
      this.data.company_name = val.company_name;
      this.data.trading_name = val.trading_name;
      this.data.abn = val.abn;
      this.data.city = val.city;
      this.data.state = val.state;
      this.data.postal_code = val.post_code;
      this.data.address = val.address;
      this.data.billing_city = val.billing_city;
      this.data.billing_state = val.billing_state;
      this.data.billing_post_code = val.billing_post_code;
      this.data.billing_address = val.billing_address;
      this.data.website = val.website;
      this.data.date_established = val.date_established;
      this.data.company_license_type = val.company_license_type;
      this.data.company_license_number = val.company_license_number;
      this.data.company_license_state = val.company_license_state;
      this.data.personal_license_type = val.personal_license_type;
      this.data.personal_license_number = val.personal_license_number;
      this.data.personal_license_state = val.personal_license_state;
      this.data.status = val.status;
      $('#AddClient').modal('show');
    },
    editClientSubmit() {
      let data = this.data;
      this.updateTradeAccounts({ id: this.data.id, data })
      .then(() => {
        util.showToast('Client successfully changed');
        $(".table-trade-accounts").DataTable().destroy()
        this.fetchTradeAccounts()
        .then(() => {
            this.initDatatables();
          });
        $('#AddClient').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    updateStatus(id, status) {
      this.updateTradeAccountStatus({ id, status })
      .then(() => {
        util.showToast('Status successfully changed');
        $(".table-trade-accounts").DataTable().destroy()
        this.fetchTradeAccounts()
        .then(() => {
            this.initDatatables();
          });
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteTradeAccount(id) {
      const action = () =>  {
        this.deleteTradeAccountById({id});
        this.fetchTradeAccounts()
        .then(() => {
          this.initDatatables();
        });
      }
      await util.deleteConfirmationToast(action)
      this.fetchTradeAccounts()
    },
    async deleteClientBySelectedIds() {
      const action = () =>  {
        this.deleteClientByIds({selectedIds: this.selectedIds})
      }
      await util.deleteConfirmationToast(action)
      this.fetchTradeAccounts()
    },
    async exportClients() {
      this.exportAllClients()
    },
    initDatatables() {
      if (!$.fn.DataTable.isDataTable( '.table-trade-accounts' )) {
        $(".table-trade-accounts").DataTable({
          autoWidth: false,
          columnDefs: [
            {
              orderable: false,
              targets: 0,
              width: 10,
            },
            {
              width: 16,
              targets: 6,
            },
            {
              className: "control",
              orderable: false,
              targets: -1,
            },
          ],
          order: [[0, "asc"]],
          dom:
            '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
          language: {
            search: "<span>Search people:</span> _INPUT_",
            searchPlaceholder: "Type to filter...",
            lengthMenu: "<span>Show:</span> _MENU_",
            paginate: {
              first: "First",
              last: "Last",
              next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
              previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
            },
          },
          lengthMenu: [25, 50, 75, 100],
          displayLength: 50,
          buttons: [
          ],
        });
      }
    },
  },
};
</script>
