import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  staff: [],
  staffDetail: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getStaff: (state) => {
    return state.staff;
  },
  getStaffDetail: (state) => {
    return state.staffDetail;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_STAFF: (state, data) => (state.staff = data),
  SET_STAFF_DETAIL: (state, data) => (state.staffDetail = data),
};

const actions = {
  async fetchStaff({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get("/staff");
      commit("SET_STAFF", response.data.users);
      commit("SET_LOADER", false);
      return response.data.users;
    } catch (error) {
      console.log(error);
      commit("SET_STAFF", {});
    }
  },
  async createStaff({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("staff", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async staffById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`/staff/${id}`);
      commit("SET_STAFF_DETAIL", response.data.data);
    } catch (error) {
      console.log(error);
      commit("SET_STAFF_DETAIL", {});
    }
    commit("SET_LOADER", false);
  },
  async editStaff({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    let response = await ApiService.put("/staff/" + id, { ...data });
    commit("SET_STAFF", response.data);
    commit("SET_LOADER", false);
    return response;
  },
  async editStaffStatus({ commit }, { id, data }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.put(`/staff/users/Activated/${id}?statut=${data}`);
      commit("SET_STAFF", response.data);
    } catch (error) {
      console.log(error);
      commit("SET_STAFF", {});
    }
    commit("SET_LOADER", false);
  },
  async deleteStaffById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete("/staff/" + id);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
