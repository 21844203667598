<template>
  <div class="credits">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Credit Notes</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddCreditModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Credit Notes
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-credit-notes" id="datatable">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Product</th>
            <th>Qty</th>
            <th>Total</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(credit, i) in getCredits" :key="credit.id">
            <td>
              <router-link :to="`/credit-notes/1`">
                {{ credit.user.fullname }}
              </router-link>
            </td>
            <td>{{ credit.product.title }}</td>
            <td>{{ credit.qty }} x ${{ credit.amount }}</td>
            <td>${{ credit.qty * credit.amount }}</td>
            <td>{{ credit.notes ?? '-' }}</td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editCredit(i)"
                      ><i class="icon-pencil"></i> Edit Credit Notes</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteCredit(credit.id)"
                      ><i class="icon-trash"></i> Delete Credit Notes</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Credit modal -->
    <div id="AddCredits" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Credit</h5>
            <h5 class="modal-title" v-else>Edit Credit</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Client</label>
                  <vue-select
                    v-if="getClients"
                    v-model="data.user_id"
                    :options="getClients"
                    placeholder="Please Select"
                    label-by="fullname"
                    value-by="id"
                    close-on-select
                    style="width: 100% !important"
                    searchable
                    @update:modelValue="(value) => onSelectedClient(value)"
                  />
                  <span v-if="errors.user_id" class="form-text text-danger">{{ errors.user_id[0] }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="data.from_tx">
                      <label class="custom-control-label" for="customSwitch1">Add From Invoice</label>
                    </div>
                  </div>
                  <div class="col" v-if="!data.from_tx">
                    <input
                      type="text"
                      placeholder="Enter Price"
                      class="form-control"
                      v-model="data.code"
                    />
                    <span v-if="errors.code" class="form-text text-danger">{{ errors.code[0] }}</span>
                  </div>
                </div>
              </div>
              <div v-if="data.from_tx">
                <div class="form-group">
                  <div class="row">
                    <label>Invoice Number</label>
                    <vue-select
                      v-if="getClientInvoices"
                      v-model="data.transaction_group_id"
                      :options="getClientInvoices"
                      placeholder="Please Select"
                      label-by="id"
                      value-by="id"
                      close-on-select
                      style="width: 100% !important"
                      searchable
                      @update:modelValue="(value) => onSelectedInvoice(value, index)"
                    />
                    <span v-if="errors.code" class="form-text text-danger">{{ errors.code[0] }}</span>
                  </div>
                </div>
                <div class="form-group" v-for="(val, i) in data.transaction" :key="i">
                  <div class="row">
                    <div class="col">
                      <label>Product {{data.products[i].selectedProduct}}</label>
                      <vue-select
                        v-if="getClientInvoices"
                        v-model="data.products[i].selectedProduct"
                        :options="data.transaction"
                        placeholder="Please Select"
                        label-by="product.title"
                        value-by="product.id"
                        close-on-select
                        style="width: 100% !important"
                        searchable
                        @update:modelValue="(value) => onSelectedProduct(value, i)"
                      />
                      <span v-if="errors.code" class="form-text text-danger">{{ errors.code[0] }}</span>
                    </div>
                    <div class="col">
                      <label>Qty</label>
                      <input
                        type="text"
                        placeholder="Enter Qty"
                        class="form-control"
                        v-model="data.products[i].qty"
                      />
                      <span v-if="errors.code" class="form-text text-danger">{{ errors.code[0] }}</span>
                    </div>
                    <div class="col">
                      <label>Price</label>
                      <input
                        type="text"
                        placeholder="Enter Price"
                        class="form-control"
                        v-model="data.products[i].amount"
                      />
                      <span v-if="errors.code" class="form-text text-danger">{{ errors.code[0] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label>Notes</label>
                  <input
                    type="text"
                    placeholder="Enter Notes"
                    class="form-control"
                    v-model="data.notes"
                  />
                  <span v-if="errors.notes" class="form-text text-danger">{{ errors.notes[0] }}</span>
                </div>
              </div>
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addCredit() : editCreditSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Credit modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import VueNextSelect from "vue-next-select";
import util from '@/helpers/util.js'

export default {
  name: "Credits",
  components: {
    "vue-select": VueNextSelect,
  },
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      countProduct: 1,
      id: null,
      data: {
        user_id: null,
        from_tx: true,
        transaction_group_id: null,
        transaction: [],
        products: [
          {
            selectedProduct: null,
            id: null,
            qty: null,
            amount: null,
          }
        ],
        notes: null,
      }
    }
  },
  created() {
    this.fetchCredits();
    this.fetchClients();
  },
  computed: {
    ...mapGetters({
      getClientInvoices: "invoices/getClientInvoices",
      getCredits: "credits/getCredits",
      getClients: "clients/getClients",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-credit-notes')) {
      $(".table-credit-notes").DataTable({
        items: [{ subitems: []}],
        autoWidth: false,
        columnDefs: [
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        responsive: {
          details: {
            type: "column",
            target: -1,
          },
        },
        buttons: [],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      invoiceByClientId: "invoices/invoiceByClientId",
      fetchClients: "clients/fetchClients",
      fetchCredits: "credits/fetchCredits",
      createCredit: "credits/createCredit",
      updateCredit: "credits/updateCredit",
      deleteCreditById: "credits/deleteCreditById",
    }),
    onSelectedClient(value) {
      this.invoiceByClientId({ id: value });
      this.data.transaction = this.getClientInvoices;
    },
    onSelectedInvoice(value) {
      this.data.transaction = this.getClientInvoices.find(val => val.id == value).transactions;
    },
    onSelectedProduct(val, index) {
      // if (this.data.product.includes(value.id)) {
      //   this.data.products = this.data.product.filter(item => item.product_id != value.id)
      // }
      console.log(this.getClientInvoices[index].transactions.find(value => value.product.id == val))
      let product = this.getClientInvoices[index].transactions.find(value => value.product.id == val);
      this.data.products[index].id = product.product_id
      this.data.products[index].qty = product.quantity
      this.data.products[index].amount = product.quantity * product.product.price
    },
    openAddCreditModal() {
      this.modal = 0;
      this.id = null;
      this.data.code = null;
      this.data.notes = null;
      $('#AddCredits').modal('show');
    },
    handleImage(event) {
      this.imageName = event.target.files[0].name
      console.log()
      this.image = event.target.files;
    },
    addCredit() {
      this.createCredit({ data: this.data }).then(() => {
        util.showToast('Credit successfully added');
        this.fetchCredits();
        $('#AddCredits').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    async editCredit(i) {
      this.modal = 1;
      let val = this.getCredits[i];
      await this.invoiceByClientId({ id: val.user.id });
      this.data.transaction = this.getClientInvoices.find(value => value.id == val.id).transactions;

      this.id = val.id
      this.data.user_id = val.user_id
      this.data.transaction_group_id = val.transaction_group_id
      this.data.products[0].id = val.product_id
      this.data.products[0].qty = val.qty
      this.data.products[0].amount = val.amount
      this.data.products[0].selectedProduct = val.product_id
      this.data.notes = val.notes
      $('#AddCredits').modal('show');
    },
    editCreditSubmit() {
      let data = this.data;
      this.updateCredit({ id: this.id, data }).then(() => {
        util.showToast('Credit successfully changed');
        this.fetchCredits();
        $('#AddCredits').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteCredit(id) {
      const action = () =>  {
        this.deleteCreditById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchCredits()
    },
    getImageUrl(image) {
      return process.env.VUE_APP_IMAGE_URL + image;
    },
  },
};
</script>
