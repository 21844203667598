<template>
  <div class="warehouses">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Warehouses</h6>
        <div>
          <button type="button" class="btn btn-danger" data-toggle="button" v-if="this.selectedIds.length > 0" @click="deleteWarehouseBySelectedIds()">
            Delete Selected
          </button>
          <button
            @click="exportWarehouses"
            type="button"
            class="btn btn-transparent btn-sm"
          >
            <i class="icon-file-pdf" style="color: red;"></i>
          </button>
          <button
            @click="exportWarehouses"
            type="button"
            class="btn btn-transparent btn-sm"
          >
            <i class="icon-file-excel" style="color: green;"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddWarehouseModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Warehouse
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-warehouses" id="datatable">
        <thead>
          <tr>
            <th class="text-center">Name</th>
            <th class="text-center">Phone</th>
            <th class="text-center">Email</th>
            <th class="text-center">Country</th>
            <th class="text-center">City</th>
            <th class="text-center">Post Code</th>
            <th class="text-center">Actions</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(warehouse, index) in getWarehouses.warehouses" :key="index">
            <td class="text-center">
              <router-link :to="`/warehouses/${warehouse.id}`">
                {{ warehouse.name }}
              </router-link>
            </td>
            <td class="text-center">{{ warehouse.mobile }}</td>
            <td class="text-center">
              <a :href="`mailto:${warehouse.email}`">{{ warehouse.email }}</a>
            </td>
            <td class="text-center">{{ warehouse.country }}</td>
            <td class="text-center">{{ warehouse.city }}</td>
            <td class="text-center">{{ warehouse.zip }}</td>
            <td class="text-center">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <div>
                      <a class="dropdown-item" @click="editWarehouse(index)"
                        ><i class="icon-pencil"></i> Edit Warehouse</a
                      >
                    </div>
                    <div>
                      <a class="dropdown-item" @click="deleteWarehouse(warehouse.id)"
                        ><i class="icon-trash"></i> Delete Warehouse</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="pl-0"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Warehouse modal -->
    <div id="AddWarehouse" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Warehouse</h5>
            <h5 class="modal-title" v-else>Edit Warehouse</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div class="modal-body py-0">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Warehouse Name</label>
                  <input
                    type="text"
                    placeholder="Enter warehouse name"
                    class="form-control"
                    v-model="data.name"
                  />
                  <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Phone</label>
                  <input
                    type="tel"
                    placeholder="Enter warehouse phone"
                    class="form-control"
                    v-model="data.mobile"
                  />
                  <span v-if="errors.phone" class="form-text text-danger">{{ errors.phone[0] }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter warehouse email"
                    class="form-control"
                    v-model="data.email"
                  />
                  <span v-if="errors.email" class="form-text text-danger">{{ errors.email[0] }}</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Country</label>
                  <input
                    type="text"
                    placeholder="Enter warehouse country"
                    class="form-control"
                    v-model="data.country"
                  />
                  <span v-if="errors.country" class="form-text text-danger">{{ errors.country[0] }}</span>
                </div>
                <div class="col-sm-4">
                  <label>City</label>
                  <input
                    type="text"
                    placeholder="Enter warehouse city"
                    class="form-control"
                    v-model="data.city"
                  />
                  <span v-if="errors.city" class="form-text text-danger">{{ errors.city[0] }}</span>
                </div>
                <div class="col-sm-4">
                  <label>Post Code</label>
                  <input
                    type="number"
                    placeholder="Enter warehouse post code"
                    class="form-control"
                    v-model="data.zip"
                  />
                  <span v-if="errors.zip" class="form-text text-danger">{{ errors.zip[0] }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addWarehouse() : editWarehouseSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Warehouse modal -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'

export default {
  name: "Warehouses",
  data() {
    return {
      errors: [],
      selectedIds: [],
      modal: 0,
      id: null,
      data: {
        name: null,
        mobile: null,
        email: null,
        country: null,
        city: null,
        zip: null,
        adresse: null,
      }
    }
  },
  created() {
    this.fetchWarehouses();
  },
  computed: {
    ...mapGetters({
      getWarehouses: "warehouses/getWarehouses",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable( '.table-warehouses' )) {
      $(".table-warehouses").DataTable({
        autoWidth: true,
        columnDefs: [
          {
            orderable: false,
            targets: 0,
            width: 10,
          },
          {
            width: 16,
            targets: 7,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        buttons: [
        ],
      });
    }

  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchWarehouses: "warehouses/fetchWarehouses",
      AddWarehouse: "warehouses/AddWarehouse",
      updateWarehouse: "warehouses/updateWarehouse",
      deleteWarehouseByIds: "warehouses/deleteWarehouseByIds",
      exportAllWarehouses: "warehouses/exportWarehouses",
    }),
    addSelectedWarehouse(id) {
      if (!this.selectedIds.includes(id)) {
        this.selectedIds.push(id);
      }else{
        this.selectedIds = this.selectedIds.filter(selectedId => selectedId != id);
      }
    },
    openAddWarehouseModal() {
      this.modal = 0;
      this.id = null;
      this.data.name = null;
      this.data.mobile = null;
      this.data.email = null;
      this.data.country = null;
      this.data.city = null;
      $('#AddWarehouse').modal('show');
    },
    addWarehouse() {
      this.AddWarehouse({ data: this.data }).then(() => {
        util.showToast('Warehouse successfully added');
        this.fetchWarehouses();
        $('#AddWarehouse').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editWarehouse(i) {
      this.modal = 1;
      console.log('tes')
      let val = this.getWarehouses.warehouses[i];
      this.id = val.id
      this.data.name = val.name
      this.data.mobile = val.mobile
      this.data.email = val.email
      this.data.country = val.country
      this.data.city = val.city
      this.data.zip = val.zip
      $('#AddWarehouse').modal('show');
    },
    editWarehouseSubmit() {
      let data = this.data;
      this.updateWarehouse({ id: this.id, data }).then(() => {
        util.showToast('Warehouse successfully changed');
        this.fetchWarehouses();
        $('#AddWarehouse').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteWarehouse(id) {
      const action = () =>  {
        this.deleteWarehouseById({id})
      }
      await util.deleteConfirmationToast(action)
      this.fetchWarehouses()
    },
  },
};
</script>
