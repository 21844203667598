<template>
  <div class="categories">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Categories</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddSubCategoryModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Sub Category
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-categories" id="datatable">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <!-- <th>Image</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category, index) in getSubCategories" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <router-link :to="`/categories/children/${category.id}`" class="text-primary">
                {{ category.name }}
              </router-link>
            </td>
            <!-- <td>
              <img
                :src="category.image"
                width="40"
                height="40"
                alt="Image Categories"
              >
            </td> -->
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editCategory(index)"
                      ><i class="icon-pencil"></i> Edit Category</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteCategory(category.id)"
                      ><i class="icon-trash"></i> Delete Category</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add SubCategory modal -->
    <div id="AddSubCategories" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Category</h5>
            <h5 class="modal-title" v-else>Edit Category</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    class="form-control"
                    v-model="subcategory.name"
                  />
                  <span v-if="errors.name" class="form-text text-danger">{{ errors.name[0] }}</span>
                </div>
              </div>
              <!-- <div class="form-group" v-if="modal == 0">
                <upload-file :max="1" maxError="Max files exceed" @changed="handleSubcategoryImages"></upload-file>
							</div>
              <div class="form-group" v-if="modal == 1">
								<label class="font-weight-semibold">Image</label>
								<upload-file :max="1" maxError="Max files exceed" @changed="handleSubcategoryImages" :imageData="[subcategory.image].filter(item => item != null)"></upload-file>
							</div> -->
            </div>
          </section>


          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addCategory() : editCategorySubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add SubCategory modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
import util from '@/helpers/util.js'
// import UploadFileVue from '@/components/UploadFile.vue';

export default {
  name: "Categories",
  components: {
    // 'upload-file': UploadFileVue,
  },
  data() {
    return {
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      id: null,
      data: {
        name: null,
        image: null,
      },
      subcategory: {
        category_id: this.$route.params.id,
        name: null,
        image: null,
      }
    }
  },
  created() {
    this.fetchSubCategoriesById({ id: this.$route.params.id })
    .then(() => {
      this.initDatatables();
    });
  },
  computed: {
    ...mapGetters({
      getCategories: "categories/getCategories",
      getSubCategories: "subcategories/getSubCategories",
    }),
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchCategories: "categories/fetchCategories",
      updateSubCategory: "subcategories/updateSubCategory",
      deleteSubCategoryById: "subcategories/deleteSubCategoryById",
      createSubCategory: "subcategories/createSubCategory",
      fetchSubCategoriesById: "subcategories/fetchSubCategoriesById",
    }),
    openAddSubCategoryModal() {
      this.modal = 0;
      this.id = null;
      this.subcategory.category_id = this.$route.params.id;
      this.subcategory.name = null;
      this.subcategory.image = null;
      $('#AddSubCategories').modal('show');
    },
    getSubCategory(id) {
      this.fetchSubCategoriesById({ id })
    },
    handleSubcategoryImages(img) {
      this.subcategory.image = img[0] ?? '';
    },
    addCategory() {
      this.createSubCategory({ data: this.subcategory }).then(() => {
        util.showToast('Category successfully added');
        $(".table-categories").DataTable().destroy()
        this.fetchSubCategoriesById({ id: this.$route.params.id })
        .then(() => {
          this.initDatatables();
        });
        $('#AddSubCategories').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editCategory(i) {
      this.modal = 1;
      let val = this.getSubCategories[i];
      this.id = val.id
      this.data.name = val.name
      this.data.image = val.image
      $('#AddSubCategories').modal('show');
    },
    editCategorySubmit() {
      let data = this.subcategory;
      this.updateSubCategory({ id: this.id, data }).then(() => {
        util.showToast('Subcategory successfully changed');
        $(".table-categories").DataTable().destroy()
        this.fetchSubCategoriesById({ id: this.$route.params.id })
        .then(() => {
          this.initDatatables();
        });
        $('#AddSubCategories').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteCategory(id) {
      const action = () =>  {
        this.deleteSubCategoryById({id})
        this.fetchSubCategoriesById({ id: this.$route.params.id })
        .then(() => {
          this.initDatatables();
        });
      }
      await util.deleteConfirmationToast(action)
    },
    initDatatables() {
      if (!$.fn.DataTable.isDataTable('.table-categories')) {
        $(".table-categories").DataTable({
          items: [{ subitems: []}],
          autoWidth: false,
          columnDefs: [
            {
              className: "control",
              orderable: false,
              targets: -1,
            },
          ],
          order: [[0, "asc"]],
          dom:
            '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
          language: {
            search: "<span>Search people:</span> _INPUT_",
            searchPlaceholder: "Type to filter...",
            lengthMenu: "<span>Show:</span> _MENU_",
            paginate: {
              first: "First",
              last: "Last",
              next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
              previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
            },
          },
          lengthMenu: [25, 50, 75, 100],
          displayLength: 50,
          // responsive: {
          //   details: {
          //     type: "column",
          //     target: -1,
          //   },
          // },
          buttons: [
            // {
            //   extend: "pdfHtml5",
            //   text: '<i class="icon-file-pdf"></i>',
            //   className: "btn bg-light",
            //   orientation: "landscape",
            //   exportOptions: {
            //     columns: [0, 1, 2, 3, 4, 5],
            //     stripHtml: true,
            //   },
            //   customize: function(doc) {
            //     doc.content[1].table.widths = Array(
            //       doc.content[1].table.body[0].length + 1
            //     )
            //       .join("*")
            //       .split("");
            //   },
            // },
          ],
        });
      }
    }
  },
};
</script>
