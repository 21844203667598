/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/r-2.2.2
 *
 * Included libraries:
 *   Responsive 2.2.2
 */

/*!
 Responsive 2.2.2
 2014-2018 SpryMedia Ltd - datatables.net/license
*/
/* eslint-disable */
(function(d) {
  "function" === typeof define && define.amd
    ? define(["jquery", "datatables.net"], function(l) {
        return d(l, window, document);
      })
    : "object" === typeof exports
    ? (module.exports = function(l, j) {
        l || (l = window);
        if (!j || !j.fn.dataTable) j = require("datatables.net")(l, j).$;
        return d(j, l, l.document);
      })
    : d(jQuery, window, document);
})(function(d, l, j, q) {
  function t(a, b, c) {
    var e = b + "-" + c;
    if (n[e]) return n[e];
    for (
      var d = [], a = a.cell(b, c).node().childNodes, b = 0, c = a.length;
      b < c;
      b++
    )
      d.push(a[b]);
    return (n[e] = d);
  }
  function r(a, b, d) {
    var e = b + "-" + d;
    if (n[e]) {
      for (
        var a = a.cell(b, d).node(),
          d = n[e][0].parentNode.childNodes,
          b = [],
          f = 0,
          g = d.length;
        f < g;
        f++
      )
        b.push(d[f]);
      d = 0;
      for (f = b.length; d < f; d++) a.appendChild(b[d]);
      n[e] = q;
    }
  }
  var o = d.fn.dataTable,
    i = function(a, b) {
      if (!o.versionCheck || !o.versionCheck("1.10.10"))
        throw "DataTables Responsive requires DataTables 1.10.10 or newer";
      this.s = { dt: new o.Api(a), columns: [], current: [] };
      this.s.dt.settings()[0].responsive ||
        (b && "string" === typeof b.details
          ? (b.details = { type: b.details })
          : b && !1 === b.details
          ? (b.details = { type: !1 })
          : b && !0 === b.details && (b.details = { type: "inline" }),
        (this.c = d.extend(!0, {}, i.defaults, o.defaults.responsive, b)),
        (a.responsive = this),
        this._constructor());
    };
  d.extend(i.prototype, {
    _constructor: function() {
      var a = this,
        b = this.s.dt,
        c = b.settings()[0],
        e = d(l).width();
      b.settings()[0]._responsive = this;
      d(l).on(
        "resize.dtr orientationchange.dtr",
        o.util.throttle(function() {
          var b = d(l).width();
          b !== e && (a._resize(), (e = b));
        })
      );
      c.oApi._fnCallbackReg(c, "aoRowCreatedCallback", function(e) {
        -1 !== d.inArray(!1, a.s.current) &&
          d(">td, >th", e).each(function(e) {
            e = b.column.index("toData", e);
            !1 === a.s.current[e] && d(this).css("display", "none");
          });
      });
      b.on("destroy.dtr", function() {
        b.off(".dtr");
        d(b.table().body()).off(".dtr");
        d(l).off("resize.dtr orientationchange.dtr");
        d.each(a.s.current, function(b, e) {
          !1 === e && a._setColumnVis(b, !0);
        });
      });
      this.c.breakpoints.sort(function(a, b) {
        return a.width < b.width ? 1 : a.width > b.width ? -1 : 0;
      });
      this._classLogic();
      this._resizeAuto();
      c = this.c.details;
      !1 !== c.type &&
        (a._detailsInit(),
        b.on("column-visibility.dtr", function() {
          a._timer && clearTimeout(a._timer);
          a._timer = setTimeout(function() {
            a._timer = null;
            a._classLogic();
            a._resizeAuto();
            a._resize();
            a._redrawChildren();
          }, 100);
        }),
        b.on("draw.dtr", function() {
          a._redrawChildren();
        }),
        d(b.table().node()).addClass("dtr-" + c.type));
      b.on("column-reorder.dtr", function() {
        a._classLogic();
        a._resizeAuto();
        a._resize();
      });
      b.on("column-sizing.dtr", function() {
        a._resizeAuto();
        a._resize();
      });
      b.on("preXhr.dtr", function() {
        var e = [];
        b.rows().every(function() {
          this.child.isShown() && e.push(this.id(true));
        });
        b.one("draw.dtr", function() {
          a._resizeAuto();
          a._resize();
          b.rows(e).every(function() {
            a._detailsDisplay(this, false);
          });
        });
      });
      b.on("init.dtr", function() {
        a._resizeAuto();
        a._resize();
        d.inArray(false, a.s.current) && b.columns.adjust();
      });
      this._resize();
    },
    _columnsVisiblity: function(a) {
      var b = this.s.dt,
        c = this.s.columns,
        e,
        f,
        g = c
          .map(function(a, b) {
            return { columnIdx: b, priority: a.priority };
          })
          .sort(function(a, b) {
            return a.priority !== b.priority
              ? a.priority - b.priority
              : a.columnIdx - b.columnIdx;
          }),
        h = d.map(c, function(e, c) {
          return !1 === b.column(c).visible()
            ? "not-visible"
            : e.auto && null === e.minWidth
            ? !1
            : !0 === e.auto
            ? "-"
            : -1 !== d.inArray(a, e.includeIn);
        }),
        m = 0;
      e = 0;
      for (f = h.length; e < f; e++) !0 === h[e] && (m += c[e].minWidth);
      e = b.settings()[0].oScroll;
      e = e.sY || e.sX ? e.iBarWidth : 0;
      m = b.table().container().offsetWidth - e - m;
      e = 0;
      for (f = h.length; e < f; e++) c[e].control && (m -= c[e].minWidth);
      var s = !1;
      e = 0;
      for (f = g.length; e < f; e++) {
        var k = g[e].columnIdx;
        "-" === h[k] &&
          !c[k].control && c[k].minWidth &&
          (s || 0 > m - c[k].minWidth ? ((s = !0), (h[k] = !1)) : (h[k] = !0),
          (m -= c[k].minWidth));
      }
      g = !1;
      e = 0;
      for (f = c.length; e < f; e++)
        if (!c[e].control && !c[e].never && !1 === h[e]) {
          g = !0;
          break;
        }
      e = 0;
      for (f = c.length; e < f; e++)
        c[e].control && (h[e] = g), "not-visible" === h[e] && (h[e] = !1);
      -1 === d.inArray(!0, h) && (h[0] = !0);
      return h;
    },
    _classLogic: function() {
      var a = this,
        b = this.c.breakpoints,
        c = this.s.dt,
        e = c
          .columns()
          .eq(0)
          .map(function(a) {
            var b = this.column(a),
              e = b.header().className,
              a = c.settings()[0].aoColumns[a].responsivePriority;
            a === q &&
              ((b = d(b.header()).data("priority")),
              (a = b !== q ? 1 * b : 1e4));
            return {
              className: e,
              includeIn: [],
              auto: !1,
              control: !1,
              never: e.match(/\bnever\b/) ? !0 : !1,
              priority: a,
            };
          }),
        f = function(a, b) {
          var c = e[a].includeIn;
          -1 === d.inArray(b, c) && c.push(b);
        },
        g = function(d, c, g, k) {
          if (g)
            if ("max-" === g) {
              k = a._find(c).width;
              c = 0;
              for (g = b.length; c < g; c++) b[c].width <= k && f(d, b[c].name);
            } else if ("min-" === g) {
              k = a._find(c).width;
              c = 0;
              for (g = b.length; c < g; c++) b[c].width >= k && f(d, b[c].name);
            } else {
              if ("not-" === g) {
                c = 0;
                for (g = b.length; c < g; c++)
                  -1 === b[c].name.indexOf(k) && f(d, b[c].name);
              }
            }
          else e[d].includeIn.push(c);
        };
      e.each(function(a, e) {
        for (
          var c = a.className.split(" "), f = !1, i = 0, l = c.length;
          i < l;
          i++
        ) {
          var j = d.trim(c[i]);
          if ("all" === j) {
            f = !0;
            a.includeIn = d.map(b, function(a) {
              return a.name;
            });
            return;
          }
          if ("none" === j || a.never) {
            f = !0;
            return;
          }
          if ("control" === j) {
            f = !0;
            a.control = !0;
            return;
          }
          d.each(b, function(a, b) {
            var d = b.name.split("-"),
              c = j.match(
                RegExp(
                  "(min\\-|max\\-|not\\-)?(" + d[0] + ")(\\-[_a-zA-Z0-9])?"
                )
              );
            c &&
              ((f = !0),
              c[2] === d[0] && c[3] === "-" + d[1]
                ? g(e, b.name, c[1], c[2] + c[3])
                : c[2] === d[0] && !c[3] && g(e, b.name, c[1], c[2]));
          });
        }
        f || (a.auto = !0);
      });
      this.s.columns = e;
    },
    _detailsDisplay: function(a, b) {
      var c = this,
        e = this.s.dt,
        f = this.c.details;
      if (f && !1 !== f.type) {
        var g = f.display(a, b, function() {
          return f.renderer(e, a[0], c._detailsObj(a[0]));
        });
        (!0 === g || !1 === g) &&
          d(e.table().node()).triggerHandler("responsive-display.dt", [
            e,
            a,
            g,
            b,
          ]);
      }
    },
    _detailsInit: function() {
      var a = this,
        b = this.s.dt,
        c = this.c.details;
      "inline" === c.type && (c.target = "td:first-child, th:first-child");
      b.on("draw.dtr", function() {
        a._tabIndexes();
      });
      a._tabIndexes();
      d(b.table().body()).on("keyup.dtr", "td, th", function(a) {
        a.keyCode === 13 && d(this).data("dtr-keyboard") && d(this).click();
      });
      var e = c.target;
      d(b.table().body()).on(
        "click.dtr mousedown.dtr mouseup.dtr",
        "string" === typeof e ? e : "td, th",
        function(c) {
          if (
            d(b.table().node()).hasClass("collapsed") &&
            d.inArray(
              d(this)
                .closest("tr")
                .get(0),
              b
                .rows()
                .nodes()
                .toArray()
            ) !== -1
          ) {
            if (typeof e === "number") {
              var g = e < 0 ? b.columns().eq(0).length + e : e;
              if (b.cell(this).index().column !== g) return;
            }
            g = b.row(d(this).closest("tr"));
            c.type === "click"
              ? a._detailsDisplay(g, false)
              : c.type === "mousedown"
              ? d(this).css("outline", "none")
              : c.type === "mouseup" &&
                d(this)
                  .blur()
                  .css("outline", "");
          }
        }
      );
    },
    _detailsObj: function(a) {
      var b = this,
        c = this.s.dt;
      return d.map(this.s.columns, function(e, d) {
        if (!e.never && !e.control)
          return {
            title: c.settings()[0].aoColumns[d].sTitle,
            data: c.cell(a, d).render(b.c.orthogonal),
            hidden: c.column(d).visible() && !b.s.current[d],
            columnIndex: d,
            rowIndex: a,
          };
      });
    },
    _find: function(a) {
      for (var b = this.c.breakpoints, c = 0, e = b.length; c < e; c++)
        if (b[c].name === a) return b[c];
    },
    _redrawChildren: function() {
      var a = this,
        b = this.s.dt;
      b.rows({ page: "current" }).iterator("row", function(c, e) {
        b.row(e);
        a._detailsDisplay(b.row(e), !0);
      });
    },
    _resize: function() {
      var a = this,
        b = this.s.dt,
        c = d(l).width(),
        e = this.c.breakpoints,
        f = e[0].name,
        g = this.s.columns,
        h,
        m = this.s.current.slice();
      for (h = e.length - 1; 0 <= h; h--)
        if (c <= e[h].width) {
          f = e[h].name;
          break;
        }
      var i = this._columnsVisiblity(f);
      this.s.current = i;
      e = !1;
      h = 0;
      for (c = g.length; h < c; h++)
        if (
          !1 === i[h] &&
          !g[h].never &&
          !g[h].control &&
          !1 === !b.column(h).visible()
        ) {
          e = !0;
          break;
        }
      d(b.table().node()).toggleClass("collapsed", e);
      var k = !1,
        j = 0;
      b.columns()
        .eq(0)
        .each(function(b, c) {
          !0 === i[c] && j++;
          i[c] !== m[c] && ((k = !0), a._setColumnVis(b, i[c]));
        });
      k &&
        (this._redrawChildren(),
        d(b.table().node()).trigger("responsive-resize.dt", [
          b,
          this.s.current,
        ]),
        0 === b.page.info().recordsDisplay &&
          d("td", b.table().body())
            .eq(0)
            .attr("colspan", j));
    },
    _resizeAuto: function() {
      var a = this.s.dt,
        b = this.s.columns;
      if (
        this.c.auto &&
        -1 !==
          d.inArray(
            !0,
            d.map(b, function(a) {
              return a.auto;
            })
          )
      ) {
        d.isEmptyObject(n) ||
          d.each(n, function(b) {
            b = b.split("-");
            r(a, 1 * b[0], 1 * b[1]);
          });
        a.table().node();
        var c = a
            .table()
            .node()
            .cloneNode(!1),
          e = d(
            a
              .table()
              .header()
              .cloneNode(!1)
          ).appendTo(c),
          f = d(a.table().body())
            .clone(!1, !1)
            .empty()
            .appendTo(c),
          g = a
            .columns()
            .header()
            .filter(function(b) {
              return a.column(b).visible();
            })
            .to$()
            .clone(!1)
            .css("display", "table-cell")
            .css("min-width", 0);
        d(f)
          .append(d(a.rows({ page: "current" }).nodes()).clone(!1))
          .find("th, td")
          .css("display", "");
        if ((f = a.table().footer())) {
          var f = d(f.cloneNode(!1)).appendTo(c),
            h = a
              .columns()
              .footer()
              .filter(function(b) {
                return a.column(b).visible();
              })
              .to$()
              .clone(!1)
              .css("display", "table-cell");
          d("<tr/>")
            .append(h)
            .appendTo(f);
        }
        d("<tr/>")
          .append(g)
          .appendTo(e);
        "inline" === this.c.details.type &&
          d(c).addClass("dtr-inline collapsed");
        d(c)
          .find("[name]")
          .removeAttr("name");
        d(c).css("position", "relative");
        c = d("<div/>")
          .css({ width: 1, height: 1, overflow: "hidden", clear: "both" })
          .append(c);
        c.insertBefore(a.table().node());
        g.each(function(c) {
          c = a.column.index("fromVisible", c);
          b[c].minWidth = this.offsetWidth || 0;
        });
        c.remove();
      }
    },
    _setColumnVis: function(a, b) {
      var c = this.s.dt,
        e = b ? "" : "none";
      d(c.column(a).header()).css("display", e);
      d(c.column(a).footer()).css("display", e);
      c.column(a)
        .nodes()
        .to$()
        .css("display", e);
      d.isEmptyObject(n) ||
        c
          .cells(null, a)
          .indexes()
          .each(function(a) {
            r(c, a.row, a.column);
          });
    },
    _tabIndexes: function() {
      var a = this.s.dt,
        b = a
          .cells({ page: "current" })
          .nodes()
          .to$(),
        c = a.settings()[0],
        e = this.c.details.target;
      b.filter("[data-dtr-keyboard]").removeData("[data-dtr-keyboard]");
      "number" === typeof e
        ? a
            .cells(null, e, { page: "current" })
            .nodes()
            .to$()
            .attr("tabIndex", c.iTabIndex)
            .data("dtr-keyboard", 1)
        : ("td:first-child, th:first-child" === e &&
            (e = ">td:first-child, >th:first-child"),
          d(e, a.rows({ page: "current" }).nodes())
            .attr("tabIndex", c.iTabIndex)
            .data("dtr-keyboard", 1));
    },
  });
  i.breakpoints = [
    { name: "desktop", width: Infinity },
    { name: "tablet-l", width: 1024 },
    { name: "tablet-p", width: 768 },
    { name: "mobile-l", width: 480 },
    { name: "mobile-p", width: 320 },
  ];
  i.display = {
    childRow: function(a, b, c) {
      if (b) {
        if (d(a.node()).hasClass("parent"))
          return a.child(c(), "child").show(), !0;
      } else {
        if (a.child.isShown())
          return a.child(!1), d(a.node()).removeClass("parent"), !1;
        a.child(c(), "child").show();
        d(a.node()).addClass("parent");
        return !0;
      }
    },
    childRowImmediate: function(a, b, c) {
      if ((!b && a.child.isShown()) || !a.responsive.hasHidden())
        return a.child(!1), d(a.node()).removeClass("parent"), !1;
      a.child(c(), "child").show();
      d(a.node()).addClass("parent");
      return !0;
    },
    modal: function(a) {
      return function(b, c, e) {
        if (c)
          d("div.dtr-modal-content")
            .empty()
            .append(e());
        else {
          var f = function() {
              g.remove();
              d(j).off("keypress.dtr");
            },
            g = d('<div class="dtr-modal"/>')
              .append(
                d('<div class="dtr-modal-display"/>')
                  .append(d('<div class="dtr-modal-content"/>').append(e()))
                  .append(
                    d('<div class="dtr-modal-close">&times;</div>').click(
                      function() {
                        f();
                      }
                    )
                  )
              )
              .append(
                d('<div class="dtr-modal-background"/>').click(function() {
                  f();
                })
              )
              .appendTo("body");
          d(j).on("keyup.dtr", function(a) {
            27 === a.keyCode && (a.stopPropagation(), f());
          });
        }
        a &&
          a.header &&
          d("div.dtr-modal-content").prepend("<h2>" + a.header(b) + "</h2>");
      };
    },
  };
  var n = {};
  i.renderer = {
    listHiddenNodes: function() {
      return function(a, b, c) {
        var e = d('<ul data-dtr-index="' + b + '" class="dtr-details"/>'),
          f = !1;
        d.each(c, function(b, c) {
          c.hidden &&
            (d(
              '<li data-dtr-index="' +
                c.columnIndex +
                '" data-dt-row="' +
                c.rowIndex +
                '" data-dt-column="' +
                c.columnIndex +
                '"><span class="dtr-title">' +
                c.title +
                "</span> </li>"
            )
              .append(
                d('<span class="dtr-data"/>').append(
                  t(a, c.rowIndex, c.columnIndex)
                )
              )
              .appendTo(e),
            (f = !0));
        });
        return f ? e : !1;
      };
    },
    listHidden: function() {
      return function(a, b, c) {
        return (a = d
          .map(c, function(a) {
            return a.hidden
              ? '<li data-dtr-index="' +
                  a.columnIndex +
                  '" data-dt-row="' +
                  a.rowIndex +
                  '" data-dt-column="' +
                  a.columnIndex +
                  '"><span class="dtr-title">' +
                  a.title +
                  '</span> <span class="dtr-data">' +
                  a.data +
                  "</span></li>"
              : "";
          })
          .join(""))
          ? d('<ul data-dtr-index="' + b + '" class="dtr-details"/>').append(a)
          : !1;
      };
    },
    tableAll: function(a) {
      a = d.extend({ tableClass: "" }, a);
      return function(b, c, e) {
        b = d
          .map(e, function(a) {
            return (
              '<tr data-dt-row="' +
              a.rowIndex +
              '" data-dt-column="' +
              a.columnIndex +
              '"><td>' +
              a.title +
              ":</td> <td>" +
              a.data +
              "</td></tr>"
            );
          })
          .join("");
        return d(
          '<table class="' + a.tableClass + ' dtr-details" width="100%"/>'
        ).append(b);
      };
    },
  };
  i.defaults = {
    breakpoints: i.breakpoints,
    auto: !0,
    details: {
      display: i.display.childRow,
      renderer: i.renderer.listHidden(),
      target: 0,
      type: "inline",
    },
    orthogonal: "display",
  };
  var p = d.fn.dataTable.Api;
  p.register("responsive()", function() {
    return this;
  });
  p.register("responsive.index()", function(a) {
    a = d(a);
    return { column: a.data("dtr-index"), row: a.parent().data("dtr-index") };
  });
  p.register("responsive.rebuild()", function() {
    return this.iterator("table", function(a) {
      a._responsive && a._responsive._classLogic();
    });
  });
  p.register("responsive.recalc()", function() {
    return this.iterator("table", function(a) {
      a._responsive && (a._responsive._resizeAuto(), a._responsive._resize());
    });
  });
  p.register("responsive.hasHidden()", function() {
    var a = this.context[0];
    return a._responsive ? -1 !== d.inArray(!1, a._responsive.s.current) : !1;
  });
  p.registerPlural(
    "columns().responsiveHidden()",
    "column().responsiveHidden()",
    function() {
      return this.iterator(
        "column",
        function(a, b) {
          return a._responsive ? a._responsive.s.current[b] : !1;
        },
        1
      );
    }
  );
  i.version = "2.2.2";
  d.fn.dataTable.Responsive = i;
  d.fn.DataTable.Responsive = i;
  d(j).on("preInit.dt.dtr", function(a, b) {
    if (
      "dt" === a.namespace &&
      (d(b.nTable).hasClass("responsive") ||
        d(b.nTable).hasClass("dt-responsive") ||
        b.oInit.responsive ||
        o.defaults.responsive)
    ) {
      var c = b.oInit.responsive;
      !1 !== c && new i(b, d.isPlainObject(c) ? c : {});
    }
  });
  return i;
});
