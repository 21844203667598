import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  featured_products: [],
  featured_product: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getFeaturedProducts: (state) => {
    return state.featured_products;
  },
  getFeaturedProduct: (state) => {
    return state.featured_product;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_FEATURED_PRODUCTS: (state, data) => (state.featured_products = data),
  SET_FEATURED_PRODUCT: (state, data) => (state.featured_product = data),
};

const actions = {
  async fetchFeaturedProducts({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`featured-products`);
      commit("SET_FEATURED_PRODUCTS", response.data.featured_products);
    } catch (error) {
      console.log(error);
      commit("SET_FEATURED_PRODUCTS", {});
    }
    commit("SET_LOADER", false);
  },
  async createFeaturedProducts({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("featured-products", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateFeaturedProducts({ commit }, { id, data }) {
    let response = await ApiService.put(`featured-products/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteFeaturedProductsById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`featured-products/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
