/* ------------------------------------------------------------------------------
 *
 *  # LegitRipple
 *
 * ---------------------------------------------------------------------------- */
/* eslint-disable */
!(function(t) {
  (t.fn.ripple = function(e) {
    if (this.length > 1)
      return this.each(function(n, i) {
        t(i).ripple(e);
      });
    if (((e = e || {}), this.off(".ripple").data("unbound", !0), e.unbind))
      return this;
    var n = function() {
      return d && !d.data("unbound");
    };
    this.addClass(
      $(this).is(":empty") ? "legitRipple legitRipple-empty" : "legitRipple"
    )
      .removeData("unbound")
      .on("tap.ripple", function(e) {
        n() || ((d = t(this)), w(e.coords));
      })
      .on("dragstart.ripple", function(t) {
        g.allowDragging || t.preventDefault();
      }),
      t(document)
        .on("move.ripple", function(t) {
          n() && b(t.coords);
        })
        .on("end.ripple", function() {
          n() && y();
        }),
      t(window).on("scroll.ripple", function(t) {
        n() && y();
      });
    var i,
      o,
      a,
      r,
      s = function(t) {
        return !!t.type.match(/^touch/);
      },
      l = function(t, e) {
        return s(t) && (t = c(t.originalEvent.touches, e)), [t.pageX, t.pageY];
      },
      c = function(e, n) {
        return t.makeArray(e).filter(function(t, e) {
          return t.identifier == n;
        })[0];
      },
      p = 0,
      u = function(t) {
        "touchstart" == t.type && (p = 3), "scroll" == t.type && (p = 0);
        var e = p && !s(t);
        return e && p--, !e;
      };
    this.on("mousedown.ripple touchstart.ripple", function(e) {
      u(e) &&
        ((i = s(e) ? e.originalEvent.changedTouches[0].identifier : -1),
        (o = t(this)),
        (a = t.Event("tap", { coords: l(e, i) })),
        ~i
          ? (r = setTimeout(function() {
              o.trigger(a), (r = null);
            }, g.touchDelay))
          : o.trigger(a));
    }),
      t(document)
        .on(
          "mousemove.ripple touchmove.ripple mouseup.ripple touchend.ripple touchcancel.ripple",
          function(e) {
            var n = e.type.match(/move/);
            r && !n && (clearTimeout(r), (r = null), o.trigger(a)),
              u(e) &&
                (s(e) ? c(e.originalEvent.changedTouches, i) : !~i) &&
                t(this).trigger(
                  n ? t.Event("move", { coords: l(e, i) }) : "end"
                );
          }
        )
        .on("contextmenu.ripple", function(t) {
          u(t);
        })
        .on("touchmove", function() {
          clearTimeout(r), (r = null);
        });
    var d,
      f,
      h,
      m,
      g = {},
      v = 0,
      x = function() {
        var n = {
          fixedPos: null,
          get dragging() {
            return !g.fixedPos;
          },
          get adaptPos() {
            return g.dragging;
          },
          get maxDiameter() {
            return (
              (Math.sqrt(Math.pow(h[0], 2) + Math.pow(h[1], 2)) /
                d.outerWidth()) *
                Math.ceil(g.adaptPos ? 100 : 200) +
              "%"
            );
          },
          scaleMode: "fixed",
          template: null,
          allowDragging: !1,
          touchDelay: 100,
          callback: null,
        };
        t.each(n, function(t, n) {
          g[t] = t in e ? e[t] : n;
        });
      },
      w = function(e) {
        (h = [d.outerWidth(), d.outerHeight()]),
          x(),
          (m = e),
          (f = t("<div/>").addClass("legitRipple-ripple")),
          g.template &&
            f
              .append(
                ("object" == typeof g.template
                  ? g.template
                  : d.children(".legitRipple-template").last()
                )
                  .clone()
                  .removeClass("legitRipple-template")
              )
              .addClass("legitRipple-custom"),
          f.appendTo(d),
          D(e, !1);
        var n = f.css("transition-duration").split(","),
          i = [parseFloat(n[0]) + "s"].concat(n.slice(1)).join(",");
        f.css("transition-duration", i).css("width", g.maxDiameter),
          f.on("transitionend webkitTransitionEnd oTransitionEnd", function() {
            t(this).data("oneEnded")
              ? t(this)
                  .off()
                  .remove()
              : t(this).data("oneEnded", !0);
          });
      },
      b = function(t) {
        var e;
        if ((v++, "proportional" === g.scaleMode)) {
          var n = Math.pow(v, (v / 100) * 0.6);
          e = n > 40 ? 40 : n;
        } else if ("fixed" == g.scaleMode && Math.abs(t[1] - m[1]) > 6)
          return void y();
        D(t, e);
      },
      y = function() {
        f
          .css("width", f.css("width"))
          .css("transition", "none")
          .css("transition", "")
          .css("width", f.css("width"))
          .css("width", g.maxDiameter)
          .css("opacity", "0"),
          (d = null),
          (v = 0);
      },
      D = function(e, n) {
        var i = [],
          o =
            g.fixedPos === !0
              ? [0.5, 0.5]
              : [
                  (g.fixedPos ? g.fixedPos[0] : e[0] - d.offset().left) / h[0],
                  (g.fixedPos ? g.fixedPos[1] : e[1] - d.offset().top) / h[1],
                ],
          a = [0.5 - o[0], 0.5 - o[1]],
          r = [
            100 / parseFloat(g.maxDiameter),
            (100 / parseFloat(g.maxDiameter)) * (h[1] / h[0]),
          ],
          s = [a[0] * r[0], a[1] * r[1]],
          l = g.dragging || 0 === v;
        if (l && "inline" == d.css("display")) {
          var c = t("<div/>")
              .text("Hi!")
              .css("font-size", 0)
              .prependTo(d),
            p = c.offset().left;
          c.remove(), (i = [e[0] - p + "px", e[1] - d.offset().top + "px"]);
        }
        l &&
          f
            .css("left", i[0] || 100 * o[0] + "%")
            .css("top", i[1] || 100 * o[1] + "%"),
          f.css(
            "transform",
            "translate3d(-50%, -50%, 0)" +
              (g.adaptPos
                ? "translate3d(" + 100 * s[0] + "%, " + 100 * s[1] + "%, 0)"
                : "") +
              (n ? "scale(" + n + ")" : "")
          ),
          g.callback && g.callback(d, f, o, g.maxDiameter);
      };
    return this;
  }),
    (t.ripple = function(e) {
      t.each(e, function(e, n) {
        t(e).ripple(n);
      });
    }),
    (t.ripple.destroy = function() {
      t(".legitRipple")
        .removeClass("legitRipple legitRipple-empty")
        .add(window)
        .add(document)
        .off(".ripple"),
        t(".legitRipple-ripple").remove();
    });
})(jQuery);

// Initialization
// ------------------------------

window.addEventListener("load", function() {
  // Ripple effect
  $(
    ".ripple-dark:not(.disabled), .sp-replacer, .list-group-item-action:not(.disabled), .ripple-light:not(.disabled), .btn:not(.disabled):not(.multiselect.btn-light):not(.file-drag-handle), .nav:not(.nav-tabs) .nav-link:not(.disabled), .navbar-nav-link:not(.disabled), .sidebar-user-material-footer > a, .select2-selection--single[class*=bg-], .wizard > .actions a, .ui-button:not(.ui-dialog-titlebar-close):not(.ui-selectmenu-button), .ui-controlgroup .ui-selectmenu-button, .ui-tabs-anchor:not(.ui-state-disabled), .plupload_button:not(.plupload_disabled), .fc-button, .page-item:not(.disabled) > .page-link"
  ).ripple({
    dragging: false,
    adaptPos: false,
    scaleMode: false,
  });
});
/* eslint-disable */
