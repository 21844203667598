import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  hotdeals: [],
  hotdeal: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getHotdeals: (state) => {
    return state.hotdeals;
  },
  getHotdeal: (state) => {
    return state.hotdeal;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_HOTDEALS: (state, data) => (state.hotdeals = data),
  SET_HOTDEAL: (state, data) => (state.hotdeal = data),
};

const actions = {
  async fetchHotdeals({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`hotdeals`);
      commit("SET_HOTDEALS", response.data.hotdeals.filter(item => item.product != null));
    } catch (error) {
      console.log(error);
      commit("SET_HOTDEALS", {});
    }
    commit("SET_LOADER", false);
  },
  async createHotdeals({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("hotdeals", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateHotdeals({ commit }, { id, data }) {
    let response = await ApiService.put(`hotdeals/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteHotdealsById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`hotdeals/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
