<template>
  <div class="blogs">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">Blogs</h6>
        <div>
          <button
            type="button"
            class="btn btn-transparent btn-sm mr-2"
            data-toggle="modal"
            @click="openAddBlogModal"
          >
            <b><i class="icon-plus-circle2 mr-2"></i></b> Add Blogs
          </button>
        </div>
      </div>

      <table class="table text-nowrap table-blogs" id="datatable">
        <thead>
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Image</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(blog, index) in getBlogs" :key="index">
            <td>{{ blog.title }}</td>
            <td>{{ blog.author }}</td>
            <td>
              <img
                :src="blog.thumbnail"
                width="40"
                height="40"
                alt=""
              >
            </td>
            <td class="text-right">
              <div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu7"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item" @click="editBlog(index)"
                      ><i class="icon-pencil"></i> Edit Blog</a
                    >
                    <a href="#" class="dropdown-item" @click="deleteBlog(blog.id)"
                      ><i class="icon-trash"></i> Delete Blog</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add Blog modal -->
    <div id="AddBlogs" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <h5 class="modal-title" v-if="modal == 0">Add Blog</h5>
            <h5 class="modal-title" v-else>Edit Blog</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <section>
            <div class="modal-body py-0">
              <div class="form-group">
                <div class="row">
                  <label>Title</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    class="form-control"
                    v-model="data.title"
                  />
                  <span v-if="errors.title" class="form-text text-danger">{{ errors.title[0] }}</span>
                </div>
              </div>
              <div class="form-group">
                  <label>Content</label>
                <div class="row d-block">
                  <ckeditor :editor="editor" v-model="data.content" :config="editorConfig"></ckeditor>
                  <span v-if="errors.content" class="form-text text-danger">{{
                    errors.content[0]
                  }}</span>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <label>Author</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    class="form-control"
                    v-model="data.author"
                  />
                  <span v-if="errors.author" class="form-text text-danger">{{ errors.author[0] }}</span>
                </div>
              </div>
              <div class="form-group" v-if="modal == 0">
                <upload-file :max="1" maxError="Max files exceed" @changed="handleImages"></upload-file>
							</div>
              <div class="form-group" v-if="modal == 1">
								<label class="font-weight-semibold">Thumbnail</label>
								<upload-file :max="1" maxError="Max files exceed" @changed="handleImages" :imageData="[data.thumbnail].filter(item => item != null)"></upload-file>
							</div>
              <span v-if="errors.thumbnail" class="form-text text-danger">{{ errors.thumbnail[0] }}</span>

              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Meta Title</label>
                    <input
                      type="text"
                      placeholder="Enter Meta"
                      class="form-control"
                      v-model="data.meta_title"
                    />
                    <small :class="data.meta_title?.length <= 65 ? 'text-success' : 'text-danger'">
                      Text Count: {{ data.meta_title?.length ?? 0 }}
                    </small>
                    <span v-if="errors.meta_title" class="form-text text-danger">{{ errors.meta_title[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>Meta Description</label>
                    <input
                      type="text"
                      placeholder="Enter Meta Description"
                      class="form-control"
                      v-model="data.meta_description"
                    />
                    <small :class="data.meta_description?.length <= 165 ? 'text-success' : 'text-danger'">
                      Text Count: {{ data.meta_description?.length ?? 0 }}
                    </small>
                    <span v-if="errors.meta_description" class="form-text text-danger">{{ errors.meta_description[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="modal-footer pt-3">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary" @click="modal == 0 ? addBlog() : editBlogSubmit()">
              Submit <i class="icon-paperplane ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Blog modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Uniform from "@/assets/js/plugins/forms/styling/uniform.min.js";
import Datatables from "@/assets/js/plugins/tables/datatables/datatables.min.js";
import Select2 from "@/assets/js/plugins/forms/selects/select2.min.js";
import Jszip from "@/assets/js/plugins/tables/datatables/extensions/jszip/jszip.min.js";
import Pdfmake from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/pdfmake.min.js";
import Vfs from "@/assets/js/plugins/tables/datatables/extensions/pdfmake/vfs_fonts.min.js";
import Buttons from "@/assets/js/plugins/tables/datatables/extensions/buttons.min.js";
import Responsive from "@/assets/js/plugins/tables/datatables/extensions/responsive.min.js";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
// import ApiService from "@/services/api.service";
import util from '@/helpers/util.js'
import UploadFileVue from '@/components/UploadFile.vue';
import UploadAdapter from '@/components/UploadAdapter.js';

export default {
  name: "Blogs",
  components: {
    'upload-file': UploadFileVue,
  },
  data() {
    return {
      editor: null,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true
        },
        extraPlugins: [function (editor) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader);
          }
        }],
        mediaEmbed: {
          previewsInData: true
        },
        indentBlock: {
          offset: 1,
          unit: 'em'
        }
      },
      modal: 0, // 0 = Add || 1 = Edit
      errors: [],
      selectedIds: [],
      id: null,
      data: {
        title: null,
        content: '',
        author: null,
        thumbnail: null,
        meta_title: null,
        meta_description: null,
      },
    }
  },
  created() {
    this.editor = !this.editor && ClassicEditor;
    this.fetchBlogs();
  },
  computed: {
    ...mapGetters({
      getBlogs: "blogs/getBlogs",
    }),
  },
  updated() {
    if (!$.fn.DataTable.isDataTable('.table-blogs')) {
      $(".table-blogs").DataTable({
        autoWidth: false,
        columnDefs: [
          {
            orderable: false,
            targets: 2,
          },
          {
            className: "control",
            orderable: false,
            targets: -1,
          },
        ],
        order: [[0, "asc"]],
        dom:
          '<"datatable-header datatable-header-accent"fBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: "<span>Search people:</span> _INPUT_",
          searchPlaceholder: "Type to filter...",
          lengthMenu: "<span>Show:</span> _MENU_",
          paginate: {
            first: "First",
            last: "Last",
            next: $("html").attr("dir") == "rtl" ? "&larr;" : "&rarr;",
            previous: $("html").attr("dir") == "rtl" ? "&rarr;" : "&larr;",
          },
        },
        lengthMenu: [25, 50, 75, 100],
        displayLength: 50,
        // responsive: {
        //   details: {
        //     type: "column",
        //     target: -1,
        //   },
        // },
        buttons: [
          // {
          //   extend: "pdfHtml5",
          //   text: '<i class="icon-file-pdf"></i>',
          //   className: "btn bg-light",
          //   orientation: "landscape",
          //   exportOptions: {
          //     columns: [0, 1, 2, 3, 4, 5],
          //     stripHtml: true,
          //   },
          //   customize: function(doc) {
          //     doc.content[1].table.widths = Array(
          //       doc.content[1].table.body[0].length + 1
          //     )
          //       .join("*")
          //       .split("");
          //   },
          // },
        ],
      });
    }
  },
  mounted() {
    Uniform;
    $(".form-check-input-styled-primary").uniform({
      wrapperClass: "border-primary text-primary",
    });
    $(".form-control-uniform").uniform();
    $(".daterange-single").daterangepicker({
      singleDatePicker: true,
    });
    Datatables;
    Select2;
    Jszip;
    Pdfmake;
    Vfs;
    Buttons;
    Responsive;
    $(".dataTables_length select").select2({
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: "auto",
    });
  },
  methods: {
    ...mapActions({
      fetchBlogs: "blogs/fetchBlogs",
      createBlog: "blogs/createBlog",
      updateBlog: "blogs/updateBlog",
      deleteBlogById: "blogs/deleteBlogById",
    }),
    openAddBlogModal() {
      this.id = null;
      this.data.title = null;
      this.data.content = '';
      this.data.author = null;
      this.data.thumbnail = null;
      this.data.meta_title = null;
      this.data.meta_description = null;
      this.modal = 0;
      $('#AddBlogs').modal('show');
    },
    handleImages(img) {
      this.data.thumbnail = img[0] ?? '';
    },
    addBlog() {
      this.createBlog({ data: this.data })
      .then(() => {
        util.showToast('Blog successfully added');
        this.fetchBlogs();
        $('#AddBlogs').modal('hide');
      }).catch(err => {
        console.log(err.response.data)
        this.errors = err.response.data.errors;
      });
    },
    editBlog(i) {
      this.modal = 1;
      let val = this.getBlogs[i];
      this.id = val.id
      this.data.title = val.title
      this.data.content = val.content ?? ''
      this.data.author = val.author
      this.data.thumbnail = val.thumbnail
      this.data.meta_title = val.meta_title
      this.data.meta_description = val.meta_description
      $('#AddBlogs').modal('show');
    },
    editBlogSubmit() {
      let data = this.data;
      this.updateBlog({ id: this.id, data }).then(() => {
        util.showToast('Blog successfully changed');
        this.fetchBlogs();
        $('#AddBlogs').modal('hide');
      }).catch(err => {
        console.log(err)
        this.errors = err.response.data.errors;
      });
    },
    async deleteBlog(id) {
      const action = () =>  {
        this.deleteBlogById({id})
        this.fetchBlogs()
      }
      await util.deleteConfirmationToast(action)
    },
  },
};
</script>
