import moment, { MomentInput } from 'moment'

const formatMoney = (payload: number): string => {
  return payload.toLocaleString("id-ID");
};

const sum = (arr: number[]): number =>
  arr.reduce((partial_sum, a) => partial_sum + a, 0);

const formatDate = (myDate: MomentInput, format: string = 'DD-MM-YYYY'): string => moment(myDate).format(format)
  
export { formatMoney, sum, formatDate };
