import { createStore } from "vuex";

import auth from "./modules/auth.module";
import warehouses from "./modules/warehouses.module";
import units from "./modules/units.module";
import blogs from "./modules/blogs.module";
import brands from "./modules/brands.module";
import colors from "./modules/colors.module";
import coupons from "./modules/coupons.module";
import categories from "./modules/categories.module";
import credits from "./modules/credits.module";
import subcategories from "./modules/subcategories.module";
import products from "./modules/products.module";
import hotdeals from "./modules/hotdeals.module";
import banners from "./modules/banners.module";
import bestsellers from "./modules/best-sellers.module";
import testimonials from "./modules/testimonials.module";
import newarrivals from "./modules/new-arrivals.module";
import featuredProducts from "./modules/featured_products.module";
import tradeAccount from "./modules/trade-account.module";
import clients from "./modules/clients.module";
import staff from "./modules/staff.module";
import timesheet from "./modules/timesheet.module";
import tickets from "./modules/tickets.module";
import roles from "./modules/roles.module";
import permissions from "./modules/permissions.module";
import invoices from "./modules/invoices.module";
import membershiprules from "./modules/membership-rules.module";
import memberships from "./modules/memberships.module";
import quotations from "./modules/quotations.module";
import points from "./modules/points.module";
import purchases from "./modules/purchases.module";
import suppliers from "./modules/suppliers.module";
import refunds from "./modules/refunds.module";
import stockManagement from "./modules/stock-management.module"
import warehouseReport from './modules/warehouseReport'
import pages from './modules/pages.module'
import settings from './modules/settings.module'

export default createStore({
  modules: {
    auth,
    warehouses,
    units,
    blogs,
    brands,
    colors,
    coupons,
    categories,
    credits,
    subcategories,
    products,
    hotdeals,
    banners,
    bestsellers,
    testimonials,
    newarrivals,
    featuredProducts,
    tradeAccount,
    clients,
    staff,
    timesheet,
    tickets,
    roles,
    permissions,
    invoices,
    memberships,
    membershiprules,
    quotations,
    points,
    purchases,
    suppliers,
    refunds,
    stockManagement,
    warehouseReport,
    pages,
    settings,
  },
});
