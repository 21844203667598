import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  categories: [],
  subcategories: [],
  category: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getCategories: (state) => {
    return state.categories;
  },
  getCategory: (state) => {
    return state.category;
  },
  getSubCategories: (state) => {
    return state.subcategories;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_CATEGORIES: (state, data) => (state.categories = data),
  SET_SUBCATEGORIES: (state, data) => (state.subcategories = data),
  SET_CATEGORY: (state, data) => (state.category = data),
};

const actions = {
  async fetchCategories({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`categories`);
      commit("SET_CATEGORIES", response.data.categories);
    } catch (error) {
      console.log(error);
      commit("SET_CATEGORIES", {});
    }
    commit("SET_LOADER", false);
  },
  async fetchSubCategoriesById({ commit }, { id }) {
    commit("SET_LOADER", true);
    let response = await ApiService.get(`categories/get-subcategory/${id}`);
    commit("SET_SUBCATEGORIES", response.data.subcategories);
    commit("SET_LOADER", false);
    return response.data
  },
  async createCategory({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("categories", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateCategory({ commit }, { id, data }) {
    console.log('response teesss')
    let response = await ApiService.put(`categories/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteCategoryById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`categories/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
