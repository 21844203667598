import ApiService from "@/services/api.service";

const state = {
  isLoading: false,
  new_arrivals: [],
  new_arrival: {},
};

const getters = {
  getLoading: (state) => {
    return state.isLoading;
  },
  getNewArrivals: (state) => {
    return state.new_arrivals;
  },
  getNewArrival: (state) => {
    return state.new_arrival;
  },
};

const mutations = {
  SET_LOADER: (state, status) => (state.isLoading = status),
  SET_NEW_ARRIVALS: (state, data) => (state.new_arrivals = data),
  SET_NEW_ARRIVAL: (state, data) => (state.new_arrival = data),
};

const actions = {
  async fetchNewArrivals({ commit }) {
    commit("SET_LOADER", true);
    try {
      let response = await ApiService.get(`new-arrivals`);
      commit("SET_NEW_ARRIVALS", response.data.new_arrivals);
    } catch (error) {
      console.log(error);
      commit("SET_NEW_ARRIVALS", {});
    }
    commit("SET_LOADER", false);
  },
  async createNewArrivals({ commit }, { data }) {
    commit("SET_LOADER", true);
    let response = ApiService.post("new-arrivals", { ...data });
    commit("SET_LOADER", false);
    return response;
  },
  async updateNewArrivals({ commit }, { id, data }) {
    let response = await ApiService.put(`new-arrivals/${id}`, { ...data });
    commit("SET_LOADER", true);
    console.log(response)
    commit("SET_LOADER", false);
    return response;
  },
  async deleteNewArrivalsById({ commit }, { id }) {
    commit("SET_LOADER", true);
    try {
      await ApiService.delete(`new-arrivals/${id}`);
    } catch (error) {
      console.log(error);
    }
    commit("SET_LOADER", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
